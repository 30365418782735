.loading-parent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display:  flex;
    justify-content: center;
    align-items: center;
    background-color: #0000006c;
    visibility: hidden;
    opacity: 0;
    z-index: 99999999;
}
/* top-0 left-0 w-full h-[100vh] flex flex-col justify-center items-center transition-all bg-[#00000002] z-[999999999999]  duration-500 */
.loading-parent.active {
    visibility: visible;
    opacity: 1;
}
.loader {
    --dim: 3rem;
    width: var(--dim);
    height: var(--dim);
    position: relative;
    animation: spin988 2s linear infinite;
  }
  
  .loader .circle {
    --color: #ff3c26;
    --dim: 1.2rem;
    width: var(--dim);
    height: var(--dim);
    background-color: var(--color);
    border-radius: 50%;
    position: absolute;
  }
  
  .loader .circle:nth-child(1) {
    top: 0;
    left: 0;
  }
  
  .loader .circle:nth-child(2) {
    top: 0;
    right: 0;
  }
  
  .loader .circle:nth-child(3) {
    bottom: 0;
    left: 0;
  }
  
  .loader .circle:nth-child(4) {
    bottom: 0;
    right: 0;
  }
  
  @keyframes spin988 {
    0% {
      transform: scale(1) rotate(0);
    }
  
    20%, 25% {
      transform: scale(1.3) rotate(90deg);
    }
  
    45%, 50% {
      transform: scale(1) rotate(180deg);
    }
  
    70%, 75% {
      transform: scale(1.3) rotate(270deg);
    }
  
    95%, 100% {
      transform: scale(1) rotate(360deg);
    }
  }