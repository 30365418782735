#subdescription-textarea{
    resize: none;
}


.create-course-lessons {
    min-height: 50vh;
    position: relative;
}

#add-section-btn-container{
    width: 100%;
    position: relative;
    bottom: 0;
    margin: 2rem 0;
}
#add-section-btn-container button{
    width: 10rem;
    margin: 0;
}

/* .create-course-lessons .accordion-header button{
    justify-content: space-between;
}
.create-course-lessons .accordion-header button .dropdown{
    padding: 1rem ;
    z-index: 1000;
} */
/* .section-lessons-quizes .lesson ,
.section-lessons-quizes .quize {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background-color: #fff;
    padding: 1rem;
border-radius: 5px;
} */

.custom-upload-file label{
    width: 100%;
    border-radius: 10px;
    text-align: center;
    padding: 2rem;
    background-color: lightblue !important;
    color: black;
    border: 3px dashed blue !important;
    font-size: 1rem;
    opacity: 0.5;
    overflow: hidden;
    cursor: pointer;
}