/*
    Table of Contents

    Name                 : MAXSKILLS
    Author               : DexignLab
    Author Portfolio     : https://themeforest.net/user/dexignlab/portfolio
    


    Table of Sass Contents

    1. Abstracts
        _bs-custom
        _inheritance
        _maps
        _mixin
        _variable

    2. bootstrap 
        -Here goes all official bootstrap scss files

    3. Global/Base
        _color
        _custom-grid
        _fonts
        _helper
        _reset

    4. layout
        +footer
        +header
        +rtl
        +sidebar
        +tables
        +theme
        +version-dark

    5. Conponents
        +app
        +charts
        +forms
        +map
        +uc
        +ui
        +widget

    6. Page
        _index-1
        _page-error
        _page-register
        _page-signin
        _page-user-lock
        _page-user-lock

*/
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");
@import url("https://fonts.googleapis.com/css?family=Nunito:400,600,700");
@import url("./../icons/simple-line-icons/css/simple-line-icons.css");
@import url("./../icons/fontawesome6/css/all.min.css");
@import url("./../icons/fontawesome/css/all.min.css");
@import url("./../icons/material-design-iconic-font/css/materialdesignicons.min.css");
@import url("./../icons/themify-icons/css/themify-icons.css");
@import url("./../icons/line-awesome/css/line-awesome.min.css");
@import url("./../icons/avasta/css/style.css");
@import url("./../icons/flaticon/flaticon.css");
@import url("./../icons/flaticon_1/flaticon_1.css");
@import url("./../icons/icomoon/icomoon.css");
@import url("./../icons/bootstrap-icons/font/bootstrap-icons.css");
@import url(./../vendor/animate/animate.min.css);
@import url(./../vendor/aos/css/aos.min.css);
@import url(./../vendor/perfect-scrollbar/css/perfect-scrollbar.css);
@import url(./../vendor/metismenu/css/metisMenu.min.css);

:root {
  /* --primary: #FF6A59; */
  --primary: #453773;
  --secondary: #4CBC9A;
  --secondary-dark: #3a9b7e;
  --primary-hover: #ff3c26;
  --primary-dark: #bf1400;
  --rgba-primary-1: rgba(255, 106, 89, 0.1);
  --rgba-primary-2: rgba(255, 106, 89, 0.2);
  --rgba-primary-3: rgba(255, 106, 89, 0.3);
  --rgba-primary-4: rgba(255, 106, 89, 0.4);
  --rgba-primary-5: rgba(255, 106, 89, 0.5);
  --rgba-primary-6: rgba(255, 106, 89, 0.6);
  --rgba-primary-7: rgba(255, 106, 89, 0.7);
  --rgba-primary-8: rgba(255, 106, 89, 0.8);
  --rgba-primary-9: rgba(255, 106, 89, 0.9);
  --rgba-secondary-1: rgba(76, 188, 154, 0.1);
  --rgba-secondary-2: rgba(76, 188, 154, 0.2);
  --rgba-secondary-3: rgba(76, 188, 154, 0.3);
  --rgba-secondary-4: rgba(76, 188, 154, 0.4);
  --rgba-secondary-5: rgba(76, 188, 154, 0.5);
  --rgba-secondary-6: rgba(76, 188, 154, 0.6);
  --rgba-secondary-7: rgba(76, 188, 154, 0.7);
  --rgba-secondary-8: rgba(76, 188, 154, 0.8);
  --rgba-secondary-9: rgba(76, 188, 154, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #374557;
}

.gradient_one {
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
}

.gradient-1 {
  background: #f0a907;
  background: -moz-linear-gradient(top, #f0a907 0%, #f53c79 100%);
  background: -webkit-linear-gradient(top, #f0a907 0%, #f53c79 100%);
  background: linear-gradient(to bottom, #f0a907 0%, #f53c79 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f0a907', endColorstr='#f53c79', GradientType=0);
}

.gradient-2 {
  background: #4dedf5;
  background: -moz-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
  background: -webkit-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
  background: linear-gradient(to bottom, #4dedf5 0%, #480ceb 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4dedf5', endColorstr='#480ceb', GradientType=0);
}

.gradient-3 {
  background: #51f5ae;
  background: -moz-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
  background: -webkit-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
  background: linear-gradient(to bottom, #51f5ae 0%, #3fbcda 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#51f5ae', endColorstr='#3fbcda', GradientType=0);
}

.gradient-4 {
  background: #f25521;
  background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: linear-gradient(to right, #f25521 0%, #f9c70a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f25521', endColorstr='#f9c70a', GradientType=1);
}

.gradient-5 {
  background: #f53c79;
  background: -moz-linear-gradient(left, #f53c79 0%, #f0a907 100%);
  background: -webkit-linear-gradient(left, #f53c79 0%, #f0a907 100%);
  background: linear-gradient(to right, #f53c79 0%, #f0a907 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f53c79', endColorstr='#f0a907', GradientType=1);
}

.gradient-6 {
  background: #36b9d8;
  background: -moz-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
  background: -webkit-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
  background: linear-gradient(to right, #36b9d8 0%, #4bffa2 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#36b9d8', endColorstr='#4bffa2', GradientType=1);
}

.gradient-7 {
  background: #4400eb;
  background: -moz-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
  background: -webkit-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
  background: linear-gradient(to right, #4400eb 0%, #44e7f5 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4400eb', endColorstr='#44e7f5', GradientType=1);
}

.gradient-8 {
  background: #F7B00F;
  background: -moz-linear-gradient(top, #F7B00F 0%, #F25521 100%);
  background: -webkit-linear-gradient(top, #F7B00F 0%, #F25521 100%);
  background: linear-gradient(to bottom, #F7B00F 0%, #F25521 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F7B00F', endColorstr='#F25521', GradientType=1);
}

.gradient-9, .datepicker.datepicker-dropdown td.day:hover, .datepicker.datepicker-dropdown th.next:hover, .datepicker.datepicker-dropdown th.prev:hover, .datepicker table tr td.selected, .datepicker table tr td.active, .datepicker table tr td.today, .datepicker table tr td.today:hover, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover {
  background: #f31e7a !important;
  background: -moz-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
  background: -webkit-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
  background: linear-gradient(to right, #f31e7a 0%, #fd712c 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f31e7a', endColorstr='#fd712c', GradientType=1);
}

.gradient-10 {
  background: #f25521 !important;
  background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: linear-gradient(to top, #f25521 0%, #f9c70a 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f25521', endColorstr='#f9c70a', GradientType=1);
}

.gradient-11 {
  background: #3398fb;
  background: -moz-linear-gradient(left, #3398fb 0%, #8553ee 100%);
  background: -webkit-linear-gradient(left, #3398fb 0%, #8553ee 100%);
  background: linear-gradient(to right, #3398fb 0%, #8553ee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3398fb', endColorstr='#8553ee', GradientType=1);
}

.gradient-12 {
  background: #36e1b4;
  background: -moz-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
  background: -webkit-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
  background: linear-gradient(to right, #36e1b4 0%, #11cae7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#36e1b4', endColorstr='#11cae7', GradientType=1);
}

.gradient-13 {
  background: #ffbf31;
  background: -moz-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
  background: -webkit-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
  background: linear-gradient(to right, #ffbf31 0%, #ff890e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffbf31', endColorstr='#ff890e', GradientType=1);
}

.gradient-14 {
  background: #23bdb8;
  background: -moz-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
  background: -webkit-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
  background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#23bdb8', endColorstr='#43e794', GradientType=1);
}

.gradient-15 {
  background: #9a56ff;
  background: -moz-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
  background: -webkit-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
  background: linear-gradient(135deg, #9a56ff 0%, #e36cd9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9a56ff', endColorstr='#e36cd9', GradientType=1);
}

.gradient-16 {
  background: #f48665;
  background: -moz-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
  background: -webkit-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
  background: linear-gradient(135deg, #f48665 0%, #fda23f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f48665', endColorstr='#fda23f', GradientType=1);
}

.gradient-17 {
  background: #e36cd9;
  background: -moz-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
  background: -webkit-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
  background: linear-gradient(135deg, #e36cd9 0%, #fe60ae 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e36cd9', endColorstr='#fe60ae', GradientType=1);
}

.gradient-18 {
  background: #a15cff;
  background: -moz-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
  background: -webkit-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
  background: linear-gradient(to right, #a15cff 0%, #ce82fd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a15cff', endColorstr='#ce82fd', GradientType=1);
}

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
/*!
 * Bootstrap v5.0.0-beta2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #5e72e4;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #e83e8c;
  --bs-red: #EE3232;
  --bs-orange: #ff9900;
  --bs-yellow: #FFFA6F;
  --bs-green: #297F00;
  --bs-teal: #20c997;
  --bs-cyan: #3065D0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #453773;
  --bs-secondary: #4CBC9A;
  --bs-success: #56c760;
  --bs-info: #58bad7;
  --bs-warning: #f0a901;
  --bs-danger: #f75a5b;
  --bs-light: #c8c8c8;
  --bs-dark: #6e6e6e;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #777777;
  background-color: #F0F0F0;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #374557;
}

h1, .h1 {
  font-size: calc(1.35rem + 1.2vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.25rem;
  }
}

h2, .h2 {
  font-size: calc(1.3125rem + 0.75vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.875rem;
  }
}

h3, .h3 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.5rem;
  }
}

h4, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.938rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #453773;
  text-decoration: underline;
}

a:hover {
  color: #cc5547;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr
    /* rtl:ignore */
  ;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #e83e8c;
  word-wrap: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #89879f;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend+* {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}

.blockquote> :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #F0F0F0;
  border: 1px solid #dee2e6;
  border-radius: 0.75rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1440) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto>* {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1>* {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2>* {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3>* {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4>* {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5>* {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6>* {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-xl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1440) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-xxl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66667%;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-striped-color: #777777;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #777777;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #777777;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #777777;
  vertical-align: top;
  border-color: #EEEEEE;
}

.table> :not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table>tbody {
  vertical-align: inherit;
}

.table>thead {
  vertical-align: bottom;
}

.table> :not(:last-child)> :last-child>* {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm> :not(caption)>*>* {
  padding: 0.25rem 0.25rem;
}

.table-bordered> :not(caption)>* {
  border-width: 1px 0;
}

.table-bordered> :not(caption)>*>* {
  border-width: 0 1px;
}

.table-borderless> :not(caption)>*>* {
  border-bottom-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover>tbody>tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #ffe1de;
  --bs-table-striped-bg: #f2d6d3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6cbc8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecd0cd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6cbc8;
}

.table-secondary {
  --bs-table-bg: #dbf2eb;
  --bs-table-striped-bg: #d0e6df;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c5dad4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cbe0d9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c5dad4;
}

.table-success {
  --bs-table-bg: #ddf4df;
  --bs-table-striped-bg: #d2e8d4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c7dcc9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cce2ce;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c7dcc9;
}

.table-info {
  --bs-table-bg: #def1f7;
  --bs-table-striped-bg: #d3e5eb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c8d9de;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cddfe4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c8d9de;
}

.table-warning {
  --bs-table-bg: #fceecc;
  --bs-table-striped-bg: #efe2c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e3d6b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e9dcbd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e3d6b8;
}

.table-danger {
  --bs-table-bg: #fddede;
  --bs-table-striped-bg: #f0d3d3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e4c8c8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eacdcd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e4c8c8;
}

.table-light {
  --bs-table-bg: #c8c8c8;
  --bs-table-striped-bg: #bebebe;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b4b4b4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #b9b9b9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b4b4b4;
}

.table-dark {
  --bs-table-bg: #6e6e6e;
  --bs-table-striped-bg: #757575;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #7d7d7d;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #797979;
  --bs-table-hover-color: #000;
  color: #fff;
  border-color: #7d7d7d;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1439.98) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.76562rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #89879f;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #777777;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #DBDBDB;
  appearance: none;
  border-radius: 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #777777;
  background-color: #fff;
  border-color: #ffb5ac;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 106, 89, 0.25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #777777;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #777777;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #777777;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  border-radius: 0.2rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.3rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.75rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.75rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #777777;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #DBDBDB;
  border-radius: 0.75rem;
  appearance: none;
}

.form-select:focus {
  border-color: #ffb5ac;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 106, 89, 0.25);
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #777777;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.76562rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
}

.form-check {
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #ffb5ac;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 106, 89, 0.25);
}

.form-check-input:checked {
  background-color: #453773;
  border-color: #453773;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #453773;
  border-color: #453773;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled]~.form-check-label, .form-check-input:disabled~.form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffb5ac'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled]+.btn, .btn-check:disabled+.btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #F0F0F0, 0 0 0 0.25rem rgba(255, 106, 89, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #F0F0F0, 0 0 0 0.25rem rgba(255, 106, 89, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #453773;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #ffd2cd;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #453773;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #ffd2cd;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating>.form-control,
.form-floating>.form-select {
  height: calc(3.5rem + 2px);
  padding: 1rem 0.75rem;
}

.form-floating>label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating>label {
    transition: none;
  }
}

.form-floating>.form-control::placeholder {
  color: transparent;
}

.form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:-webkit-autofill~label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control,
.input-group>.form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group>.form-control:focus,
.input-group>.form-select:focus {
  z-index: 3;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #777777;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #DBDBDB;
  border-radius: 0.75rem;
}

.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text,
.input-group-lg>.btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.3rem;
}

.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text,
.input-group-sm>.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  border-radius: 0.2rem;
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation> :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation>.dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #56c760;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76562rem;
  color: #000;
  background-color: rgba(86, 199, 96, 0.9);
  border-radius: 0.75rem;
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #56c760;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2356c760' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #56c760;
  box-shadow: 0 0 0 0.25rem rgba(86, 199, 96, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #56c760;
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2356c760' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #56c760;
  box-shadow: 0 0 0 0.25rem rgba(86, 199, 96, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #56c760;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #56c760;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(86, 199, 96, 0.25);
}

.was-validated .form-check-input:valid~.form-check-label, .form-check-input.is-valid~.form-check-label {
  color: #56c760;
}

.form-check-inline .form-check-input~.valid-feedback {
  margin-left: .5em;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #f75a5b;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76562rem;
  color: #000;
  background-color: rgba(247, 90, 91, 0.9);
  border-radius: 0.75rem;
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f75a5b;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f75a5b'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f75a5b' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #f75a5b;
  box-shadow: 0 0 0 0.25rem rgba(247, 90, 91, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #f75a5b;
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f75a5b'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f75a5b' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #f75a5b;
  box-shadow: 0 0 0 0.25rem rgba(247, 90, 91, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #f75a5b;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #f75a5b;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(247, 90, 91, 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label, .form-check-input.is-invalid~.form-check-label {
  color: #f75a5b;
}

.form-check-inline .form-check-input~.invalid-feedback {
  margin-left: .5em;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #777777;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.75rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #777777;
}

.btn-check:focus+.btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 106, 89, 0.25);
}

.btn:disabled, .btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #000;
  background-color: #453773;
  border-color: #453773;
}

.btn-primary:hover {
  color: #000;
  background-color: #ff8072;
  border-color: #ff796a;
}

.btn-check:focus+.btn-primary, .btn-primary:focus {
  color: #000;
  background-color: #ff8072;
  border-color: #ff796a;
  box-shadow: 0 0 0 0.25rem rgba(217, 90, 76, 0.5);
}

.btn-check:checked+.btn-primary,
.btn-check:active+.btn-primary, .btn-primary:active, .btn-primary.active,
.show>.btn-primary.dropdown-toggle {
  color: #000;
  background-color: #ff887a;
  border-color: #ff796a;
}

.btn-check:checked+.btn-primary:focus,
.btn-check:active+.btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 90, 76, 0.5);
}

.btn-primary:disabled, .btn-primary.disabled {
  color: #000;
  background-color: #453773;
  border-color: #453773;
}

.btn-secondary {
  color: #000;
  background-color: #4CBC9A;
  border-color: #4CBC9A;
}

.btn-secondary:hover {
  color: #000;
  background-color: #67c6a9;
  border-color: #5ec3a4;
}

.btn-check:focus+.btn-secondary, .btn-secondary:focus {
  color: #000;
  background-color: #67c6a9;
  border-color: #5ec3a4;
  box-shadow: 0 0 0 0.25rem rgba(65, 160, 131, 0.5);
}

.btn-check:checked+.btn-secondary,
.btn-check:active+.btn-secondary, .btn-secondary:active, .btn-secondary.active,
.show>.btn-secondary.dropdown-toggle {
  color: #000;
  background-color: #70c9ae;
  border-color: #5ec3a4;
}

.btn-check:checked+.btn-secondary:focus,
.btn-check:active+.btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(65, 160, 131, 0.5);
}

.btn-secondary:disabled, .btn-secondary.disabled {
  color: #000;
  background-color: #4CBC9A;
  border-color: #4CBC9A;
}

.btn-success {
  color: #000;
  background-color: #56c760;
  border-color: #56c760;
}

.btn-success:hover {
  color: #000;
  background-color: #6fcf78;
  border-color: #67cd70;
}

.btn-check:focus+.btn-success, .btn-success:focus {
  color: #000;
  background-color: #6fcf78;
  border-color: #67cd70;
  box-shadow: 0 0 0 0.25rem rgba(73, 169, 82, 0.5);
}

.btn-check:checked+.btn-success,
.btn-check:active+.btn-success, .btn-success:active, .btn-success.active,
.show>.btn-success.dropdown-toggle {
  color: #000;
  background-color: #78d280;
  border-color: #67cd70;
}

.btn-check:checked+.btn-success:focus,
.btn-check:active+.btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(73, 169, 82, 0.5);
}

.btn-success:disabled, .btn-success.disabled {
  color: #000;
  background-color: #56c760;
  border-color: #56c760;
}

.btn-info {
  color: #000;
  background-color: #58bad7;
  border-color: #58bad7;
}

.btn-info:hover {
  color: #000;
  background-color: #71c4dd;
  border-color: #69c1db;
}

.btn-check:focus+.btn-info, .btn-info:focus {
  color: #000;
  background-color: #71c4dd;
  border-color: #69c1db;
  box-shadow: 0 0 0 0.25rem rgba(75, 158, 183, 0.5);
}

.btn-check:checked+.btn-info,
.btn-check:active+.btn-info, .btn-info:active, .btn-info.active,
.show>.btn-info.dropdown-toggle {
  color: #000;
  background-color: #79c8df;
  border-color: #69c1db;
}

.btn-check:checked+.btn-info:focus,
.btn-check:active+.btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(75, 158, 183, 0.5);
}

.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #58bad7;
  border-color: #58bad7;
}

.btn-warning {
  color: #000;
  background-color: #f0a901;
  border-color: #f0a901;
}

.btn-warning:hover {
  color: #000;
  background-color: #f2b627;
  border-color: #f2b21a;
}

.btn-check:focus+.btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #f2b627;
  border-color: #f2b21a;
  box-shadow: 0 0 0 0.25rem rgba(204, 144, 1, 0.5);
}

.btn-check:checked+.btn-warning,
.btn-check:active+.btn-warning, .btn-warning:active, .btn-warning.active,
.show>.btn-warning.dropdown-toggle {
  color: #000;
  background-color: #f3ba34;
  border-color: #f2b21a;
}

.btn-check:checked+.btn-warning:focus,
.btn-check:active+.btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(204, 144, 1, 0.5);
}

.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #f0a901;
  border-color: #f0a901;
}

.btn-danger {
  color: #000;
  background-color: #f75a5b;
  border-color: #f75a5b;
}

.btn-danger:hover {
  color: #000;
  background-color: #f87374;
  border-color: #f86b6b;
}

.btn-check:focus+.btn-danger, .btn-danger:focus {
  color: #000;
  background-color: #f87374;
  border-color: #f86b6b;
  box-shadow: 0 0 0 0.25rem rgba(210, 77, 77, 0.5);
}

.btn-check:checked+.btn-danger,
.btn-check:active+.btn-danger, .btn-danger:active, .btn-danger.active,
.show>.btn-danger.dropdown-toggle {
  color: #000;
  background-color: #f97b7c;
  border-color: #f86b6b;
}

.btn-check:checked+.btn-danger:focus,
.btn-check:active+.btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(210, 77, 77, 0.5);
}

.btn-danger:disabled, .btn-danger.disabled {
  color: #000;
  background-color: #f75a5b;
  border-color: #f75a5b;
}

.btn-light {
  color: #000;
  background-color: #c8c8c8;
  border-color: #c8c8c8;
}

.btn-light:hover {
  color: #000;
  background-color: #d0d0d0;
  border-color: #cecece;
}

.btn-check:focus+.btn-light, .btn-light:focus {
  color: #000;
  background-color: #d0d0d0;
  border-color: #cecece;
  box-shadow: 0 0 0 0.25rem rgba(170, 170, 170, 0.5);
}

.btn-check:checked+.btn-light,
.btn-check:active+.btn-light, .btn-light:active, .btn-light.active,
.show>.btn-light.dropdown-toggle {
  color: #000;
  background-color: lightgray;
  border-color: #cecece;
}

.btn-check:checked+.btn-light:focus,
.btn-check:active+.btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(170, 170, 170, 0.5);
}

.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #c8c8c8;
  border-color: #c8c8c8;
}

.btn-dark {
  color: #fff;
  background-color: #6e6e6e;
  border-color: #6e6e6e;
}

.btn-dark:hover {
  color: #fff;
  background-color: #5e5e5e;
  border-color: #585858;
}

.btn-check:focus+.btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #5e5e5e;
  border-color: #585858;
  box-shadow: 0 0 0 0.25rem rgba(132, 132, 132, 0.5);
}

.btn-check:checked+.btn-dark,
.btn-check:active+.btn-dark, .btn-dark:active, .btn-dark.active,
.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #585858;
  border-color: #535353;
}

.btn-check:checked+.btn-dark:focus,
.btn-check:active+.btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(132, 132, 132, 0.5);
}

.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #6e6e6e;
  border-color: #6e6e6e;
}

.btn-outline-primary {
  color: #453773;
  border-color: #453773;
}

.btn-outline-primary:hover {
  color: #000;
  background-color: #453773;
  border-color: #453773;
}

.btn-check:focus+.btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 106, 89, 0.5);
}

.btn-check:checked+.btn-outline-primary,
.btn-check:active+.btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #000;
  background-color: #453773;
  border-color: #453773;
}

.btn-check:checked+.btn-outline-primary:focus,
.btn-check:active+.btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 106, 89, 0.5);
}

.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #453773;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #4CBC9A;
  border-color: #4CBC9A;
}

.btn-outline-secondary:hover {
  color: #000;
  background-color: #4CBC9A;
  border-color: #4CBC9A;
}

.btn-check:focus+.btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(76, 188, 154, 0.5);
}

.btn-check:checked+.btn-outline-secondary,
.btn-check:active+.btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #000;
  background-color: #4CBC9A;
  border-color: #4CBC9A;
}

.btn-check:checked+.btn-outline-secondary:focus,
.btn-check:active+.btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(76, 188, 154, 0.5);
}

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #4CBC9A;
  background-color: transparent;
}

.btn-outline-success {
  color: #56c760;
  border-color: #56c760;
}

.btn-outline-success:hover {
  color: #000;
  background-color: #56c760;
  border-color: #56c760;
}

.btn-check:focus+.btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(86, 199, 96, 0.5);
}

.btn-check:checked+.btn-outline-success,
.btn-check:active+.btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #000;
  background-color: #56c760;
  border-color: #56c760;
}

.btn-check:checked+.btn-outline-success:focus,
.btn-check:active+.btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(86, 199, 96, 0.5);
}

.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #56c760;
  background-color: transparent;
}

.btn-outline-info {
  color: #58bad7;
  border-color: #58bad7;
}

.btn-outline-info:hover {
  color: #000;
  background-color: #58bad7;
  border-color: #58bad7;
}

.btn-check:focus+.btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(88, 186, 215, 0.5);
}

.btn-check:checked+.btn-outline-info,
.btn-check:active+.btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #58bad7;
  border-color: #58bad7;
}

.btn-check:checked+.btn-outline-info:focus,
.btn-check:active+.btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(88, 186, 215, 0.5);
}

.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #58bad7;
  background-color: transparent;
}

.btn-outline-warning {
  color: #f0a901;
  border-color: #f0a901;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #f0a901;
  border-color: #f0a901;
}

.btn-check:focus+.btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(240, 169, 1, 0.5);
}

.btn-check:checked+.btn-outline-warning,
.btn-check:active+.btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #f0a901;
  border-color: #f0a901;
}

.btn-check:checked+.btn-outline-warning:focus,
.btn-check:active+.btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(240, 169, 1, 0.5);
}

.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #f0a901;
  background-color: transparent;
}

.btn-outline-danger {
  color: #f75a5b;
  border-color: #f75a5b;
}

.btn-outline-danger:hover {
  color: #000;
  background-color: #f75a5b;
  border-color: #f75a5b;
}

.btn-check:focus+.btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(247, 90, 91, 0.5);
}

.btn-check:checked+.btn-outline-danger,
.btn-check:active+.btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #000;
  background-color: #f75a5b;
  border-color: #f75a5b;
}

.btn-check:checked+.btn-outline-danger:focus,
.btn-check:active+.btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(247, 90, 91, 0.5);
}

.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #f75a5b;
  background-color: transparent;
}

.btn-outline-light {
  color: #c8c8c8;
  border-color: #c8c8c8;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #c8c8c8;
  border-color: #c8c8c8;
}

.btn-check:focus+.btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(200, 200, 200, 0.5);
}

.btn-check:checked+.btn-outline-light,
.btn-check:active+.btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #c8c8c8;
  border-color: #c8c8c8;
}

.btn-check:checked+.btn-outline-light:focus,
.btn-check:active+.btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(200, 200, 200, 0.5);
}

.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #c8c8c8;
  background-color: transparent;
}

.btn-outline-dark {
  color: #6e6e6e;
  border-color: #6e6e6e;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #6e6e6e;
  border-color: #6e6e6e;
}

.btn-check:focus+.btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(110, 110, 110, 0.5);
}

.btn-check:checked+.btn-outline-dark,
.btn-check:active+.btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #6e6e6e;
  border-color: #6e6e6e;
}

.btn-check:checked+.btn-outline-dark:focus,
.btn-check:active+.btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(110, 110, 110, 0.5);
}

.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #6e6e6e;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #453773;
  text-decoration: underline;
}

.btn-link:hover {
  color: #cc5547;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg>.btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm>.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 0.875rem;
  color: #777777;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.75rem;
}

.dropdown-menu[data-bs-popper] {
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto
    /* rtl:ignore */
  ;
  left: 0
    /* rtl:ignore */
  ;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0
    /* rtl:ignore */
  ;
  left: auto
    /* rtl:ignore */
  ;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto
      /* rtl:ignore */
    ;
    left: 0
      /* rtl:ignore */
    ;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0
      /* rtl:ignore */
    ;
    left: auto
      /* rtl:ignore */
    ;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    right: auto
      /* rtl:ignore */
    ;
    left: 0
      /* rtl:ignore */
    ;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    right: 0
      /* rtl:ignore */
    ;
    left: auto
      /* rtl:ignore */
    ;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto
      /* rtl:ignore */
    ;
    left: 0
      /* rtl:ignore */
    ;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0
      /* rtl:ignore */
    ;
    left: auto
      /* rtl:ignore */
    ;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto
      /* rtl:ignore */
    ;
    left: 0
      /* rtl:ignore */
    ;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0
      /* rtl:ignore */
    ;
    left: auto
      /* rtl:ignore */
    ;
  }
}

@media (min-width: 1440) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto
      /* rtl:ignore */
    ;
    left: 0
      /* rtl:ignore */
    ;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0
      /* rtl:ignore */
    ;
    left: auto
      /* rtl:ignore */
    ;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #453773;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.76562rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #453773;
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn:hover,
.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn:hover,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:nth-child(n + 3),
.btn-group> :not(.btn-check)+.btn,
.btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm+.dropdown-toggle-split, .btn-group-sm>.btn+.dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split, .btn-group-lg>.btn+.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
  width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn~.btn,
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #F0F0F0;
  border-color: #dee2e6 #dee2e6 #F0F0F0;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.75rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #453773;
}

.nav-fill>.nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified>.nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar>.container,
.navbar>.container-fluid, .navbar>.container-sm, .navbar>.container-md, .navbar>.container-lg, .navbar>.container-xl, .navbar>.container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.33594rem;
  padding-bottom: 0.33594rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.75rem;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1440) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.75rem - 1px);
  border-bottom-left-radius: calc(0.75rem - 1px);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link+.card-link {
  margin-left: 1rem
    /* rtl:ignore */
  ;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.75rem - 1px) calc(0.75rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: #fff;
  border-bottom-color: #fff;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.75rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.75rem - 1px);
  border-bottom-left-radius: calc(0.75rem - 1px);
}

.card-group>.card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  color: #777777;
  text-align: left;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button.collapsed {
  border-bottom-width: 0;
}

.accordion-button:not(.collapsed) {
  color: #e65f50;
  background-color: #fff0ee;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e65f50'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(180deg);
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23777777'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #ffb5ac;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 106, 89, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.accordion-collapse {
  border: solid rgba(0, 0, 0, 0.125);
  border-width: 0 1px;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-button {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item:first-of-type .accordion-button {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/")
    /* rtl: var(--bs-breadcrumb-divider, "/") */
  ;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #453773;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #cc5547;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  color: #cc5547;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 106, 89, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #453773;
  border-color: #453773;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.75rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.75rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #994035;
  background-color: #ffe1de;
  border-color: #ffd2cd;
}

.alert-primary .alert-link {
  color: #7a332a;
}

.alert-secondary {
  color: #2e715c;
  background-color: #dbf2eb;
  border-color: #c9ebe1;
}

.alert-secondary .alert-link {
  color: #255a4a;
}

.alert-success {
  color: #34773a;
  background-color: #ddf4df;
  border-color: #cceecf;
}

.alert-success .alert-link {
  color: #2a5f2e;
}

.alert-info {
  color: #357081;
  background-color: #def1f7;
  border-color: #cdeaf3;
}

.alert-info .alert-link {
  color: #2a5a67;
}

.alert-warning {
  color: #906501;
  background-color: #fceecc;
  border-color: #fbe5b3;
}

.alert-warning .alert-link {
  color: #735101;
}

.alert-danger {
  color: #943637;
  background-color: #fddede;
  border-color: #fdcece;
}

.alert-danger .alert-link {
  color: #762b2c;
}

.alert-light {
  color: #505050;
  background-color: #f4f4f4;
  border-color: #efefef;
}

.alert-light .alert-link {
  color: #404040;
}

.alert-dark {
  color: #424242;
  background-color: #e2e2e2;
  border-color: #d4d4d4;
}

.alert-dark .alert-link {
  color: #353535;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0.75rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #453773;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.75rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #777777;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #453773;
  border-color: #453773;
}

.list-group-item+.list-group-item {
  border-top-width: 0;
}

.list-group-item+.list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal>.list-group-item:first-child {
  border-bottom-left-radius: 0.75rem;
  border-top-right-radius: 0;
}

.list-group-horizontal>.list-group-item:last-child {
  border-top-right-radius: 0.75rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal>.list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm>.list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md>.list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg>.list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl>.list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1440) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl>.list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush>.list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #994035;
  background-color: #ffe1de;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #994035;
  background-color: #e6cbc8;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #994035;
  border-color: #994035;
}

.list-group-item-secondary {
  color: #2e715c;
  background-color: #dbf2eb;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #2e715c;
  background-color: #c5dad4;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #2e715c;
  border-color: #2e715c;
}

.list-group-item-success {
  color: #34773a;
  background-color: #ddf4df;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #34773a;
  background-color: #c7dcc9;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #34773a;
  border-color: #34773a;
}

.list-group-item-info {
  color: #357081;
  background-color: #def1f7;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #357081;
  background-color: #c8d9de;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #357081;
  border-color: #357081;
}

.list-group-item-warning {
  color: #906501;
  background-color: #fceecc;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #906501;
  background-color: #e3d6b8;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #906501;
  border-color: #906501;
}

.list-group-item-danger {
  color: #943637;
  background-color: #fddede;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #943637;
  background-color: #e4c8c8;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #943637;
  border-color: #943637;
}

.list-group-item-light {
  color: #505050;
  background-color: #f4f4f4;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #505050;
  background-color: gainsboro;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}

.list-group-item-dark {
  color: #424242;
  background-color: #e2e2e2;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #424242;
  background-color: #cbcbcb;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #424242;
  border-color: #424242;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.75rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 106, 89, 0.25);
  opacity: 1;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.75rem;
}

.toast:not(.showing):not(.show) {
  opacity: 0;
}

.toast.hide {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container> :not(:last-child) {
  margin-bottom: 15px;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #EEEEEE;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #EEEEEE;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer>* {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1439.98) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76562rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.75rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0
    /* rtl:ignore */
  ;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76562rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top>.popover-arrow, .bs-popover-auto[data-popper-placement^="top"]>.popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top>.popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top>.popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end>.popover-arrow, .bs-popover-auto[data-popper-placement^="right"]>.popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-end>.popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-end>.popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom>.popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom>.popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom>.popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start>.popover-arrow, .bs-popover-auto[data-popper-placement^="left"]>.popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-start>.popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-start>.popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #374557;
  background-color: #f0f0f0;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #777777;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg)
      /* rtl:ignore */
    ;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {

  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #453773;
}

.link-primary:hover, .link-primary:focus {
  color: #ff887a;
}

.link-secondary {
  color: #4CBC9A;
}

.link-secondary:hover, .link-secondary:focus {
  color: #70c9ae;
}

.link-success {
  color: #56c760;
}

.link-success:hover, .link-success:focus {
  color: #78d280;
}

.link-info {
  color: #58bad7;
}

.link-info:hover, .link-info:focus {
  color: #79c8df;
}

.link-warning {
  color: #f0a901;
}

.link-warning:hover, .link-warning:focus {
  color: #f3ba34;
}

.link-danger {
  color: #f75a5b;
}

.link-danger:hover, .link-danger:focus {
  color: #f97b7c;
}

.link-light {
  color: #c8c8c8;
}

.link-light:hover, .link-light:focus {
  color: lightgray;
}

.link-dark {
  color: #6e6e6e;
}

.link-dark:hover, .link-dark:focus {
  color: #585858;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1440) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #EEEEEE !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #EEEEEE !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #EEEEEE !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #EEEEEE !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #EEEEEE !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #453773 !important;
}

.border-secondary {
  border-color: #4CBC9A !important;
}

.border-success {
  border-color: #56c760 !important;
}

.border-info {
  border-color: #58bad7 !important;
}

.border-warning {
  border-color: #f0a901 !important;
}

.border-danger {
  border-color: #f75a5b !important;
}

.border-light {
  border-color: #c8c8c8 !important;
}

.border-dark {
  border-color: #6e6e6e !important;
}

.border-white {
  border-color: #fff !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-2 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-3 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-4 {
  font-size: 1.125rem !important;
}

.fs-5 {
  font-size: 1rem !important;
}

.fs-6 {
  font-size: 0.938rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-primary {
  color: #453773 !important;
}

.text-secondary {
  color: #4CBC9A !important;
}

.text-success {
  color: #56c760 !important;
}

.text-info {
  color: #58bad7 !important;
}

.text-warning {
  color: #f0a901 !important;
}

.text-danger {
  color: #f75a5b !important;
}

.text-light {
  color: #c8c8c8 !important;
}

.text-dark {
  color: #6e6e6e !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #777777 !important;
}

.text-muted {
  color: #89879f !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.bg-primary {
  background-color: #453773 !important;
}

.bg-secondary {
  background-color: #4CBC9A !important;
}

.bg-success {
  background-color: #56c760 !important;
}

.bg-info {
  background-color: #58bad7 !important;
}

.bg-warning {
  background-color: #f0a901 !important;
}

.bg-danger {
  background-color: #f75a5b !important;
}

.bg-light {
  background-color: #c8c8c8 !important;
}

.bg-dark {
  background-color: #6e6e6e !important;
}

.bg-body {
  background-color: #F0F0F0 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.75rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.75rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.rounded-end {
  border-top-right-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.75rem !important;
  border-top-left-radius: 0.75rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1440) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important;
  }

  .fs-2 {
    font-size: 1.875rem !important;
  }

  .fs-3 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

.c-pointer {
  cursor: pointer;
}

* {
  outline: none;
  padding: 0;
}

*::after {
  margin: 0;
  padding: 0;
}

*::before {
  margin: 0;
  padding: 0;
}

::selection {
  color: #fff;
  background: var(--primary);
}

@media only screen and (max-width: 100rem) {
  :root {
    font-size: 14px;
  }
}

body {
  overflow-x: hidden;
  height: 100%;
  position: relative;
  max-width: 100%;
  font-size: 0.875rem;
}

@media only screen and (max-width: 61.9375rem) {
  body {
    font-size: 0.875rem;
  }
}

p {
  line-height: 1.8;
}

.box-shadow-none {
  box-shadow: none !important;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
  overflow: hidden;
  position: relative;
}

#main-wrapper.show {
  opacity: 1;
}

.rounded-lg {
  border-radius: 0.625rem;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  color: #777777;
  text-decoration: none;
}

a:hover, a:focus, a.active {
  text-decoration: none;
}

.btn-link:hover, .btn-link:focus, .btn-link.active {
  text-decoration: none;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.fs-12 {
  font-size: 0.75rem !important;
  line-height: 1.5;
}

.fs-13 {
  font-size: 0.8125rem !important;
  line-height: 1.5;
}

.fs-14 {
  font-size: 0.875rem !important;
  line-height: 1.6;
}

.fs-15 {
  font-size: 0.875rem !important;
  line-height: 1.5;
}

.fs-16 {
  font-size: 1rem !important;
  line-height: 1.6;
}

@media only screen and (max-width: 35.9375rem) {
  .fs-16 {
    font-size: 0.875rem !important;
  }
}

.fs-18 {
  font-size: 1.125rem !important;
  line-height: 1.5;
}

@media only screen and (max-width: 35.9375rem) {
  .fs-18 {
    font-size: 1rem !important;
  }
}

.fs-20 {
  font-size: 1.25rem !important;
  line-height: 1.5;
}

.fs-22 {
  font-size: 1.375rem !important;
  line-height: 1.5;
}

.fs-24 {
  font-size: 1.5rem !important;
  line-height: 1.4;
}

.fs-26 {
  font-size: 1.625rem !important;
  line-height: 1.4;
}

.fs-28 {
  font-size: 1.75rem !important;
  line-height: 1.4;
}

@media only screen and (max-width: 35.9375rem) {
  .fs-28 {
    font-size: 1.5rem !important;
  }
}

.fs-30 {
  font-size: 1.875rem !important;
  line-height: 1.4;
}

.fs-32 {
  font-size: 2rem !important;
  line-height: 1.25;
}

.fs-34 {
  font-size: 2.125rem !important;
  line-height: 1.25;
}

.fs-35 {
  font-size: 2.1875rem !important;
  line-height: 1.25;
}

.fs-36 {
  font-size: 2.25rem !important;
  line-height: 1.25;
}

.fs-38 {
  font-size: 2.375rem !important;
  line-height: 1.25;
}

.fs-46 {
  font-size: 2.875rem !important;
  line-height: 1.25;
}

.fs-48 {
  font-size: 3rem !important;
  line-height: 1.25;
}

.font-w100 {
  font-weight: 100;
}

.font-w200 {
  font-weight: 200;
}

.font-w300 {
  font-weight: 300;
}

.font-w400 {
  font-weight: 400;
}

.font-w500 {
  font-weight: 500;
}

.font-w600 {
  font-weight: 600;
}

.font-w700 {
  font-weight: 700;
}

.font-w800 {
  font-weight: 800;
}

.font-w900 {
  font-weight: 900;
}

h1, .h1, .h1, h2, .h2, .h2, h3, .h3, .h3, h4, .h4, .h4, h5, .h5, .h5, h6, .h6, .h6 {
  line-height: 1.5;
  font-weight: 600;
}

.w-space-no {
  white-space: nowrap;
}

.content-body .container {
  margin-top: 2.5rem;
}

.content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl, .content-body .container-xxl {
  padding-top: 2.5rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

@media only screen and (max-width: 75rem) {
  .content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl, .content-body .container-xxl {
    padding-top: 1.875rem;
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl, .content-body .container-xxl {
    padding-top: 1.25rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl, .content-body .container-xxl {
    padding-top: 0.9375rem;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

/*sp*/
.row.sp4,
.sp4 {
  margin-left: -0.125rem;
  margin-right: -0.125rem;
}

.row.sp4 [class*="col-"],
.sp4 [class*="col-"] {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}

.op1 {
  opacity: 0.1;
}

.op2 {
  opacity: 0.2;
}

.op3 {
  opacity: 0.3;
}

.op4 {
  opacity: 0.4;
}

.op5 {
  opacity: 0.5;
}

.op6 {
  opacity: 0.6;
}

.op7 {
  opacity: 0.7;
}

.op8 {
  opacity: 0.8;
}

.op9 {
  opacity: 0.9;
}

.content-heading {
  font-size: 1rem;
  margin-bottom: 1.875rem;
  margin-top: 3.125rem;
  border-bottom: 0.0625rem solid #DBDBDB;
  padding-bottom: 0.625rem;
}

[direction="rtl"] .content-heading {
  text-align: right;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.vh-100 {
  height: 100vh !important;
}

.support-ticket {
  position: fixed;
  bottom: 1.875rem;
  right: 0.9375rem;
  z-index: 999999;
}

.support-ticket-btn {
  width: 6.25rem;
  background: #7CB442;
  animation: 0.7s ease-in-out 0s infinite alternate none running crescendo;
  border-radius: 3.125rem;
  color: #fff;
  font-size: 0.5rem;
  font-size: 1rem;
  padding: 0.3125rem 0.625rem 0.4375rem;
  text-align: center;
  display: inline-block;
  box-shadow: rgba(124, 180, 66, 0.7) 0rem 0.5rem 2.1875rem 0rem;
}

.support-ticket-btn:hover, .support-ticket-btn:focus {
  color: #fff;
}

.text-blue {
  color: #5e72e4;
}

.text-indigo {
  color: #6610f2;
}

.text-purple {
  color: #6f42c1;
}

.text-pink {
  color: #e83e8c;
}

.text-red {
  color: #EE3232;
}

.text-orange {
  color: #ff9900;
}

.text-yellow {
  color: #FFFA6F;
}

.text-green {
  color: #297F00;
}

.text-teal {
  color: #20c997;
}

.text-cyan {
  color: #3065D0;
}

/* .bg-gray-dark{
	background:$color_pallate_2 !important;
}
.bg-purpal{
	background:$color_pallate_4 !important;
}
.bg-red{
	background:$color_pallate_5 !important;
}
.bg-blue-dark{
	background:$color_pallate_6 !important;
} */
.bg-blue {
  background: #496ecc !important;
}

.bg-orange {
  background: #ed8030 !important;
}

.bg-green {
  background: #299e4a !important;
}

.bg-purpel {
  background: #9517c1 !important;
}

.bg-dark-blue {
  background: #251e71 !important;
}

/* .bg-blue-light{
	background:$color_pallate_8 !important;
}
.bg-green-light{
	background:$color_pallate_9 !important;
}
.bg-green{
	background:$color_pallate_10 !important;
} */
.bg-black {
  background: #000;
}

.text-black {
  color: #000 !important;
}

.dlab-scroll {
  position: relative;
}

.scale1 {
  transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  display: inline-block;
}

.scale2 {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  display: inline-block;
}

.scale3 {
  transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  display: inline-block;
}

.scale4 {
  transform: scale(1.4);
  -moz-transform: scale(1.4);
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4);
  display: inline-block;
}

.scale5 {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  display: inline-block;
}

.scale-2 {
  transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
  display: inline-block;
}

@-webkit-keyframes crescendo {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

.height10 {
  height: 0.625rem;
}

.height20 {
  height: 1.25rem;
}

.height30 {
  height: 1.875rem;
}

.height40 {
  height: 2.5rem;
}

.height50 {
  height: 3.125rem;
}

.height60 {
  height: 3.75rem;
}

.height70 {
  height: 4.375rem;
}

.height80 {
  height: 5rem;
}

.height90 {
  height: 5.625rem;
}

.height100 {
  height: 6.25rem;
}

.height110 {
  height: 6.875rem;
}

.height120 {
  height: 7.5rem;
}

.height130 {
  height: 8.125rem;
}

.height140 {
  height: 8.75rem;
}

.height150 {
  height: 9.375rem;
}

.height160 {
  height: 10rem;
}

.height170 {
  height: 10.625rem;
}

.height180 {
  height: 11.25rem;
}

.height190 {
  height: 11.875rem;
}

.height200 {
  height: 12.5rem;
}

.height210 {
  height: 13.125rem;
}

.height220 {
  height: 13.75rem;
}

.height230 {
  height: 14.375rem;
}

.height240 {
  height: 15rem;
}

.height250 {
  height: 15.625rem;
}

.height260 {
  height: 16.25rem;
}

.height270 {
  height: 16.875rem;
}

.height280 {
  height: 17.5rem;
}

.height290 {
  height: 18.125rem;
}

.height300 {
  height: 18.75rem;
}

.height310 {
  height: 19.375rem;
}

.height320 {
  height: 20rem;
}

.height330 {
  height: 20.625rem;
}

.height340 {
  height: 21.25rem;
}

.height350 {
  height: 21.875rem;
}

.height360 {
  height: 22.5rem;
}

.height370 {
  height: 23.125rem;
}

.height380 {
  height: 23.75rem;
}

.height390 {
  height: 24.375rem;
}

.height400 {
  height: 25rem;
}

.height415 {
  height: 25.9375rem;
}

.height500 {
  height: 31.25rem;
}

.height550 {
  height: 34.375rem;
}

.height600 {
  height: 37.5rem;
}

.height630 {
  height: 39.375rem;
}

.height720 {
  height: 45rem;
}

.height750 {
  height: 46.875rem;
}

.height800 {
  height: 50rem;
}

.width10 {
  width: 0.625rem;
}

.width20 {
  width: 1.25rem;
}

.width30 {
  width: 1.875rem;
}

.width40 {
  width: 2.5rem;
}

.width50 {
  width: 3.125rem;
}

.width60 {
  width: 3.75rem;
}

.width70 {
  width: 4.375rem;
}

.width80 {
  width: 5rem;
}

.width90 {
  width: 5.625rem;
}

.width100 {
  width: 6.25rem;
}

.width110 {
  width: 6.875rem;
}

.width120 {
  width: 7.5rem;
}

.width130 {
  width: 8.125rem;
}

.width140 {
  width: 8.75rem;
}

.width150 {
  width: 9.375rem;
}

.width160 {
  width: 10rem;
}

.width170 {
  width: 10.625rem;
}

.width180 {
  width: 11.25rem;
}

.width190 {
  width: 11.875rem;
}

.width200 {
  width: 12.5rem;
}

.width210 {
  width: 13.125rem;
}

.width220 {
  width: 13.75rem;
}

.width230 {
  width: 14.375rem;
}

.width240 {
  width: 15rem;
}

.width250 {
  width: 15.625rem;
}

.width260 {
  width: 16.25rem;
}

.width270 {
  width: 16.875rem;
}

.width280 {
  width: 17.5rem;
}

.width290 {
  width: 18.125rem;
}

.width300 {
  width: 18.75rem;
}

.width310 {
  width: 19.375rem;
}

.width320 {
  width: 20rem;
}

.width330 {
  width: 20.625rem;
}

.width340 {
  width: 21.25rem;
}

.width350 {
  width: 21.875rem;
}

.width360 {
  width: 22.5rem;
}

.width370 {
  width: 23.125rem;
}

.width380 {
  width: 23.75rem;
}

.width390 {
  width: 24.375rem;
}

.width400 {
  width: 25rem;
}

.rounded {
  border-radius: 0.625rem !important;
}

label {
  margin-bottom: 0.5rem;
}

@keyframes gXGDoR {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

@keyframes crescendo {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

@keyframes gXGDoR {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
}

/* Editable */
#preloader {
  background-color: #fff;
  padding: 0;
  margin: 0;
  height: 100%;
  position: fixed;
  z-index: 99999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#preloader .lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

#preloader .lds-ripple div {
  position: absolute;
  border: 4px solid var(--primary);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

#preloader .lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

[data-theme-version="dark"] #preloader {
  background-color: #20203c;
}

.footer {
  padding-left: 17.1875rem;
  font-size: 0.875rem;
}

.footer .copyright {
  padding: 0.9375rem;
}

.footer .copyright p {
  text-align: center;
  margin: 0;
  color: #918f8f;
}

.footer .copyright a {
  color: var(--primary);
}

[data-sidebar-style="mini"] .nav-control,
[data-layout="horizontal"] .nav-control {
  display: none;
}

@media only screen and (max-width: 47.9375rem) {
  [data-sidebar-style="overlay"] .nav-header .logo-abbr {
    display: block;
  }
}

[data-header-position="fixed"] .nav-header {
  position: fixed;
}

/* .brand-logo img {
  width: 11rem; } */

.nav-header {
  height: 6.25rem;
  width: 18.75rem;
  display: inline-block;
  text-align: left;
  position: absolute;
  top: 0;
  background-color: var(--nav-headbg);
  transition: all .2s ease;
  z-index: 5;
  /*  .logo-abbr {
        max-width: 2.9375rem;
		@include respond('laptop') {
			max-width: 2.8125rem;
		}
		@include respond('phone') {
			width:2.1875rem;
			height:2.1875rem;
		}
    } */
}

.nav-header .logo-compact {
  display: none;
}

@media only screen and (max-width: 87.5rem) {
  .nav-header {
    height: 5.5rem;
    width: 17rem;
  }
}

.nav-header .brand-logo {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.125rem;
  color: #fff;
  text-decoration: none;
  padding-left: 3rem;
  padding-right: 3rem;
  font-weight: 700;
}

@media only screen and (max-width: 87.5rem) {
  .nav-header .brand-logo {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

[data-sidebar-style="compact"] .nav-header .brand-logo,
[data-sidebar-style="mini"] .nav-header .brand-logo {
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
}

@media only screen and (max-width: 47.9375rem) {
  .nav-header .brand-logo {
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
  }
}

.nav-header .brand-title {
  margin-left: 0.9375rem;
  max-width: 8.75rem;
  font-size: 2.375rem;
  color: #000;
}

[data-theme-version="dark"] .nav-header .brand-title {
  background-position: 0 120%;
}

@media only screen and (max-width: 47.9375rem) {
  .nav-header {
    top: 0;
    background: transparent;
  }
}

.nav-header .rect-primary-rect {
  fill: var(--primary);
}

@media only screen and (max-width: 74.9375rem) {
  .nav-header {
    height: 5rem;
  }
}

@media only screen and (max-width: 63.9375rem) {
  .nav-header {
    width: 5rem;
  }

  .nav-header .brand-title {
    display: none;
  }
}

.nav-control {
  cursor: pointer;
  position: absolute;
  right: -5rem;
  text-align: center;
  top: 55%;
  transform: translateY(-50%);
  z-index: 9999;
  font-size: 1.4rem;
  padding: 0.125rem 0.5rem 0;
  border-radius: 0.125rem;
}

@media only screen and (max-width: 87.5rem) {
  .nav-control {
    right: -5.556rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .nav-control {
    right: -3rem;
  }
}

.hamburger {
  display: inline-block;
  left: 0rem;
  position: relative;
  top: 0rem;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 1.625rem;
  z-index: 999;
}

.hamburger .line {
  background: #000;
  display: block;
  height: 0.1875rem;
  border-radius: 0.1875rem;
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
  margin-right: auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger .line:nth-child(1) {
  width: 1.625rem;
}

.hamburger .line:nth-child(2) {
  width: 1.625rem;
}

.hamburger .line:nth-child(3) {
  width: 0.9375rem;
}

.hamburger:hover {
  cursor: pointer;
}

.hamburger:hover .line {
  width: 1.625rem;
}

.hamburger.is-active .line:nth-child(1),
.hamburger.is-active .line:nth-child(3) {
  width: 0.625rem;
  height: 0.125rem;
}

.hamburger.is-active .line:nth-child(2) {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  width: 1.375rem;
  height: 0.125rem;
}

.hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(4px) translateX(12px) rotate(45deg);
  transform: translateY(4px) translateX(12px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-4px) translateX(12px) rotate(-45deg);
  transform: translateY(-4px) translateX(12px) rotate(-45deg);
}

@media (min-width: 47.9375rem) {
  [data-sidebar-style="compact"] .nav-control {
    display: none;
  }

  [data-sidebar-style="compact"] .nav-header {
    width: 11.25rem;
  }
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .brand-title {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .logo-abbr {
  display: block;
}

[data-sidebar-style="full"][data-layout="horizontal"] .logo-compact {
  display: none;
}

[data-sidebar-style="mini"] .nav-header {
  height: 6.5rem;
}

[data-sidebar-style="mini"] .nav-header .logo-abbr {
  display: block;
}

@media only screen and (max-width: 63.9375rem) {
  [data-sidebar-style="mini"] .nav-header {
    height: 5.5rem;
  }
}

[data-sidebar-style="compact"][data-layout="vertical"] .nav-header .brand-title {
  display: none;
}

[data-sidebar-style="compact"][data-layout="vertical"] .nav-header .logo-compact {
  max-width: 4.6875rem;
}

[data-sidebar-style="compact"][data-layout="horizontal"] .nav-header .brand-logo {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  justify-content: start;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header {
  width: 10.625rem;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header .brand-title {
  display: none;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header .logo-compact {
  display: none;
}

.header {
  height: 6.25rem;
  z-index: 1;
  position: relative;
  padding: 0rem;
  /*  background:var(--headerbg); */
  z-index: 3;
  padding-left: 18.563rem;
  padding-right: 100px;
  padding-top: 0rem;
  transition: all .2s ease;
}

.header.is-fixed {
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 47.9375rem) {
  .header {
    padding-right: 0px;
  }
}

.header .header-content {
  height: 100%;
  padding-left: 7rem;
  padding-right: 2.475rem;
  align-items: center;
  display: flex;
  position: relative;
}

.header .header-content:after {
  position: absolute;
  bottom: 0;
  left: 46px;
  content: "";
  background: rgba(255, 255, 255, 0.5);
  height: 1px;
  width: 94%;
  z-index: -1;
}

@media only screen and (max-width: 87.5rem) {
  .header .header-content {
    padding-left: 7rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .header .header-content {
    padding-left: 3.75rem;
    padding-right: 0.938rem;
  }

  .header .header-content:after {
    left: -5rem;
    width: calc(100% + 5rem);
  }
}

.header .navbar {
  padding: 0;
  height: 100%;
  width: 100%;
}

.header .navbar .navbar-collapse {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 87.5rem) {
  .header {
    height: 5.5rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .header {
    height: 5rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .header {
    padding-top: 0;
  }
}

[data-headerbg="color_2"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_3"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_4"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_5"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_6"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_7"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_8"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_9"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_10"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_11"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_12"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_13"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_14"] .header {
  background: var(--headerbg);
}

[data-headerbg="color_15"] .header {
  background: var(--headerbg);
}

/* pulse in SVG */
svg.pulse-svg {
  overflow: visible;
}

svg.pulse-svg .first-circle, svg.pulse-svg .second-circle, svg.pulse-svg .third-circle {
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation: pulse-me 3s linear infinite;
  animation: pulse-me 3s linear infinite;
  fill: var(--primary);
}

svg.pulse-svg .second-circle {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

svg.pulse-svg .third-circle {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

/* pulse in CSS */
.pulse-css {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  border-radius: 3.5rem;
  height: 1.25rem;
  position: absolute;
  background: #fe8630;
  right: 0.375rem;
  top: 0.3125rem;
  border: 0.25rem solid #fff;
  width: 1.25rem;
}

.pulse-css:after, .pulse-css:before {
  content: '';
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -.2rem;
  background-color: #D8B9C3;
  margin: auto;
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation: pulse-me 3s linear infinite;
  animation: pulse-me 3s linear infinite;
}

[direction="rtl"] .pulse-css:after, [direction="rtl"] .pulse-css:before {
  left: auto;
  right: -.2rem;
}

@media only screen and (max-width: 87.5rem) {
  .pulse-css {
    height: 1rem;
    width: 1rem;
  }
}

@-webkit-keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0;
  }

  50% {
    opacity: 0.1;
  }

  70% {
    opacity: 0.09;
  }

  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0;
  }

  50% {
    opacity: 0.1;
  }

  70% {
    opacity: 0.09;
  }

  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

[data-sidebar-style="full"] .header,
[data-sidebar-style="overlay"] .header {
  width: 100%;
}

@media only screen and (max-width: 87.5rem) {

  [data-sidebar-style="full"] .header,
  [data-sidebar-style="overlay"] .header {
    width: 100%;
    padding-left: 17rem;
  }
}

@media only screen and (max-width: 63.9375rem) {

  [data-sidebar-style="full"] .header,
  [data-sidebar-style="overlay"] .header {
    width: 100%;
    padding-left: 5rem;
  }
}

[data-sidebar-style="mini"] .header {
  width: 100%;
  height: 6.5rem;
  padding-left: 7.5rem;
}

@media only screen and (max-width: 63.9375rem) {
  [data-sidebar-style="mini"] .header {
    height: 5.5rem;
    padding-left: 6.5rem;
  }
}

[data-sidebar-style="compact"] .header {
  width: 100%;
  padding-left: 12.5rem;
}

[data-sidebar-style="compact"] .header .header-content {
  padding-left: 2.5rem;
}

[data-header-position="fixed"] .header {
  position: fixed;
  top: 0;
  width: 100%;
}

[data-header-position="fixed"] .content-body {
  padding-top: 6.25rem;
}

@media only screen and (max-width: 87.5rem) {
  [data-header-position="fixed"] .content-body {
    padding-top: 5.5rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  [data-header-position="fixed"] .content-body {
    padding-top: 5rem;
  }
}

[data-header-position="fixed"] .dlabnav {
  margin-top: 0;
}

[data-sidebar-style="compact"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] .header {
  width: 74.9375rem;
}

[data-sidebar-style="modern"] .header {
  width: 100%;
  padding-left: 11.9rem;
}

[data-sidebar-style="modern"][data-layout="horizontal"] .nav-header .brand-logo {
  justify-content: start;
}

[data-sidebar-style="modern"][data-layout="horizontal"] .header .header-content {
  padding-left: 1.875rem;
}

.header-left {
  height: 100%;
  display: flex;
  align-items: center;
}

.header-left .breadcrumb {
  margin-bottom: 0;
}

.header-left .agenda a {
  border-radius: 1.75rem;
}

.header-left .agenda a i {
  margin-right: 0.75rem;
}

@media only screen and (max-width: 87.5rem) {
  .header-left .agenda a i {
    margin-right: 0rem;
  }
}

@media only screen and (max-width: 93.75rem) {
  .header-left .agenda a {
    font-size: 0;
    border-radius: 0.75rem;
    padding: 0.8125rem 0.9375rem;
  }

  .header-left .agenda a i {
    font-size: 1.25rem;
    margin-right: 0;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-left .agenda {
    display: none;
  }
}

.header-left .dashboard_bar {
  font-size: 2.188rem;
  font-weight: 600;
  color: #000;
}

@media only screen and (max-width: 74.9375rem) {
  .header-left .dashboard_bar {
    font-size: 2.125rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .header-left .dashboard_bar {
    display: none;
  }
}

.header-left .dashboard_bar.sub-bar {
  font-size: 1.875rem;
}

.header-left .search-area {
  width: 18.875rem;
  border-radius: 2.375rem;
  margin-left: 7rem;
}

@media only screen and (max-width: 100rem) {
  .header-left .search-area {
    width: 18.75rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .header-left .search-area {
    display: none;
  }
}

.header-left .search-area .form-control {
  border: 0;
  background: #F6F6F6;
  border-top-left-radius: 2.625rem;
  border-bottom-left-radius: 2.625rem;
  height: 3.5rem;
}

@media only screen and (max-width: 87.5rem) {
  .header-left .search-area .form-control {
    height: 3rem;
  }
}

.header-left .search-area .input-group-text {
  border-top-right-radius: 2.625rem;
  border-bottom-right-radius: 2.625rem;
  background: #F1F1F1;
}

.header-left .search-area .input-group-text a i {
  font-size: 1.5rem;
}

@media only screen and (max-width: 87.5rem) {
  .header-left .search-area .input-group-text {
    height: 3rem;
  }
}

[data-sidebar-style="compact"] .header-left {
  margin-left: 0;
}

.header-right {
  height: 100%;
}

.header-right .header-profile {
  position: relative !important;
}

.header-right .header-profile .dropdown-menu:after {
  content: none;
}

.header-right .nav-item {
  display: flex;
  justify-content: center;
  position: unset;
}

.header-right .nav-item .nav-link {
  color: #464a53;
  font-size: 1.125rem;
}

.header-right .right-sidebar {
  margin-right: -1.875rem;
}

.header-right .right-sidebar a {
  height: 5rem;
  width: 5rem;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  border-left: 0.0625rem solid #c8c8c8;
}

.header-right>li:not(:first-child) {
  padding-left: 1.25rem;
}

@media only screen and (max-width: 74.9375rem) {
  .header-right>li:not(:first-child) {
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-right>li:not(:first-child) {
    padding-left: 0.4rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .header-right .notification_dropdown {
    position: relative;
  }
}

.header-right .notification_dropdown .nav-link {
  position: relative;
  background: var(--rgba-primary-1);
  border-radius: 0.625rem;
  padding: 0.8375rem;
  margin: 10px 0;
  line-height: 1;
}

@media only screen and (max-width: 87.5rem) {
  .header-right .notification_dropdown .nav-link {
    padding: 0.625rem;
  }
}

.header-right .notification_dropdown .nav-link i {
  font-size: 1.5rem;
}

@media only screen and (max-width: 87.5rem) {
  .header-right .notification_dropdown .nav-link i {
    font-size: 1.125rem;
  }
}

@media only screen and (max-width: 87.5rem) {
  .header-right .notification_dropdown .nav-link svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-right .notification_dropdown .nav-link svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .header-right .notification_dropdown .nav-link {
    margin: 0px 10px;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-right .notification_dropdown .nav-link {
    margin: 0px 5px;
  }
}

.header-right .notification_dropdown .dropdown-item:focus a, .header-right .notification_dropdown .dropdown-item:active a {
  color: #fff;
}

.header-right .notification_dropdown .dropdown-item a {
  color: #6e6e6e;
}

.header-right .notification_dropdown .dropdown-item a:hover {
  text-decoration: none;
}

[data-theme-version="dark"] .header-right .dropdown-menu {
  box-shadow: none;
}

.header-right .dropdown-menu:after {
  content: "";
  width: 1px;
  background: #000;
  position: absolute;
  right: 40px;
  height: 100%;
  z-index: 10;
  top: 0;
  opacity: 0.2;
}

@media only screen and (max-width: 74.9375rem) {
  .header-right .dropdown-menu:after {
    content: none;
  }
}

.header-right .search-area {
  width: 32.063rem;
}

.header-right .search-area .form-control {
  background: #FAFAFA;
  border: 0;
  padding-right: 18px;
}

.header-right .search-area ::placeholder {
  color: #A098AE;
  font-size: 16px;
  font-weight: 400;
}

.header-right .search-area .input-group-text {
  border-radius: 0.625rem;
  background: #FAFAFA;
  padding: 0px 12px;
  border: 0;
  padding-right: 0;
}

.header-right .search-area .input-group-text i {
  font-size: 1.5rem;
}

@media only screen and (max-width: 100rem) {
  .header-right .search-area {
    width: 15.625rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .header-right .search-area {
    display: none;
  }
}

.header-right .blur-img {
  position: absolute;
  left: 27%;
  top: 0;
  filter: blur(554px);
}

.dlab-fullscreen #icon-minimize {
  display: none;
}

.dlab-fullscreen.active #icon-full {
  display: none;
}

.dlab-fullscreen.active #icon-minimize {
  display: inline-block;
}

.notification_dropdown .dropdown-menu-end {
  border-width: 0;
  box-shadow: none;
  border-radius: 30px 0 0 30px;
  inset: 0 calc(100% + -40px) 0 auto !important;
  top: 0;
  min-width: 23.375rem;
  height: 100%;
  padding-right: 2.5rem;
  z-index: -3;
  margin-top: 0;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  display: block !important;
  transform: translateX(calc(100% + 60px)) !important;
}

.notification_dropdown .dropdown-menu-end.show {
  transform: translateX(0) !important;
  box-shadow: -30px 0px 30px 0 rgba(0, 0, 0, 0.1);
  margin: 0 !important;
}

.notification_dropdown .dropdown-menu-end .widget-media,
.notification_dropdown .dropdown-menu-end .widget-timeline {
  height: calc(100% - 3.2rem) !important;
}

.notification_dropdown .dropdown-menu-end .notification_title {
  background: var(--primary);
  color: #fff;
  padding: 0.625rem 1.25rem;
}

.notification_dropdown .dropdown-menu-end .notification_title h5, .notification_dropdown .dropdown-menu-end .notification_title .h5 {
  color: #fff;
  margin-bottom: 0.1875rem;
}

.notification_dropdown .dropdown-menu-end .media {
  width: 2.8125rem;
  height: 2.8125rem;
  font-size: 1.125rem;
}

[data-theme-version="dark"] .notification_dropdown .dropdown-menu-end .media {
  border-color: #333754;
}

.notification_dropdown .dropdown-menu-end .media>span {
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 3.125rem;
  display: inline-block;
  padding: 0.4375rem 0.5625rem;
  margin-right: 0.625rem;
}

[direction="rtl"].notification_dropdown .dropdown-menu-end .media>span {
  margin-right: 0;
  margin-left: 0.625rem;
}

.notification_dropdown .dropdown-menu-end .media>span.success {
  background: #c9edcc;
  color: #56c760;
}

.notification_dropdown .dropdown-menu-end .media>span.primary {
  background: var(--rgba-primary-1);
  color: var(--primary);
}

.notification_dropdown .dropdown-menu-end .media>span.danger {
  background: #fcbbbc;
  color: #f75a5b;
}

.notification_dropdown .dropdown-menu-end .media .notify-time {
  width: 100%;
  margin-right: 0;
  color: #828690;
}

.notification_dropdown .dropdown-menu-end .media p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 12.5rem;
  margin-bottom: 0;
  margin-top: 0.3125rem;
}

@media only screen and (max-width: 35.9375rem) {
  .notification_dropdown .dropdown-menu-end .media p {
    max-width: 6.25rem;
  }
}

.notification_dropdown .dropdown-menu-end .all-notification {
  display: block;
  padding: 0.9375rem 1.875rem 0;
  text-align: center;
  border-top: 0.0625rem solid #c8c8c8;
}

.notification_dropdown .dropdown-menu-end .all-notification i {
  margin-left: 0.625rem;
}

@media only screen and (max-width: 47.9375rem) {
  .notification_dropdown .dropdown-menu-end {
    right: 0;
    height: 27rem;
    top: 45px;
    transform: translateX(0);
    padding-right: 0;
    width: 18rem;
    display: none !important;
    border-radius: 0.625rem;
  }

  .notification_dropdown .dropdown-menu-end.show {
    display: block !important;
    right: -4rem !important;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .notification_dropdown .dropdown-menu-end {
    min-width: 18.5rem;
    right: -4rem !important;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .notification_dropdown .dropdown-menu-end {
    inset: 55px calc(100% + -40px) 0 auto !important;
  }
}

.header-profile>a.nav-link {
  padding: 0;
  display: flex;
  align-items: center;
}

.header-profile>a.nav-link i {
  font-weight: 700;
}

.header-profile>a.nav-link .header-info {
  padding-left: 0rem;
  text-align: left;
}

@media only screen and (max-width: 87.5rem) {
  .header-profile>a.nav-link .header-info {
    padding-left: 0.625rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-profile>a.nav-link .header-info {
    display: none;
  }
}

.header-profile>a.nav-link .header-info span {
  font-size: 1rem;
  color: #000;
  display: block;
  font-weight: 600;
}

.header-profile>a.nav-link .header-info strong {
  color: #6e6e6e;
}

.header-profile>a.nav-link .header-info small, .header-profile>a.nav-link .header-info .small {
  display: block;
  font-size: 0.8125rem;
  color: #89879f;
  font-weight: 400;
  line-height: 1.2;
}

@media only screen and (max-width: 87.5rem) {
  .header-profile>a.nav-link {
    margin-left: 0rem;
    padding-left: 0rem;
  }

  .header-profile>a.nav-link .header-info span {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-profile>a.nav-link {
    margin-left: 0rem;
    padding-left: 0rem;
    border-left: 0;
  }
}

.header-profile .dropdown-menu {
  padding: 0.9375rem 0;
  min-width: 12.5rem;
}

.header-profile .dropdown-menu a:hover, .header-profile .dropdown-menu a:focus, .header-profile .dropdown-menu a.active {
  color: #453773;
}

.header-profile img {
  width: 2.813rem;
  height: 2.813rem;
  border: 3px solid var(--primary);
  border-radius: 0.625rem;
}

@media only screen and (max-width: 87.5rem) {
  .header-profile img {
    width: 2.4375rem;
    height: 2.4375rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .header-profile img {
    border: 2px solid var(--primary);
  }
}

.header-profile .dropdown-toggle i {
  font-size: 1.25rem;
}

@media only screen and (max-width: 35.9375rem) {
  .header-profile .dropdown-toggle span {
    display: none;
  }
}

.header-profile .profile_title {
  background: #453773;
  color: #fff;
  padding: 0.625rem 1.25rem;
}

.header-profile .profile_title h5, .header-profile .profile_title .h5 {
  color: #fff;
  margin-bottom: 0.1875rem;
}

.header-profile .dropdown-item {
  padding: 0.5rem 1.5rem;
}

[data-container="boxed"] .search-area {
  display: none !important;
}

.rtl {
  text-align: right;
  direction: rtl;
}

.rtl .nav {
  padding-right: 0;
}

.rtl .navbar-nav .nav-item {
  float: right;
}

.rtl .navbar-nav .nav-item+.nav-item {
  margin-right: 1rem;
  margin-left: inherit;
}

.rtl th {
  text-align: right;
}

.rtl .alert-dismissible {
  padding-right: 1.25rem;
  padding-left: 4rem;
}

.rtl .dropdown-menu {
  right: 0;
  text-align: right;
}

.rtl .checkbox label {
  padding-right: 1.25rem;
  padding-left: inherit;
}

.rtl .btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-radius: 0 0.75rem 0.75rem 0;
}

.rtl .btn-group>.btn:last-child:not(:first-child),
.rtl .btn-group>.dropdown-toggle:not(:first-child) {
  border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child {
  border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .custom-control-label::after,
.rtl .custom-control-label::before {
  right: 0;
  left: inherit;
}

.rtl .custom-select {
  padding: 0.375rem 0.75rem 0.375rem 1.75rem;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat left 0.75rem center;
  background-size: 8px 10px;
}

.rtl .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.rtl .input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.rtl .input-group>.input-group-append:not(:last-child)>.btn,
.rtl .input-group>.input-group-append:not(:last-child)>.input-group-text,
.rtl .input-group>.input-group-prepend>.btn,
.rtl .input-group>.input-group-prepend>.input-group-text {
  border-radius: 0 0.75rem 0.75rem 0;
}

.rtl .input-group>.input-group-append>.btn,
.rtl .input-group>.input-group-append>.input-group-text,
.rtl .input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.rtl .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.rtl .input-group>.input-group-prepend:not(:first-child)>.btn,
.rtl .input-group>.input-group-prepend:not(:first-child)>.input-group-text {
  border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .input-group>.custom-select:not(:first-child),
.rtl .input-group>.form-control:not(:first-child) {
  border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .input-group>.custom-select:not(:last-child),
.rtl .input-group>.form-control:not(:last-child) {
  border-radius: 0 0.75rem 0.75rem 0;
}

.rtl .input-group>.custom-select:not(:last-child):not(:first-child),
.rtl .input-group>.form-control:not(:last-child):not(:first-child) {
  border-radius: 0;
}

.rtl .custom-control {
  padding-right: 1.5rem;
  padding-left: inherit;
  margin-right: inherit;
  margin-left: 1rem;
}

.rtl .custom-control-indicator {
  right: 0;
  left: inherit;
}

.rtl .custom-file-label::after {
  right: initial;
  left: -1px;
  border-radius: .25rem 0 0 .25rem;
}

.rtl .radio input,
.rtl .radio-inline,
.rtl .checkbox input,
.rtl .checkbox-inline input {
  margin-right: -1.25rem;
  margin-left: inherit;
}

.rtl .list-group {
  padding-right: 0;
  padding-left: 40px;
}

.rtl .close {
  float: left;
}

.rtl .modal-header .close {
  margin: -15px auto -15px -15px;
}

.rtl .modal-footer> :not(:first-child) {
  margin-right: .25rem;
}

.rtl .alert-dismissible .close {
  right: inherit;
  left: 0;
}

.rtl .dropdown-toggle::after {
  margin-right: .255em;
  margin-left: 0;
}

.rtl .form-check-input {
  margin-right: -1.25rem;
  margin-left: inherit;
}

.rtl .form-check-label {
  padding-right: 1.25rem;
  padding-left: inherit;
}

.rtl .offset-1 {
  margin-right: 8.33333%;
  margin-left: 0;
}

.rtl .offset-2 {
  margin-right: 16.66667%;
  margin-left: 0;
}

.rtl .offset-3 {
  margin-right: 25%;
  margin-left: 0;
}

.rtl .offset-4 {
  margin-right: 33.33333%;
  margin-left: 0;
}

.rtl .offset-5 {
  margin-right: 41.66667%;
  margin-left: 0;
}

.rtl .offset-6 {
  margin-right: 50%;
  margin-left: 0;
}

.rtl .offset-7 {
  margin-right: 58.33333%;
  margin-left: 0;
}

.rtl .offset-8 {
  margin-right: 66.66667%;
  margin-left: 0;
}

.rtl .offset-9 {
  margin-right: 75%;
  margin-left: 0;
}

.rtl .offset-10 {
  margin-right: 83.33333%;
  margin-left: 0;
}

.rtl .offset-11 {
  margin-right: 91.66667%;
  margin-left: 0;
}

@media (min-width: 576px) {
  .rtl .offset-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .rtl .offset-sm-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }

  .rtl .offset-sm-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }

  .rtl .offset-sm-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .rtl .offset-sm-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }

  .rtl .offset-sm-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }

  .rtl .offset-sm-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .rtl .offset-sm-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }

  .rtl .offset-sm-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }

  .rtl .offset-sm-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .rtl .offset-sm-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }

  .rtl .offset-sm-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .rtl .offset-md-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .rtl .offset-md-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }

  .rtl .offset-md-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }

  .rtl .offset-md-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .rtl .offset-md-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }

  .rtl .offset-md-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }

  .rtl .offset-md-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .rtl .offset-md-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }

  .rtl .offset-md-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }

  .rtl .offset-md-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .rtl .offset-md-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }

  .rtl .offset-md-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .rtl .offset-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .rtl .offset-lg-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }

  .rtl .offset-lg-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }

  .rtl .offset-lg-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .rtl .offset-lg-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }

  .rtl .offset-lg-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }

  .rtl .offset-lg-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .rtl .offset-lg-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }

  .rtl .offset-lg-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }

  .rtl .offset-lg-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .rtl .offset-lg-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }

  .rtl .offset-lg-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  .rtl .offset-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .rtl .offset-xl-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }

  .rtl .offset-xl-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }

  .rtl .offset-xl-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .rtl .offset-xl-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }

  .rtl .offset-xl-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }

  .rtl .offset-xl-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .rtl .offset-xl-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }

  .rtl .offset-xl-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }

  .rtl .offset-xl-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .rtl .offset-xl-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }

  .rtl .offset-xl-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

@media (min-width: 1440) {
  .rtl .offset-xxl-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .rtl .offset-xxl-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }

  .rtl .offset-xxl-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }

  .rtl .offset-xxl-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .rtl .offset-xxl-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }

  .rtl .offset-xxl-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }

  .rtl .offset-xxl-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .rtl .offset-xxl-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }

  .rtl .offset-xxl-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }

  .rtl .offset-xxl-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .rtl .offset-xxl-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }

  .rtl .offset-xxl-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

.rtl .mr-0,
.rtl .mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.rtl .ml-0,
.rtl .mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.rtl .mr-1,
.rtl .mx-1 {
  margin-right: 0 !important;
  margin-left: 0.25rem !important;
}

.rtl .ml-1,
.rtl .mx-1 {
  margin-left: 0 !important;
  margin-right: 0.25rem !important;
}

.rtl .mr-2,
.rtl .mx-2 {
  margin-right: 0 !important;
  margin-left: 0.5rem !important;
}

.rtl .ml-2,
.rtl .mx-2 {
  margin-left: 0 !important;
  margin-right: 0.5rem !important;
}

.rtl .mr-3,
.rtl .mx-3 {
  margin-right: 0 !important;
  margin-left: 1rem !important;
}

.rtl .ml-3,
.rtl .mx-3 {
  margin-left: 0 !important;
  margin-right: 1rem !important;
}

.rtl .mr-4,
.rtl .mx-4 {
  margin-right: 0 !important;
  margin-left: 1.5rem !important;
}

.rtl .ml-4,
.rtl .mx-4 {
  margin-left: 0 !important;
  margin-right: 1.5rem !important;
}

.rtl .mr-5,
.rtl .mx-5 {
  margin-right: 0 !important;
  margin-left: 3rem !important;
}

.rtl .ml-5,
.rtl .mx-5 {
  margin-left: 0 !important;
  margin-right: 3rem !important;
}

.rtl .pr-0,
.rtl .px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.rtl .pl-0,
.rtl .px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.rtl .pr-1,
.rtl .px-1 {
  padding-right: 0 !important;
  padding-left: 0.25rem !important;
}

.rtl .pl-1,
.rtl .px-1 {
  padding-left: 0 !important;
  padding-right: 0.25rem !important;
}

.rtl .pr-2,
.rtl .px-2 {
  padding-right: 0 !important;
  padding-left: 0.5rem !important;
}

.rtl .pl-2,
.rtl .px-2 {
  padding-left: 0 !important;
  padding-right: 0.5rem !important;
}

.rtl .pr-3,
.rtl .px-3 {
  padding-right: 0 !important;
  padding-left: 1rem !important;
}

.rtl .pl-3,
.rtl .px-3 {
  padding-left: 0 !important;
  padding-right: 1rem !important;
}

.rtl .pr-4,
.rtl .px-4 {
  padding-right: 0 !important;
  padding-left: 1.5rem !important;
}

.rtl .pl-4,
.rtl .px-4 {
  padding-left: 0 !important;
  padding-right: 1.5rem !important;
}

.rtl .pr-5,
.rtl .px-5 {
  padding-right: 0 !important;
  padding-left: 3rem !important;
}

.rtl .pl-5,
.rtl .px-5 {
  padding-left: 0 !important;
  padding-right: 3rem !important;
}

.rtl .mr-auto {
  margin-right: 0 !important;
  margin-left: auto !important;
}

.rtl .ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important;
}

.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

@media (min-width: 576px) {

  .rtl .mr-sm-0,
  .rtl .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .rtl .ml-sm-0,
  .rtl .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rtl .mr-sm-1,
  .rtl .mx-sm-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }

  .rtl .ml-sm-1,
  .rtl .mx-sm-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }

  .rtl .mr-sm-2,
  .rtl .mx-sm-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  .rtl .ml-sm-2,
  .rtl .mx-sm-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }

  .rtl .mr-sm-3,
  .rtl .mx-sm-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }

  .rtl .ml-sm-3,
  .rtl .mx-sm-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .rtl .mr-sm-4,
  .rtl .mx-sm-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }

  .rtl .ml-sm-4,
  .rtl .mx-sm-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }

  .rtl .mr-sm-5,
  .rtl .mx-sm-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }

  .rtl .ml-sm-5,
  .rtl .mx-sm-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }

  .rtl .pr-sm-0,
  .rtl .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .rtl .pl-sm-0,
  .rtl .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .rtl .pr-sm-1,
  .rtl .px-sm-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }

  .rtl .pl-sm-1,
  .rtl .px-sm-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }

  .rtl .pr-sm-2,
  .rtl .px-sm-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }

  .rtl .pl-sm-2,
  .rtl .px-sm-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }

  .rtl .pr-sm-3,
  .rtl .px-sm-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }

  .rtl .pl-sm-3,
  .rtl .px-sm-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }

  .rtl .pr-sm-4,
  .rtl .px-sm-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .rtl .pl-sm-4,
  .rtl .px-sm-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }

  .rtl .pr-sm-5,
  .rtl .px-sm-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }

  .rtl .pl-sm-5,
  .rtl .px-sm-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }

  .rtl .mr-sm-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .ml-sm-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {

  .rtl .mr-md-0,
  .rtl .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .rtl .ml-md-0,
  .rtl .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rtl .mr-md-1,
  .rtl .mx-md-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }

  .rtl .ml-md-1,
  .rtl .mx-md-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }

  .rtl .mr-md-2,
  .rtl .mx-md-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  .rtl .ml-md-2,
  .rtl .mx-md-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }

  .rtl .mr-md-3,
  .rtl .mx-md-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }

  .rtl .ml-md-3,
  .rtl .mx-md-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .rtl .mr-md-4,
  .rtl .mx-md-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }

  .rtl .ml-md-4,
  .rtl .mx-md-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }

  .rtl .mr-md-5,
  .rtl .mx-md-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }

  .rtl .ml-md-5,
  .rtl .mx-md-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }

  .rtl .pr-md-0,
  .rtl .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .rtl .pl-md-0,
  .rtl .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .rtl .pr-md-1,
  .rtl .px-md-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }

  .rtl .pl-md-1,
  .rtl .px-md-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }

  .rtl .pr-md-2,
  .rtl .px-md-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }

  .rtl .pl-md-2,
  .rtl .px-md-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }

  .rtl .pr-md-3,
  .rtl .px-md-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }

  .rtl .pl-md-3,
  .rtl .px-md-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }

  .rtl .pr-md-4,
  .rtl .px-md-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .rtl .pl-md-4,
  .rtl .px-md-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }

  .rtl .pr-md-5,
  .rtl .px-md-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }

  .rtl .pl-md-5,
  .rtl .px-md-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }

  .rtl .mr-md-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .ml-md-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {

  .rtl .mr-lg-0,
  .rtl .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .rtl .ml-lg-0,
  .rtl .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rtl .mr-lg-1,
  .rtl .mx-lg-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }

  .rtl .ml-lg-1,
  .rtl .mx-lg-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }

  .rtl .mr-lg-2,
  .rtl .mx-lg-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  .rtl .ml-lg-2,
  .rtl .mx-lg-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }

  .rtl .mr-lg-3,
  .rtl .mx-lg-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }

  .rtl .ml-lg-3,
  .rtl .mx-lg-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .rtl .mr-lg-4,
  .rtl .mx-lg-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }

  .rtl .ml-lg-4,
  .rtl .mx-lg-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }

  .rtl .mr-lg-5,
  .rtl .mx-lg-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }

  .rtl .ml-lg-5,
  .rtl .mx-lg-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }

  .rtl .pr-lg-0,
  .rtl .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .rtl .pl-lg-0,
  .rtl .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .rtl .pr-lg-1,
  .rtl .px-lg-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }

  .rtl .pl-lg-1,
  .rtl .px-lg-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }

  .rtl .pr-lg-2,
  .rtl .px-lg-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }

  .rtl .pl-lg-2,
  .rtl .px-lg-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }

  .rtl .pr-lg-3,
  .rtl .px-lg-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }

  .rtl .pl-lg-3,
  .rtl .px-lg-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }

  .rtl .pr-lg-4,
  .rtl .px-lg-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .rtl .pl-lg-4,
  .rtl .px-lg-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }

  .rtl .pr-lg-5,
  .rtl .px-lg-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }

  .rtl .pl-lg-5,
  .rtl .px-lg-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }

  .rtl .mr-lg-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .ml-lg-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {

  .rtl .mr-xl-0,
  .rtl .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .rtl .ml-xl-0,
  .rtl .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rtl .mr-xl-1,
  .rtl .mx-xl-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }

  .rtl .ml-xl-1,
  .rtl .mx-xl-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }

  .rtl .mr-xl-2,
  .rtl .mx-xl-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  .rtl .ml-xl-2,
  .rtl .mx-xl-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }

  .rtl .mr-xl-3,
  .rtl .mx-xl-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }

  .rtl .ml-xl-3,
  .rtl .mx-xl-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .rtl .mr-xl-4,
  .rtl .mx-xl-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }

  .rtl .ml-xl-4,
  .rtl .mx-xl-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }

  .rtl .mr-xl-5,
  .rtl .mx-xl-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }

  .rtl .ml-xl-5,
  .rtl .mx-xl-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }

  .rtl .pr-xl-0,
  .rtl .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .rtl .pl-xl-0,
  .rtl .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .rtl .pr-xl-1,
  .rtl .px-xl-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }

  .rtl .pl-xl-1,
  .rtl .px-xl-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }

  .rtl .pr-xl-2,
  .rtl .px-xl-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }

  .rtl .pl-xl-2,
  .rtl .px-xl-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }

  .rtl .pr-xl-3,
  .rtl .px-xl-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }

  .rtl .pl-xl-3,
  .rtl .px-xl-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }

  .rtl .pr-xl-4,
  .rtl .px-xl-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .rtl .pl-xl-4,
  .rtl .px-xl-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }

  .rtl .pr-xl-5,
  .rtl .px-xl-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }

  .rtl .pl-xl-5,
  .rtl .px-xl-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }

  .rtl .mr-xl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .ml-xl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1440) {

  .rtl .mr-xxl-0,
  .rtl .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .rtl .ml-xxl-0,
  .rtl .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rtl .mr-xxl-1,
  .rtl .mx-xxl-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }

  .rtl .ml-xxl-1,
  .rtl .mx-xxl-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }

  .rtl .mr-xxl-2,
  .rtl .mx-xxl-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  .rtl .ml-xxl-2,
  .rtl .mx-xxl-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }

  .rtl .mr-xxl-3,
  .rtl .mx-xxl-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }

  .rtl .ml-xxl-3,
  .rtl .mx-xxl-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .rtl .mr-xxl-4,
  .rtl .mx-xxl-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }

  .rtl .ml-xxl-4,
  .rtl .mx-xxl-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }

  .rtl .mr-xxl-5,
  .rtl .mx-xxl-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }

  .rtl .ml-xxl-5,
  .rtl .mx-xxl-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }

  .rtl .pr-xxl-0,
  .rtl .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .rtl .pl-xxl-0,
  .rtl .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .rtl .pr-xxl-1,
  .rtl .px-xxl-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }

  .rtl .pl-xxl-1,
  .rtl .px-xxl-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }

  .rtl .pr-xxl-2,
  .rtl .px-xxl-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }

  .rtl .pl-xxl-2,
  .rtl .px-xxl-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }

  .rtl .pr-xxl-3,
  .rtl .px-xxl-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }

  .rtl .pl-xxl-3,
  .rtl .px-xxl-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }

  .rtl .pr-xxl-4,
  .rtl .px-xxl-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .rtl .pl-xxl-4,
  .rtl .px-xxl-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }

  .rtl .pr-xxl-5,
  .rtl .px-xxl-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }

  .rtl .pl-xxl-5,
  .rtl .px-xxl-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }

  .rtl .mr-xxl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .ml-xxl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

.rtl .text-right {
  text-align: left !important;
}

.rtl .text-left {
  text-align: right !important;
}

@media (min-width: 576px) {
  .rtl .text-sm-right {
    text-align: left !important;
  }

  .rtl .text-sm-left {
    text-align: right !important;
  }
}

@media (min-width: 768px) {
  .rtl .text-md-right {
    text-align: left !important;
  }

  .rtl .text-md-left {
    text-align: right !important;
  }
}

@media (min-width: 992px) {
  .rtl .text-lg-right {
    text-align: left !important;
  }

  .rtl .text-lg-left {
    text-align: right !important;
  }
}

@media (min-width: 1200px) {
  .rtl .text-xl-right {
    text-align: left !important;
  }

  .rtl .text-xl-left {
    text-align: right !important;
  }
}

@media (min-width: 1440) {
  .rtl .text-xxl-right {
    text-align: left !important;
  }

  .rtl .text-xxl-left {
    text-align: right !important;
  }
}

.rtl .mx-0 {
  margin-right: auto;
  margin-left: 0 !important;
}

.rtl .mx-0 {
  margin-left: auto;
  margin-right: 0 !important;
}

.rtl .mx-1 {
  margin-right: auto;
  margin-left: 0.25rem !important;
}

.rtl .mx-1 {
  margin-left: auto;
  margin-right: 0.25rem !important;
}

.rtl .mx-2 {
  margin-right: auto;
  margin-left: 0.5rem !important;
}

.rtl .mx-2 {
  margin-left: auto;
  margin-right: 0.5rem !important;
}

.rtl .mx-3 {
  margin-right: auto;
  margin-left: 1rem !important;
}

.rtl .mx-3 {
  margin-left: auto;
  margin-right: 1rem !important;
}

.rtl .mx-4 {
  margin-right: auto;
  margin-left: 1.5rem !important;
}

.rtl .mx-4 {
  margin-left: auto;
  margin-right: 1.5rem !important;
}

.rtl .mx-5 {
  margin-right: auto;
  margin-left: 3rem !important;
}

.rtl .mx-5 {
  margin-left: auto;
  margin-right: 3rem !important;
}

.rtl .px-0 {
  padding-right: auto;
  padding-left: 0 !important;
}

.rtl .px-0 {
  padding-left: auto;
  padding-right: 0 !important;
}

.rtl .px-1 {
  padding-right: auto;
  padding-left: 0.25rem !important;
}

.rtl .px-1 {
  padding-left: auto;
  padding-right: 0.25rem !important;
}

.rtl .px-2 {
  padding-right: auto;
  padding-left: 0.5rem !important;
}

.rtl .px-2 {
  padding-left: auto;
  padding-right: 0.5rem !important;
}

.rtl .px-3 {
  padding-right: auto;
  padding-left: 1rem !important;
}

.rtl .px-3 {
  padding-left: auto;
  padding-right: 1rem !important;
}

.rtl .px-4 {
  padding-right: auto;
  padding-left: 1.5rem !important;
}

.rtl .px-4 {
  padding-left: auto;
  padding-right: 1.5rem !important;
}

.rtl .px-5 {
  padding-right: auto;
  padding-left: 3rem !important;
}

.rtl .px-5 {
  padding-left: auto;
  padding-right: 3rem !important;
}

.rtl .mr-auto {
  margin-right: 0 !important;
  margin-left: auto !important;
}

.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.rtl .ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important;
}

.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .rtl .mx-sm-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }

  .rtl .mx-sm-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }

  .rtl .mx-sm-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }

  .rtl .mx-sm-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }

  .rtl .mx-sm-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }

  .rtl .mx-sm-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }

  .rtl .mx-sm-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }

  .rtl .mx-sm-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }

  .rtl .mx-sm-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }

  .rtl .mx-sm-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }

  .rtl .mx-sm-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }

  .rtl .mx-sm-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }

  .rtl .px-sm-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }

  .rtl .px-sm-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }

  .rtl .px-sm-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }

  .rtl .px-sm-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }

  .rtl .px-sm-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }

  .rtl .px-sm-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }

  .rtl .px-sm-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }

  .rtl .px-sm-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }

  .rtl .px-sm-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }

  .rtl .px-sm-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }

  .rtl .px-sm-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }

  .rtl .px-sm-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }

  .rtl .mr-sm-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .rtl .ml-sm-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .rtl .mx-md-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }

  .rtl .mx-md-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }

  .rtl .mx-md-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }

  .rtl .mx-md-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }

  .rtl .mx-md-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }

  .rtl .mx-md-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }

  .rtl .mx-md-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }

  .rtl .mx-md-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }

  .rtl .mx-md-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }

  .rtl .mx-md-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }

  .rtl .mx-md-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }

  .rtl .mx-md-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }

  .rtl .px-md-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }

  .rtl .px-md-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }

  .rtl .px-md-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }

  .rtl .px-md-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }

  .rtl .px-md-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }

  .rtl .px-md-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }

  .rtl .px-md-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }

  .rtl .px-md-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }

  .rtl .px-md-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }

  .rtl .px-md-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }

  .rtl .px-md-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }

  .rtl .px-md-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }

  .rtl .mr-md-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .rtl .ml-md-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .rtl .mx-lg-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }

  .rtl .mx-lg-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }

  .rtl .mx-lg-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }

  .rtl .mx-lg-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }

  .rtl .mx-lg-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }

  .rtl .mx-lg-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }

  .rtl .mx-lg-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }

  .rtl .mx-lg-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }

  .rtl .mx-lg-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }

  .rtl .mx-lg-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }

  .rtl .mx-lg-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }

  .rtl .mx-lg-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }

  .rtl .px-lg-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }

  .rtl .px-lg-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }

  .rtl .px-lg-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }

  .rtl .px-lg-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }

  .rtl .px-lg-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }

  .rtl .px-lg-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }

  .rtl .px-lg-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }

  .rtl .px-lg-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }

  .rtl .px-lg-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }

  .rtl .px-lg-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }

  .rtl .px-lg-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }

  .rtl .px-lg-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }

  .rtl .mr-lg-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .rtl .ml-lg-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .rtl .mx-xl-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }

  .rtl .mx-xl-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }

  .rtl .mx-xl-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }

  .rtl .mx-xl-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }

  .rtl .mx-xl-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }

  .rtl .mx-xl-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }

  .rtl .mx-xl-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }

  .rtl .mx-xl-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }

  .rtl .mx-xl-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }

  .rtl .mx-xl-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }

  .rtl .mx-xl-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }

  .rtl .mx-xl-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }

  .rtl .px-xl-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }

  .rtl .px-xl-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }

  .rtl .px-xl-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }

  .rtl .px-xl-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }

  .rtl .px-xl-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }

  .rtl .px-xl-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }

  .rtl .px-xl-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }

  .rtl .px-xl-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }

  .rtl .px-xl-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }

  .rtl .px-xl-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }

  .rtl .px-xl-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }

  .rtl .px-xl-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }

  .rtl .mr-xl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .rtl .ml-xl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1440) {
  .rtl .mx-xxl-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }

  .rtl .mx-xxl-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }

  .rtl .mx-xxl-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }

  .rtl .mx-xxl-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }

  .rtl .mx-xxl-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }

  .rtl .mx-xxl-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }

  .rtl .mx-xxl-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }

  .rtl .mx-xxl-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }

  .rtl .mx-xxl-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }

  .rtl .mx-xxl-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }

  .rtl .mx-xxl-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }

  .rtl .mx-xxl-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }

  .rtl .px-xxl-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }

  .rtl .px-xxl-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }

  .rtl .px-xxl-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }

  .rtl .px-xxl-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }

  .rtl .px-xxl-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }

  .rtl .px-xxl-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }

  .rtl .px-xxl-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }

  .rtl .px-xxl-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }

  .rtl .px-xxl-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }

  .rtl .px-xxl-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }

  .rtl .px-xxl-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }

  .rtl .px-xxl-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }

  .rtl .mr-xxl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .rtl .ml-xxl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

.rtl .float-left {
  float: right !important;
}

.rtl .float-right {
  float: left !important;
}

.rtl .float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .rtl .float-sm-left {
    float: right !important;
  }

  .rtl .float-sm-right {
    float: left !important;
  }

  .rtl .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .rtl .float-md-left {
    float: right !important;
  }

  .rtl .float-md-right {
    float: left !important;
  }

  .rtl .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .rtl .float-lg-left {
    float: right !important;
  }

  .rtl .float-lg-right {
    float: left !important;
  }

  .rtl .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .rtl .float-xl-left {
    float: right !important;
  }

  .rtl .float-xl-right {
    float: left !important;
  }

  .rtl .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1440) {
  .rtl .float-xxl-left {
    float: right !important;
  }

  .rtl .float-xxl-right {
    float: left !important;
  }

  .rtl .float-xxl-none {
    float: none !important;
  }
}

[direction="rtl"][data-theme-version="dark"] .border, [direction="rtl"][data-theme-version="dark"] .border-left, [direction="rtl"][data-theme-version="dark"] .border-right {
  border-color: #333754 !important;
}

[direction="rtl"] .text-right {
  text-align: left !important;
}

[direction="rtl"] .text-left {
  text-align: right !important;
}

[direction="rtl"] .border-right {
  border-left: 0.0625rem solid #DBDBDB !important;
  border-right: 0 !important;
}

[direction="rtl"] .border-left {
  border-right: 0.0625rem solid #DBDBDB !important;
  border-left: 0 !important;
}

[direction="rtl"] .dropdown-menu {
  left: auto;
}

[direction="rtl"] .dropdown-menu-right {
  left: 0;
  right: auto;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .dropdown-menu-right {
    left: 0.9375rem;
  }
}

[direction="rtl"] .notification_dropdown .dropdown-menu-right .media>span {
  margin-left: 0.625rem;
  margin-right: 0;
}

[direction="rtl"]:not([data-container="boxed"]) .nav-header {
  left: auto;
  right: 0;
}

[direction="rtl"][data-container="wide-boxed"] .nav-header {
  left: auto;
  right: auto;
}

[direction="rtl"] .nav-header {
  text-align: right;
  right: auto;
}

[direction="rtl"] .nav-header .brand-title {
  margin-left: 0;
  margin-right: 0.9375rem;
}

[direction="rtl"] .nav-header .brand-logo {
  padding-left: 0;
  padding-right: 1.75rem;
}

[data-sidebar-style="compact"][direction="rtl"] .nav-header .brand-logo {
  padding-right: 0;
}

[data-sidebar-style="compact"][direction="rtl"] .nav-header .brand-logo[data-layout="horizontal"] {
  padding-right: 1.875rem;
}

[data-sidebar-style="mini"][direction="rtl"] .nav-header .brand-logo {
  padding-right: 0;
}

[data-sidebar-style="modern"][direction="rtl"] .nav-header .brand-logo {
  padding-right: 0;
}

[data-layout="horizontal"][data-sidebar-style="modern"][direction="rtl"] .nav-header .brand-logo {
  padding-right: 1.875rem;
}

@media (max-width: 767.98px) {
  [direction="rtl"] .nav-header .brand-logo {
    padding-right: 0;
  }
}

[direction="rtl"] .nav-control {
  right: auto;
  left: -4.0625rem;
}

@media (max-width: 767.98px) {
  [direction="rtl"] .nav-control {
    left: -4.0625rem;
  }
}

@media (max-width: 575.98px) {
  [direction="rtl"] .nav-control {
    left: -2.0625rem;
  }
}

[direction="rtl"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active {
  right: 0;
}

[direction="rtl"][data-sidebar-style="compact"][data-layout="horizontal"] .nav-header .brand-logo {
  padding-right: 2.5rem;
}

[direction="rtl"][data-sidebar-style="modern"][data-layout="horizontal"] .nav-header {
  width: 16rem;
}

[direction="rtl"] .header {
  padding: 0;
  padding-right: 21.563rem;
}

@media (max-width: 767.98px) {
  [direction="rtl"] .header {
    padding-right: 5rem;
    padding-left: 0;
  }
}

[direction="rtl"] .header .header-content {
  padding-left: 1.875rem;
  padding-right: 5.3125rem;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .header .header-content {
    padding-right: 3.5rem;
    padding-left: 0.938rem;
  }
}

[data-sidebar-style="compact"][direction="rtl"] .header .header-content {
  padding-right: 1.875rem;
}

[data-sidebar-style="modern"][direction="rtl"] .header .header-content {
  padding-right: 5.3125rem;
}

[data-sidebar-style="overlay"][direction="rtl"] .header .header-content {
  padding-right: 5.3125rem;
}

@media only screen and (max-width: 35.9375rem) {
  [data-sidebar-style="overlay"][direction="rtl"] .header .header-content {
    padding-right: 0.5rem;
  }
}

[direction="rtl"] .header .nav-control {
  right: 0.4375rem;
  left: auto;
}

[direction="rtl"] .header-right>li:not(:first-child) {
  padding-left: 0;
  padding-right: 1.25rem;
  margin-right: 0 !important;
}

@media only screen and (max-width: 47.9375rem) {
  [direction="rtl"] .header-right>li:not(:first-child) {
    padding-right: 0.5rem;
  }
}

[direction="rtl"] .header-right .search-area .input-group-append .input-group-text {
  padding-right: auto;
  padding-left: 1.25rem;
}

[direction="rtl"] .header-right .search-area .form-control {
  padding-left: auto;
  padding-right: 1.25rem;
}

[direction="rtl"] .header-right .header-profile>a.nav-link {
  margin-left: auto;
  padding-left: auto;
  margin-right: 0.9375rem;
  padding-right: 1.875rem;
  border-right: 0.0625rem solid #EEEEEE;
  border-left: 0;
}

[direction="rtl"] .header-right .header-profile>a.nav-link .header-info {
  padding-right: 1.25rem;
  padding-left: auto;
  text-align: right;
}

@media only screen and (max-width: 87.5rem) {
  [direction="rtl"] .header-right .header-profile>a.nav-link .header-info {
    padding-right: 0.625rem;
  }
}

@media only screen and (max-width: 87.5rem) {
  [direction="rtl"] .header-right .header-profile>a.nav-link {
    margin-right: 0.625rem;
    padding-right: 1.25rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .header-right .header-profile>a.nav-link {
    margin-right: 0rem;
    padding-right: 0rem;
    border-right: 0;
  }
}

[direction="rtl"] .header-left .search_bar .dropdown-menu,
[direction="rtl"] .header-left .search_bar .dropdown-menu.show {
  right: 2.5rem !important;
}

@media only screen and (max-width: 35.9375rem) {

  [direction="rtl"] .header-left .search_bar .dropdown-menu,
  [direction="rtl"] .header-left .search_bar .dropdown-menu.show {
    right: -6.25rem !important;
  }
}

[direction="rtl"] .header-left .search_bar .search_icon {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .header-left .search_bar .search_icon {
    border-radius: 5rem;
  }
}

[direction="rtl"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 21.563rem;
}

[direction="rtl"][data-layout="horizontal"] .header .header-content {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

[direction="rtl"][data-layout="horizontal"][data-sidebar-style="full"] .nav-header .brand-logo {
  padding-right: 2.5rem;
}

[direction="rtl"][data-layout="horizontal"][data-sidebar-style="mini"] .header {
  padding-right: 7.75rem;
}

[direction="rtl"][data-sidebar-style="mini"] .header {
  padding-right: 6.25rem;
}

[direction="rtl"][data-sidebar-style="compact"] .header {
  padding: 0 0.9375rem;
  padding-right: 11.25rem;
}

[direction="rtl"][data-sidebar-style="compact"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 21.563rem;
}

[direction="rtl"][data-sidebar-style="modern"] .header {
  padding: 0 0.9375rem;
  padding-right: 10.625rem;
}

[direction="rtl"][data-sidebar-style="modern"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 16rem;
}

[direction="rtl"] {
  text-align: right;
}

[direction="rtl"] .dlabnav {
  text-align: right;
}

[direction="rtl"] .dlabnav .metismenu ul:after {
  left: auto;
  right: 1.5625rem;
}

[direction="rtl"] .dlabnav .metismenu ul a:before {
  left: auto;
  right: 2.625rem;
}

[data-sidebar-style="full"][direction="rtl"] .dlabnav .metismenu li>a i {
  padding: 0 0 0 0;
  margin-right: 0;
  margin-left: 0.625rem;
}

[direction="rtl"] .dlabnav .metismenu li>a svg {
  margin-left: 0.3125rem;
  margin-right: 0;
}

[data-sidebar-style="compact"][direction="rtl"] .dlabnav .metismenu li>a svg {
  left: auto;
  margin-left: auto;
  margin-right: auto;
}

[data-sidebar-style="icon-hover"][direction="rtl"] .dlabnav .metismenu li>a svg {
  margin-left: 0;
}

[direction="rtl"] .dlabnav .metismenu li ul a {
  padding-right: 6rem;
  padding-left: 0.625rem;
}

[direction="rtl"] .dlabnav .metismenu li.active>.has-arrow:after {
  transform: rotate(45deg) translateY(-50%);
}

[direction="rtl"] .dlabnav .metismenu .has-arrow:after {
  left: 1.5625rem;
  right: auto;
}

[data-layout="horizontal"][direction="rtl"] .dlabnav .metismenu .has-arrow:after {
  left: 1.125rem;
}

[data-sidebar-style="modern"][direction="rtl"] .dlabnav .metismenu .has-arrow:after {
  -webkit-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li .has-arrow:after {
  left: 1.5rem;
  right: auto;
}

[direction="rtl"][data-sidebar-style="mini"] .dlabnav .metismenu>li>a>i {
  padding: 0;
}

[direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li>ul a.has-arrow:after {
  left: 1.5625rem;
  right: auto;
}

[direction="rtl"][data-sidebar-style="compact"] .dlabnav .metismenu li>a i {
  padding: 0;
  margin-left: auto;
  margin-right: auto;
}

[direction="rtl"][data-sidebar-style="compact"] .dlabnav .metismenu li ul a {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu li>ul li:hover ul {
  right: 11.8125rem;
  left: 0;
}

[direction="rtl"] {
  /* ===== Light gallery ===== */
}

[direction="rtl"] .select2-container--default .select2-selection--single .select2-selection__arrow {
  left: 15px;
  right: auto;
}

[direction="rtl"] .input-group>.bootstrap-select:not(:first-child) .dropdown-toggle {
  border-radius: 0.75rem 0 0 0.75rem;
}

[direction="rtl"] .list-group {
  padding-left: 0;
}

[direction="rtl"] .form-check-input {
  margin-left: -1.25rem;
  margin-right: inherit;
}

[direction="rtl"] .form-check-inline .form-check-input {
  margin-right: 0;
  margin-left: 10px;
}

[direction="rtl"] .radio input,
[direction="rtl"] .radio-inline,
[direction="rtl"] .checkbox input,
[direction="rtl"] .checkbox-inline input {
  margin-left: 0;
  margin-right: 0;
}

[direction="rtl"] .content-body {
  margin-right: 21.563rem;
  margin-left: auto;
}

[data-sidebar-style="modern"][direction="rtl"] .content-body {
  margin-right: 9.375rem;
}

[direction="rtl"] .content-body .page-titles {
  text-align: right;
}

[direction="rtl"] .doctor-info-details .media-body span i,
[direction="rtl"] .recovered-chart-deta .col [class*="bg-"] {
  margin-right: 0;
  margin-left: 10px;
}

[direction="rtl"] .patients-chart-deta .col,
[direction="rtl"] .patients-chart-deta .col [class*="bg-"],
[direction="rtl"] .recovered-chart-deta .col {
  margin-right: 0;
  margin-left: 15px;
}

[direction="rtl"] .best-doctor .timeline .timeline-panel .media .number {
  left: auto;
  right: -13px;
}

[direction="rtl"] .doctor-info-details .media i {
  right: 0;
  left: -15px;
}

[direction="rtl"] .review-table .disease {
  border-left: 0;
  border-right: 1px solid #eee;
  padding-left: 0;
  padding-right: 20px;
}

[direction="rtl"] .apexcharts-legend-text {
  margin: 4px;
}

[direction="rtl"] .doctor-info-details .media-body {
  padding-left: 0;
  padding-right: 40px;
}

[direction="rtl"] .custom-control {
  margin-left: 0;
}

[direction="rtl"] .review-tab.nav-pills li:first-child a.nav-link {
  border-radius: 0 0.75rem 0 0;
}

[direction="rtl"] .review-tab.nav-pills li:last-child a.nav-link {
  border-radius: 0.75rem 0 0 0;
}

[direction="rtl"] .form-head .btn i {
  margin-left: 5px;
  margin-right: 0;
}

[direction="rtl"] .iconbox {
  padding-left: 0;
  padding-right: 70px;
}

[direction="rtl"] .iconbox i {
  left: auto;
  right: 0;
}

[direction="rtl"] .table.tr-rounded tr td:first-child, [direction="rtl"] .table.tr-rounded tr th:first-child {
  border-radius: 0 0.625rem 0.625rem 0;
}

[direction="rtl"] .table.tr-rounded tr td:last-child, [direction="rtl"] .table.tr-rounded tr th:last-child {
  border-radius: 0.625rem 0 0 0.625rem;
}

[direction="rtl"] .custom-switch.toggle-switch.text-right {
  padding-left: 48px;
  padding-right: 0;
}

[direction="rtl"] .toggle-switch.text-right .custom-control-label:before {
  right: auto !important;
  left: -47px;
}

[direction="rtl"] .toggle-switch.text-right .custom-control-label:after {
  right: auto !important;
  left: -28px;
}

[direction="rtl"] .toggle-switch.text-right .custom-control-input:checked~.custom-control-label:after {
  left: -62px;
  right: auto !important;
}

[direction="rtl"] .check-switch {
  padding-right: 40px;
}

[direction="rtl"] .check-switch .custom-control-label:after, [direction="rtl"] .check-switch .custom-control-label:before {
  right: -35px !important;
}

[direction="rtl"] .bar-chart .apexcharts-yaxis {
  transform: translatex(101%);
}

[direction="rtl"] .detault-daterange .input-group-text {
  padding: 0.532rem 0.75rem;
  padding-right: auto;
  padding-left: 0;
}

[direction="rtl"] .form-wrapper .input-group .form-control {
  text-align: left;
}

[direction="rtl"] .timeline-chart .apexcharts-yaxis {
  transform: translateX(0);
}

[direction="rtl"] .card-table td:first-child {
  padding-right: 30px;
  padding-left: 10px;
}

[direction="rtl"] .card-table td:last-child {
  padding-left: 30px;
  padding-right: 10px;
}

[direction="rtl"] .chatbox .img_cont {
  margin-right: 0px;
  margin-left: 10px;
}

[direction="rtl"] .profile-tab .nav-item .nav-link {
  margin-right: 0;
  margin-left: 30px;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .profile-tab .nav-item .nav-link {
    margin-left: 0px;
  }
}

[direction="rtl"] .widget-timeline .timeline>li>.timeline-panel {
  margin-left: 0px;
  margin-right: 40px;
}

[direction="rtl"] .widget-timeline.style-1 .timeline .timeline-badge.timeline-badge+.timeline-panel {
  padding: 5px 15px 5px 10px;
  border-width: 0px 5px 0px 0px;
}

[direction="rtl"] .widget-timeline.style-1 .timeline .timeline-badge.timeline-badge+.timeline-panel:after {
  border: 0;
  right: -9px;
  width: 7px;
  left: auto;
  height: 7px;
}

[direction="rtl"] .widget-timeline .timeline>li>.timeline-badge {
  left: auto;
  right: 0px;
}

[direction="rtl"] .widget-timeline .timeline>li>.timeline-panel:after {
  left: auto;
  right: -5px;
}

[direction="rtl"] .chatbox .msg_cotainer {
  margin-left: 0;
  margin-right: 10px;
}

[direction="rtl"] .new-arrival-content .price {
  float: left;
}

[direction="rtl"] .input-group>.bootstrap-select:not(:first-child) .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

[direction="rtl"] .input-group>.bootstrap-select:not(:last-child) .dropdown-toggle {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

[direction="rtl"] .breadcrumb-item+.breadcrumb-item {
  padding-right: 0.5rem;
  padding-left: 0;
}

[direction="rtl"] .breadcrumb-item+.breadcrumb-item::before {
  padding-right: 0;
  padding-left: 0.5rem;
}

[direction="rtl"] .chatbox .chatbox-close {
  left: 340px;
  right: auto;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .chatbox .chatbox-close {
    left: 280px;
  }
}

[direction="rtl"] .separator {
  margin-right: 0;
  margin-left: 9px;
}

[direction="rtl"] .card-tabs .nav-tabs {
  padding-right: 5px;
}

[direction="rtl"] .chatbox .msg_cotainer {
  margin-left: 0;
  margin-right: 10px;
  border-radius: 1.375rem 0 1.375rem 1.375rem;
}

[direction="rtl"] .chatbox .msg_cotainer:after {
  left: auto;
  right: -10px;
  transform: rotate(-90deg);
}

[direction="rtl"] .chatbox .type_msg .input-group .input-group-append .btn {
  border-top-right-radius: 38px !important;
  border-bottom-right-radius: 38px !important;
}

[direction="rtl"] .chatbox .msg_cotainer_send {
  margin-right: 0px;
  margin-left: 10px;
  border-radius: 0 6px 6px 6px;
}

[direction="rtl"] .chatbox .msg_cotainer_send:after {
  right: auto;
  left: -10px;
  transform: rotate(90deg);
}

[direction="rtl"] .new-arrival-content .price {
  float: left;
}

[direction="rtl"] .trending-menus .tr-row .num {
  margin-right: 0;
  margin-left: 15px;
}

[direction="rtl"] .default-select.style-2 .btn:after {
  margin-left: 0;
  margin-right: 0.5em;
}

[direction="rtl"] .widget-timeline-icon li .icon {
  left: auto;
  right: -2px;
}

[direction="rtl"] .widget-timeline-icon li a {
  padding: 25px 0px 0px 50px;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .widget-timeline-icon .timeline {
    margin-left: 0;
    margin-right: 10px;
  }

  [direction="rtl"] .widget-timeline-icon li {
    border-left: 0;
    border-right: 6px solid #453773;
  }

  [direction="rtl"] .widget-timeline-icon li a {
    padding: 0 30px 30px 0;
  }

  [direction="rtl"] .widget-timeline-icon li .icon {
    right: -12px;
  }

  [direction="rtl"] .widget-timeline-icon li:last-child {
    border-color: transparent;
  }
}

[direction="rtl"] #revenueMap .apexcharts-yaxis, [direction="rtl"] #customerMapkm .apexcharts-yaxis {
  transform: translateX(0px);
}

[direction="rtl"] .mail-list .list-group-item i {
  padding-right: 0;
  padding-left: 0.625rem;
}

[direction="rtl"] .dlab-demo-panel {
  right: auto;
  left: -380px;
}

[direction="rtl"] .dlab-demo-panel.show {
  right: unset;
  left: 0;
}

[direction="rtl"] .dlab-demo-panel .dlab-demo-trigger {
  left: 100%;
  right: auto;
  border-radius: 0 5px 5px 0;
  box-shadow: 5px 3px 5px 0px rgba(119, 119, 119, 0.15);
}

[direction="rtl"][data-layout="horizontal"] .content-body {
  margin-right: 0;
}

[direction="rtl"][data-layout="horizontal"] .dlabnav .metismenu li li .has-arrow:after {
  -webkit-transform: rotate(-4deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
}

[direction="rtl"][data-sidebar-style="mini"]:not([data-layout="horizontal"]) .content-body {
  margin-right: 6.25rem;
}

[direction="rtl"][data-sidebar-style="compact"]:not([data-layout="horizontal"]) .content-body {
  margin-right: 11.25rem;
}

[direction="rtl"][data-sidebar-style="overlay"] .content-body {
  margin-right: 0;
}

[direction="rtl"] #external-events .external-event:before {
  margin-right: 0;
  margin-left: .9rem;
}

[direction="rtl"] .post-input a i {
  margin-left: 15px;
  margin-right: 0;
}

[direction="rtl"][data-sidebar-style="compact"] .dlabnav .metismenu .has-arrow:after {
  -webkit-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
}

[direction="rtl"] .dlabnav .metismenu .has-arrow:after {
  -webkit-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg) translateY(-50%);
}

[direction="rtl"] .dlabnav .metismenu .has-arrow[aria-expanded=true]:after,
[direction="rtl"] .dlabnav .metismenu .mm-active>.has-arrow:after {
  -webkit-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg);
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu .has-arrow[aria-expanded=true]:after,
[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu .mm-active>.has-arrow:after {
  -webkit-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(-45deg);
}

[direction="rtl"] .chatbox {
  left: -500px;
  right: auto;
}

[direction="rtl"] .chatbox.active {
  left: 0;
  right: auto;
}

@media only screen and (max-width: 575px) {
  [direction="rtl"] .best-doctor .timeline .timeline-panel .media {
    float: right;
    margin-right: 0 !important;
    margin-left: 15px !important;
  }
}

[direction="rtl"] .default-select.style-1 .btn:after {
  margin-left: 0;
  margin-right: 0.5em;
}

[direction="rtl"] .pagination .page-indicator {
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

[direction="rtl"] .lg-outer.lg-visible {
  direction: ltr;
}

[direction="rtl"] .chart-point .chart-point-list {
  margin: 0;
  padding-right: 20px;
}

[direction="rtl"] .noUi-target {
  direction: rtl;
}

[direction="rtl"] .noUi-vertical .noUi-pips-vertical {
  left: -20px;
}

[direction="rtl"] .noUi-vertical .noUi-value-vertical {
  padding-left: 0;
  padding-right: 25px;
}

[direction="rtl"] .sidebar-right .ps--active-x>.ps__rail-x {
  display: none;
}

[direction="rtl"] .form-wizard .nav-wizard li .nav-link:after {
  right: 50%;
  left: auto;
}

[direction="rtl"] .dtp>.dtp-content {
  right: 50%;
  left: auto;
}

[direction="rtl"] .modal-header .close {
  margin: 0;
  left: 0;
  top: 0px;
  right: auto;
}

[direction="rtl"] .input-group-prepend .btn+.btn {
  border-radius: 0 !important;
}

[direction="rtl"] .form-control+.input-group-append .btn:first-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

[direction="rtl"] .input-group>.custom-file:not(:first-child) .custom-file-label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0.75rem;
  border-top-left-radius: 0.75rem;
}

[direction="rtl"] .custom-file-label::after {
  border-radius: .75rem 0 0 .75rem;
}

[direction="rtl"] .input-group>.custom-file:not(:last-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

[direction="rtl"] .input-group>.custom-file:not(:last-child) .custom-file-label::after {
  border-radius: 0;
}

@media only screen and (max-width: 1350px) and (min-width: 1200px) {
  [direction="rtl"] .content-body {
    margin-right: 17rem;
  }
}

[direction="rtl"] .sidebar-right {
  right: auto;
  left: -50rem;
}

[direction="rtl"] .sidebar-right.show {
  left: 5.25rem;
  right: unset;
}

[direction="rtl"] .sidebar-right .sidebar-right-trigger {
  left: 100%;
  right: auto;
  border-radius: 0 5px 5px 0;
  box-shadow: 5px 3px 5px 0px rgba(119, 119, 119, 0.15);
}

[direction="rtl"] .sidebar-right .sidebar-close-trigger {
  right: auto;
  left: -48px;
}

[direction="rtl"] .bootstrap-select .dropdown-toggle .filter-option {
  text-align: right;
}

html[dir="rtl"] [direction="rtl"] .footer {
  padding-right: 17.1875rem;
  padding-left: 0;
}

@media (max-width: 767.98px) {
  html[dir="rtl"] [direction="rtl"] .footer {
    padding-right: 0;
  }
}

html[dir="rtl"] [direction="rtl"][data-sidebar-style="overlay"] .footer {
  padding-right: 0;
}

html[dir="rtl"] [direction="rtl"] .menu-toggle .footer {
  padding-right: 3.75rem;
}

html[dir="rtl"] [direction="rtl"][data-container="boxed"] .footer {
  padding-right: 0;
}

html[dir="rtl"] [direction="rtl"][data-sidebar-style="mini"]:not([data-layout="horizontal"]) .footer {
  padding-right: 3.75rem;
}

html[dir="rtl"] [direction="rtl"][data-sidebar-style="compact"]:not([data-layout="horizontal"]) .footer {
  padding-right: 9.375rem;
}

.nav-label {
  margin: 0.625rem 1.875rem 0;
  padding: 1.5625rem 0 0.625rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.05rem;
  border-top: 0.0625rem solid #eaeaea;
  color: #999999;
}

[data-theme-version="dark"] .nav-label {
  border-color: #333754;
}

.nav-label.first {
  border: 0rem;
  margin-top: 0rem;
}

.nav-badge {
  position: absolute;
  right: 2.8125rem;
  top: 0.625rem;
}

.content-body {
  margin-left: 18.563rem;
  z-index: 0;
  transition: all .2s ease;
  padding-right: 5.625rem;
}

@media only screen and (max-width: 87.5rem) {
  .content-body {
    margin-left: 17rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .content-body {
    padding-right: 0rem;
  }
}

.bell img {
  -webkit-animation: ring 8s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 0.25rem;
  -moz-animation: ring 8s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 0.25rem;
  animation: ring 8s .7s ease-in-out infinite;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }

  1% {
    -moz-transform: rotate(30deg);
  }

  3% {
    -moz-transform: rotate(-28deg);
  }

  5% {
    -moz-transform: rotate(34deg);
  }

  7% {
    -moz-transform: rotate(-32deg);
  }

  9% {
    -moz-transform: rotate(30deg);
  }

  11% {
    -moz-transform: rotate(-28deg);
  }

  13% {
    -moz-transform: rotate(26deg);
  }

  15% {
    -moz-transform: rotate(-24deg);
  }

  17% {
    -moz-transform: rotate(22deg);
  }

  19% {
    -moz-transform: rotate(-20deg);
  }

  21% {
    -moz-transform: rotate(18deg);
  }

  23% {
    -moz-transform: rotate(-16deg);
  }

  25% {
    -moz-transform: rotate(14deg);
  }

  27% {
    -moz-transform: rotate(-12deg);
  }

  29% {
    -moz-transform: rotate(10deg);
  }

  31% {
    -moz-transform: rotate(-8deg);
  }

  33% {
    -moz-transform: rotate(6deg);
  }

  35% {
    -moz-transform: rotate(-4deg);
  }

  37% {
    -moz-transform: rotate(2deg);
  }

  39% {
    -moz-transform: rotate(-1deg);
  }

  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

.dlabnav {
  width: 18.75rem;
  padding-bottom: 0;
  height: calc(100% - 6.25rem);
  position: absolute;
  top: 6.25rem;
  padding-top: 1rem;
  z-index: 6;
  background-color: var(--sidebar-bg);
  transition: all .2s ease;
  box-shadow: 0rem 0.9375rem 1.875rem 0rem rgba(0, 0, 0, 0.02);
}

@media only screen and (max-width: 87.5rem) {
  .dlabnav {
    top: 5.5rem;
    height: calc(100% - 78px);
  }
}

@media only screen and (max-width: 74.9375rem) {
  .dlabnav {
    top: 5rem;
    height: calc(100% - 70px);
  }
}

@media only screen and (max-width: 47.9375rem) {
  .dlabnav {
    width: 18rem;
  }
}

.dlabnav .dlabnav-scroll {
  position: relative;
  height: 100%;
}

@media only screen and (max-width: 87.5rem) {
  .dlabnav {
    width: 17rem;
  }
}

.dlabnav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.dlabnav .metismenu {
  display: flex;
  flex-direction: column;
}

.dlabnav .metismenu.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
}

.dlabnav .metismenu>li {
  display: flex;
  flex-direction: column;
}

.dlabnav .metismenu>li a>i {
  font-size: 1.1rem;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 0;
  height: auto;
  width: auto;
  text-align: center;
  margin-right: 0.95rem;
  border-radius: 0.125rem;
  line-height: 1;
  border-radius: 0.625rem;
}

[data-sidebar-style="compact"] .dlabnav .metismenu>li a>i {
  display: block;
  padding: 0;
  background: rgba(0, 0, 0, 0.05);
  /* background:rgba($light,0.2); */
  color: rgba(0, 0, 0, 0.3);
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 0.625rem;
  line-height: 3.45rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.3125rem;
}

[data-sidebar-style="compact"] .dlabnav .metismenu>li a>i[data-theme-version="dark"] {
  color: #fff;
}

@media only screen and (max-width: 84.375rem) {
  .dlabnav .metismenu>li a>i {
    height: auto;
    line-height: 0.0625rem;
    width: auto;
    font-size: 1.5rem;
    padding: 0;
    color: #969BA0;
  }
}

.dlabnav .metismenu>li>a {
  font-weight: 500;
  display: inline-block;
  font-size: 1rem;
  color: #737B8B;
}

.dlabnav .metismenu>li>a i {
  color: #96A0AF;
}

.dlabnav .metismenu>li>a svg {
  max-width: 1rem;
  max-height: 1rem;
  height: 100%;
  margin-right: 0.4125rem;
  margin-top: -0.0875rem;
}

.dlabnav .metismenu>li>a svg path {
  fill: #96a0af;
}

.dlabnav .metismenu>li>a g [fill] {
  fill: #8088a1;
}

.dlabnav .metismenu>li:hover>a, .dlabnav .metismenu>li:focus>a {
  /*  background-color: lighten($color: $primary, $amount: 40%); */
  color: var(--primary);
}

.dlabnav .metismenu>li:hover>a g [fill], .dlabnav .metismenu>li:focus>a g [fill] {
  fill: var(--primary);
}

.dlabnav .metismenu>li.mm-active>a {
  color: #fff;
  background: var(--primary);
  font-weight: 500;
  box-shadow: 0px 20px 50px 0px #4537731A;
}

.dlabnav .metismenu>li.mm-active>a i {
  font-weight: 100;
}

.dlabnav .metismenu>li.mm-active>a svg path {
  fill: #fff;
}

.dlabnav .metismenu>li.mm-active>a g [fill] {
  fill: var(--primary);
}

.dlabnav .metismenu>li.mm-active>a:after {
  border-top: 0.3125rem solid #fff;
  border-left: 0.3125rem solid #fff;
}

[data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a i {
  background: var(--primary);
  color: #fff;
}

[data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a svg {
  background: var(--primary);
}

[data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a svg path {
  fill: #fff;
}

.dlabnav .metismenu li {
  position: relative;
}

.dlabnav .metismenu ul {
  transition: all .2s ease-in-out;
  position: relative;
  z-index: 1;
  padding: 0.5rem 0;
}

.dlabnav .metismenu ul a {
  padding-top: .5rem;
  padding-bottom: .5rem;
  position: relative;
  font-size: 0.9375rem;
  padding-left: 3.5rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 84.375rem) {
  .dlabnav .metismenu ul a {
    padding-left: 4.6rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .dlabnav .metismenu ul a {
    padding-left: 3.4rem;
    font-size: 0.875rem;
  }
}

[data-sidebar-style="full"] .dlabnav .metismenu ul a:before {
  content: "";
  height: 0.125rem;
  width: 0.375rem;
  background: var(--rgba-primary-5);
  position: absolute;
  left: 1.8rem;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

[data-sidebar-style="full"] .dlabnav .metismenu ul a:hover {
  padding-left: 4.2rem;
}

@media only screen and (max-width: 78.125rem) {
  [data-sidebar-style="full"] .dlabnav .metismenu ul a:hover {
    padding-left: 3.3rem;
  }
}

[data-sidebar-style="full"] .dlabnav .metismenu ul a:hover:before {
  width: 1.25rem;
}

.dlabnav .metismenu ul a:hover, .dlabnav .metismenu ul a:focus, .dlabnav .metismenu ul a.mm-active {
  text-decoration: none;
  color: var(--primary);
}

.dlabnav .metismenu ul a:before {
  position: absolute;
  content: none;
  height: 0.125rem;
  width: 0.5rem;
  top: 50%;
  left: 1.75rem;
  border-radius: 3.75rem;
  transform: translateY(-50%);
}

@media only screen and (max-width: 84.375rem) {
  .dlabnav .metismenu ul a:before {
    left: 2.8125rem;
  }
}

.dlabnav .metismenu a {
  position: relative;
  display: block;
  padding: 0.625rem 1.875rem;
  outline-width: 0;
  color: #737B8B;
  text-decoration: none;
}

@media only screen and (max-width: 47.9375rem) {
  .dlabnav .metismenu a {
    padding: 0.625rem 1.25rem;
  }
}

.dlabnav .metismenu .has-arrow:after {
  /* width:  .5rem;
                height: .5rem;
                border-color: inherit; */
  border-top: 0.3125rem solid #C8C8C8;
  border-left: 0.3125rem solid #C8C8C8;
  border-bottom: 0.3125rem solid transparent;
  border-right: 0.3125rem solid transparent;
  right: 1.875rem;
  top: 50%;
  -webkit-transform: rotate(-225deg) translateY(-50%);
  transform: rotate(-225deg) translateY(-50%);
}

.dlabnav .metismenu .has-arrow[aria-expanded=true]:after,
.dlabnav .metismenu .mm-active>.has-arrow:after {
  -webkit-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg) translateY(-50%);
  /* margin-right: 2rem; */
}

.dlabnav .main-menu {
  padding: 0.625rem 1.875rem;
}

.dlabnav .header-info2 {
  padding: 0 20px;
}

.dlabnav .header-info2 img {
  height: 3.75rem;
  width: 3.75rem;
  border-radius: 1.25rem;
  margin-right: 1.2rem;
}

.dlabnav .header-info2 span {
  font-size: 1.25rem;
  font-weight: 500;
  color: #393939;
}

@media only screen and (max-width: 87.5rem) {
  .dlabnav .header-info2 span {
    font-size: 0.9rem;
  }
}

.dlabnav .header-info2 i {
  margin-left: 1rem;
  font-size: 1.2rem;
}

@media only screen and (max-width: 87.5rem) {
  .dlabnav .header-info2 {
    padding: 0;
  }
}

.dlabnav .ps__rail-y {
  left: 0 !important;
  opacity: 0.1;
}

.dlabnav .ps__rail-y:hover {
  opacity: 0.1;
}

.copyright {
  padding: 0 1.875rem;
}

.copyright p {
  font-size: 0.875rem;
}

.copyright strong {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
}

@media only screen and (max-width: 63.9375rem) {
  .nav-header {
    width: 5rem;
  }
}

@media (max-width: 47.9375rem) {
  .brand-title {
    display: none;
  }

  .footer {
    padding-left: 0;
  }

  .dlabnav {
    left: 0;
    top: 5rem;
  }
}

.sidebar-dropdown {
  font-size: 0.875rem;
  font-weight: 500;
  color: #000;
  cursor: pointer;
}

.sidebar-dropdown .sidebar-select i {
  transition: all 0.5s;
  font-size: 1rem;
  color: #000;
}

.sidebar-dropdown .dropdown-menu {
  inset: auto 60px -30px auto !important;
  min-width: 4rem;
}

.sidebar-dropdown .dropdown-menu:after {
  content: none;
}

.sidebar-dropdown .dropdown-menu .dropdown-item {
  padding: 0.4rem 1.45rem;
}

@media only screen and (max-width: 61.9375rem) {
  .sidebar-dropdown .dropdown-menu {
    inset: auto -10px -100px auto !important;
  }
}

.sidebar-dropdown.show .sidebar-select i {
  transform: rotate(-180deg);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu>ul.collapse:not(.in),
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu>ul.collapse:not(.in) {
  height: 15.75rem !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu:hover>a {
  width: calc(70vw + 3.75rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 13.75rem;
  width: 70vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-xl:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-xl:hover>a {
  width: calc(70vw + 3rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-xl:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 12.5rem;
  width: 70vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-xl:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-xl:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-lg:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-lg:hover>a {
  width: calc(55vw + 3rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-lg:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 12.5rem;
  width: 55vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-lg:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-lg:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-md:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-md:hover>a {
  width: calc(45vw + 3);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-md:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 18.75rem;
  width: 45vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-md:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-md:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-sm:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-sm:hover>a {
  width: calc(30vw + 3);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-sm:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 18.125rem;
  width: 30vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-sm:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-sm:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu:hover>a {
  width: calc(60vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 25rem;
  width: 60vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-xl:hover>a {
  width: calc(60vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 25.625rem;
  width: 60vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-lg:hover>a {
  width: calc(50vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 16.25rem;
  width: 50vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-md:hover>a {
  width: calc(40vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 18.75rem;
  width: 40vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-sm:hover>a {
  width: calc(22vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 18.125rem;
  width: 22vw;
}

[data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:not(:last-child) {
  position: static;
}

[data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul {
  left: 0;
  right: 0;
}

[data-theme-version="dark"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li {
  border-color: #333754;
}

[data-sibebarbg="color_2"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li {
  border-color: #2b735d;
}

[data-sibebarbg="color_3"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li {
  border-color: #f21900;
}

[data-sibebarbg="color_4"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li {
  border-color: #f85500;
}

[data-sibebarbg="color_5"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li {
  border-color: #001ecb;
}

[data-sibebarbg="color_6"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li {
  border-color: #0057d8;
}

[data-sibebarbg="color_7"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li {
  border-color: #1971a3;
}

[data-sibebarbg="color_8"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li {
  border-color: #7b3581;
}

[data-sibebarbg="color_9"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li {
  border-color: #56704e;
}

[data-sibebarbg="color_10"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li {
  border-color: #372b99;
}

[data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li a {
  transition: all .4s ease-in-out;
}

[data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li a:hover {
  border-radius: 0.25rem;
}

[data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 13.75rem;
  width: 70vw;
  z-index: 99;
}

[data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul::after {
  content: "";
  height: 100%;
  width: 0.0625rem;
  position: absolute;
  background-color: #fff;
  right: 2.8125rem;
  top: 0;
}

[data-theme-version="dark"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #262647;
}

[data-sibebarbg="color_2"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #39987b;
}

[data-sibebarbg="color_3"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #ff3c26;
}

[data-sibebarbg="color_4"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #ff752c;
}

[data-sibebarbg="color_5"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #0025fe;
}

[data-sibebarbg="color_6"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #0c6dff;
}

[data-sibebarbg="color_7"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #208fcf;
}

[data-sibebarbg="color_8"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #9e44a5;
}

[data-sibebarbg="color_9"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #6d8f62;
}

[data-sibebarbg="color_10"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #4636c1;
}

[data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul ul a {
  width: 101%;
}

[data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 13.125rem;
  width: 70vw;
}

[data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 13.125rem;
  width: 43.75rem;
  height: 13.125rem !important;
}

@media only screen and (min-width: 75rem) and (max-width: 93.75rem) {
  [data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu-lg:hover>ul {
    width: 43.75rem;
  }
}

[data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 20rem;
  width: 54vw;
}

@media only screen and (min-width: 75rem) and (max-width: 93.75rem) {
  [data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu-md:hover>ul {
    width: 60vw;
  }
}

[data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 20rem;
  width: 25vw;
}

@media only screen and (min-width: 75rem) and (max-width: 93.75rem) {
  [data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu-sm:hover>ul {
    width: 35vw;
  }
}

[data-layout="horizontal"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

[data-layout="horizontal"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 21.875rem;
  width: 100%;
}

[data-layout="horizontal"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 21.875rem;
  width: 55vw;
}

[data-layout="horizontal"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 18.75rem;
  width: 45vw;
}

[data-layout="horizontal"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 18.125rem;
  width: 50vw;
}

:root {
  --primary: #453773;
  --secondary: #4CBC9A;
  --secondary-dark: #3a9b7e;
  --primary-hover: #ff3c26;
  --primary-dark: #bf1400;
  --rgba-primary-1: rgba(255, 106, 89, 0.1);
  --rgba-primary-2: rgba(255, 106, 89, 0.2);
  --rgba-primary-3: rgba(255, 106, 89, 0.3);
  --rgba-primary-4: rgba(255, 106, 89, 0.4);
  --rgba-primary-5: rgba(255, 106, 89, 0.5);
  --rgba-primary-6: rgba(255, 106, 89, 0.6);
  --rgba-primary-7: rgba(255, 106, 89, 0.7);
  --rgba-primary-8: rgba(255, 106, 89, 0.8);
  --rgba-primary-9: rgba(255, 106, 89, 0.9);
  --rgba-secondary-1: rgba(76, 188, 154, 0.1);
  --rgba-secondary-2: rgba(76, 188, 154, 0.2);
  --rgba-secondary-3: rgba(76, 188, 154, 0.3);
  --rgba-secondary-4: rgba(76, 188, 154, 0.4);
  --rgba-secondary-5: rgba(76, 188, 154, 0.5);
  --rgba-secondary-6: rgba(76, 188, 154, 0.6);
  --rgba-secondary-7: rgba(76, 188, 154, 0.7);
  --rgba-secondary-8: rgba(76, 188, 154, 0.8);
  --rgba-secondary-9: rgba(76, 188, 154, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #374557;
}

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
[data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li {
  padding: 0 1.5rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li>a {
  font-size: 1rem;
  padding: 0.825rem 1.5rem;
  margin: 5px 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 0.625rem;
  /* &:before{
						position:absolute;
						content:"";
						background:var(--primary);
						width:0;
						height:100%;
						top:0;
						right:0rem;
						@include transitionMedium;
						border-radius:1.25rem;
						
					} */
}

[data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a {
  /* &:before{
							width:0.563rem;
							@include respond ('laptop'){
								width:0.600rem;	
							}
						} */
}

[data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
  font-weight: 300;
}

[data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li .has-arrow:after {
  right: 1.5rem;
}

@media only screen and (max-width: 87.5rem) {
  [data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li {
    padding: 0 0.9rem;
  }

  [data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li>a {
    font-size: 1rem;
    padding: 0.9375rem 1.25rem;
  }
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header {
  width: 6.5rem;
  z-index: 999;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .brand-logo {
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control {
  right: -4rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: var(--primary);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .copyright,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .plus-box {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header {
  padding-left: 7.5rem;
  width: 100%;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header {
  padding: 0 0.9375rem;
  padding-right: 7.5rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header .header-content {
  padding-left: 5rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav {
  width: 6.5rem;
  overflow: visible;
  position: absolute;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .nav-text {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .slimScrollDiv,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .dlabnav-scroll {
  overflow: visible !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .header-info2 {
  padding: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .sidebar-info {
  display: none !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .header-profile2 {
  margin: 0 0.5rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu li {
  position: relative;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu li a {
  background: transparent;
  margin: 0.125rem 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu li a svg {
  max-width: 1.5rem;
  max-height: 1.5rem;
  margin-right: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu li a:before {
  content: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu li a i {
  margin: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu li>ul {
  position: absolute;
  left: 5.8rem;
  top: 0;
  width: 12rem;
  z-index: 1001;
  display: none;
  padding-left: 0.0625rem;
  height: auto !important;
  box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
  border-radius: 0.375rem;
  margin-left: 0;
  border: 0;
  background: #fff;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu li>ul {
  left: auto;
  right: 5rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu li>ul li:hover ul {
  left: 11.8125rem;
  top: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu li>ul li:hover ul:after {
  content: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu li:hover>ul {
  display: block;
  height: auto;
  overflow: visible;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li {
  transition: all 0.4s ease-in-out;
  padding: 0 1.125rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li>a {
  padding: 0.725rem 0.775rem;
  margin: 3px 6px;
  text-align: center;
  border-radius: 0.625rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li>a.has-arrow:after {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mm-active>a {
  background: var(--primary);
  border-radius: 0.625rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
  padding: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li:hover:nth-last-child(-n + 1)>ul {
  bottom: 0;
  top: auto;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li:hover>a {
  border-radius: 0.625rem;
  background: var(--primary);
  color: #fff;
}

[data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li:hover>a {
  background: #292d4a;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li:hover>a i {
  color: #fff;
  /* background:$primary; */
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li:hover>a svg path {
  fill: #fff;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li:hover>ul {
  height: auto !important;
  padding: 0.625rem 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li:hover>ul a {
  padding: 0.375rem 1.25rem 0.375rem 1.25rem;
  margin-left: -.1rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li:hover>ul ul {
  padding: 0.625rem 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li:hover>ul ul a {
  padding: 0.375rem 1.25rem 0.375rem 1.25rem;
  margin-left: -.1rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu .nav-label,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu .nav-badge {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .content-body {
  margin-left: 6.5rem;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .content-body {
  margin-right: 5.7rem;
  margin-left: auto;
  border: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle+.footer {
  padding-left: 5.7rem;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle+.footer {
  padding-left: 0;
  padding-right: 5.7rem;
}

[data-sidebar-style="full"][data-layout="horizontal"] .header .header-content {
  padding-left: 1.875rem;
}

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
:root {
  --primary: #453773;
  --secondary: #4CBC9A;
  --secondary-dark: #3a9b7e;
  --primary-hover: #ff3c26;
  --primary-dark: #bf1400;
  --rgba-primary-1: rgba(255, 106, 89, 0.1);
  --rgba-primary-2: rgba(255, 106, 89, 0.2);
  --rgba-primary-3: rgba(255, 106, 89, 0.3);
  --rgba-primary-4: rgba(255, 106, 89, 0.4);
  --rgba-primary-5: rgba(255, 106, 89, 0.5);
  --rgba-primary-6: rgba(255, 106, 89, 0.6);
  --rgba-primary-7: rgba(255, 106, 89, 0.7);
  --rgba-primary-8: rgba(255, 106, 89, 0.8);
  --rgba-primary-9: rgba(255, 106, 89, 0.9);
  --rgba-secondary-1: rgba(76, 188, 154, 0.1);
  --rgba-secondary-2: rgba(76, 188, 154, 0.2);
  --rgba-secondary-3: rgba(76, 188, 154, 0.3);
  --rgba-secondary-4: rgba(76, 188, 154, 0.4);
  --rgba-secondary-5: rgba(76, 188, 154, 0.5);
  --rgba-secondary-6: rgba(76, 188, 154, 0.6);
  --rgba-secondary-7: rgba(76, 188, 154, 0.7);
  --rgba-secondary-8: rgba(76, 188, 154, 0.8);
  --rgba-secondary-9: rgba(76, 188, 154, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #374557;
}

@media only screen and (min-width: 48rem) {
  [data-sidebar-style="mini"] .nav-header {
    width: 6.25rem;
  }

  [data-sidebar-style="mini"] .nav-header .nav-control {
    z-index: -1;
  }

  [data-sidebar-style="mini"] .nav-header .nav-control .hamburger {
    left: 6.25rem !important;
  }

  [data-sidebar-style="mini"] .nav-header .nav-control .hamburger .line {
    background-color: #6e6e6e !important;
  }

  [data-sidebar-style="mini"] .nav-header .brand-title {
    display: none;
  }

  [data-sidebar-style="mini"] .nav-header .hamburger {
    display: none;
  }

  [data-sidebar-style="mini"] .header .header-content {
    padding-left: 1.875rem;
  }

  [direction="rtl"][data-sidebar-style="mini"] .header .header-content {
    padding-right: 1.875rem;
  }

  [data-sidebar-style="mini"] .dlabnav {
    width: 6.25rem;
    overflow: visible;
    position: absolute;
    z-index: 2;
    top: 6.5rem;
  }

  [data-sidebar-style="mini"] .dlabnav .copyright,
  [data-sidebar-style="mini"] .dlabnav .plus-box {
    display: none;
  }

  [data-sidebar-style="mini"] .dlabnav .nav-text {
    display: none;
  }

  [data-sidebar-style="mini"] .dlabnav .slimScrollDiv,
  [data-sidebar-style="mini"] .dlabnav .dlabnav-scroll {
    overflow: visible !important;
  }

  [data-sidebar-style="mini"] .dlabnav .nav-user {
    padding: 0.6875rem;
  }

  [data-sidebar-style="mini"] .dlabnav .nav-user .media-body {
    display: none;
  }

  [data-sidebar-style="mini"] .dlabnav .header-profile {
    margin-bottom: 0;
    margin-top: 0.75rem;
  }

  [data-sidebar-style="mini"] .dlabnav .header-profile:hover>a.nav-link {
    border-radius: 3rem;
  }

  [data-sidebar-style="mini"] .dlabnav .header-profile img {
    width: 3rem;
    height: 3rem;
  }

  [data-sidebar-style="mini"] .dlabnav .header-profile>a.nav-link {
    border-radius: 3rem;
    padding: 0.3125rem 0.3125rem;
  }

  [data-sidebar-style="mini"] .dlabnav .header-profile .header-info {
    display: none;
  }

  [data-sidebar-style="mini"] .dlabnav .metismenu li a {
    padding: 0.813rem 0.875rem;
  }

  [data-sidebar-style="mini"] .dlabnav .metismenu li a svg {
    margin-right: 0;
  }

  [data-sidebar-style="mini"] .dlabnav .metismenu li a i {
    height: auto;
    width: auto;
    line-height: 1;
    margin: 0;
  }

  [data-sidebar-style="mini"] .dlabnav .metismenu li>ul {
    position: absolute;
    left: 6.25rem;
    top: 2.9375rem;
    width: 11.875rem;
    z-index: 1001;
    display: none;
    padding-left: 0.0625rem;
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
    height: auto !important;
    border-radius: 0.625rem;
    background: #fff;
  }

  [direction="rtl"]:not([data-layout="horizontal"])[data-sidebar-style="mini"] .dlabnav .metismenu li>ul {
    left: auto;
    right: 6.25rem;
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
  }

  [data-sidebar-style="mini"] .dlabnav .metismenu>li {
    padding: 0.125rem 1.25rem;
  }

  [data-sidebar-style="mini"] .dlabnav .metismenu>li>a {
    padding: 0.725rem 0.775rem;
    /* width: 64px;
						height: 64px; */
    text-align: center;
    margin: 3px 6px;
    line-height: 1;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
  }

  [data-sidebar-style="mini"] .dlabnav .metismenu>li>a>i {
    padding: 0;
    font-size: 1.375rem;
  }

  [data-sidebar-style="mini"] .dlabnav .metismenu>li>a.has-arrow:after {
    display: none;
  }

  [data-sidebar-style="mini"] .dlabnav .metismenu .nav-label,
  [data-sidebar-style="mini"] .dlabnav .metismenu .nav-badge {
    display: none;
  }
}

@media only screen and (min-width: 48rem) and (max-width: 63.9375rem) {
  [data-sidebar-style="mini"] .dlabnav {
    top: 5.5rem;
  }
}

@media only screen and (min-width: 48rem) {
  [data-sidebar-style="mini"] .dlabnav .header-info2 {
    padding: 0;
  }

  [data-sidebar-style="mini"] .dlabnav .sidebar-info {
    display: none !important;
  }

  [data-sidebar-style="mini"] .dlabnav .header-profile2 {
    margin: 0 0.5rem;
  }

  [data-sidebar-style="mini"] .content-body {
    margin-left: 6.5rem;
  }

  [data-sidebar-style="mini"] .footer {
    padding-left: 6.5rem;
  }

  [data-sidebar-style="mini"][data-header-position="fixed"] .content-body {
    padding-top: 6.5rem;
  }
}

@media only screen and (min-width: 48rem) and (max-width: 63.9375rem) {
  [data-sidebar-style="mini"][data-header-position="fixed"] .content-body {
    padding-top: 5.5rem;
  }
}

@media only screen and (min-width: 48rem) {
  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav {
    position: absolute !important;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu li:hover>ul {
    display: block;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:nth-last-child(-n + 1)>ul {
    bottom: 0;
    top: auto !important;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:nth-last-child(-n + 1)>ul:after {
    top: auto;
    bottom: 1.25rem;
  }
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:nth-last-child(-n + 1)>ul {
    bottom: 0;
    top: auto !important;
  }
}

@media only screen and (min-width: 48rem) {
  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li>ul {
    overflow: visible;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li>ul:after {
    content: none;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li>ul li:hover ul {
    padding: 0.625rem 0;
    width: 13rem;
    left: 13rem;
    top: -0.625rem;
    border: 0;
    margin: 0;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li>ul li:hover ul:after {
    content: none;
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li>ul li:hover ul {
    left: auto;
    right: 13rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a {
    background: var(--primary);
    color: #fff;
    border-radius: 0.625rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
    color: #fff;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
    background: var(--primary);
    color: var(--primary);
    /* box-shadow:0 12px 15px 0 var(--rgba-primary-1); */
    border-radius: 0.625rem;
    position: unset;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
    color: #fff;
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a .nav-text {
    padding-left: auto;
    padding-right: 1.6875rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:hover>ul {
    height: auto !important;
    overflow: visible;
    border: 0;
    margin-left: 0;
    left: 6.25rem;
    width: 13rem;
    border-radius: 0.625rem;
    border: 0;
    padding: 0.625rem 0;
    top: 0;
  }

  [data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:hover>ul {
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:hover>ul {
    left: auto;
    right: 6.25rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:hover>ul a {
    padding: 0.375rem 1.25rem 0.375rem 1.25rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:hover>ul a:before {
    content: none;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:hover>ul a.has-arrow:after {
    right: 1.25rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:hover>ul ul a {
    padding: 0.375rem 1.25rem 0.375rem 1.25rem;
    margin-left: -0.1rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:hover>ul ul a:before {
    content: none;
  }

  [data-sidebar-style="mini"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] .header {
    width: 74.9375rem;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .dlabnav .metismenu>li {
    padding: 0;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .dlabnav .metismenu>li>a {
    padding: 1.125rem;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .dlabnav .metismenu>li>a i {
    padding: 0;
    margin: 0;
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="horizontal"] .dlabnav .metismenu>li>a {
    padding: 1.125rem;
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="horizontal"] .dlabnav .metismenu>li>a svg {
    margin-left: 0;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .dlabnav .metismenu>li>a svg {
    margin-right: 0;
    margin-top: 0;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .dlabnav .metismenu>li>ul li a {
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  [data-sidebar-style="mini"] .dlabnav li.mm-active ul {
    height: auto !important;
  }

  [data-sidebar-style="mini"] .dlabnav li a.has-arrow::after {
    transform: rotate(-225deg) translateY(-50%);
  }
}

:root {
  --primary: #453773;
  --secondary: #4CBC9A;
  --secondary-dark: #3a9b7e;
  --primary-hover: #ff3c26;
  --primary-dark: #bf1400;
  --rgba-primary-1: rgba(255, 106, 89, 0.1);
  --rgba-primary-2: rgba(255, 106, 89, 0.2);
  --rgba-primary-3: rgba(255, 106, 89, 0.3);
  --rgba-primary-4: rgba(255, 106, 89, 0.4);
  --rgba-primary-5: rgba(255, 106, 89, 0.5);
  --rgba-primary-6: rgba(255, 106, 89, 0.6);
  --rgba-primary-7: rgba(255, 106, 89, 0.7);
  --rgba-primary-8: rgba(255, 106, 89, 0.8);
  --rgba-primary-9: rgba(255, 106, 89, 0.9);
  --rgba-secondary-1: rgba(76, 188, 154, 0.1);
  --rgba-secondary-2: rgba(76, 188, 154, 0.2);
  --rgba-secondary-3: rgba(76, 188, 154, 0.3);
  --rgba-secondary-4: rgba(76, 188, 154, 0.4);
  --rgba-secondary-5: rgba(76, 188, 154, 0.5);
  --rgba-secondary-6: rgba(76, 188, 154, 0.6);
  --rgba-secondary-7: rgba(76, 188, 154, 0.7);
  --rgba-secondary-8: rgba(76, 188, 154, 0.8);
  --rgba-secondary-9: rgba(76, 188, 154, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #374557;
}

@media (min-width: 64rem) {
  [data-layout="horizontal"] .nav-header {
    width: 21.563rem;
    height: 6.5rem;
    top: 0;
  }

  [data-layout="horizontal"] .nav-header .nav-control {
    display: none;
  }

  [data-layout="horizontal"] .nav-header .brand-logo {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  [data-layout="horizontal"] .header {
    width: 100%;
    height: 5.5rem;
    padding-left: 21.563rem;
    padding-top: 0;
  }

  [data-layout="horizontal"] .dlabnav {
    width: 100%;
    position: relative;
    height: auto;
    padding-bottom: 0;
    top: 0;
    z-index: 2;
  }

  [data-layout="horizontal"] .dlabnav .slimScrollDiv {
    overflow: visible !important;
  }

  [data-layout="horizontal"] .dlabnav .slimScrollDiv .dlabnav-scroll {
    overflow: visible !important;
  }

  [data-layout="horizontal"] .dlabnav .dlabnav-scroll {
    overflow: visible !important;
  }

  [data-layout="horizontal"] .dlabnav .slimScrollBar {
    display: none !important;
  }

  [data-layout="horizontal"] .dlabnav .header-profile {
    margin-right: 0.9375rem;
    margin-bottom: 0;
    display: none;
  }

  [data-layout="horizontal"] .dlabnav .header-profile:hover>a.nav-link {
    border-radius: 3rem;
  }

  [data-layout="horizontal"] .dlabnav .header-profile img {
    height: 2.8125rem;
    width: 2.8125rem;
  }

  [data-layout="horizontal"] .dlabnav .header-profile>a.nav-link {
    border-radius: 3rem;
    padding: 0.3125rem 0.3125rem;
  }

  [data-layout="horizontal"] .dlabnav .header-profile .header-info {
    display: none;
  }

  [data-layout="horizontal"] .dlabnav .header-profile2 {
    display: none;
  }

  [data-layout="horizontal"] .dlabnav .nav-user,
  [data-layout="horizontal"] .dlabnav .nav-label {
    display: none;
  }

  [data-layout="horizontal"] .dlabnav .metismenu {
    flex-direction: row;
    padding: 0.625rem 1.25rem;
    margin-bottom: 0;
    display: inline-flex;
    flex-wrap: wrap;
  }

  [data-layout="horizontal"] .dlabnav .metismenu .collapse.in {
    display: none;
  }

  [data-layout="horizontal"] .dlabnav .metismenu ul {
    border-left: 0;
  }

  [data-theme-version="dark"][data-layout="horizontal"] .dlabnav .metismenu ul {
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
  }

  [data-layout="horizontal"] .dlabnav .metismenu li {
    flex-direction: column;
    position: relative;
    margin-top: -12px;
  }

  [data-layout="horizontal"] .dlabnav .metismenu li:hover>ul {
    display: block;
  }

  [data-layout="horizontal"] .dlabnav .metismenu li>ul {
    position: absolute;
    height: auto !important;
    top: 100%;
    width: 100%;
    min-width: 13.75rem;
    z-index: 999;
    left: auto;
    right: auto;
    padding: 0.5rem 0;
    display: none;
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
    margin: 0;
    background: #fff;
    border-radius: 0.625rem;
  }

  [data-theme-version="dark"][data-layout="horizontal"] .dlabnav .metismenu li>ul {
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
    background: #292d4a;
  }

  [data-layout="horizontal"] .dlabnav .metismenu li>ul li {
    padding: 0;
  }

  [data-layout="horizontal"] .dlabnav .metismenu li>ul li a {
    transition: all .4s ease-in-out;
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
    margin-left: -.1rem;
  }

  [direction="rtl"][data-layout="horizontal"] .dlabnav .metismenu li>ul li a {
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
    text-align: right;
  }

  [data-layout="horizontal"] .dlabnav .metismenu li>ul li a:hover {
    border-radius: .4rem;
    padding-left: 1.25rem;
  }

  [data-layout="horizontal"] .dlabnav .metismenu li>ul li a:before {
    content: none;
    left: 1.375rem;
  }

  [direction="rtl"][data-layout="horizontal"] .dlabnav .metismenu li>ul li a:before {
    left: auto;
    right: 0.375rem;
  }

  [data-layout="horizontal"] .dlabnav .metismenu li>ul ul {
    left: 100%;
    top: 0;
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
  }

  [direction="rtl"][data-layout="horizontal"] .dlabnav .metismenu li>ul ul {
    left: auto;
    right: 100%;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li {
    flex: 0 0 auto;
    position: relative;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li>a i {
    margin-right: 0.3125rem;
  }

  [data-theme-version="dark"][data-layout="horizontal"] .dlabnav .metismenu>li {
    border-color: rgba(255, 255, 255, 0.07);
  }

  [data-theme-version="dark"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active {
    border-color: transparent;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:hover, [data-layout="horizontal"] .dlabnav .metismenu>li.mm-active {
    padding: 0;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:hover>a, [data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a {
    background: var(--primary) !important;
    color: #fff;
    border-radius: 0.625rem;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:hover>a i, [data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a i {
    color: #fff;
    background: transparent;
    box-shadow: none;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:hover>a svg path, [data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a svg path {
    fill: #fff;
  }

  [direction="rtl"][data-layout="horizontal"] .dlabnav .metismenu>li:first-child {
    border-right: 0;
  }

  [data-theme-version="dark"][direction="rtl"][data-layout="horizontal"] .dlabnav .metismenu>li {
    border-color: #333754;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li>a {
    padding: 0.825rem 2.5rem 0.725rem 0.9375rem;
    margin: 0.125rem 0.125rem;
  }

  [direction="rtl"][data-layout="horizontal"] .dlabnav .metismenu>li>a {
    padding: 0.9375rem 0.9375rem 0.9375rem 2.5rem;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li>a i {
    padding: 0 0.4375rem 0 0;
    height: auto;
    width: auto;
    line-height: 1;
  }

  [direction="rtl"][data-layout="horizontal"] .dlabnav .metismenu>li>a i {
    padding: 0 0 0 0.4375rem;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li>a .nav-badge {
    display: none;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li>a:after {
    right: 1.25rem;
    transform: rotate(-135deg) translateY(-50%);
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:hover {
    border-color: transparent;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:hover>ul {
    display: flex !important;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto !important;
    box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem rgba(20, 0, 30, 0.1);
    border-radius: 0.625rem;
  }

  [data-theme-version="dark"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>ul {
    box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem rgba(0, 0, 0, 0.1);
    background: #292d4a;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li>ul>li:hover ul.collapse {
    display: block !important;
    position: absolute;
    left: auto !important;
    right: -100% !important;
    top: 0 !important;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:nth-last-child(-n + 5)>ul {
    left: auto;
    right: 0;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:nth-last-child(-n + 5)>ul>li:hover ul.collapse {
    right: auto !important;
    left: -100% !important;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:nth-last-child(-n + 5)>ul.left {
    left: 0;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:nth-last-child(-n + 5)>ul.left>li:hover ul.collapse {
    left: 100% !important;
  }

  [direction="rtl"][data-layout="horizontal"] .dlabnav .metismenu>li:nth-last-child(-n + 5)>ul.left {
    left: auto;
    right: 0;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:last-child>ul ul {
    left: -100%;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:last-child>ul.left ul {
    left: 100%;
  }

  [direction="rtl"][data-layout="horizontal"] .dlabnav .metismenu>li:last-child>ul.left ul {
    left: auto;
    right: 100%;
  }

  [direction="rtl"][data-layout="horizontal"] .dlabnav .metismenu>li:nth-last-child(-n + 3)>ul {
    left: 0;
    right: auto;
  }

  [direction="rtl"][data-layout="horizontal"] .dlabnav .metismenu>li:nth-last-child(-n + 3)>ul>li:hover ul.collapse {
    right: -100% !important;
    left: auto !important;
  }

  [data-layout="horizontal"] .dlabnav .copyright,
  [data-layout="horizontal"] .dlabnav .plus-box {
    display: none;
  }

  [data-layout="horizontal"] .content-body {
    margin-left: 0;
  }

  [data-layout="horizontal"] .content-body .container-fluid, [data-layout="horizontal"] .content-body .container-sm, [data-layout="horizontal"] .content-body .container-md, [data-layout="horizontal"] .content-body .container-lg, [data-layout="horizontal"] .content-body .container-xl, [data-layout="horizontal"] .content-body .container-xxl {
    padding-top: 2.5rem;
  }

  [data-layout="horizontal"] .content-body .page-titles {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 1.875rem;
  }

  [data-layout="horizontal"] .footer {
    margin-left: 0;
    margin: 0 auto;
    padding-left: 0;
  }

  [data-header-position="fixed"][data-layout="horizontal"] .dlabnav {
    top: 6.5rem;
  }

  [data-header-position="fixed"][data-layout="horizontal"] .header {
    height: 6.5rem;
  }

  [data-header-position="fixed"][data-layout="horizontal"] .content-body {
    padding-top: 6.5rem;
  }

  [data-header-position="fixed"][data-sidebar-position="fixed"] .dlabnav {
    position: fixed;
  }

  [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"] .content-body {
    padding-top: 11.5rem;
  }

  [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="modern"] .content-body {
    padding-top: 13.125rem;
  }

  [data-layout="horizontal"][data-container="boxed"] .footer {
    margin-left: 0;
    max-width: 74.9375rem;
    margin: 0 auto;
  }

  [data-layout="horizontal"][data-container="wide"] .page-titles {
    margin-left: -1.875rem;
    margin-right: -1.875rem;
  }

  [data-layout="horizontal"][data-sidebar-style="modern"] .dlabnav .header-profile {
    margin-bottom: 0;
  }

  [data-layout="horizontal"][data-sidebar-style="modern"] .dlabnav .header-profile img {
    height: 3.75rem;
    width: 3.75rem;
    margin-bottom: 0 !important;
  }

  [data-layout="horizontal"][data-sidebar-style="modern"] .dlabnav .header-profile>a.nav-link {
    border: 0.0625rem solid #EEEEEE;
    padding: 0.25rem 0.25rem;
    border-radius: 3rem;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .page-titles {
    margin-top: 0;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .dlabnav .header-profile {
    margin-bottom: 0;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .dlabnav .header-profile img {
    height: 3.75rem;
    width: 3.75rem;
    margin-bottom: 0 !important;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .dlabnav .header-profile>a.nav-link {
    border: 0.0625rem solid #EEEEEE;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .dlabnav .metismenu>li>ul {
    top: 4.5rem;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .dlabnav .metismenu>li>a {
    padding: 1.125rem 1.25rem 0.625rem 1.25rem;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .dlabnav .metismenu>li>a::after {
    display: none;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .dlabnav .metismenu>li>a .nav-text {
    margin-top: 0.3125rem;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .dlabnav .metismenu>li>a>i {
    width: auto;
    margin: 0;
    height: auto;
    line-height: 1;
    padding: 0;
    background: transparent;
    border-radius: 0;
    margin-bottom: 0;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .dlabnav .metismenu>li li {
    text-align: left;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .nav-header {
    width: 7.75rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .nav-header .brand-logo {
    justify-content: start;
    padding-left: 0;
    padding-right: 0;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .header {
    width: 100%;
    padding-left: 7.75rem;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .metismenu>li a {
    width: auto;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .metismenu>li:hover a .nav-text {
    display: none;
  }

  [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="compact"] .content-body {
    padding-top: 13.125rem;
  }

  [data-sidebar-position="fixed"][data-layout="horizontal"] .dlabnav.fixed {
    position: fixed;
    padding: 0 0.9375rem;
    left: 0;
    top: 0;
    border-radius: 0;
    width: 100%;
  }
}

@media (min-width: 47.9375rem) {
  [data-sidebar-style="compact"] .nav-header {
    width: 11.25rem;
  }

  [data-sidebar-style="compact"] .dlabnav .metismenu .has-arrow[aria-expanded=true]:after, [data-sidebar-style="compact"] .dlabnav .metismenu .mm-active>.has-arrow:after {
    margin-right: 0;
  }

  [data-sidebar-style="compact"] .dlabnav .header-profile {
    margin-bottom: 0.3125rem;
  }

  [data-sidebar-style="compact"] .dlabnav .header-profile>a.nav-link {
    display: block;
    text-align: center;
    border: 0;
  }

  [data-sidebar-style="compact"] .dlabnav .header-profile>a.nav-link img {
    margin-bottom: 0.3125rem;
  }

  [data-sidebar-style="compact"] .dlabnav .header-profile>a.nav-link .header-info {
    margin-left: 0 !important;
    text-align: center;
    display: none;
  }

  [data-sidebar-style="compact"] .dlabnav .header-profile>a.nav-link .header-info small, [data-sidebar-style="compact"] .dlabnav .header-profile>a.nav-link .header-info .small {
    text-align: center !important;
  }

  [data-sidebar-style="compact"] .dlabnav .header-profile .dropdown-menu {
    min-width: 11rem;
  }

  [data-sidebar-style="compact"] .dlabnav .header-profile a svg {
    display: unset !important;
  }

  [data-sidebar-style="compact"] .dlabnav .nav-user {
    display: none;
  }

  [data-sidebar-style="compact"] .dlabnav .metismenu li {
    text-align: center;
  }

  [data-sidebar-style="compact"] .dlabnav .metismenu li a {
    padding: 0.7rem 0.5rem;
  }

  [data-sidebar-style="compact"] .dlabnav .metismenu li a svg {
    display: block;
    padding: 0;
    background: rgba(0, 0, 0, 0.05);
    /* background:rgba($light,0.2); */
    color: rgba(0, 0, 0, 0.3);
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 0.625rem;
    line-height: 3.75rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.3125rem;
  }

  [data-sidebar-style="compact"] .dlabnav .metismenu li a i {
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
  }

  [data-sidebar-style="compact"] .dlabnav .metismenu li ul:after {
    content: none;
  }

  [data-sidebar-style="compact"] .dlabnav .metismenu li>a {
    box-shadow: none;
    font-size: 0.9375rem;
  }

  [data-sidebar-style="compact"] .dlabnav .plus-box, [data-sidebar-style="compact"] .dlabnav .copyright {
    display: none;
  }

  [data-sidebar-style="compact"] .dlabnav .copyright {
    padding: 0 1.25rem;
    margin-top: 1.25rem;
  }

  [data-sidebar-style="compact"] .dlabnav .header-info2 {
    padding: 0;
  }

  [data-sidebar-style="compact"] .dlabnav .header-info2 img {
    margin-left: -1rem;
  }

  [data-sidebar-style="compact"] .dlabnav .sidebar-info {
    display: none !important;
  }

  [data-sidebar-style="compact"] .dlabnav .header-profile2 {
    margin: 0 3rem;
  }

  [data-sidebar-style="compact"] .dlabnav .feature-box,
  [data-sidebar-style="compact"] .dlabnav .support-box {
    display: none;
  }

  [data-sidebar-style="compact"] .nav-text {
    display: inline-block;
    margin-top: 0.3125rem;
  }

  [data-sidebar-style="compact"] .nav-label.first {
    display: none;
  }

  [data-sidebar-style="compact"] .nav-badge {
    display: none;
  }

  [data-sidebar-style="compact"] .footer {
    padding-left: 12.5rem;
  }

  [data-sidebar-style="compact"] .content-body {
    margin-left: 10.5rem;
  }

  [data-sidebar-style="compact"][data-theme-version="dark"][data-layout="horizontal"] .dlabnav .metismenu li>a i {
    color: #fff;
  }

  [data-sidebar-style="compact"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .metismenu li.mm-active>a i {
    background: var(--primary);
    color: #fff;
  }

  [data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu li:hover>a i {
    background: var(--rgba-primary-1);
    color: var(--primary);
  }
}

[data-layout="horizontal"][data-sidebar-style="compact"] .footer {
  padding-left: 0;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .content-body {
  margin-left: 0;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .dlabnav {
  margin-bottom: 0;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .nav-header {
  width: 13.25rem;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .nav-header .brand-logo {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .dlabnav {
  width: 11.25rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu ul {
  margin-left: 0;
  border: 0;
  background: rgba(0, 0, 0, 0.02);
  padding: 0.625rem 0;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu ul {
  background: 255, 255, 255, 0.05;
}

[data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu ul a:before {
  content: none;
}

[data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu ul ul a {
  padding: 0.625rem 0.9375rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu>li>a {
  padding: 1.2rem 0.5rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu>li>a.has-arrow::after {
  top: 0.0625rem;
  display: inline-block;
  right: auto;
  margin-left: 0.3125rem;
  position: relative;
  width: 0.4375rem;
  height: 0.4375rem;
  border-width: 0.125rem 0rem 0rem 0.125rem;
}

[direction="rtl"][data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu>li>a.has-arrow::after {
  left: auto;
  margin-left: 0;
  margin-right: 0.3125rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a {
  background: transparent;
  color: var(--primary);
}

@media (min-width: 47.9375rem) {
  [data-sidebar-style="icon-hover"][data-layout="horizontal"] .header .header-content {
    padding-left: 1.875rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu li.mm-active>ul {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .header-info2 {
    justify-content: space-between;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .header-info2 img {
    order: 1;
    margin-right: 0;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .dlabnav .metismenu li.mm-active>ul {
    display: block;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header {
    width: 7rem;
    border-radius: 0 0 0 0 !important;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo {
    padding-left: 1.875rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo .logo-abbr {
    display: block;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo .brand-title {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .nav-control {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .header {
    padding-left: 7rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .header {
    padding-right: 7rem;
    padding-left: 0.9375rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .header .header-content {
    padding-left: 2rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .header .header-content {
    padding-right: 1.375rem;
    padding-left: 0;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav {
    overflow: visible;
    position: absolute;
    left: -11.7rem;
  }
}

@media only screen and (min-width: 47.9375rem) and (min-width: 47.9375rem) and (max-width: 87.5rem) {
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav {
    left: -10rem;
  }
}

@media (min-width: 47.9375rem) {
  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav {
    left: auto;
    right: -14.563rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .nav-label {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .header-profile img {
    order: 1;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .header-profile .header-info {
    margin-left: 0 !important;
    padding-left: 0 !important;
    margin-right: 0.625rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu>li {
    padding: 0 1.5625rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu>li>a {
    display: flex;
    justify-content: space-between;
    padding: 0.825rem 1.5rem;
    border-radius: 0.625rem;
    margin: 5px 0;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu>li>a>svg,
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
    order: 1;
    margin-right: 0;
    margin-top: 0;
    padding-right: 0;
    height: auto;
    width: auto;
    line-height: 1;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu>li>a>svg, [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
    padding-left: 0;
    padding-right: 0;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a {
    background: var(--primary);
    color: #fff;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
    color: #fff;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu ul {
    border-left: 0;
    padding-left: 0;
    padding-right: 0;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu ul {
    padding-right: 0;
    padding-left: 0;
  }

  [data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu ul {
    border-color: #333754;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu ul:after {
    left: auto;
    right: 1.75rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu ul:after {
    left: 1.75rem;
    right: auto;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu ul a {
    position: relative;
    padding-left: 1.3rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu ul a {
    padding-right: 1.25rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu ul a::before {
    left: 1.25rem;
    right: auto;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu ul a::before {
    right: auto;
    left: -0.3125rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu .has-arrow::after {
    right: 5rem;
    opacity: 0;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu .has-arrow::after {
    right: auto;
    left: 5rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .copyright,
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .plus-box {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav.mm-show {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .nav-header {
    width: 18.7rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .nav-header .brand-logo {
    padding-left: 1.6rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .nav-header .brand-logo .brand-title {
    display: block;
  }
}

@media only screen and (min-width: 47.9375rem) and (max-width: 87.5rem) {
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .nav-header {
    width: 17rem;
  }
}

@media (min-width: 47.9375rem) {
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle.mm-show {
    display: block;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .header {
    padding-left: 3.38rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .header {
    padding-right: 4.38rem;
    padding-left: 0.9375rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .dlabnav {
    left: 0;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .dlabnav .metismenu .has-arrow:after {
    opacity: 1;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .dlabnav {
    left: auto;
    right: 0;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .content-body {
    margin-left: 7rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .content-body {
    margin-left: 0;
    margin-right: 7rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .footer {
    padding-left: 7rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .footer {
    margin-left: 0;
    margin-right: 7rem;
  }
}

@media (min-width: 47.9375rem) {
  [data-sidebar-style="modern"] .nav-header {
    width: 10.625rem;
  }

  [data-sidebar-style="modern"] .nav-header .brand-logo {
    justify-content: center;
  }

  [data-sidebar-style="modern"] .dlabnav .header-profile {
    margin-bottom: 0.3125rem;
  }

  [data-sidebar-style="modern"] .dlabnav .header-profile>a.nav-link {
    display: block;
    text-align: center;
    margin: 0 -0.625rem 0.9375rem;
    padding: 0.9375rem 0.625rem;
    border-radius: 0.625rem;
  }

  [data-sidebar-style="modern"] .dlabnav .header-profile>a.nav-link img {
    margin-bottom: 0.3125rem;
  }

  [data-sidebar-style="modern"] .dlabnav .header-profile>a.nav-link .header-info {
    margin-left: 0 !important;
    text-align: center;
    display: none;
  }

  [data-sidebar-style="modern"] .dlabnav .header-profile>a.nav-link .header-info small, [data-sidebar-style="modern"] .dlabnav .header-profile>a.nav-link .header-info .small {
    text-align: center !important;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu>li {
    text-align: center;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu>li>a {
    padding: 1.25rem 0.9375rem 1.25rem 0.9375rem;
    margin: 0.125rem 0;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu>li>a::after {
    display: none;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu>li>a:hover>a, [data-sidebar-style="modern"] .dlabnav .metismenu>li>a:focus>a, [data-sidebar-style="modern"] .dlabnav .metismenu>li>a:active>a, [data-sidebar-style="modern"] .dlabnav .metismenu>li>a.mm-active>a {
    background-color: var(--primary-dark);
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu>li:hover, [data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active {
    padding: 0;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a, [data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a {
    background: transparent !important;
    color: black;
    border-radius: 0.625rem;
    box-shadow: none;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a i, [data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a i {
    color: var(--primary) !important;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu>li li {
    text-align: left;
  }

  [direction="rtl"][data-sidebar-style="modern"] .dlabnav .metismenu>li li {
    text-align: right;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu li a {
    padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
    font-size: 0.9375rem;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu li ul:after {
    content: none;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu li>ul {
    height: auto !important;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu .nav-label {
    display: none;
  }

  [data-sidebar-style="modern"] .dlabnav .nav-label {
    display: none;
  }

  [data-sidebar-style="modern"] .dlabnav .nav-text {
    display: block;
    margin-top: 0.3125rem;
  }

  [data-sidebar-style="modern"] .dlabnav .plus-box,
  [data-sidebar-style="modern"] .dlabnav .copyright {
    display: none;
  }

  [data-sidebar-style="modern"] .dlabnav .sidebar-info {
    display: none !important;
  }

  [data-sidebar-style="modern"] .footer {
    padding-left: 11.9rem;
  }

  [data-sidebar-style="modern"] .content-body {
    margin-left: 10.9rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .dlabnav .metismenu li>a i {
    padding: 0;
    margin: 0;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav {
    width: 10.625rem;
    left: 0;
  }

  [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .dlabnav {
    left: auto;
    right: 0;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .slimScrollDiv,
  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .dlabnav-scroll {
    overflow: visible !important;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu {
    padding: 0.625rem 0.875rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu>li>a {
    padding: 1.375rem 0.6em 0.9375rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu>li>a i {
    padding: 0;
    height: auto;
    width: auto;
    margin: 0;
    line-height: 1;
    margin-bottom: 0.3125rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu>li>ul {
    display: none;
    padding: 1.875rem 0.9375rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu li {
    position: relative;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu li a {
    padding: 1rem 1rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu li a:before {
    content: none;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu li ul {
    position: absolute;
    left: 105%;
    top: 0;
    bottom: auto;
    background-color: #fff;
    border: 0.0625rem solid #DBDBDB;
    width: 12.5rem;
  }

  [data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu li ul {
    background: #292d4a;
    box-shadow: 0rem 0rem 0.8125rem 0rem rgba(0, 0, 0, 0.1);
  }

  [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu li ul {
    left: auto;
    right: 105%;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu li:hover>ul {
    display: block;
    left: 100%;
    padding: 1rem 0;
    margin-left: 0;
    border: 0;
    box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem rgba(20, 0, 30, 0.1);
    border-radius: 0.625rem;
  }

  [data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu li:hover>ul {
    box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem rgba(20, 0, 30, 0.1);
  }

  [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu li:hover>ul {
    left: auto;
    right: 100%;
    box-shadow: -0.3125rem 0.3125rem 1.875rem 0rem rgba(20, 0, 30, 0.1);
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .nav-label {
    display: none;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .nav-text {
    display: block;
    margin-top: 0;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .header-profile2 {
    padding: 0 2.5rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .nav-control {
    right: -4.25rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .header .header-content {
    padding-left: 6rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .dlabnav, [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .nav-header {
    left: -10.625rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .header {
    padding-left: 0;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .content-body {
    margin-left: 0;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .nav-header {
    width: 21.75rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .header {
    padding-left: 21.75rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .footer,
  [data-sidebar-style="modern"][data-layout="horizontal"] .content-body {
    margin-left: 0;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .dlabnav .metismenu>li>a {
    padding: 0.9375rem 1.5625rem 0.75rem 1.5625rem;
    margin: 0 0.125rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .dlabnav .metismenu>li>ul {
    top: 4.8rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"][data-container="boxed"] .dlabnav .metismenu>li>a {
    padding: 0.8125rem 1.25rem;
  }
}

[data-sidebar-style="overlay"][data-layout="vertical"] .dlabnav {
  border-radius: 0 0 0 0 !important;
}

@media only screen and (max-width: 47.9375rem) {
  [data-sidebar-style="overlay"][data-layout="vertical"] .dlabnav {
    border-radius: 0 0 0 0 !important;
  }
}

[data-sidebar-style="overlay"][data-layout="vertical"] .nav-header {
  border-radius: 0;
}

[data-sidebar-style="overlay"][data-layout="vertical"] .menu-toggle .nav-header {
  border-radius: 0 0 0 0;
}

[data-sidebar-style="overlay"] .dlabnav {
  left: -100%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.2);
}

[data-sidebar-style="overlay"] .dlabnav .metismenu>li {
  padding: 0 1.875rem;
}

[data-sidebar-style="overlay"] .dlabnav .metismenu>li>a {
  font-size: 1rem;
  padding: 0.825rem 1.5rem;
  color: #7f7184;
  border-radius: 0.625rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

[data-sidebar-style="overlay"] .dlabnav .metismenu>li>a i {
  height: auto;
  width: auto;
  line-height: 1;
}

[data-sidebar-style="overlay"] .dlabnav .metismenu>li:hover>a {
  color: var(--primary);
}

[data-sidebar-style="overlay"] .dlabnav .metismenu>li:hover>a i {
  color: var(--primary);
}

[data-sidebar-style="overlay"] .dlabnav .metismenu>li.mm-active>a {
  /* background: var(--rgba-primary-1); */
  color: #fff;
}

[data-sidebar-style="overlay"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

@media only screen and (max-width: 35.9375rem) {
  [data-sidebar-style="overlay"] .dlabnav .metismenu>li {
    padding: 0rem 0.9375rem;
  }
}

[data-sidebar-style="overlay"] .dlabnav .metismenu ul a {
  padding-top: .5rem;
  padding-bottom: .5rem;
  position: relative;
  font-size: 0.9375rem;
  padding-left: 4rem;
}

[data-sidebar-style="overlay"] .dlabnav .metismenu ul a:before {
  left: 1.5625rem;
}

[direction="rtl"][data-sidebar-style="overlay"] .dlabnav {
  left: auto;
  right: -100%;
}

@media only screen and (max-width: 47.9375rem) {
  [data-sidebar-style="overlay"] .dlabnav .metismenu>li>a {
    font-size: 0.875rem;
    padding: 0.75rem 0.875rem;
  }

  [data-sidebar-style="overlay"] .dlabnav .metismenu>li>a i {
    font-size: 1.125rem;
  }

  [data-sidebar-style="overlay"] .dlabnav .metismenu ul li a {
    padding-left: 3.4rem;
  }
}

[data-sidebar-style="overlay"] .content-body {
  margin-left: 0;
}

[data-sidebar-style="overlay"] .nav-header {
  position: absolute;
}

[data-sidebar-style="overlay"] .nav-header .hamburger.is-active {
  left: 0;
}

[data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: var(--primary);
}

[data-sidebar-style="overlay"] .menu-toggle .nav-header {
  position: absolute;
  left: auto;
}

[data-sidebar-style="overlay"] .menu-toggle .dlabnav {
  left: 0;
}

[direction="rtl"][data-sidebar-style="overlay"] .menu-toggle .dlabnav {
  left: auto;
  right: 0;
}

[data-sidebar-style="overlay"] .footer {
  padding-left: 0;
}

[data-sidebar-style="overlay"][data-header-position="fixed"] .nav-header {
  position: fixed;
}

[data-sidebar-position="fixed"][data-header-position="fixed"] .nav-header {
  position: fixed;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .nav-header {
  position: fixed;
  border-top-left-radius: 0;
  border-top-right-radius: 1.25rem;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .dlabnav {
  position: fixed;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .dlabnav .dlabnav-scroll {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .menu-toggle .dlabnav {
  position: fixed;
}

[data-layout="vertical"] .nav-header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

[data-layout="vertical"] .dlabnav {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 1.25rem;
}

[data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="wide-boxed"] .dlabnav,
[data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="boxed"] .dlabnav,
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="wide-boxed"] .dlabnav,
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="boxed"] .dlabnav,
[data-sidebar-style="overlay"][data-layout="vertical"][data-container="wide-boxed"] .dlabnav,
[data-sidebar-style="overlay"][data-layout="vertical"][data-container="boxed"] .dlabnav,
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="overlay"][data-layout="vertical"][data-container="wide-boxed"] .dlabnav,
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="overlay"][data-layout="vertical"][data-container="boxed"] .dlabnav {
  position: absolute;
}

.sidebar-right-trigger {
  position: absolute;
  z-index: 9;
  top: 7.75rem;
  right: 100%;
  background-color: #453773;
  color: #fff;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  text-align: center;
  font-size: 1.75rem;
  line-height: 3rem;
  border-radius: 0.3125rem 0 0 0.3125rem;
  box-shadow: -0.3125rem 0.1875rem 0.3125rem 0rem rgba(119, 119, 119, 0.15);
}

[data-theme-version="dark"] .sidebar-right-trigger {
  color: #fff;
}

@media only screen and (max-width: 64rem) {
  .sidebar-right-trigger {
    display: none;
  }
}

.sidebar-right-trigger:hover {
  color: #fff;
}

.sidebar-right {
  right: -50rem;
  position: fixed;
  top: 0;
  width: 50rem;
  background-color: #fff;
  margin-top: 3.5rem;
  transition: all .5s ease-in-out;
  border-radius: 0.625rem;
  z-index: 9999;
}

.sidebar-right [for="primary_color_1"],
.sidebar-right [for="secondary_color_1"],
.sidebar-right [for="nav_header_color_1"],
.sidebar-right [for="header_color_1"],
.sidebar-right [for="sidebar_color_1"],
.sidebar-right .bg-label-pattern {
  background: transparent;
  background-image: url("../images/pattern/pattern5.png");
  background-size: 130%;
}

.sidebar-right .bootstrap-select {
  height: 3rem;
  border-radius: 0.375rem;
}

.sidebar-right .bootstrap-select .btn {
  padding: 0.75rem 0.9375rem;
  font-size: 0.9375rem;
  border-color: #d1d1d1;
  border-radius: 0.375rem;
}

[direction="rtl"] .sidebar-right .slimScrollDiv {
  overflow: visible !important;
}

.sidebar-right .note-text {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  opacity: 0.5;
  font-size: 12px;
}

.sidebar-right .sidebar-close-trigger {
  position: absolute;
  z-index: 2;
  font-size: 1.75rem;
  top: 0;
  right: -3rem;
  height: 3rem;
  width: 3rem;
  line-height: 3rem;
  text-align: center;
  background: black;
  color: #fff;
}

.sidebar-right.show {
  right: 5.25rem;
  box-shadow: 0rem 0rem 3.125rem rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

@media only screen and (max-width: 47.9375rem) {
  .sidebar-right.show {
    display: none;
  }
}

.sidebar-right.show .bg-overlay {
  position: fixed;
  width: 100%;
  cursor: pointer;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
}

.sidebar-right .card-tabs .nav-tabs {
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 2;
  border-bottom: 4px solid var(--rgba-primary-1);
}

.sidebar-right .card-tabs .nav-tabs .nav-item {
  margin-bottom: 0;
  flex: 1;
}

.sidebar-right .card-tabs .nav-tabs .nav-item .nav-link {
  border: 0;
  font-size: 1.125rem;
  position: relative;
  text-align: center;
  background-color: #fff;
  border-radius: 0;
  margin: 0;
}

.sidebar-right .card-tabs .nav-tabs .nav-item .nav-link.active {
  background: var(--rgba-primary-1);
  color: #000;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link.active {
  border-right: none;
  border-left: none;
  border-top: none;
}

.sidebar-right .sidebar-right-inner>h4, .sidebar-right .sidebar-right-inner>.h4 {
  padding: 0.625rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  background: #fff;
  margin: 0rem 0rem 0rem 0rem;
}

.sidebar-right .tab-content {
  padding: 1.25rem 1.25rem 0;
  min-height: 480px;
  background: #fff;
}

.sidebar-right .tab-content .tab-pane .admin-settings .row>div {
  margin-bottom: 1.25rem;
}

.sidebar-right .tab-content .tab-pane .admin-settings p {
  color: #353535;
  font-weight: 500;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"] {
  display: none;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]+label {
  display: inline-block;
  width: 2.1875rem;
  height: 2.1875rem;
  cursor: pointer;
  transition: all .1s ease;
  border-radius: 0.25rem;
  margin-right: 0.3125rem;
  margin-bottom: 0.1875rem;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]:checked+label {
  position: relative;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]:checked+label::after {
  height: 2.0625rem;
  width: 2.0625rem;
  left: -0.25rem;
  top: -0.25rem;
  content: "";
  position: absolute;
  background-color: inherit;
  border-radius: 0.375rem;
  opacity: 0.4;
}

.sidebar-right #nav_header_color_1+label,
.sidebar-right #header_color_1+label,
.sidebar-right #sidebar_color_1+label,
.sidebar-right #secondary_color_1+label,
.sidebar-right #primary_color_1+label {
  background-color: #fff;
}

.sidebar-right #nav_header_color_2+label,
.sidebar-right #header_color_2+label,
.sidebar-right #sidebar_color_2+label,
.sidebar-right #secondary_color_2+label,
.sidebar-right #primary_color_2+label {
  background-color: #49bb98;
}

.sidebar-right #nav_header_color_3+label,
.sidebar-right #header_color_3+label,
.sidebar-right #sidebar_color_3+label,
.sidebar-right #secondary_color_3+label,
.sidebar-right #primary_color_3+label {
  background-color: #453773;
}

.sidebar-right #nav_header_color_4+label,
.sidebar-right #header_color_4+label,
.sidebar-right #sidebar_color_4+label,
.sidebar-right #secondary_color_4+label,
.sidebar-right #primary_color_4+label {
  background-color: #ff965f;
}

.sidebar-right #nav_header_color_5+label,
.sidebar-right #header_color_5+label,
.sidebar-right #sidebar_color_5+label,
.sidebar-right #secondary_color_5+label,
.sidebar-right #primary_color_5+label {
  background-color: #3250ff;
}

.sidebar-right #nav_header_color_6+label,
.sidebar-right #header_color_6+label,
.sidebar-right #sidebar_color_6+label,
.sidebar-right #secondary_color_6+label,
.sidebar-right #primary_color_6+label {
  background-color: #3f8cff;
}

.sidebar-right #nav_header_color_7+label,
.sidebar-right #header_color_7+label,
.sidebar-right #sidebar_color_7+label,
.sidebar-right #secondary_color_7+label,
.sidebar-right #primary_color_7+label {
  background-color: #40a7e2;
}

.sidebar-right #nav_header_color_8+label,
.sidebar-right #header_color_8+label,
.sidebar-right #sidebar_color_8+label,
.sidebar-right #secondary_color_8+label,
.sidebar-right #primary_color_8+label {
  background-color: #b65fbd;
}

.sidebar-right #nav_header_color_9+label,
.sidebar-right #header_color_9+label,
.sidebar-right #sidebar_color_9+label,
.sidebar-right #secondary_color_9+label,
.sidebar-right #primary_color_9+label {
  background-color: #88a67e;
}

.sidebar-right #nav_header_color_10+label,
.sidebar-right #header_color_10+label,
.sidebar-right #sidebar_color_10+label,
.sidebar-right #secondary_color_10+label,
.sidebar-right #primary_color_10+label {
  background-color: #6759d1;
}

.sidebar-right #nav_header_color_11+label,
.sidebar-right #header_color_11+label,
.sidebar-right #sidebar_color_11+label,
.sidebar-right #secondary_color_11+label,
.sidebar-right #primary_color_11+label {
  background-color: #c6164f;
}

.sidebar-right #nav_header_color_12+label,
.sidebar-right #header_color_12+label,
.sidebar-right #sidebar_color_12+label,
.sidebar-right #secondary_color_12+label,
.sidebar-right #primary_color_12+label {
  background-color: #343a40;
}

.sidebar-right #nav_header_color_13+label,
.sidebar-right #header_color_13+label,
.sidebar-right #sidebar_color_13+label,
.sidebar-right #secondary_color_13+label,
.sidebar-right #primary_color_13+label {
  background-color: #2a2a2a;
}

.sidebar-right #nav_header_color_14+label,
.sidebar-right #header_color_14+label,
.sidebar-right #sidebar_color_14+label,
.sidebar-right #secondary_color_14+label,
.sidebar-right #primary_color_14+label {
  background-color: #4885ed;
}

.sidebar-right #nav_header_color_15+label,
.sidebar-right #header_color_15+label,
.sidebar-right #sidebar_color_15+label,
.sidebar-right #secondary_color_15+label,
.sidebar-right #primary_color_15+label {
  background-color: #4cb32b;
}

.sidebar-right #nav_header_color_1+label,
.sidebar-right #header_color_1+label,
.sidebar-right #sidebar_color_1+label,
.sidebar-right #secondary_color_1+label,
.sidebar-right #primary_color_1+label {
  border: 0.0625rem solid #c4c4c4;
}

.sidebar-right.style-1 {
  height: 100vh;
  width: 15.625rem;
  margin-top: 0;
  right: -15.625rem;
}

.sidebar-right.style-1 .sidebar-right-inner {
  background: #fff;
}

.sidebar-right.style-1 .sidebar-right-trigger {
  top: 12.4rem;
}

.sidebar-right.style-1.show {
  right: 0;
}

.sidebar-right.style-1.show .sidebar-right-trigger {
  display: block;
}

.nice-select.wide {
  line-height: 2.1125rem;
  height: 52px;
}

.nice-select.wide.form-control-sm {
  height: 35px;
  line-height: 1rem;
}

@keyframes bounce {
  0% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }

  50% {
    transform: translateX(8%);
    -webkit-transform: translateX(8%);
  }

  100% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }
}

@-webkit-keyframes bounce {
  0% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }

  50% {
    transform: translateX(8%);
    -webkit-transform: translateX(8%);
  }

  100% {
    transform: translateY(-8%);
    -webkit-transform: translateY(-8%);
  }
}

@media only screen and (max-width: 87.5rem) {
  .nice-select.wide {
    line-height: 2rem;
  }
}

.nav-user {
  background: var(--primary);
  margin-bottom: 0.625rem;
  padding: 1.25rem 1.5625rem 0.9375rem;
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
  .nav-user {
    padding: 1.25rem 0.9375rem 0.9375rem;
  }
}

.nav-user img {
  width: 2.1875rem;
  height: 2.1875rem;
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
  .nav-user img {
    width: 2.1875rem;
    height: 2.1875rem;
    margin-bottom: 0.625rem;
  }
}

.nav-user h5, .nav-user .h5 {
  margin-left: 0.625rem;
  margin-bottom: 0.1875rem;
  color: #fff;
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
  .nav-user h5, .nav-user .h5 {
    display: none;
  }
}

[data-sibebarbg="color_2"] .nav-user h5, [data-sibebarbg="color_2"] .nav-user .h5 {
  color: #fff;
}

.nav-user p {
  margin-left: 0.625rem;
  margin-bottom: 0.5rem;
  color: #afcff7;
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
  .nav-user p {
    display: none;
  }
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
  .nav-user i {
    margin-top: 0.9375rem;
    display: block;
  }
}

.menu-toggle .nav-user {
  padding: 1.25rem 0.9375rem 0.9375rem;
}

.menu-toggle .nav-user img {
  width: 2.1875rem;
  height: 2.1875rem;
  margin-bottom: 0.625rem;
}

.menu-toggle .nav-user h5, .menu-toggle .nav-user .h5,
.menu-toggle .nav-user p {
  display: none;
}

.menu-toggle .nav-user i {
  margin-top: 0.9375rem;
  display: block;
}

.menu-toggle .nav-user .dropdown-menu {
  left: 2.8125rem !important;
  top: 1.375rem !important;
}

.chatbox {
  width: 21.25rem;
  height: 100vh;
  position: fixed;
  right: -31.25rem;
  top: 0;
  z-index: 999;
  background: #fff;
  box-shadow: 0rem 0rem 1.875rem 0rem rgba(82, 63, 105, 0.15);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

[data-theme-version="dark"] .chatbox {
  background: #292d4a;
}

.chatbox .chatbox-close {
  position: absolute;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  width: 0;
  height: 100%;
  right: 21.25rem;
  background: #000;
  z-index: 1;
  opacity: 0.1;
  cursor: pointer;
}

.chatbox .card-fotter {
  padding: 0.75rem 1rem;
}

.chatbox .card-body {
  padding: 1rem;
}

.chatbox.active {
  right: 0;
}

.chatbox.active .chatbox-close {
  width: 100vw;
}

.chatbox .type_msg {
  padding-top: 0.625rem;
}

.chatbox .nav {
  padding: 1rem 1rem 0 1rem;
  background: var(--rgba-primary-1);
  border: 0;
  justify-content: space-between;
}

.chatbox .nav .nav-link {
  color: var(--primary);
  opacity: 0.7;
  text-transform: uppercase;
}

.chatbox .nav .nav-link:hover, .chatbox .nav .nav-link.active {
  background: transparent;
  color: var(--primary);
  opacity: 1;
  border-color: var(--primary);
}

.chatbox .img_cont {
  width: 2.5rem;
  border-radius: 2.5rem;
  margin-right: 0.625rem;
  position: relative;
  height: 2.5rem;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 0.875rem;
  min-width: 2.5rem;
  min-height: 2.5rem;
}

.chatbox .img_cont .icon {
  color: #fff;
}

.chatbox .img_cont.primary {
  background: var(--rgba-primary-1);
  color: var(--primary);
}

[data-theme-version="dark"] .chatbox .img_cont.primary {
  background: var(--rgba-primary-1);
}

.chatbox .img_cont.warning {
  background: #fff8e7;
  color: #f0a901;
}

[data-theme-version="dark"] .chatbox .img_cont.warning {
  background: rgba(240, 169, 1, 0.1);
}

.chatbox .img_cont.success {
  background: #c9edcc;
  color: #56c760;
}

[data-theme-version="dark"] .chatbox .img_cont.success {
  background: rgba(86, 199, 96, 0.1);
}

.chatbox .img_cont.info {
  background: #d3edf5;
  color: #58bad7;
}

[data-theme-version="dark"] .chatbox .img_cont.info {
  background: rgba(88, 186, 215, 0.1);
}

.chatbox .img_cont img {
  width: 100%;
}

.chatbox .img_cont .online_icon {
  background: #56c760;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.9375rem;
  right: -0.0625rem;
  bottom: 0rem;
  border: 0.125rem solid #fff;
}

.chatbox .img_cont .online_icon.offline {
  background: #f75a5b;
}

.chatbox .card {
  box-shadow: none;
}

.chatbox .search {
  height: 2.5rem;
}

.chatbox .user_info span {
  font-size: 0.9375rem;
  color: #000;
  display: block;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.3125rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  max-width: 10.625rem;
}

[data-theme-version="dark"] .chatbox .user_info span {
  color: #fff;
}

.chatbox .user_info p {
  font-size: 0.8125rem;
  margin-bottom: 0;
  line-height: 1.2;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  max-width: 10.625rem;
}

.chatbox .contacts li {
  padding: 0.75rem 1rem;
  border-bottom: 0.0625rem solid #eee;
  cursor: pointer;
}

[data-theme-version="dark"] .chatbox .contacts li {
  border-color: #333754;
}

.chatbox .contacts li>div {
  display: flex;
  align-items: center;
}

.chatbox .contacts li:hover {
  background: #f4f7ff;
}

[data-theme-version="dark"] .chatbox .contacts li:hover {
  background-color: #20203c;
}

.chatbox .contacts .name-first-letter {
  background: #F0F0F0;
  padding: 0.25rem 1rem;
  font-weight: 700;
  color: #000;
  position: sticky;
  top: 0;
  z-index: 1;
}

[data-theme-version="dark"] .chatbox .contacts .name-first-letter {
  color: #fff;
  background: #20203c;
}

.chatbox .msg_card_body {
  height: calc(100vh - 205px);
}

.chatbox .contacts_body {
  height: calc(100vh - 120px);
}

.chatbox .card-header {
  background: #f4f7ff;
  padding: 0.9375rem 1.25rem;
  justify-content: center;
}

.chatbox .card-header h6, .chatbox .card-header .h6 {
  font-size: 0.9375rem;
}

.chatbox .card-header p {
  line-height: 1.2;
  font-size: 0.75rem;
  color: #777777;
}

.chatbox .chat-list-header {
  justify-content: space-between;
  background: #fff;
}

[data-theme-version="dark"] .chatbox .chat-list-header {
  background: #292d4a;
}

.chatbox .chat-list-header a {
  text-align: center;
  width: 1.875rem;
  height: 1.875rem;
  background: #F0F0F0;
  border-radius: 0.375rem;
  line-height: 1.675rem;
  display: block;
}

[data-theme-version="dark"] .chatbox .chat-list-header a {
  background: var(--rgba-primary-1);
}

[data-theme-version="dark"] .chatbox .chat-list-header a svg g [fill] {
  fill: #fff;
}

.chatbox .img_cont_msg {
  width: 1.875rem;
  height: 1.875rem;
  display: block;
  max-width: 1.875rem;
  min-width: 1.875rem;
}

.chatbox .img_cont_msg img {
  width: 100%;
}

.chatbox .msg_cotainer {
  background: var(--primary);
  margin-left: 0.625rem;
  border-radius: 0 0.625rem 0.625rem 0.625rem;
  padding: 0.625rem 0.9375rem;
  color: #fff;
  position: relative;
}

.chatbox .msg_cotainer .msg_time {
  display: block;
  font-size: 0.6875rem;
  color: #fff;
  margin-top: 0.3125rem;
  opacity: 0.5;
}

.chatbox .msg_cotainer:after {
  content: "";
  position: absolute;
  left: -0.625rem;
  border-right: 0.625rem solid var(--primary);
  border-bottom: 0.625rem solid transparent;
  border-top: 0rem solid;
  top: 0;
}

.chatbox .msg_cotainer_send {
  background: #F0F0F0;
  padding: 0.625rem 0.9375rem;
  border-radius: 0.375rem 0rem 0.375rem 0.375rem;
  margin-right: 0.625rem;
  color: #222;
  position: relative;
  text-align: right;
}

[data-theme-version="dark"] .chatbox .msg_cotainer_send {
  background: #20203c;
  color: #fff;
}

.chatbox .msg_cotainer_send .msg_time_send {
  display: block;
  font-size: 0.6875rem;
  text-align: right;
  margin-top: 0.3125rem;
  opacity: 0.6;
}

.chatbox .msg_cotainer_send:after {
  content: "";
  position: absolute;
  right: -0.625rem;
  border-left: 0.625rem solid #F0F0F0;
  border-bottom: 0.625rem solid transparent;
  border-top: 0rem solid;
  top: 0;
}

[data-theme-version="dark"] .chatbox .msg_cotainer_send:after {
  border-left: 0.625rem solid #20203c;
}

.chatbox .type_msg .form-control {
  padding: 0.625rem 0;
  height: 3.125rem;
  border: 0;
  resize: none;
}

.chatbox .type_msg .form-control:focus {
  z-index: 0;
}

.chatbox .type_msg .btn {
  font-size: 1.125rem;
  border-radius: 2.375rem !important;
  width: 2.375rem;
  height: 2.375rem;
  padding: 0;
  margin-top: 0.375rem;
}

.chatbox .video_cam {
  margin-left: 0.9375rem;
}

.chatbox .video_cam span {
  width: 2.1875rem;
  height: 2.1875rem;
  background: #10ca93;
  text-align: center;
  line-height: 2.1875rem;
  border-radius: 2.1875rem;
  color: #fff;
  margin-right: 0.3125rem;
  align-self: center;
  font-size: 1rem;
  padding: 0 0.1875rem;
  display: inline-block;
}

.chatbox .note_card .contacts li {
  padding: 0.75rem 1rem;
}

@media only screen and (max-width: 36rem) {
  .chatbox {
    width: 17.5rem;
  }

  .chatbox .chatbox-close {
    right: 17.5rem;
  }
}

.dlab-demo-panel {
  right: -100%;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  transition: all .5s ease-in-out;
  z-index: 9999;
}

.dlab-demo-panel .note-text {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  font-size: 12px;
  background-color: transprent;
  color: #9b9a9a;
}

.dlab-demo-panel .dlab-demo-trigger {
  position: absolute;
  z-index: 9;
  top: 7.75rem;
  right: 100%;
  background-color: #627EEA;
  color: #fff;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  text-align: center;
  font-size: 1.75rem;
  line-height: 3rem;
  border-radius: 0.3125rem 0 0 0.3125rem;
  box-shadow: -0.3125rem 0.1875rem 0.3125rem 0rem rgba(119, 119, 119, 0.15);
}

@media only screen and (max-width: 63.9375rem) {
  .dlab-demo-panel .dlab-demo-trigger {
    display: none;
  }
}

.dlab-demo-panel .dlab-demo-close {
  height: 1.875rem;
  color: #000 !important;
  width: 1.875rem;
  border-radius: 0.5rem;
  background: #fff;
  line-height: 1.875rem;
  text-align: center;
}

.dlab-demo-panel:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.85);
  display: block;
  backdrop-filter: blur(4px);
}

.dlab-demo-panel.show {
  right: 0;
  box-shadow: 0rem 0rem 3.125rem rgba(0, 0, 0, 0.2);
  z-index: 99999;
  overflow: hidden;
}

.dlab-demo-panel.show .sidebar-right-trigger {
  display: none;
}

.dlab-demo-panel.show .bg-close {
  position: fixed;
  z-index: -2;
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.15);
}

.dlab-demo-panel .dlab-demo-inner {
  padding: 1.875rem 1.875rem;
}

.dlab-demo-panel .dlab-demo-content {
  height: calc(100vh - 200px);
  padding: 0 15px;
  margin: 0 -15px;
  display: flex;
}

.dlab-demo-panel .dlab-demo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.5rem;
}

.dlab-demo-panel .dlab-demo-header h4, .dlab-demo-panel .dlab-demo-header .h4 {
  margin-bottom: 0;
  color: #fff;
}

.dlab-demo-panel .dlab-demo-bx {
  overflow: hidden;
  border: 3px solid #efefef;
  box-shadow: 0rem 0rem 0.3125rem rgba(0, 0, 0, 0.1);
  margin-bottom: 0.625rem;
}

.dlab-demo-panel .dlab-demo-bx.demo-active {
  border-color: #4CBC9A;
}

.dlab-demo-panel .dlab-demo-bx.demo-active .overlay-layer {
  opacity: 1;
}

.dlab-demo-panel .overlay-bx {
  position: relative;
}

.dlab-demo-panel .overlay-bx .overlay-layer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  opacity: 0;
  border: 0;
}

.dlab-demo-panel .overlay-bx:hover .overlay-layer {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  opacity: 1;
}

:root {
  --nav-headbg: #FFFFFF;
  --sidebar-bg: #FFFFFF;
  --headerbg: #E2E2E2;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"],
[data-nav-headerbg="color_2"] {
  --nav-headbg: #49bb98;
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-1,
[data-nav-headerbg="color_2"] .nav-header .brand-logo .logo-abbr .line-1 {
  fill: #fff;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
[data-nav-headerbg="color_2"] .nav-header .brand-logo .logo-abbr .line-2 {
  fill: #fff;
}

@media only screen and (max-width: 47.9375rem) {

  [data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
  [data-nav-headerbg="color_2"] .nav-header .brand-logo .logo-abbr .line-2 {
    fill: var(--primary);
  }
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header .brand-logo .brand-title .line-3,
[data-nav-headerbg="color_2"] .nav-header .brand-logo .brand-title .line-3 {
  fill: #fff;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_2"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"],
[data-nav-headerbg="color_3"] {
  --nav-headbg: #453773;
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-1,
[data-nav-headerbg="color_3"] .nav-header .brand-logo .logo-abbr .line-1 {
  fill: #fff;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
[data-nav-headerbg="color_3"] .nav-header .brand-logo .logo-abbr .line-2 {
  fill: #fff;
}

@media only screen and (max-width: 47.9375rem) {

  [data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
  [data-nav-headerbg="color_3"] .nav-header .brand-logo .logo-abbr .line-2 {
    fill: var(--primary);
  }
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header .brand-logo .brand-title .line-3,
[data-nav-headerbg="color_3"] .nav-header .brand-logo .brand-title .line-3 {
  fill: #fff;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_3"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"],
[data-nav-headerbg="color_4"] {
  --nav-headbg: #ff965f;
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-1,
[data-nav-headerbg="color_4"] .nav-header .brand-logo .logo-abbr .line-1 {
  fill: #fff;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
[data-nav-headerbg="color_4"] .nav-header .brand-logo .logo-abbr .line-2 {
  fill: #fff;
}

@media only screen and (max-width: 47.9375rem) {

  [data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
  [data-nav-headerbg="color_4"] .nav-header .brand-logo .logo-abbr .line-2 {
    fill: var(--primary);
  }
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header .brand-logo .brand-title .line-3,
[data-nav-headerbg="color_4"] .nav-header .brand-logo .brand-title .line-3 {
  fill: #fff;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_4"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"],
[data-nav-headerbg="color_5"] {
  --nav-headbg: #3250ff;
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-1,
[data-nav-headerbg="color_5"] .nav-header .brand-logo .logo-abbr .line-1 {
  fill: #fff;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
[data-nav-headerbg="color_5"] .nav-header .brand-logo .logo-abbr .line-2 {
  fill: #fff;
}

@media only screen and (max-width: 47.9375rem) {

  [data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
  [data-nav-headerbg="color_5"] .nav-header .brand-logo .logo-abbr .line-2 {
    fill: var(--primary);
  }
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header .brand-logo .brand-title .line-3,
[data-nav-headerbg="color_5"] .nav-header .brand-logo .brand-title .line-3 {
  fill: #fff;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_5"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"],
[data-nav-headerbg="color_6"] {
  --nav-headbg: #3f8cff;
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-1,
[data-nav-headerbg="color_6"] .nav-header .brand-logo .logo-abbr .line-1 {
  fill: #fff;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
[data-nav-headerbg="color_6"] .nav-header .brand-logo .logo-abbr .line-2 {
  fill: #fff;
}

@media only screen and (max-width: 47.9375rem) {

  [data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
  [data-nav-headerbg="color_6"] .nav-header .brand-logo .logo-abbr .line-2 {
    fill: var(--primary);
  }
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header .brand-logo .brand-title .line-3,
[data-nav-headerbg="color_6"] .nav-header .brand-logo .brand-title .line-3 {
  fill: #fff;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_6"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"],
[data-nav-headerbg="color_7"] {
  --nav-headbg: #40a7e2;
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-1,
[data-nav-headerbg="color_7"] .nav-header .brand-logo .logo-abbr .line-1 {
  fill: #fff;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
[data-nav-headerbg="color_7"] .nav-header .brand-logo .logo-abbr .line-2 {
  fill: #fff;
}

@media only screen and (max-width: 47.9375rem) {

  [data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
  [data-nav-headerbg="color_7"] .nav-header .brand-logo .logo-abbr .line-2 {
    fill: var(--primary);
  }
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header .brand-logo .brand-title .line-3,
[data-nav-headerbg="color_7"] .nav-header .brand-logo .brand-title .line-3 {
  fill: #fff;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_7"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"],
[data-nav-headerbg="color_8"] {
  --nav-headbg: #b65fbd;
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-1,
[data-nav-headerbg="color_8"] .nav-header .brand-logo .logo-abbr .line-1 {
  fill: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
[data-nav-headerbg="color_8"] .nav-header .brand-logo .logo-abbr .line-2 {
  fill: #fff;
}

@media only screen and (max-width: 47.9375rem) {

  [data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
  [data-nav-headerbg="color_8"] .nav-header .brand-logo .logo-abbr .line-2 {
    fill: var(--primary);
  }
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header .brand-logo .brand-title .line-3,
[data-nav-headerbg="color_8"] .nav-header .brand-logo .brand-title .line-3 {
  fill: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_8"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"],
[data-nav-headerbg="color_9"] {
  --nav-headbg: #88a67e;
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-1,
[data-nav-headerbg="color_9"] .nav-header .brand-logo .logo-abbr .line-1 {
  fill: #fff;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
[data-nav-headerbg="color_9"] .nav-header .brand-logo .logo-abbr .line-2 {
  fill: #fff;
}

@media only screen and (max-width: 47.9375rem) {

  [data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
  [data-nav-headerbg="color_9"] .nav-header .brand-logo .logo-abbr .line-2 {
    fill: var(--primary);
  }
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header .brand-logo .brand-title .line-3,
[data-nav-headerbg="color_9"] .nav-header .brand-logo .brand-title .line-3 {
  fill: #fff;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_9"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"],
[data-nav-headerbg="color_10"] {
  --nav-headbg: #6759d1;
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-1,
[data-nav-headerbg="color_10"] .nav-header .brand-logo .logo-abbr .line-1 {
  fill: #fff;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
[data-nav-headerbg="color_10"] .nav-header .brand-logo .logo-abbr .line-2 {
  fill: #fff;
}

@media only screen and (max-width: 47.9375rem) {

  [data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
  [data-nav-headerbg="color_10"] .nav-header .brand-logo .logo-abbr .line-2 {
    fill: var(--primary);
  }
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header .brand-logo .brand-title .line-3,
[data-nav-headerbg="color_10"] .nav-header .brand-logo .brand-title .line-3 {
  fill: #fff;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_10"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"],
[data-nav-headerbg="color_11"] {
  --nav-headbg: #c6164f;
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-1,
[data-nav-headerbg="color_11"] .nav-header .brand-logo .logo-abbr .line-1 {
  fill: #fff;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
[data-nav-headerbg="color_11"] .nav-header .brand-logo .logo-abbr .line-2 {
  fill: #fff;
}

@media only screen and (max-width: 47.9375rem) {

  [data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
  [data-nav-headerbg="color_11"] .nav-header .brand-logo .logo-abbr .line-2 {
    fill: var(--primary);
  }
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header .brand-logo .brand-title .line-3,
[data-nav-headerbg="color_11"] .nav-header .brand-logo .brand-title .line-3 {
  fill: #fff;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_11"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"],
[data-nav-headerbg="color_12"] {
  --nav-headbg: #343a40;
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-1,
[data-nav-headerbg="color_12"] .nav-header .brand-logo .logo-abbr .line-1 {
  fill: #fff;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
[data-nav-headerbg="color_12"] .nav-header .brand-logo .logo-abbr .line-2 {
  fill: #fff;
}

@media only screen and (max-width: 47.9375rem) {

  [data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
  [data-nav-headerbg="color_12"] .nav-header .brand-logo .logo-abbr .line-2 {
    fill: var(--primary);
  }
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header .brand-logo .brand-title .line-3,
[data-nav-headerbg="color_12"] .nav-header .brand-logo .brand-title .line-3 {
  fill: #fff;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_12"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"],
[data-nav-headerbg="color_13"] {
  --nav-headbg: #2a2a2a;
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-1,
[data-nav-headerbg="color_13"] .nav-header .brand-logo .logo-abbr .line-1 {
  fill: #fff;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
[data-nav-headerbg="color_13"] .nav-header .brand-logo .logo-abbr .line-2 {
  fill: #fff;
}

@media only screen and (max-width: 47.9375rem) {

  [data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
  [data-nav-headerbg="color_13"] .nav-header .brand-logo .logo-abbr .line-2 {
    fill: var(--primary);
  }
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header .brand-logo .brand-title .line-3,
[data-nav-headerbg="color_13"] .nav-header .brand-logo .brand-title .line-3 {
  fill: #fff;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_13"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"],
[data-nav-headerbg="color_14"] {
  --nav-headbg: #4885ed;
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-1,
[data-nav-headerbg="color_14"] .nav-header .brand-logo .logo-abbr .line-1 {
  fill: #fff;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
[data-nav-headerbg="color_14"] .nav-header .brand-logo .logo-abbr .line-2 {
  fill: #fff;
}

@media only screen and (max-width: 47.9375rem) {

  [data-nav-headerbg="color_14"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
  [data-nav-headerbg="color_14"] .nav-header .brand-logo .logo-abbr .line-2 {
    fill: var(--primary);
  }
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .nav-header .brand-logo .brand-title .line-3,
[data-nav-headerbg="color_14"] .nav-header .brand-logo .brand-title .line-3 {
  fill: #fff;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_14"] .nav-header .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"],
[data-nav-headerbg="color_15"] {
  --nav-headbg: #4cb32b;
  /* &[data-nav-headerbg="color_8"]{
				.nav-header{
					.brand-logo{
						.brand-title{
							path{
								fill:$black;		
							}
							tspan{
								fill:$black;	
							}
						}	
					}
					.hamburger .line{
						background:$black;
					}
				}
			} */
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-1,
[data-nav-headerbg="color_15"] .nav-header .brand-logo .logo-abbr .line-1 {
  fill: #fff;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
[data-nav-headerbg="color_15"] .nav-header .brand-logo .logo-abbr .line-2 {
  fill: #fff;
}

@media only screen and (max-width: 47.9375rem) {

  [data-nav-headerbg="color_15"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr .line-2,
  [data-nav-headerbg="color_15"] .nav-header .brand-logo .logo-abbr .line-2 {
    fill: var(--primary);
  }
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .nav-header .brand-logo .brand-title .line-3,
[data-nav-headerbg="color_15"] .nav-header .brand-logo .brand-title .line-3 {
  fill: #fff;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_15"] .nav-header .hamburger .line {
  background: #000;
}

[data-sibebarbg="color_2"][data-theme-version="dark"],
[data-sibebarbg="color_2"] {
  --sidebar-bg: #49bb98;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.dlabnav .metismenu > li.mm-active > a,
				.dlabnav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.dlabnav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .dlabnav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sibebarbg="color_2"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #49bb98 !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sibebarbg="color_2"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_2"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box h5, [data-sibebarbg="color_2"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box p,
[data-sibebarbg="color_2"][data-layout="vertical"] .dlabnav .plus-box h5,
[data-sibebarbg="color_2"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_2"][data-layout="vertical"] .dlabnav .plus-box p {
  color: #fff;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul, [data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul, [data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul, [data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul, [data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_2"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_2"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_2"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_2"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_2"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #6ec9ad;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_2"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_2"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_2"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_2"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_2"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #54bf9e !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a, [data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i, [data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #6ec9ad !important;
  color: #fff !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sibebarbg="color_2"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover, [data-sibebarbg="color_2"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus, [data-sibebarbg="color_2"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sibebarbg="color_2"] .dlabnav .metismenu li ul a:hover,
[data-sibebarbg="color_2"] .dlabnav .metismenu li ul a:focus,
[data-sibebarbg="color_2"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sibebarbg="color_2"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sibebarbg="color_2"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_2"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sibebarbg="color_2"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sibebarbg="color_2"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sibebarbg="color_2"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sibebarbg="color_2"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sibebarbg="color_2"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .plus-box,
[data-sibebarbg="color_2"] .plus-box {
  background-color: #6ec9ad;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_2"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_3"][data-theme-version="dark"],
[data-sibebarbg="color_3"] {
  --sidebar-bg: #453773;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.dlabnav .metismenu > li.mm-active > a,
				.dlabnav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.dlabnav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .dlabnav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sibebarbg="color_3"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #453773 !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sibebarbg="color_3"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_3"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box h5, [data-sibebarbg="color_3"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box p,
[data-sibebarbg="color_3"][data-layout="vertical"] .dlabnav .plus-box h5,
[data-sibebarbg="color_3"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_3"][data-layout="vertical"] .dlabnav .plus-box p {
  color: #fff;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul, [data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul, [data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul, [data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul, [data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_3"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_3"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_3"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_3"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_3"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #ff988c;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_3"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_3"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_3"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_3"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_3"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #ff7868 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a, [data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i, [data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #ff988c !important;
  color: #fff !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sibebarbg="color_3"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover, [data-sibebarbg="color_3"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus, [data-sibebarbg="color_3"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sibebarbg="color_3"] .dlabnav .metismenu li ul a:hover,
[data-sibebarbg="color_3"] .dlabnav .metismenu li ul a:focus,
[data-sibebarbg="color_3"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sibebarbg="color_3"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sibebarbg="color_3"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_3"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sibebarbg="color_3"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sibebarbg="color_3"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sibebarbg="color_3"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sibebarbg="color_3"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sibebarbg="color_3"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .plus-box,
[data-sibebarbg="color_3"] .plus-box {
  background-color: #ff988c;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_3"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_4"][data-theme-version="dark"],
[data-sibebarbg="color_4"] {
  --sidebar-bg: #ff965f;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.dlabnav .metismenu > li.mm-active > a,
				.dlabnav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.dlabnav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .dlabnav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sibebarbg="color_4"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #ff965f !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sibebarbg="color_4"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_4"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box h5, [data-sibebarbg="color_4"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box p,
[data-sibebarbg="color_4"][data-layout="vertical"] .dlabnav .plus-box h5,
[data-sibebarbg="color_4"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_4"][data-layout="vertical"] .dlabnav .plus-box p {
  color: #fff;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul, [data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul, [data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul, [data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul, [data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_4"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_4"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_4"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_4"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_4"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #ffb792;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_4"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_4"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_4"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_4"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_4"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #ffa06e !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a, [data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i, [data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #ffb792 !important;
  color: #fff !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sibebarbg="color_4"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover, [data-sibebarbg="color_4"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus, [data-sibebarbg="color_4"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sibebarbg="color_4"] .dlabnav .metismenu li ul a:hover,
[data-sibebarbg="color_4"] .dlabnav .metismenu li ul a:focus,
[data-sibebarbg="color_4"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sibebarbg="color_4"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sibebarbg="color_4"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_4"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sibebarbg="color_4"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sibebarbg="color_4"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sibebarbg="color_4"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sibebarbg="color_4"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sibebarbg="color_4"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .plus-box,
[data-sibebarbg="color_4"] .plus-box {
  background-color: #ffb792;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_4"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_5"][data-theme-version="dark"],
[data-sibebarbg="color_5"] {
  --sidebar-bg: #3250ff;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.dlabnav .metismenu > li.mm-active > a,
				.dlabnav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.dlabnav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .dlabnav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sibebarbg="color_5"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #3250ff !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sibebarbg="color_5"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_5"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box h5, [data-sibebarbg="color_5"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box p,
[data-sibebarbg="color_5"][data-layout="vertical"] .dlabnav .plus-box h5,
[data-sibebarbg="color_5"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_5"][data-layout="vertical"] .dlabnav .plus-box p {
  color: #fff;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul, [data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul, [data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul, [data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul, [data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_5"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_5"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_5"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_5"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_5"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #657cff;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_5"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_5"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_5"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_5"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_5"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #415dff !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a, [data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i, [data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #657cff !important;
  color: #fff !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sibebarbg="color_5"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover, [data-sibebarbg="color_5"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus, [data-sibebarbg="color_5"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sibebarbg="color_5"] .dlabnav .metismenu li ul a:hover,
[data-sibebarbg="color_5"] .dlabnav .metismenu li ul a:focus,
[data-sibebarbg="color_5"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sibebarbg="color_5"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sibebarbg="color_5"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_5"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sibebarbg="color_5"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sibebarbg="color_5"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sibebarbg="color_5"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sibebarbg="color_5"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sibebarbg="color_5"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .plus-box,
[data-sibebarbg="color_5"] .plus-box {
  background-color: #657cff;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_5"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_6"][data-theme-version="dark"],
[data-sibebarbg="color_6"] {
  --sidebar-bg: #3f8cff;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.dlabnav .metismenu > li.mm-active > a,
				.dlabnav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.dlabnav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .dlabnav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sibebarbg="color_6"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #3f8cff !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sibebarbg="color_6"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_6"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box h5, [data-sibebarbg="color_6"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box p,
[data-sibebarbg="color_6"][data-layout="vertical"] .dlabnav .plus-box h5,
[data-sibebarbg="color_6"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_6"][data-layout="vertical"] .dlabnav .plus-box p {
  color: #fff;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul, [data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul, [data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul, [data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul, [data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_6"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_6"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_6"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_6"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_6"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #72abff;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_6"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_6"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_6"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_6"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_6"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #4e95ff !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a, [data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i, [data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #72abff !important;
  color: #fff !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sibebarbg="color_6"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover, [data-sibebarbg="color_6"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus, [data-sibebarbg="color_6"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sibebarbg="color_6"] .dlabnav .metismenu li ul a:hover,
[data-sibebarbg="color_6"] .dlabnav .metismenu li ul a:focus,
[data-sibebarbg="color_6"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sibebarbg="color_6"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sibebarbg="color_6"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_6"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sibebarbg="color_6"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sibebarbg="color_6"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sibebarbg="color_6"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sibebarbg="color_6"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sibebarbg="color_6"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .plus-box,
[data-sibebarbg="color_6"] .plus-box {
  background-color: #72abff;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_6"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_7"][data-theme-version="dark"],
[data-sibebarbg="color_7"] {
  --sidebar-bg: #40a7e2;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.dlabnav .metismenu > li.mm-active > a,
				.dlabnav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.dlabnav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .dlabnav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sibebarbg="color_7"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #40a7e2 !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sibebarbg="color_7"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_7"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box h5, [data-sibebarbg="color_7"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box p,
[data-sibebarbg="color_7"][data-layout="vertical"] .dlabnav .plus-box h5,
[data-sibebarbg="color_7"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_7"][data-layout="vertical"] .dlabnav .plus-box p {
  color: #fff;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul, [data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul, [data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul, [data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul, [data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_7"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_7"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_7"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_7"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_7"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #6cbbe9;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_7"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_7"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_7"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_7"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_7"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #4dade4 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a, [data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i, [data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #6cbbe9 !important;
  color: #fff !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sibebarbg="color_7"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover, [data-sibebarbg="color_7"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus, [data-sibebarbg="color_7"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sibebarbg="color_7"] .dlabnav .metismenu li ul a:hover,
[data-sibebarbg="color_7"] .dlabnav .metismenu li ul a:focus,
[data-sibebarbg="color_7"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sibebarbg="color_7"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sibebarbg="color_7"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_7"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sibebarbg="color_7"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sibebarbg="color_7"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sibebarbg="color_7"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sibebarbg="color_7"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sibebarbg="color_7"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .plus-box,
[data-sibebarbg="color_7"] .plus-box {
  background-color: #6cbbe9;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_7"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_8"][data-theme-version="dark"],
[data-sibebarbg="color_8"] {
  --sidebar-bg: #b65fbd;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.dlabnav .metismenu > li.mm-active > a,
				.dlabnav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.dlabnav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .dlabnav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sibebarbg="color_8"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #b65fbd !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sibebarbg="color_8"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_8"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box h5, [data-sibebarbg="color_8"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box p,
[data-sibebarbg="color_8"][data-layout="vertical"] .dlabnav .plus-box h5,
[data-sibebarbg="color_8"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_8"][data-layout="vertical"] .dlabnav .plus-box p {
  color: #fff;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul, [data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul, [data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul, [data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul, [data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_8"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_8"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_8"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_8"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_8"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #c683cc;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_8"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_8"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_8"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_8"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_8"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #bb6ac1 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a, [data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i, [data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #c683cc !important;
  color: #fff !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sibebarbg="color_8"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover, [data-sibebarbg="color_8"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus, [data-sibebarbg="color_8"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sibebarbg="color_8"] .dlabnav .metismenu li ul a:hover,
[data-sibebarbg="color_8"] .dlabnav .metismenu li ul a:focus,
[data-sibebarbg="color_8"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sibebarbg="color_8"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sibebarbg="color_8"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_8"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sibebarbg="color_8"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sibebarbg="color_8"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sibebarbg="color_8"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sibebarbg="color_8"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sibebarbg="color_8"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .plus-box,
[data-sibebarbg="color_8"] .plus-box {
  background-color: #c683cc;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_8"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_9"][data-theme-version="dark"],
[data-sibebarbg="color_9"] {
  --sidebar-bg: #88a67e;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.dlabnav .metismenu > li.mm-active > a,
				.dlabnav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.dlabnav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .dlabnav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sibebarbg="color_9"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #88a67e !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sibebarbg="color_9"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_9"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box h5, [data-sibebarbg="color_9"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box p,
[data-sibebarbg="color_9"][data-layout="vertical"] .dlabnav .plus-box h5,
[data-sibebarbg="color_9"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_9"][data-layout="vertical"] .dlabnav .plus-box p {
  color: #fff;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul, [data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul, [data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul, [data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul, [data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_9"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_9"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_9"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_9"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_9"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #a4bb9c;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_9"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_9"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_9"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_9"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_9"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #90ac87 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a, [data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i, [data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #a4bb9c !important;
  color: #fff !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sibebarbg="color_9"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover, [data-sibebarbg="color_9"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus, [data-sibebarbg="color_9"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sibebarbg="color_9"] .dlabnav .metismenu li ul a:hover,
[data-sibebarbg="color_9"] .dlabnav .metismenu li ul a:focus,
[data-sibebarbg="color_9"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sibebarbg="color_9"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sibebarbg="color_9"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_9"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sibebarbg="color_9"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sibebarbg="color_9"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sibebarbg="color_9"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sibebarbg="color_9"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sibebarbg="color_9"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .plus-box,
[data-sibebarbg="color_9"] .plus-box {
  background-color: #a4bb9c;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_9"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_10"][data-theme-version="dark"],
[data-sibebarbg="color_10"] {
  --sidebar-bg: #6759d1;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.dlabnav .metismenu > li.mm-active > a,
				.dlabnav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.dlabnav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .dlabnav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sibebarbg="color_10"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #6759d1 !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sibebarbg="color_10"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_10"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box h5, [data-sibebarbg="color_10"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box p,
[data-sibebarbg="color_10"][data-layout="vertical"] .dlabnav .plus-box h5,
[data-sibebarbg="color_10"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_10"][data-layout="vertical"] .dlabnav .plus-box p {
  color: #fff;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul, [data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul, [data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul, [data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul, [data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_10"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_10"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_10"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_10"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_10"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #8c81dc;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_10"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_10"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_10"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_10"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_10"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #7265d4 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a, [data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i, [data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #8c81dc !important;
  color: #fff !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sibebarbg="color_10"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover, [data-sibebarbg="color_10"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus, [data-sibebarbg="color_10"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sibebarbg="color_10"] .dlabnav .metismenu li ul a:hover,
[data-sibebarbg="color_10"] .dlabnav .metismenu li ul a:focus,
[data-sibebarbg="color_10"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sibebarbg="color_10"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sibebarbg="color_10"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_10"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sibebarbg="color_10"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sibebarbg="color_10"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sibebarbg="color_10"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sibebarbg="color_10"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sibebarbg="color_10"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .plus-box,
[data-sibebarbg="color_10"] .plus-box {
  background-color: #8c81dc;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_10"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_11"][data-theme-version="dark"],
[data-sibebarbg="color_11"] {
  --sidebar-bg: #c6164f;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.dlabnav .metismenu > li.mm-active > a,
				.dlabnav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.dlabnav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .dlabnav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sibebarbg="color_11"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #c6164f !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sibebarbg="color_11"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_11"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box h5, [data-sibebarbg="color_11"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box p,
[data-sibebarbg="color_11"][data-layout="vertical"] .dlabnav .plus-box h5,
[data-sibebarbg="color_11"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_11"][data-layout="vertical"] .dlabnav .plus-box p {
  color: #fff;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul, [data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul, [data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul, [data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul, [data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_11"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_11"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_11"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_11"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_11"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #e72866;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_11"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_11"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_11"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_11"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_11"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #d41854 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a, [data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i, [data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #e72866 !important;
  color: #fff !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sibebarbg="color_11"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover, [data-sibebarbg="color_11"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus, [data-sibebarbg="color_11"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sibebarbg="color_11"] .dlabnav .metismenu li ul a:hover,
[data-sibebarbg="color_11"] .dlabnav .metismenu li ul a:focus,
[data-sibebarbg="color_11"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sibebarbg="color_11"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sibebarbg="color_11"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_11"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sibebarbg="color_11"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sibebarbg="color_11"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sibebarbg="color_11"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sibebarbg="color_11"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sibebarbg="color_11"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .plus-box,
[data-sibebarbg="color_11"] .plus-box {
  background-color: #e72866;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_11"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_12"][data-theme-version="dark"],
[data-sibebarbg="color_12"] {
  --sidebar-bg: #343a40;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.dlabnav .metismenu > li.mm-active > a,
				.dlabnav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.dlabnav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .dlabnav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sibebarbg="color_12"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #343a40 !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sibebarbg="color_12"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_12"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box h5, [data-sibebarbg="color_12"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box p,
[data-sibebarbg="color_12"][data-layout="vertical"] .dlabnav .plus-box h5,
[data-sibebarbg="color_12"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_12"][data-layout="vertical"] .dlabnav .plus-box p {
  color: #fff;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul, [data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul, [data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul, [data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul, [data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_12"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_12"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_12"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_12"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_12"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #4b545c;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_12"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_12"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_12"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_12"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_12"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #3b4248 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a, [data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i, [data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #4b545c !important;
  color: #fff !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sibebarbg="color_12"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover, [data-sibebarbg="color_12"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus, [data-sibebarbg="color_12"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sibebarbg="color_12"] .dlabnav .metismenu li ul a:hover,
[data-sibebarbg="color_12"] .dlabnav .metismenu li ul a:focus,
[data-sibebarbg="color_12"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sibebarbg="color_12"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sibebarbg="color_12"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_12"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sibebarbg="color_12"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sibebarbg="color_12"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sibebarbg="color_12"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sibebarbg="color_12"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sibebarbg="color_12"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .plus-box,
[data-sibebarbg="color_12"] .plus-box {
  background-color: #4b545c;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_12"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_13"][data-theme-version="dark"],
[data-sibebarbg="color_13"] {
  --sidebar-bg: #2a2a2a;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.dlabnav .metismenu > li.mm-active > a,
				.dlabnav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.dlabnav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .dlabnav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sibebarbg="color_13"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #2a2a2a !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sibebarbg="color_13"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_13"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box h5, [data-sibebarbg="color_13"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box p,
[data-sibebarbg="color_13"][data-layout="vertical"] .dlabnav .plus-box h5,
[data-sibebarbg="color_13"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_13"][data-layout="vertical"] .dlabnav .plus-box p {
  color: #fff;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul, [data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul, [data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul, [data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul, [data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_13"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_13"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_13"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_13"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_13"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #444444;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_13"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_13"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_13"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_13"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_13"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #323232 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a, [data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i, [data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #444444 !important;
  color: #fff !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sibebarbg="color_13"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover, [data-sibebarbg="color_13"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus, [data-sibebarbg="color_13"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sibebarbg="color_13"] .dlabnav .metismenu li ul a:hover,
[data-sibebarbg="color_13"] .dlabnav .metismenu li ul a:focus,
[data-sibebarbg="color_13"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sibebarbg="color_13"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sibebarbg="color_13"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_13"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sibebarbg="color_13"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sibebarbg="color_13"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sibebarbg="color_13"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sibebarbg="color_13"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sibebarbg="color_13"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .plus-box,
[data-sibebarbg="color_13"] .plus-box {
  background-color: #444444;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_13"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_14"][data-theme-version="dark"],
[data-sibebarbg="color_14"] {
  --sidebar-bg: #4885ed;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.dlabnav .metismenu > li.mm-active > a,
				.dlabnav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.dlabnav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .dlabnav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sibebarbg="color_14"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #4885ed !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sibebarbg="color_14"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_14"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box h5, [data-sibebarbg="color_14"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box p,
[data-sibebarbg="color_14"][data-layout="vertical"] .dlabnav .plus-box h5,
[data-sibebarbg="color_14"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_14"][data-layout="vertical"] .dlabnav .plus-box p {
  color: #fff;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul, [data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul, [data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul, [data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul, [data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_14"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_14"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_14"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_14"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_14"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #76a4f2;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_14"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_14"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_14"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_14"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_14"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #568eee !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a, [data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i, [data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #76a4f2 !important;
  color: #fff !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sibebarbg="color_14"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover, [data-sibebarbg="color_14"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus, [data-sibebarbg="color_14"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sibebarbg="color_14"] .dlabnav .metismenu li ul a:hover,
[data-sibebarbg="color_14"] .dlabnav .metismenu li ul a:focus,
[data-sibebarbg="color_14"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sibebarbg="color_14"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sibebarbg="color_14"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_14"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sibebarbg="color_14"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sibebarbg="color_14"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sibebarbg="color_14"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sibebarbg="color_14"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sibebarbg="color_14"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .plus-box,
[data-sibebarbg="color_14"] .plus-box {
  background-color: #76a4f2;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_14"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_15"][data-theme-version="dark"],
[data-sibebarbg="color_15"] {
  --sidebar-bg: #4cb32b;
  /* .hamburger .line{
				background:$white;
			} */
  /* &[data-sidebar-style="full"][data-layout="vertical"]{
				.dlabnav .metismenu > li.mm-active > a,
				.dlabnav .metismenu > li:hover > a{
						background:transparent;
				}	
			} */
  /* &[data-sibebarbg="color_8"]{
				.dlabnav .metismenu ul a:before {
					background:rgba($black,0.5);
				}
				 .dlabnav {
					.metismenu{
						a{
							color:rgba($black,0.6)!important;
							&:hover,
							&:focus,
							&.mm-active {
								color:$black!important;
							}
						}
						.has-arrow:after{
							    border-color: rgba(0,0,0,0.85) transparent transparent rgba(0,0,0,0.85);
						}
						&>li{
							&>a{
								color:rgba($black,0.6)!important;
								i{
									color:rgba($black,0.6)!important;
								}
							}
							&.mm-active{
								&>a{
									background:rgba($black,0.6)!important;
									&:before{
										background:rgba($black,0.6)!important;
									}
									
									
								}	
							}
						}
					}
					.copyright p{
						color:rgba($black,0.6);							
					}
					.book-box{
						background: rgba($black,0.4);
					}
					.header-info2 span{
					color: rgba($black,0.6)
					}
					.sidebar-info{
						color: rgba($black,0.6); 
						i{
							color: rgba($black,0.6) 		
						}
					}
				} 
				
				 .plus-box{
					background-color:rgba($black,0.4);	
				} 
			} */
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sibebarbg="color_15"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #4cb32b !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sibebarbg="color_15"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_15"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box h5, [data-sibebarbg="color_15"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .plus-box p,
[data-sibebarbg="color_15"][data-layout="vertical"] .dlabnav .plus-box h5,
[data-sibebarbg="color_15"][data-layout="vertical"] .dlabnav .plus-box .h5,
[data-sibebarbg="color_15"][data-layout="vertical"] .dlabnav .plus-box p {
  color: #fff;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul, [data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul, [data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul, [data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul, [data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_15"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_15"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_15"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_15"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sibebarbg="color_15"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #63d140;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a, [data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_15"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_15"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_15"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_15"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_15"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #51bf2e !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a, [data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i, [data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #63d140 !important;
  color: #fff !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sibebarbg="color_15"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover, [data-sibebarbg="color_15"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus, [data-sibebarbg="color_15"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sibebarbg="color_15"] .dlabnav .metismenu li ul a:hover,
[data-sibebarbg="color_15"] .dlabnav .metismenu li ul a:focus,
[data-sibebarbg="color_15"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sibebarbg="color_15"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sibebarbg="color_15"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_15"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sibebarbg="color_15"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sibebarbg="color_15"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sibebarbg="color_15"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sibebarbg="color_15"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sibebarbg="color_15"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .plus-box,
[data-sibebarbg="color_15"] .plus-box {
  background-color: #63d140;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_15"] .copyright {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"],
[data-headerbg="color_2"] {
  --headerbg: #49bb98;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_2"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_2"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_2"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_2"] .search-area .input-group-text,
[data-headerbg="color_2"] .search-area .form-control,
[data-headerbg="color_2"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .input-group-text svg path,
[data-headerbg="color_2"] .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_2"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_2"] .header-right .search-area .form-control,
[data-headerbg="color_2"] .header-right .search-area .input-group-text {
  background-color: #6ec9ad;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_2"] .header-right .search-area .form-control i,
[data-headerbg="color_2"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_2"] .header-right .notification_dropdown .nav-link {
  background-color: #6ec9ad;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg="color_2"] .header-right .notification_dropdown .nav-link svg path {
  fill: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select,
[data-headerbg="color_2"] .header-right .dlab-side-menu .search-coundry .dashboard-select {
  color: #000;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after,
[data-headerbg="color_2"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after {
  border-color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_2"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small, [data-headerbg="color_2"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small, [data-headerbg="color_2"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_2"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_2"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_2"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_2"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_3"][data-theme-version="dark"],
[data-headerbg="color_3"] {
  --headerbg: #453773;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_3"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_3"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_3"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_3"] .search-area .input-group-text,
[data-headerbg="color_3"] .search-area .form-control,
[data-headerbg="color_3"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .input-group-text svg path,
[data-headerbg="color_3"] .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_3"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_3"] .header-right .search-area .form-control,
[data-headerbg="color_3"] .header-right .search-area .input-group-text {
  background-color: #ff988c;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_3"] .header-right .search-area .form-control i,
[data-headerbg="color_3"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_3"] .header-right .notification_dropdown .nav-link {
  background-color: #ff988c;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg="color_3"] .header-right .notification_dropdown .nav-link svg path {
  fill: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select,
[data-headerbg="color_3"] .header-right .dlab-side-menu .search-coundry .dashboard-select {
  color: #000;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after,
[data-headerbg="color_3"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after {
  border-color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_3"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small, [data-headerbg="color_3"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small, [data-headerbg="color_3"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_3"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_3"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_3"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_3"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_4"][data-theme-version="dark"],
[data-headerbg="color_4"] {
  --headerbg: #ff965f;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_4"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_4"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_4"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_4"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .input-group-text svg path,
[data-headerbg="color_4"] .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_4"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_4"] .header-right .search-area .form-control,
[data-headerbg="color_4"] .header-right .search-area .input-group-text {
  background-color: #ffb792;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_4"] .header-right .search-area .form-control i,
[data-headerbg="color_4"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_4"] .header-right .notification_dropdown .nav-link {
  background-color: #ffb792;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg="color_4"] .header-right .notification_dropdown .nav-link svg path {
  fill: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select,
[data-headerbg="color_4"] .header-right .dlab-side-menu .search-coundry .dashboard-select {
  color: #000;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after,
[data-headerbg="color_4"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after {
  border-color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_4"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small, [data-headerbg="color_4"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small, [data-headerbg="color_4"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_4"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_4"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_4"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_4"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_5"][data-theme-version="dark"],
[data-headerbg="color_5"] {
  --headerbg: #3250ff;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_5"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_5"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_5"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_5"] .search-area .input-group-text,
[data-headerbg="color_5"] .search-area .form-control,
[data-headerbg="color_5"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .input-group-text svg path,
[data-headerbg="color_5"] .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_5"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_5"] .header-right .search-area .form-control,
[data-headerbg="color_5"] .header-right .search-area .input-group-text {
  background-color: #657cff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_5"] .header-right .search-area .form-control i,
[data-headerbg="color_5"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_5"] .header-right .notification_dropdown .nav-link {
  background-color: #657cff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg="color_5"] .header-right .notification_dropdown .nav-link svg path {
  fill: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select,
[data-headerbg="color_5"] .header-right .dlab-side-menu .search-coundry .dashboard-select {
  color: #000;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after,
[data-headerbg="color_5"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after {
  border-color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_5"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small, [data-headerbg="color_5"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small, [data-headerbg="color_5"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_5"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_5"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_5"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_5"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_6"][data-theme-version="dark"],
[data-headerbg="color_6"] {
  --headerbg: #3f8cff;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_6"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_6"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_6"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_6"] .search-area .input-group-text,
[data-headerbg="color_6"] .search-area .form-control,
[data-headerbg="color_6"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .input-group-text svg path,
[data-headerbg="color_6"] .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_6"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_6"] .header-right .search-area .form-control,
[data-headerbg="color_6"] .header-right .search-area .input-group-text {
  background-color: #72abff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_6"] .header-right .search-area .form-control i,
[data-headerbg="color_6"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_6"] .header-right .notification_dropdown .nav-link {
  background-color: #72abff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg="color_6"] .header-right .notification_dropdown .nav-link svg path {
  fill: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select,
[data-headerbg="color_6"] .header-right .dlab-side-menu .search-coundry .dashboard-select {
  color: #000;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after,
[data-headerbg="color_6"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after {
  border-color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_6"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small, [data-headerbg="color_6"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small, [data-headerbg="color_6"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_6"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_6"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_6"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_6"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_7"][data-theme-version="dark"],
[data-headerbg="color_7"] {
  --headerbg: #40a7e2;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_7"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_7"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_7"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_7"] .search-area .input-group-text,
[data-headerbg="color_7"] .search-area .form-control,
[data-headerbg="color_7"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .input-group-text svg path,
[data-headerbg="color_7"] .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_7"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_7"] .header-right .search-area .form-control,
[data-headerbg="color_7"] .header-right .search-area .input-group-text {
  background-color: #6cbbe9;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_7"] .header-right .search-area .form-control i,
[data-headerbg="color_7"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_7"] .header-right .notification_dropdown .nav-link {
  background-color: #6cbbe9;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg="color_7"] .header-right .notification_dropdown .nav-link svg path {
  fill: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select,
[data-headerbg="color_7"] .header-right .dlab-side-menu .search-coundry .dashboard-select {
  color: #000;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after,
[data-headerbg="color_7"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after {
  border-color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_7"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small, [data-headerbg="color_7"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small, [data-headerbg="color_7"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_7"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_7"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_7"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_7"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_8"][data-theme-version="dark"],
[data-headerbg="color_8"] {
  --headerbg: #b65fbd;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_8"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_8"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_8"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_8"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .input-group-text svg path,
[data-headerbg="color_8"] .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_8"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_8"] .header-right .search-area .input-group-text {
  background-color: #c683cc;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_8"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_8"] .header-right .notification_dropdown .nav-link {
  background-color: #c683cc;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg="color_8"] .header-right .notification_dropdown .nav-link svg path {
  fill: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select,
[data-headerbg="color_8"] .header-right .dlab-side-menu .search-coundry .dashboard-select {
  color: #000;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after,
[data-headerbg="color_8"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after {
  border-color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_8"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small, [data-headerbg="color_8"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small, [data-headerbg="color_8"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_8"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_8"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_8"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_8"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_9"][data-theme-version="dark"],
[data-headerbg="color_9"] {
  --headerbg: #88a67e;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_9"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_9"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_9"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_9"] .search-area .input-group-text,
[data-headerbg="color_9"] .search-area .form-control,
[data-headerbg="color_9"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .input-group-text svg path,
[data-headerbg="color_9"] .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_9"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_9"] .header-right .search-area .form-control,
[data-headerbg="color_9"] .header-right .search-area .input-group-text {
  background-color: #a4bb9c;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_9"] .header-right .search-area .form-control i,
[data-headerbg="color_9"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_9"] .header-right .notification_dropdown .nav-link {
  background-color: #a4bb9c;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg="color_9"] .header-right .notification_dropdown .nav-link svg path {
  fill: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select,
[data-headerbg="color_9"] .header-right .dlab-side-menu .search-coundry .dashboard-select {
  color: #000;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after,
[data-headerbg="color_9"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after {
  border-color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_9"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small, [data-headerbg="color_9"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small, [data-headerbg="color_9"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_9"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_9"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_9"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_9"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_10"][data-theme-version="dark"],
[data-headerbg="color_10"] {
  --headerbg: #6759d1;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_10"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_10"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_10"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_10"] .search-area .input-group-text,
[data-headerbg="color_10"] .search-area .form-control,
[data-headerbg="color_10"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .input-group-text svg path,
[data-headerbg="color_10"] .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_10"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_10"] .header-right .search-area .form-control,
[data-headerbg="color_10"] .header-right .search-area .input-group-text {
  background-color: #8c81dc;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_10"] .header-right .search-area .form-control i,
[data-headerbg="color_10"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_10"] .header-right .notification_dropdown .nav-link {
  background-color: #8c81dc;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg="color_10"] .header-right .notification_dropdown .nav-link svg path {
  fill: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select,
[data-headerbg="color_10"] .header-right .dlab-side-menu .search-coundry .dashboard-select {
  color: #000;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after,
[data-headerbg="color_10"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after {
  border-color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_10"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small, [data-headerbg="color_10"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small, [data-headerbg="color_10"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_10"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_10"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_10"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_10"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_11"][data-theme-version="dark"],
[data-headerbg="color_11"] {
  --headerbg: #c6164f;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_11"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_11"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_11"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_11"] .search-area .input-group-text,
[data-headerbg="color_11"] .search-area .form-control,
[data-headerbg="color_11"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .input-group-text svg path,
[data-headerbg="color_11"] .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_11"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_11"] .header-right .search-area .form-control,
[data-headerbg="color_11"] .header-right .search-area .input-group-text {
  background-color: #e72866;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_11"] .header-right .search-area .form-control i,
[data-headerbg="color_11"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_11"] .header-right .notification_dropdown .nav-link {
  background-color: #e72866;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg="color_11"] .header-right .notification_dropdown .nav-link svg path {
  fill: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select,
[data-headerbg="color_11"] .header-right .dlab-side-menu .search-coundry .dashboard-select {
  color: #000;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after,
[data-headerbg="color_11"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after {
  border-color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_11"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small, [data-headerbg="color_11"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small, [data-headerbg="color_11"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_11"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_11"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_11"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_11"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_12"][data-theme-version="dark"],
[data-headerbg="color_12"] {
  --headerbg: #343a40;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_12"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_12"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_12"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_12"] .search-area .input-group-text,
[data-headerbg="color_12"] .search-area .form-control,
[data-headerbg="color_12"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .input-group-text svg path,
[data-headerbg="color_12"] .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_12"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_12"] .header-right .search-area .form-control,
[data-headerbg="color_12"] .header-right .search-area .input-group-text {
  background-color: #4b545c;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_12"] .header-right .search-area .form-control i,
[data-headerbg="color_12"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_12"] .header-right .notification_dropdown .nav-link {
  background-color: #4b545c;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg="color_12"] .header-right .notification_dropdown .nav-link svg path {
  fill: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select,
[data-headerbg="color_12"] .header-right .dlab-side-menu .search-coundry .dashboard-select {
  color: #000;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after,
[data-headerbg="color_12"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after {
  border-color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_12"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small, [data-headerbg="color_12"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small, [data-headerbg="color_12"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_12"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_12"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_12"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_12"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_13"][data-theme-version="dark"],
[data-headerbg="color_13"] {
  --headerbg: #2a2a2a;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_13"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_13"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_13"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_13"] .search-area .input-group-text,
[data-headerbg="color_13"] .search-area .form-control,
[data-headerbg="color_13"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .input-group-text svg path,
[data-headerbg="color_13"] .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_13"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_13"] .header-right .search-area .form-control,
[data-headerbg="color_13"] .header-right .search-area .input-group-text {
  background-color: #444444;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_13"] .header-right .search-area .form-control i,
[data-headerbg="color_13"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_13"] .header-right .notification_dropdown .nav-link {
  background-color: #444444;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg="color_13"] .header-right .notification_dropdown .nav-link svg path {
  fill: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select,
[data-headerbg="color_13"] .header-right .dlab-side-menu .search-coundry .dashboard-select {
  color: #000;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after,
[data-headerbg="color_13"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after {
  border-color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_13"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small, [data-headerbg="color_13"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small, [data-headerbg="color_13"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_13"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_13"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_13"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_13"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_14"][data-theme-version="dark"],
[data-headerbg="color_14"] {
  --headerbg: #4885ed;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_14"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_14"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_14"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_14"] .search-area .input-group-text,
[data-headerbg="color_14"] .search-area .form-control,
[data-headerbg="color_14"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .input-group-text svg path,
[data-headerbg="color_14"] .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_14"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_14"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_14"] .header-right .search-area .form-control,
[data-headerbg="color_14"] .header-right .search-area .input-group-text {
  background-color: #76a4f2;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_14"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_14"] .header-right .search-area .form-control i,
[data-headerbg="color_14"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_14"] .header-right .notification_dropdown .nav-link {
  background-color: #76a4f2;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg="color_14"] .header-right .notification_dropdown .nav-link svg path {
  fill: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select,
[data-headerbg="color_14"] .header-right .dlab-side-menu .search-coundry .dashboard-select {
  color: #000;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after,
[data-headerbg="color_14"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after {
  border-color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_14"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small, [data-headerbg="color_14"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small, [data-headerbg="color_14"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_14"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_14"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_14"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_14"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_15"][data-theme-version="dark"],
[data-headerbg="color_15"] {
  --headerbg: #4cb32b;
  /* &[data-headerbg="color_8"]{
					
				.search-area .input-group-text,
				.search-area .form-control,.search-area .form-control::placeholder{
					color:$black;
					background:#F1F1F1;
				}
				.header-left{
					.search-area .form-control,
					.search-area .input-group-text{
						i{
							color:$black;
						}
					}
					.dashboard_bar{
						color:$black;	
					}
					.plus-icon a{
						background-color:$black;	
					}
					
				}
				.hamburger .line{
					background:$black;
					}
				
				.header-right{
				svg{
					path{
						fill:rgba($black,0.4);	
					}
				}
			} */
}

[data-headerbg="color_15"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_15"][data-theme-version="dark"] .search-area .form-control, [data-headerbg="color_15"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_15"] .search-area .input-group-text,
[data-headerbg="color_15"] .search-area .form-control,
[data-headerbg="color_15"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .input-group-text svg path,
[data-headerbg="color_15"] .input-group-text svg path {
  fill: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_15"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_15"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_15"] .header-right .search-area .form-control,
[data-headerbg="color_15"] .header-right .search-area .input-group-text {
  background-color: #63d140;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_15"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_15"] .header-right .search-area .form-control i,
[data-headerbg="color_15"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_15"] .header-right .notification_dropdown .nav-link {
  background-color: #63d140;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link svg path,
[data-headerbg="color_15"] .header-right .notification_dropdown .nav-link svg path {
  fill: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select,
[data-headerbg="color_15"] .header-right .dlab-side-menu .search-coundry .dashboard-select {
  color: #000;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after,
[data-headerbg="color_15"] .header-right .dlab-side-menu .search-coundry .dashboard-select:after {
  border-color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_15"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small, [data-headerbg="color_15"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small, [data-headerbg="color_15"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_15"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_15"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_15"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_15"] .hamburger .line {
  background: #fff !important;
}

@media (min-width: 62rem) {
  [data-container="boxed"] #main-wrapper {
    max-width: 74.9375rem;
    margin: 0 auto;
    box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.1);
  }

  [direction="rtl"][data-container="boxed"] #main-wrapper {
    text-align: right;
  }

  [data-container="boxed"] .invoice-num {
    font-size: 1.375rem;
  }
}

@media only screen and (min-width: 84.375rem) {
  [data-layout="vertical"][data-sidebar-style="overlay"][data-container="boxed"][data-header-position="fixed"] .header {
    width: 74.9375rem;
  }
}

@media only screen and (min-width: 75rem) and (max-width: 84.3125rem) {
  [data-layout="vertical"][data-sidebar-style="overlay"][data-container="boxed"][data-header-position="fixed"] .header {
    width: 74.9375rem;
  }
}

[data-header-position="fixed"][data-layout="horizontal"][data-container="boxed"] .dlabnav {
  max-width: 74.9375rem;
}

[data-layout="vertical"][data-container="boxed"][data-header-position="fixed"] .header,
[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"] .header {
  width: 74.9375rem;
}

[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"][data-sidebar-style="mini"] .header {
  width: 74.9375rem;
}

[data-container="boxed"] .metismenu.fixed {
  left: auto;
  max-width: 74.9375rem;
}

[data-container="boxed"] .page-titles {
  margin-bottom: 3rem;
  padding: 0.9375rem 0.9375rem;
}

[data-container="boxed"] .content-body .container-fluid, [data-container="boxed"] .content-body .container-sm, [data-container="boxed"] .content-body .container-md, [data-container="boxed"] .content-body .container-lg, [data-container="boxed"] .content-body .container-xl, [data-container="boxed"] .content-body .container-xxl {
  padding: 2.5rem;
}

[data-container="boxed"][data-layout="vertical"] .page-titles {
  margin-left: 0;
  margin-right: 0;
}

[data-layout="vertical"][data-container="boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .nav-header {
  position: absolute;
}

[data-layout="vertical"][data-container="boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .menu-toggle .dlabnav {
  position: absolute;
}

[data-container="boxed"][data-sidebar-position="fixed"][data-layout="horizontal"] .dlabnav.fixed {
  left: auto;
  max-width: 74.9375rem;
}

@media (min-width: 62rem) {
  [data-container="wide-boxed"] #main-wrapper {
    max-width: 92.5rem;
    margin: 0 auto;
    box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.1);
  }

  [direction="rtl"][data-container="wide-boxed"] #main-wrapper {
    text-align: right;
  }
}

@media only screen and (min-width: 84.375rem) {
  [data-layout="vertical"][data-sidebar-style="overlay"][data-container="wide-boxed"][data-header-position="fixed"] .header {
    width: 92.5rem;
  }
}

@media only screen and (min-width: 75rem) and (max-width: 100rem) {
  [data-layout="vertical"][data-sidebar-style="overlay"][data-container="wide-boxed"][data-header-position="fixed"] .header {
    width: 92.5rem;
  }
}

[data-sidebar-style="compact"][data-header-position="fixed"][data-container="wide-boxed"][data-layout="vertical"] .header {
  width: 92.5rem;
}

[data-header-position="fixed"][data-layout="horizontal"][data-container="wide-boxed"] .dlabnav {
  max-width: 92.5rem;
}

[data-layout="vertical"][data-container="wide-boxed"][data-header-position="fixed"] .header,
[data-layout="horizontal"][data-container="wide-boxed"][data-header-position="fixed"] .header {
  width: 92.5rem;
}

[data-layout="horizontal"][data-container="wide-boxed"][data-header-position="fixed"][data-sidebar-style="mini"] .header {
  width: 92.5rem;
}

[data-container="wide-boxed"] .metismenu.fixed {
  left: auto;
  max-width: 92.5rem;
}

[data-container="wide-boxed"] .page-titles {
  margin-bottom: 3rem;
  padding: 0.9375rem 0.9375rem;
}

[data-container="wide-boxed"] .content-body .container-fluid, [data-container="wide-boxed"] .content-body .container-sm, [data-container="wide-boxed"] .content-body .container-md, [data-container="wide-boxed"] .content-body .container-lg, [data-container="wide-boxed"] .content-body .container-xl, [data-container="wide-boxed"] .content-body .container-xxl {
  padding: 2.5rem;
}

[data-container="wide-boxed"][data-layout="vertical"] .page-titles {
  margin-left: 0;
  margin-right: 0;
}

[data-layout="vertical"][data-container="wide-boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .nav-header {
  position: absolute;
}

[data-layout="vertical"][data-container="wide-boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .menu-toggle .dlabnav {
  position: absolute;
}

[data-container="wide-boxed"][data-sidebar-position="fixed"][data-layout="horizontal"] .dlabnav.fixed {
  left: auto;
  max-width: 92.5rem;
}

[data-primary="color_2"] {
  --primary: #49bb98;
  --primary-hover: #39987b;
  --primary-dark: #1d4e3f;
  --rgba-primary-1: rgba(73, 187, 152, 0.1);
  --rgba-primary-2: rgba(73, 187, 152, 0.2);
  --rgba-primary-3: rgba(73, 187, 152, 0.3);
  --rgba-primary-4: rgba(73, 187, 152, 0.4);
  --rgba-primary-5: rgba(73, 187, 152, 0.5);
  --rgba-primary-6: rgba(73, 187, 152, 0.6);
  --rgba-primary-7: rgba(73, 187, 152, 0.7);
  --rgba-primary-8: rgba(73, 187, 152, 0.8);
  --rgba-primary-9: rgba(73, 187, 152, 0.9);
}

[data-primary="color_3"] {
  --primary: #453773;
  --primary-hover: #ff3c26;
  --primary-dark: #bf1400;
  --rgba-primary-1: rgba(255, 106, 89, 0.1);
  --rgba-primary-2: rgba(255, 106, 89, 0.2);
  --rgba-primary-3: rgba(255, 106, 89, 0.3);
  --rgba-primary-4: rgba(255, 106, 89, 0.4);
  --rgba-primary-5: rgba(255, 106, 89, 0.5);
  --rgba-primary-6: rgba(255, 106, 89, 0.6);
  --rgba-primary-7: rgba(255, 106, 89, 0.7);
  --rgba-primary-8: rgba(255, 106, 89, 0.8);
  --rgba-primary-9: rgba(255, 106, 89, 0.9);
}

[data-primary="color_4"] {
  --primary: #ff965f;
  --primary-hover: #ff752c;
  --primary-dark: #c54400;
  --rgba-primary-1: rgba(255, 150, 95, 0.1);
  --rgba-primary-2: rgba(255, 150, 95, 0.2);
  --rgba-primary-3: rgba(255, 150, 95, 0.3);
  --rgba-primary-4: rgba(255, 150, 95, 0.4);
  --rgba-primary-5: rgba(255, 150, 95, 0.5);
  --rgba-primary-6: rgba(255, 150, 95, 0.6);
  --rgba-primary-7: rgba(255, 150, 95, 0.7);
  --rgba-primary-8: rgba(255, 150, 95, 0.8);
  --rgba-primary-9: rgba(255, 150, 95, 0.9);
}

[data-primary="color_5"] {
  --primary: #3250ff;
  --primary-hover: #0025fe;
  --primary-dark: #001698;
  --rgba-primary-1: rgba(50, 80, 255, 0.1);
  --rgba-primary-2: rgba(50, 80, 255, 0.2);
  --rgba-primary-3: rgba(50, 80, 255, 0.3);
  --rgba-primary-4: rgba(50, 80, 255, 0.4);
  --rgba-primary-5: rgba(50, 80, 255, 0.5);
  --rgba-primary-6: rgba(50, 80, 255, 0.6);
  --rgba-primary-7: rgba(50, 80, 255, 0.7);
  --rgba-primary-8: rgba(50, 80, 255, 0.8);
  --rgba-primary-9: rgba(50, 80, 255, 0.9);
}

[data-primary="color_6"] {
  --primary: #3f8cff;
  --primary-hover: #0c6dff;
  --primary-dark: #0042a5;
  --rgba-primary-1: rgba(63, 140, 255, 0.1);
  --rgba-primary-2: rgba(63, 140, 255, 0.2);
  --rgba-primary-3: rgba(63, 140, 255, 0.3);
  --rgba-primary-4: rgba(63, 140, 255, 0.4);
  --rgba-primary-5: rgba(63, 140, 255, 0.5);
  --rgba-primary-6: rgba(63, 140, 255, 0.6);
  --rgba-primary-7: rgba(63, 140, 255, 0.7);
  --rgba-primary-8: rgba(63, 140, 255, 0.8);
  --rgba-primary-9: rgba(63, 140, 255, 0.9);
}

[data-primary="color_7"] {
  --primary: #40a7e2;
  --primary-hover: #208fcf;
  --primary-dark: #125277;
  --rgba-primary-1: rgba(64, 167, 226, 0.1);
  --rgba-primary-2: rgba(64, 167, 226, 0.2);
  --rgba-primary-3: rgba(64, 167, 226, 0.3);
  --rgba-primary-4: rgba(64, 167, 226, 0.4);
  --rgba-primary-5: rgba(64, 167, 226, 0.5);
  --rgba-primary-6: rgba(64, 167, 226, 0.6);
  --rgba-primary-7: rgba(64, 167, 226, 0.7);
  --rgba-primary-8: rgba(64, 167, 226, 0.8);
  --rgba-primary-9: rgba(64, 167, 226, 0.9);
}

[data-primary="color_8"] {
  --primary: #b65fbd;
  --primary-hover: #9e44a5;
  --primary-dark: #59265d;
  --rgba-primary-1: rgba(182, 95, 189, 0.1);
  --rgba-primary-2: rgba(182, 95, 189, 0.2);
  --rgba-primary-3: rgba(182, 95, 189, 0.3);
  --rgba-primary-4: rgba(182, 95, 189, 0.4);
  --rgba-primary-5: rgba(182, 95, 189, 0.5);
  --rgba-primary-6: rgba(182, 95, 189, 0.6);
  --rgba-primary-7: rgba(182, 95, 189, 0.7);
  --rgba-primary-8: rgba(182, 95, 189, 0.8);
  --rgba-primary-9: rgba(182, 95, 189, 0.9);
}

[data-primary="color_9"] {
  --primary: #88a67e;
  --primary-hover: #6d8f62;
  --primary-dark: #3f5239;
  --rgba-primary-1: rgba(136, 166, 126, 0.1);
  --rgba-primary-2: rgba(136, 166, 126, 0.2);
  --rgba-primary-3: rgba(136, 166, 126, 0.3);
  --rgba-primary-4: rgba(136, 166, 126, 0.4);
  --rgba-primary-5: rgba(136, 166, 126, 0.5);
  --rgba-primary-6: rgba(136, 166, 126, 0.6);
  --rgba-primary-7: rgba(136, 166, 126, 0.7);
  --rgba-primary-8: rgba(136, 166, 126, 0.8);
  --rgba-primary-9: rgba(136, 166, 126, 0.9);
}

[data-primary="color_10"] {
  --primary: #6759d1;
  --primary-hover: #4636c1;
  --primary-dark: #291f72;
  --rgba-primary-1: rgba(103, 89, 209, 0.1);
  --rgba-primary-2: rgba(103, 89, 209, 0.2);
  --rgba-primary-3: rgba(103, 89, 209, 0.3);
  --rgba-primary-4: rgba(103, 89, 209, 0.4);
  --rgba-primary-5: rgba(103, 89, 209, 0.5);
  --rgba-primary-6: rgba(103, 89, 209, 0.6);
  --rgba-primary-7: rgba(103, 89, 209, 0.7);
  --rgba-primary-8: rgba(103, 89, 209, 0.8);
  --rgba-primary-9: rgba(103, 89, 209, 0.9);
}

[data-primary="color_11"] {
  --primary: #c6164f;
  --primary-hover: #98113d;
  --primary-dark: #3c0718;
  --rgba-primary-1: rgba(198, 22, 79, 0.1);
  --rgba-primary-2: rgba(198, 22, 79, 0.2);
  --rgba-primary-3: rgba(198, 22, 79, 0.3);
  --rgba-primary-4: rgba(198, 22, 79, 0.4);
  --rgba-primary-5: rgba(198, 22, 79, 0.5);
  --rgba-primary-6: rgba(198, 22, 79, 0.6);
  --rgba-primary-7: rgba(198, 22, 79, 0.7);
  --rgba-primary-8: rgba(198, 22, 79, 0.8);
  --rgba-primary-9: rgba(198, 22, 79, 0.9);
}

[data-primary="color_12"] {
  --primary: #343a40;
  --primary-hover: #1d2124;
  --primary-dark: black;
  --rgba-primary-1: rgba(52, 58, 64, 0.1);
  --rgba-primary-2: rgba(52, 58, 64, 0.2);
  --rgba-primary-3: rgba(52, 58, 64, 0.3);
  --rgba-primary-4: rgba(52, 58, 64, 0.4);
  --rgba-primary-5: rgba(52, 58, 64, 0.5);
  --rgba-primary-6: rgba(52, 58, 64, 0.6);
  --rgba-primary-7: rgba(52, 58, 64, 0.7);
  --rgba-primary-8: rgba(52, 58, 64, 0.8);
  --rgba-primary-9: rgba(52, 58, 64, 0.9);
}

[data-primary="color_13"] {
  --primary: #2a2a2a;
  --primary-hover: #111111;
  --primary-dark: black;
  --rgba-primary-1: rgba(42, 42, 42, 0.1);
  --rgba-primary-2: rgba(42, 42, 42, 0.2);
  --rgba-primary-3: rgba(42, 42, 42, 0.3);
  --rgba-primary-4: rgba(42, 42, 42, 0.4);
  --rgba-primary-5: rgba(42, 42, 42, 0.5);
  --rgba-primary-6: rgba(42, 42, 42, 0.6);
  --rgba-primary-7: rgba(42, 42, 42, 0.7);
  --rgba-primary-8: rgba(42, 42, 42, 0.8);
  --rgba-primary-9: rgba(42, 42, 42, 0.9);
}

[data-primary="color_14"] {
  --primary: #4885ed;
  --primary-hover: #1a66e8;
  --primary-dark: #0e3d8e;
  --rgba-primary-1: rgba(72, 133, 237, 0.1);
  --rgba-primary-2: rgba(72, 133, 237, 0.2);
  --rgba-primary-3: rgba(72, 133, 237, 0.3);
  --rgba-primary-4: rgba(72, 133, 237, 0.4);
  --rgba-primary-5: rgba(72, 133, 237, 0.5);
  --rgba-primary-6: rgba(72, 133, 237, 0.6);
  --rgba-primary-7: rgba(72, 133, 237, 0.7);
  --rgba-primary-8: rgba(72, 133, 237, 0.8);
  --rgba-primary-9: rgba(72, 133, 237, 0.9);
}

[data-primary="color_15"] {
  --primary: #4cb32b;
  --primary-hover: #3b8a21;
  --primary-dark: #18380d;
  --rgba-primary-1: rgba(76, 179, 43, 0.1);
  --rgba-primary-2: rgba(76, 179, 43, 0.2);
  --rgba-primary-3: rgba(76, 179, 43, 0.3);
  --rgba-primary-4: rgba(76, 179, 43, 0.4);
  --rgba-primary-5: rgba(76, 179, 43, 0.5);
  --rgba-primary-6: rgba(76, 179, 43, 0.6);
  --rgba-primary-7: rgba(76, 179, 43, 0.7);
  --rgba-primary-8: rgba(76, 179, 43, 0.8);
  --rgba-primary-9: rgba(76, 179, 43, 0.9);
}

[data-secondary="color_2"] {
  --secondary: #49bb98;
  --secondary-dark: #39987b;
}

[data-secondary="color_3"] {
  --secondary: #453773;
  --secondary-dark: #ff3c26;
}

[data-secondary="color_4"] {
  --secondary: #ff965f;
  --secondary-dark: #ff752c;
}

[data-secondary="color_5"] {
  --secondary: #3250ff;
  --secondary-dark: #0025fe;
}

[data-secondary="color_6"] {
  --secondary: #3f8cff;
  --secondary-dark: #0c6dff;
}

[data-secondary="color_7"] {
  --secondary: #40a7e2;
  --secondary-dark: #208fcf;
}

[data-secondary="color_8"] {
  --secondary: #b65fbd;
  --secondary-dark: #9e44a5;
}

[data-secondary="color_9"] {
  --secondary: #88a67e;
  --secondary-dark: #6d8f62;
}

[data-secondary="color_10"] {
  --secondary: #6759d1;
  --secondary-dark: #4636c1;
}

[data-secondary="color_11"] {
  --secondary: #c6164f;
  --secondary-dark: #98113d;
}

[data-secondary="color_12"] {
  --secondary: #343a40;
  --secondary-dark: #1d2124;
}

[data-secondary="color_13"] {
  --secondary: #2a2a2a;
  --secondary-dark: #111111;
}

[data-secondary="color_14"] {
  --secondary: #4885ed;
  --secondary-dark: #1a66e8;
}

[data-secondary="color_15"] {
  --secondary: #4cb32b;
  --secondary-dark: #3b8a21;
}

[data-typography="opensans"] {
  font-family: 'Open Sans', sans-serif;
}

[data-typography="poppins"] {
  font-family: 'poppins', sans-serif;
}

[data-typography="cairo"] {
  font-family: 'Cairo', sans-serif;
}

[data-typography="roboto"] {
  font-family: 'Roboto', sans-serif;
}

[data-typography="helvetica"] {
  font-family: 'HelveticaNeue';
}

:root {
  --primary: #453773;
  --secondary: #4CBC9A;
  --secondary-dark: #3a9b7e;
  --primary-hover: #ff3c26;
  --primary-dark: #bf1400;
  --rgba-primary-1: rgba(255, 106, 89, 0.1);
  --rgba-primary-2: rgba(255, 106, 89, 0.2);
  --rgba-primary-3: rgba(255, 106, 89, 0.3);
  --rgba-primary-4: rgba(255, 106, 89, 0.4);
  --rgba-primary-5: rgba(255, 106, 89, 0.5);
  --rgba-primary-6: rgba(255, 106, 89, 0.6);
  --rgba-primary-7: rgba(255, 106, 89, 0.7);
  --rgba-primary-8: rgba(255, 106, 89, 0.8);
  --rgba-primary-9: rgba(255, 106, 89, 0.9);
  --rgba-secondary-1: rgba(76, 188, 154, 0.1);
  --rgba-secondary-2: rgba(76, 188, 154, 0.2);
  --rgba-secondary-3: rgba(76, 188, 154, 0.3);
  --rgba-secondary-4: rgba(76, 188, 154, 0.4);
  --rgba-secondary-5: rgba(76, 188, 154, 0.5);
  --rgba-secondary-6: rgba(76, 188, 154, 0.6);
  --rgba-secondary-7: rgba(76, 188, 154, 0.7);
  --rgba-secondary-8: rgba(76, 188, 154, 0.8);
  --rgba-secondary-9: rgba(76, 188, 154, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #374557;
}

[data-theme-version="transparent"] {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  position: relative;
  color: #fff;
}

[data-theme-version="transparent"] h1, [data-theme-version="transparent"] .h1,
[data-theme-version="transparent"] h2,
[data-theme-version="transparent"] .h2,
[data-theme-version="transparent"] h3,
[data-theme-version="transparent"] .h3,
[data-theme-version="transparent"] h4,
[data-theme-version="transparent"] .h4,
[data-theme-version="transparent"] h5,
[data-theme-version="transparent"] .h5,
[data-theme-version="transparent"] h6,
[data-theme-version="transparent"] .h6 {
  color: #fff !important;
}

[data-theme-version="transparent"] a.link {
  color: #ddd;
}

[data-theme-version="transparent"] a.link:focus,
[data-theme-version="transparent"] a.link:hover {
  color: #58bad7;
}

[data-theme-version="transparent"] a {
  color: #fff;
}

[data-theme-version="transparent"] a:hover {
  color: #828690;
}

[data-theme-version="transparent"] .border-right {
  border-right: 1px solid #333754 !important;
}

[data-theme-version="transparent"] .border-left {
  border-left: 1px solid #333754 !important;
}

[data-theme-version="transparent"] .border-top {
  border-top: 1px solid #333754 !important;
}

[data-theme-version="transparent"] .border-bottom {
  border-bottom: 1px solid #333754 !important;
}

[data-theme-version="transparent"] .border {
  border: 1px solid #333754 !important;
}

[data-theme-version="transparent"] .dropdown-menu {
  background-color: #292d4a;
}

[data-theme-version="transparent"] .dropdown-item:hover, [data-theme-version="transparent"] .dropdown-item:focus {
  background-color: #20203c;
  color: #fff;
}

[data-theme-version="transparent"] .form-control {
  background-color: #20203c;
  border-color: #333754;
  color: #fff;
}

[data-theme-version="transparent"] .card {
  background-color: rgba(0, 0, 0, 0.15);
}

[data-theme-version="transparent"] .header {
  background-color: rgba(0, 0, 0, 0.15);
}

[data-theme-version="transparent"] .header-left input {
  border-color: #333754;
  color: #fff;
}

[data-theme-version="transparent"] .header-left input:focus {
  box-shadow: none;
  border-color: #453773;
}

[data-theme-version="transparent"] .header-left input::placeholder {
  color: #fff;
}

[data-theme-version="transparent"] .header-right .dropdown .nav-link:hover {
  color: #fff;
}

[data-theme-version="transparent"] .header-right .notification_dropdown .dropdown-item a {
  color: #fff;
}

[data-theme-version="transparent"] .nav-header,
[data-theme-version="transparent"] .nav-control {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

[data-theme-version="transparent"] .nav-control {
  color: #fff;
}

[data-theme-version="transparent"] .brand-logo {
  color: #fff;
}

[data-theme-version="transparent"] .brand-logo:hover {
  color: #fff;
}

[data-theme-version="transparent"] .nav-header,
[data-theme-version="transparent"] .nav-control {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

[data-theme-version="transparent"] .nav-control {
  color: #fff;
}

[data-theme-version="transparent"] .brand-logo {
  color: #fff;
}

[data-theme-version="transparent"] .brand-logo:hover {
  color: #fff;
}

[data-theme-version="transparent"] .dlabnav {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

[data-theme-version="transparent"] .dlabnav .metismenu>li>a {
  color: rgba(0, 0, 0, 0.15);
}

[data-theme-version="transparent"] .dlabnav .metismenu>li:hover>a, [data-theme-version="transparent"] .dlabnav .metismenu>li:focus>a, [data-theme-version="transparent"] .dlabnav .metismenu>li.mm-active>a {
  background-color: rgba(0, 0, 0, 0.15) !important;
  color: #fff;
}

[data-theme-version="transparent"] .dlabnav .metismenu>li:hover>a::after, [data-theme-version="transparent"] .dlabnav .metismenu>li:focus>a::after, [data-theme-version="transparent"] .dlabnav .metismenu>li.mm-active>a::after {
  border-color: transparent transparent #fff transparent;
}

[data-theme-version="transparent"] .dlabnav .metismenu>li.mm-active ul ul {
  background-color: transparent;
}

[data-theme-version="transparent"] .dlabnav .metismenu ul {
  background-color: rgba(0, 0, 0, 0.15);
}

[data-theme-version="transparent"] .dlabnav .metismenu ul a:hover, [data-theme-version="transparent"] .dlabnav .metismenu ul a:focus, [data-theme-version="transparent"] .dlabnav .metismenu ul a.mm-active {
  color: #fff;
}

[data-theme-version="transparent"] .dlabnav .metismenu a {
  color: rgba(0, 0, 0, 0.15);
}

[data-theme-version="transparent"] .dlabnav .metismenu ul {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

[data-theme-version="transparent"] .dlabnav .metismenu .has-arrow:after {
  border-color: transparent transparent rgba(0, 0, 0, 0.15) transparent;
}

:root {
  --primary: #453773;
  --secondary: #4CBC9A;
  --secondary-dark: #3a9b7e;
  --primary-hover: #ff3c26;
  --primary-dark: #bf1400;
  --rgba-primary-1: rgba(255, 106, 89, 0.1);
  --rgba-primary-2: rgba(255, 106, 89, 0.2);
  --rgba-primary-3: rgba(255, 106, 89, 0.3);
  --rgba-primary-4: rgba(255, 106, 89, 0.4);
  --rgba-primary-5: rgba(255, 106, 89, 0.5);
  --rgba-primary-6: rgba(255, 106, 89, 0.6);
  --rgba-primary-7: rgba(255, 106, 89, 0.7);
  --rgba-primary-8: rgba(255, 106, 89, 0.8);
  --rgba-primary-9: rgba(255, 106, 89, 0.9);
  --rgba-secondary-1: rgba(76, 188, 154, 0.1);
  --rgba-secondary-2: rgba(76, 188, 154, 0.2);
  --rgba-secondary-3: rgba(76, 188, 154, 0.3);
  --rgba-secondary-4: rgba(76, 188, 154, 0.4);
  --rgba-secondary-5: rgba(76, 188, 154, 0.5);
  --rgba-secondary-6: rgba(76, 188, 154, 0.6);
  --rgba-secondary-7: rgba(76, 188, 154, 0.7);
  --rgba-secondary-8: rgba(76, 188, 154, 0.8);
  --rgba-secondary-9: rgba(76, 188, 154, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #374557;
}

[data-theme-version="dark"] {
  background: #20203c;
  color: #828690;
  --nav-headbg: #292d4a;
  --sidebar-bg: #292d4a;
  --headerbg: #292d4a;
}

[data-theme-version="dark"] h1, [data-theme-version="dark"] .h1,
[data-theme-version="dark"] h2,
[data-theme-version="dark"] .h2,
[data-theme-version="dark"] h3,
[data-theme-version="dark"] .h3,
[data-theme-version="dark"] h4,
[data-theme-version="dark"] .h4,
[data-theme-version="dark"] h5,
[data-theme-version="dark"] .h5,
[data-theme-version="dark"] h6,
[data-theme-version="dark"] .h6 {
  color: #fff !important;
}

[data-theme-version="dark"] a.link {
  color: #ddd;
}

[data-theme-version="dark"] a.link:focus,
[data-theme-version="dark"] a.link:hover {
  color: #58bad7;
}

[data-theme-version="dark"] a:hover {
  color: #fff;
}

[data-theme-version="dark"] .border-right {
  border-right: 0.0625rem solid #333754 !important;
}

[data-theme-version="dark"] .border-left {
  border-left: 0.0625rem solid #333754 !important;
}

[data-theme-version="dark"] .border-top {
  border-top: 0.0625rem solid #333754 !important;
}

[data-theme-version="dark"] .border-bottom {
  border-bottom: 0.0625rem solid #333754 !important;
}

[data-theme-version="dark"] .border {
  border: 0.0625rem solid #333754 !important;
}

[data-theme-version="dark"] .card {
  background-color: #292d4a;
  box-shadow: none;
}

[data-theme-version="dark"] .dropdown-menu {
  background-color: #292d4a;
  box-shadow: 0rem 0rem 0rem 0.0625rem rgba(255, 255, 255, 0.1);
}

[data-theme-version="dark"] .dropdown-menu .dropdown-item {
  color: #777777;
}

[data-theme-version="dark"] .dropdown-menu .dropdown-item.selected, [data-theme-version="dark"] .dropdown-menu .dropdown-item.selected.active, [data-theme-version="dark"] .dropdown-menu .dropdown-item.active, [data-theme-version="dark"] .dropdown-menu .dropdown-item:hover, [data-theme-version="dark"] .dropdown-menu .dropdown-item:focus {
  background-color: #292d4a;
  color: #fff;
}

[data-theme-version="dark"] a {
  color: #fff !important;
}

[data-theme-version="dark"] .btn-link g [fill] {
  fill: #fff;
}

[data-theme-version="dark"] .btn-light:active,
[data-theme-version="dark"] .btn-light:focus,
[data-theme-version="dark"] .btn-light:hover {
  color: #000;
}

[data-theme-version="dark"] .form-control {
  background-color: #2E2E40;
  border-color: #333754;
  color: #fff;
}

[data-theme-version="dark"] .modal-content {
  background: #292d4a;
}

[data-theme-version="dark"] .modal-footer,
[data-theme-version="dark"] .modal-header {
  border-color: #333754;
}

[data-theme-version="dark"] .close {
  font-weight: 400;
  color: #fff;
  text-shadow: none;
}

[data-theme-version="dark"] .close:hover {
  color: #fff;
}

[data-theme-version="dark"] .star-rating .product-review,
[data-theme-version="dark"] .new-arrival-content .item {
  color: #fff;
}

[data-theme-version="dark"] .custom-dropdown .dropdown-menu {
  border-color: #333754;
}

[data-theme-version="dark"] .widget-stat .media>span {
  background: #333754;
  border-color: #333754;
  color: #fff;
}

[data-theme-version="dark"] .plus-minus-input .custom-btn {
  background: #20203c;
  border-color: #333754;
}

[data-theme-version="dark"] .dropdown-divider,
[data-theme-version="dark"] .size-filter ul li {
  border-color: #333754;
}

[data-theme-version="dark"] .custom-select {
  border-color: #333754;
  color: #828690;
  background: #20203c;
}

[data-theme-version="dark"] .nav-tabs {
  border-color: #333754 !important;
}

[data-theme-version="dark"] .mail-list .list-group-item.active i {
  color: #fff;
}

[data-theme-version="dark"] hr {
  border-color: #333754;
}

[data-theme-version="dark"] .grid-col {
  background: #20203c;
}

[data-theme-version="dark"] .noUi-target {
  border-color: #333754;
  border-radius: 0.5rem;
  box-shadow: none;
}

[data-theme-version="dark"] .noUi-target .noUi-connects {
  background: #333754;
}

[data-theme-version="dark"] .noUi-marker-large,
[data-theme-version="dark"] .noUi-marker {
  background: #333754;
}

[data-theme-version="dark"] .input-group-text {
  background: #292d4a;
  color: #fff;
  border-color: #333754;
}

[data-theme-version="dark"] .note-editor.note-frame {
  border-color: #333754;
}

[data-theme-version="dark"] .note-editor.note-frame .btn {
  color: #fff;
}

[data-theme-version="dark"] .note-editor.note-frame .note-editing-area .note-editable {
  color: #fff;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel {
  border-color: #333754;
}

[data-theme-version="dark"] .notification_dropdown .dropdown-menu-right .all-notification {
  border-color: #333754;
}

[data-theme-version="dark"] #user-activity .nav-tabs .nav-link {
  border-color: #333754;
}

[data-theme-version="dark"] #user-activity .nav-tabs .nav-link.active {
  background: #20203c;
  color: #fff;
}

[data-theme-version="dark"] .list-group-item-action {
  color: #777777;
}

[data-theme-version="dark"] .list-group-item-action:focus,
[data-theme-version="dark"] .list-group-item-action:hover,
[data-theme-version="dark"] .list-group-item-action:focus {
  background-color: #20203c;
  border-color: #20203c;
}

[data-theme-version="dark"] .list-group-item.active {
  color: #fff;
  border-color: var(--primary);
}

[data-theme-version="dark"] .list-group-item.active:focus, [data-theme-version="dark"] .list-group-item.active:hover, [data-theme-version="dark"] .list-group-item.active:focus {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

[data-theme-version="dark"] .swal2-popup {
  background: #292d4a;
}

[data-theme-version="dark"] .form-head .btn-outline-primary {
  border-color: #333754;
}

[data-theme-version="dark"] .form-head .btn-outline-primary:hover {
  border-color: var(--primary);
}

[data-theme-version="dark"] .review-tab.nav-pills li a.nav-link.active {
  background: transparent;
}

[data-theme-version="dark"] .new-arrival-content h4 a, [data-theme-version="dark"] .new-arrival-content .h4 a {
  color: #fff;
}

[data-theme-version="dark"] .text-black {
  color: #fff !important;
}

[data-theme-version="dark"] .abilities-chart .ct-chart .ct-label {
  fill: #fff;
}

[data-theme-version="dark"] .morris_chart_height text tspan {
  fill: #fff;
}

[data-theme-version="dark"] .btn-link {
  color: #fff;
}

[data-theme-version="dark"] .order-bg {
  background: #20203c;
}

[data-theme-version="dark"] .detault-daterange {
  background: #20203c;
  color: #fff;
}

[data-theme-version="dark"] .detault-daterange .input-group-text {
  background: #292d4a;
  border: 0;
}

[data-theme-version="dark"] .dataTablesCard {
  background-color: #292d4a;
}

[data-theme-version="dark"] .compose-content .dropzone {
  background: #20203c !important;
}

[data-theme-version="dark"] .compose-content .dropzone .dlab-message .dlab-button {
  color: #fff;
}

[data-theme-version="dark"] .daterangepicker {
  background: #20203c;
  border-color: var(--primary);
}

[data-theme-version="dark"] .daterangepicker .calendar-table {
  border-color: var(--primary);
  background: #20203c;
}

[data-theme-version="dark"] .daterangepicker .calendar-table .table-condensed td:hover {
  background-color: var(--primary);
  color: #fff;
}

[data-theme-version="dark"] .daterangepicker:after {
  border-bottom: 0.375rem solid #20203c;
}

[data-theme-version="dark"] .daterangepicker select.hourselect, [data-theme-version="dark"] .daterangepicker select.minuteselect, [data-theme-version="dark"] .daterangepicker select.secondselect, [data-theme-version="dark"] .daterangepicker select.ampmselect {
  background: #20203c;
  border: 0.0625rem solid #333754;
  color: #fff;
}

[data-theme-version="dark"] .daterangepicker td.off,
[data-theme-version="dark"] .daterangepicker td.off.in-range,
[data-theme-version="dark"] .daterangepicker td.off.start-date,
[data-theme-version="dark"] .daterangepicker td.off.end-date {
  background-color: #292d4a;
}

[data-theme-version="dark"] .daterangepicker td.off:hover,
[data-theme-version="dark"] .daterangepicker td.off.in-range:hover,
[data-theme-version="dark"] .daterangepicker td.off.start-date:hover,
[data-theme-version="dark"] .daterangepicker td.off.end-date:hover {
  background-color: var(--primary);
  color: #fff;
}

[data-theme-version="dark"] .app-fullcalendar .fc-button {
  background-color: #20203c;
  border-color: var(--primary);
  color: #fff;
  text-shadow: none;
}

[data-theme-version="dark"] .app-fullcalendar .fc-button:hover, [data-theme-version="dark"] .app-fullcalendar .fc-button.fc-stat-hover {
  background-color: var(--primary);
}

[data-theme-version="dark"] .swal2-popup .swal2-styled:focus {
  outline: 0;
  box-shadow: 0 0 0 0.125rem #333754, 0 0 0 0.25rem var(--rgba-primary-1);
}

[data-theme-version="dark"] .dd-handle {
  border-color: #333754;
}

[data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #292d4a !important;
}

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link {
  border-color: #333754;
}

[data-theme-version="dark"] .nav-tabs .nav-link:hover, [data-theme-version="dark"] .nav-tabs .nav-link.active {
  border-color: var(--primary);
  background: transparent;
  color: white !important;
}

[data-theme-version="dark"] .clockpicker-popover .popover-content {
  background-color: #292d4a;
}

[data-theme-version="dark"] .clockpicker-plate {
  background-color: #20203c;
}

[data-theme-version="dark"] .clockpicker-popover .popover-title {
  background-color: #20203c;
  color: #fff;
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link span {
  background-color: #20203c;
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link:after {
  background: #20203c;
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.active:after, [data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.done:after {
  background: var(--primary);
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.active span, [data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.done span {
  background: var(--primary);
}

[data-theme-version="dark"] .check-switch .custom-control-label:after, [data-theme-version="dark"] .check-switch .custom-control-label:before {
  border-color: var(--primary);
}

[data-theme-version="dark"] .fc-unthemed .fc-today {
  background: #20203c;
}

[data-theme-version="dark"] .fc-unthemed .fc-divider, [data-theme-version="dark"] .fc-unthemed .fc-list-heading td, [data-theme-version="dark"] .fc-unthemed .fc-popover .fc-header {
  background: #333754;
}

[data-theme-version="dark"] .picker__box {
  background: #20203c;
}

[data-theme-version="dark"] .picker__box .picker__button--clear, [data-theme-version="dark"] .picker__box .picker__button--close, [data-theme-version="dark"] .picker__box .picker__button--today {
  background: #292d4a;
  color: #fff;
}

[data-theme-version="dark"] .picker__box .picker__button--clear:hover:before, [data-theme-version="dark"] .picker__box .picker__button--close:hover:before, [data-theme-version="dark"] .picker__box .picker__button--today:hover:before {
  color: #fff;
}

[data-theme-version="dark"] .picker {
  color: #999;
}

[data-theme-version="dark"] .dtp>.dtp-content {
  background: #20203c;
}

[data-theme-version="dark"] .dtp table.dtp-picker-days tr>td>a {
  color: #68686a;
}

[data-theme-version="dark"] .dtp table.dtp-picker-days tr>td>a.selected {
  color: #fff;
}

[data-theme-version="dark"] .order-request tbody tr {
  border-color: #333754;
}

[data-theme-version="dark"] .card-list li {
  color: #fff;
}

[data-theme-version="dark"] .card-bx .change-btn:hover {
  color: var(--primary);
}

[data-theme-version="dark"] .invoice-card.bg-warning {
  background-color: #5b3c1f !important;
}

[data-theme-version="dark"] .invoice-card.bg-success {
  background-color: #2a6729 !important;
}

[data-theme-version="dark"] .invoice-card.bg-info {
  background-color: #4c276a !important;
}

[data-theme-version="dark"] .invoice-card.bg-secondary {
  background-color: #1c3e52 !important;
}

[data-theme-version="dark"] .user-list li {
  border-color: #292d4a;
}

[data-theme-version="dark"] .toggle-switch {
  color: #fff;
}

[data-theme-version="dark"] .bar-chart .apexcharts-text tspan {
  fill: #969ba0;
}

[data-theme-version="dark"] .bar-chart line {
  stroke: #333754;
}

[data-theme-version="dark"] .coin-card {
  background: #0f6a62;
  background: -moz-linear-gradient(left, #0f6a62 0%, #084355 100%);
  background: -webkit-linear-gradient(left, #0f6a62 0%, #084355 100%);
  background: linear-gradient(to right, #0f6a62 0%, #084355 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0f6a62', endColorstr='#084355', GradientType=1);
}

[data-theme-version="dark"] .coin-card .coin-icon {
  background: rgba(0, 0, 0, 0.2);
}

[data-theme-version="dark"] .accordion.style-1 .accordion-item, [data-theme-version="dark"] .invoice-list {
  border-color: #333754;
}

[data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed .user-info a, [data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed .user-info, [data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed>span {
  color: #fff;
}

[data-theme-version="dark"] .ic-card>a {
  background: #25479f;
}

[data-theme-version="dark"] .ic-card>a:first-child {
  border-color: #25479f;
}

[data-theme-version="dark"] .ic-card span {
  color: #fff;
}

[data-theme-version="dark"] table.dataTable thead th, [data-theme-version="dark"] table.dataTable thead td {
  border-color: #333754 !important;
}

[data-theme-version="dark"] .form-check .form-check-input {
  background: transparent;
}

[data-theme-version="dark"] .form-check .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

[data-theme-version="dark"] .paging_simple_numbers.dataTables_paginate {
  background: #20203c;
}

[data-theme-version="dark"] .dataTables_info {
  color: #fff;
}

[data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate span .paginate_button.current,
[data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate span .paginate_button:hover {
  background: var(--primary);
  color: #fff !important;
}

[data-theme-version="dark"] .dashboard-select {
  background: var(--rgba-primary-1);
  color: white;
  border-color: #333754;
}

[data-theme-version="dark"] .dashboard-select .list {
  background: #292d4a;
}

[data-theme-version="dark"] .dashboard-select .option:hover, [data-theme-version="dark"] .dashboard-select .option.focus, [data-theme-version="dark"] .dashboard-select .option.selected.focus {
  background: #20203c;
}

[data-theme-version="dark"] .card-tabs.style-1 .nav-tabs {
  background: #292d4a;
}

[data-theme-version="dark"] .transaction-details {
  border-color: #333754;
}

[data-theme-version="dark"] .description {
  color: #fff;
}

[data-theme-version="dark"] .transaction-details .amount-bx {
  background: #3f250d;
}

[data-theme-version="dark"] .transaction-details .amount-bx i {
  background: #8d3b0c;
}

[data-theme-version="dark"] .nice-select .option {
  background: #292d4a;
}

[data-theme-version="dark"] .nice-select .option:hover, [data-theme-version="dark"] .nice-select .option.focus, [data-theme-version="dark"] .nice-select .option.selected.focus {
  background: #292d4a;
}

[data-theme-version="dark"] .card-tabs.style-1 {
  border-color: #333754;
}

[data-theme-version="dark"] .nice-select .list {
  background: #20203c;
}

[data-theme-version="dark"] .table-search .input-group .input-group-text {
  background: #292d4a;
}

[data-theme-version="dark"] .dark-btn svg path {
  stroke: #fff;
}

[data-theme-version="dark"] .bar-chart .apexcharts-legend-text {
  color: #fff !important;
}

[data-theme-version="dark"] .Sales tspan {
  fill: #fff;
}

[data-theme-version="dark"] .timeline-chart rect {
  fill: #20203c;
}

[data-theme-version="dark"] .header-info span {
  color: #fff !important;
}

[data-theme-version="dark"] .smallipop-instance.blue {
  color: #fff;
}

[data-theme-version="dark"] .property-map .smallipop.smallimap-mapicon .circle-marker {
  border-color: #333754;
}

[data-theme-version="dark"] .property-features li {
  color: #fff;
}

[data-theme-version="dark"] .review-tabs-1 {
  background: #292d4a;
}

[data-theme-version="dark"] .coin-tabs {
  background: #2E2E40;
}

[data-theme-version="dark"] .coin-tabs .nav-link.active {
  background: var(--primary) !important;
  color: #fff;
}

[data-theme-version="dark"] .separate-row>div {
  border-color: #333754;
}

[data-theme-version="dark"] .bg-white {
  background: #2E2E40 !important;
}

[data-theme-version="dark"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-theme-version="dark"] .brand-title path {
  fill: #fff;
}

[data-theme-version="dark"] .brand-title tspan {
  fill: #fff;
}

[data-theme-version="dark"] .shapreter-row>div {
  border-color: #333754;
}

[data-theme-version="dark"] .chartBar line {
  stroke: #333754;
}

[data-theme-version="dark"] .search-job .search-dropdown:after {
  background: #333754;
}

[data-theme-version="dark"] .chartjs line {
  stroke: #333754;
}

[data-theme-version="dark"] .form-label {
  color: #fff;
}

[data-theme-version="dark"] .datepicker-switch {
  color: #fff;
}

[data-theme-version="dark"] .datepicker.datepicker-dropdown td.day, [data-theme-version="dark"] .datepicker.datepicker-dropdown th.next, [data-theme-version="dark"] .datepicker.datepicker-dropdown th.prev {
  color: #fff;
}

[data-theme-version="dark"] .datepicker.datepicker-dropdown th.dow {
  color: #fff;
}

[data-theme-version="dark"] .datepicker.datepicker-dropdown th.datepicker-switch, [data-theme-version="dark"] .datepicker.datepicker-dropdown th.next, [data-theme-version="dark"] .datepicker.datepicker-dropdown th.prev {
  color: #fff;
}

[data-theme-version="dark"] .btn-close {
  color: #fff !important;
}

[data-theme-version="dark"] .bgl-secondary {
  color: #fff;
}

[data-theme-version="dark"] .application tr td .btn {
  color: #fff;
}

[data-theme-version="dark"] .btn-link svg path {
  stroke: #fff;
}

[data-theme-version="dark"] .alert.alert-outline-secondary,
[data-theme-version="dark"] .alert.alert-outline-dark {
  color: #fff;
}

[data-theme-version="dark"] .form-control-plaintext {
  color: #fff;
}

[data-theme-version="dark"] .timeline-panel .media-body small, [data-theme-version="dark"] .timeline-panel .media-body .small {
  color: #fff;
}

[data-theme-version="dark"] .jobs {
  border-color: #333754;
}

[data-theme-version="dark"] .apexcharts-gridlines-vertical line {
  stroke: #333754;
}

[data-theme-version="dark"] .apexcharts-text tspan {
  fill: #fff;
}

[data-theme-version="dark"] .widget-courses .schedule-icon {
  color: #fff;
}

[data-theme-version="dark"] .card-schedule .up-comming-schedule,
[data-theme-version="dark"] .upcomming-task-widget {
  background: #292d4a;
}

[data-theme-version="dark"] .card-schedule .up-comming-schedule.style-1 {
  background: #20203c;
}

[data-theme-version="dark"] .card-schedule .up-comming-schedule.style-1 .date-box {
  background: #292d4a;
  color: #fff;
}

[data-theme-version="dark"] .instructors-box .instructors-media .custome-badge .badge {
  color: #fff;
}

[data-theme-version="dark"] .instructors-box .instructors-media .info-box {
  background: #20203c;
}

[data-theme-version="dark"] .course-details-tab .nav-tabs .nav-link {
  color: #cbc3c3;
}

[data-theme-version="dark"] .message-box,
[data-theme-version="dark"] .chat-icon,
[data-theme-version="dark"] .chat-people,
[data-theme-version="dark"] .chat-tabs .chat-bx,
[data-theme-version="dark"] .share-files,
[data-theme-version="dark"] .type-massage,
[data-theme-version="dark"] .work-ic {
  border-color: #333754;
}

[data-theme-version="dark"] .timeline-active .modulel {
  background: #292d4a;
}

[data-theme-version="dark"] .timeline-active li .time {
  background: #292d4a;
}

[data-theme-version="dark"] .achievements .achievements-content span,
[data-theme-version="dark"] .bio .bio-content {
  background: #20203c;
}

[data-theme-version="dark"] .score-active.style-1 .dashboard-select {
  background: #20203c;
}

[data-theme-version="dark"] #activity line {
  stroke: #333754;
}

[data-theme-version="dark"] .pagination-down ul li a {
  background: #20203c;
}

[data-theme-version="dark"] .pagination-down ul li a.active {
  background: var(--primary);
}

[data-theme-version="dark"] .custome-accordion .accordion-button {
  background: #292d4a;
}

[data-theme-version="dark"] .custome-accordion .acc-courses {
  background: #20203c;
}

[data-theme-version="dark"] .widget-timeline .side-border h4, [data-theme-version="dark"] .widget-timeline .side-border .h4, [data-theme-version="dark"] .widget-timeline .side-border .h4,
[data-theme-version="dark"] .widget-timeline .side-border .custom-dropdown {
  background: #292d4a;
}

[data-theme-version="dark"] .widget-timeline .side-border:after {
  border-color: #333754;
}

[data-theme-version="dark"] #columnChart line {
  stroke: #333754;
}

[data-theme-version="dark"] #sellingActivity svg path {
  stroke: #292d4a;
}

[data-theme-version="dark"] #sellingActivity line {
  stroke: #333754;
}

[data-theme-version="dark"] .review-box {
  background: #292d4a;
}

[data-theme-version="dark"] #NewCustomers line {
  stroke: #fff;
}

[data-theme-version="dark"] .students-list .search-area .form-control,
[data-theme-version="dark"] .students-list .search-area .input-group-text {
  background: #20203c;
  border: #333754;
}

[data-theme-version="dark"] .custome-accordion.style-1 .acc-courses {
  background: #292d4a;
  border-color: #333754;
}

[data-theme-version="dark"] .miac,
[data-theme-version="dark"] .profile-img img, [data-theme-version="dark"] .profile-img span,
[data-theme-version="dark"] .compose-content .dropzone,
[data-theme-version="dark"] .fc-theme-standard td, [data-theme-version="dark"] .fc-theme-standard th,
[data-theme-version="dark"] .fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid, [data-theme-version="dark"] .fc-scrollgrid, [data-theme-version="dark"] table {
  border-color: #333754;
}

[data-theme-version="dark"] .btn-icon-end {
  border-color: #333754;
}

[data-theme-version="dark"] .raiting li {
  border-color: #333754;
}

[data-theme-version="dark"] .miac svg path {
  fill: #fff;
}

[data-theme-version="dark"] .type-massage.style-1 {
  background: #20203c;
}

[data-theme-version="dark"] .header .header-content:after {
  background: #333754;
}

[data-theme-version="dark"] .search-coundry .dashboard-select {
  color: #fff;
  background: transparent;
}

[data-theme-version="dark"] .search-coundry .dashboard-select .option {
  color: #fff;
}

[data-theme-version="dark"] .search-coundry .dashboard-select:after {
  border-bottom: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
}

[data-theme-version="dark"] .sidebar-right .nice-select .option.selected {
  color: #000;
}

[data-theme-version="dark"] #redial .apexcharts-datalabels-group text {
  fill: #fff;
}

[data-theme-version="dark"] .notification_dropdown .dropdown-menu-end .all-notification {
  border-color: #333754;
}

[data-theme-version="dark"] .app-fullcalendar1 .fc .fc-button-group>.fc-button {
  border-color: #333754;
}

[data-theme-version="dark"] .app-fullcalendar1 .fc-direction-ltr .fc-daygrid-event.fc-event-end, [data-theme-version="dark"] .app-fullcalendar1 .fc-direction-rtl .fc-daygrid-event.fc-event-start {
  background: #292d4a !important;
  border-color: #333754;
}

[data-theme-version="dark"] .card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed td.day {
  color: #ffffff !important;
}

[data-theme-version="dark"] .dlab-side-menu {
  background: transparent;
}

[data-theme-version="dark"] .nice-select .option.selected {
  color: #fff;
}

[data-theme-version="dark"] .dlab-cource,
[data-theme-version="dark"] .message-box,
[data-theme-version="dark"] .type-massage {
  background-color: #292d4a;
}

[data-theme-version="dark"] .dlab-cource .form-control,
[data-theme-version="dark"] .message-box .form-control,
[data-theme-version="dark"] .type-massage .form-control {
  background-color: transparent;
}

[data-theme-version="dark"] .card-calendar.style-1 .bootstrap-datetimepicker-widget table .picker-switch {
  color: #fff;
}

[data-theme-version="dark"] .fc .fc-button-group>.fc-button {
  border-color: #333754;
}

[data-theme-version="dark"] .fc-daygrid-dot-event {
  background-color: #20203c;
  border-color: #20203c;
}

[data-theme-version="dark"] #redial .apexcharts-radialbar-hollow {
  fill: #292d4a;
}

[data-theme-version="dark"] .chat-tabs .chat-bx.active:after {
  background-color: #20203c;
}

[data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.day:hover, [data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.hour:hover, [data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.minute:hover, [data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.second:hover {
  background: #20203c;
}

[data-theme-version="dark"] .chat-box-area .message-received p {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

[data-theme-version="dark"] .chat-icon ul li a,
[data-theme-version="dark"] .file {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-theme-version="dark"] .chat-icon ul li a svg path,
[data-theme-version="dark"] .file svg path {
  fill: #fff;
}

[data-theme-version="dark"] .course-dedails-bx .description .user-pic span,
[data-theme-version="dark"] .course-learn ul li {
  color: #fff;
}

[data-theme-version="dark"] .course-details-tab .user-pic2 p {
  color: #828690;
}

[data-theme-version="dark"] #columnChart .apexcharts-bar-series path {
  stroke: #292d4a;
}

[data-theme-version="dark"] .btn-close {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'%3E%3C!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3E%3Cpath d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z' fill='%23fff'/%3E%3C/svg%3E");
}

[data-theme-version="dark"] .select-dropdown .selectBtn {
  color: #fff !important;
}

[data-theme-version="dark"] .process-calendar .react-calendar,
[data-theme-version="dark"] .instructor-calendar .react-calendar {
  background: transparent;
}

[data-theme-version="dark"] .process-calendar .react-calendar .react-calendar__month-view__weekdays,
[data-theme-version="dark"] .process-calendar .react-calendar .react-calendar__month-view__days__day,
[data-theme-version="dark"] .process-calendar .react-calendar .react-calendar__navigation__arrow,
[data-theme-version="dark"] .process-calendar .react-calendar .react-calendar__navigation__label__labelText--from,
[data-theme-version="dark"] .instructor-calendar .react-calendar .react-calendar__month-view__weekdays,
[data-theme-version="dark"] .instructor-calendar .react-calendar .react-calendar__month-view__days__day,
[data-theme-version="dark"] .instructor-calendar .react-calendar .react-calendar__navigation__arrow,
[data-theme-version="dark"] .instructor-calendar .react-calendar .react-calendar__navigation__label__labelText--from {
  color: #fff;
}

[data-theme-version="dark"] .timeline-active .modulel .sub-module {
  background: #20203c;
}

[data-theme-version="dark"] .activity-chart line {
  stroke: #333754;
}

[data-theme-version="dark"] .custome-accordion .accordion-header .acc-heading {
  color: #fff;
}

[data-theme-version="dark"] .sidebar-dropdown {
  color: #fff;
}

[data-theme-version="dark"] .sidebar-dropdown .sidebar-select i {
  color: #fff;
}

[data-theme-version="dark"] .compose-content .dzu-dropzone {
  background-color: #292d4a !important;
}

[data-theme-version="dark"] .compose-content .dzu-dropzone label {
  background-color: #292d4a !important;
}

[data-theme-version="dark"] .form-wizard .nav-link span {
  border: 0.125rem solid var(--primary);
  color: var(--primary);
  background-color: #20203c;
}

[data-theme-version="dark"] .form-wizard .nav-link.active span {
  background: var(--primary);
  color: #fff;
}

[data-theme-version="dark"] .form-wizard .nav-link.active:after {
  background: var(--primary) !important;
}

[data-theme-version="dark"] .tox .tox-toolbar, [data-theme-version="dark"] .tox .tox-toolbar__overflow, [data-theme-version="dark"] .tox .tox-toolbar__primary {
  background-color: #292d4a;
}

[data-theme-version="dark"] .tox .tox-edit-area__iframe {
  background-color: #292d4a;
  color: #fff;
}

[data-theme-version="dark"] .tox .tox-tbtn {
  color: #fff;
}

[data-theme-version="dark"] .tox .tox-tbtn svg {
  fill: #fff;
}

[data-theme-version="dark"] .tox .tox-statusbar {
  background-color: #292d4a;
}

[data-theme-version="dark"] .tox .tox-statusbar__wordcount,
[data-theme-version="dark"] .tox .tox-statusbar__path-item,
[data-theme-version="dark"] .tox .tox-statusbar a {
  color: #fff;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-position="fixed"] .header {
  border-color: #333754;
}

[data-theme-version="dark"] .header {
  border-color: #333754;
}

[data-theme-version="dark"] .header.is-fixed {
  background: rgba(0, 0, 0, 0.5);
}

[data-theme-version="dark"] .header-left .dashboard_bar {
  color: #fff;
}

[data-theme-version="dark"] .search-area .form-control {
  background: #292d4a;
}

[data-theme-version="dark"] .search-area .input-group-text {
  border: 0;
  background: #292d4a;
}

[data-theme-version="dark"] .search-area .input-group-text a {
  color: #4f7086;
}

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link {
  /* background: $d-bg!important; */
}

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link .badge {
  border-color: #292d4a;
}

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link svg path {
  fill: #fff;
}

[data-theme-version="dark"] .header-right .dropdown .nav-link {
  color: #fff;
}

[data-theme-version="dark"] .header-right .dropdown .nav-link:hover {
  color: #fff;
}

[data-theme-version="dark"] .nav-header .hamburger .line {
  background: #fff !important;
}

[data-theme-version="dark"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff !important;
}

[data-theme-version="dark"] .nav-header {
  border-color: #333754;
}

[data-theme-version="dark"] .nav-control {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo:hover {
  color: #fff;
}

[data-theme-version="dark"] .svg-title-path {
  fill: #fff;
}

[data-theme-version="dark"] .nav-header {
  border-color: #333754;
}

[data-theme-version="dark"] .nav-control {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo:hover {
  color: #fff;
}

[data-theme-version="dark"] .svg-title-path {
  fill: #fff;
}

[data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li>ul {
  background-color: #292d4a;
}

[data-theme-version="dark"] .fixed-content-box {
  background-color: #292d4a;
}

[data-theme-version="dark"] .fixed-content-box .head-name {
  background: #292d4a;
  color: #fff;
  border-color: #333754;
}

[data-theme-version="dark"] .fixed-content-box+.header+.dlabnav {
  background-color: #20203c;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-position="fixed"] .dlabnav {
  border-color: #333754;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a, [data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a {
  background: transparent;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a i, [data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a i {
  color: var(--primary);
  background: var(--rgba-primary-1);
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu>li a>i {
  color: rgba(255, 255, 255, 0.3);
}

[data-theme-version="dark"] .dlabnav .header-profile>a.nav-link {
  border-color: #333754;
}

[data-theme-version="dark"] .dlabnav .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-theme-version="dark"] .dlabnav .metismenu>li>a {
  color: #b3b3b3;
}

[data-theme-version="dark"] .dlabnav .metismenu>li:hover>a, [data-theme-version="dark"] .dlabnav .metismenu>li:focus>a, [data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a {
  color: #fff !important;
  /* background:var(--rgba-primary-1); */
}

[data-theme-version="dark"] .dlabnav .metismenu>li:hover>a::after, [data-theme-version="dark"] .dlabnav .metismenu>li:focus>a::after, [data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a::after {
  border-top: 0.3125rem solid #fff;
  border-left: 0.3125rem solid #fff;
  border-bottom: 0.3125rem solid transparent;
  border-right: 0.3125rem solid transparent;
}

[data-theme-version="dark"] .dlabnav .metismenu>li:hover>a i, [data-theme-version="dark"] .dlabnav .metismenu>li:focus>a i, [data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-theme-version="dark"] .dlabnav .metismenu>li.mm-active ul ul {
  background-color: transparent;
}

[data-theme-version="dark"] .dlabnav .metismenu ul:after {
  background-color: #333754;
}

[data-theme-version="dark"] .dlabnav .metismenu ul a:hover, [data-theme-version="dark"] .dlabnav .metismenu ul a:focus, [data-theme-version="dark"] .dlabnav .metismenu ul a.mm-active {
  color: #fff !important;
}

[data-theme-version="dark"] .dlabnav .metismenu ul a:before {
  background-color: #fff;
}

[data-theme-version="dark"] .dlabnav .metismenu a {
  color: #b3b3b3 !important;
}

[data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after {
  border-top: 0.3125rem solid #b3b3b3;
  border-left: 0.3125rem solid #b3b3b3;
  border-bottom: 0.3125rem solid transparent;
  border-right: 0.3125rem solid transparent;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs {
  border-color: var(--rgba-primary-1) !important;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link {
  color: #000 !important;
}

[data-theme-version="dark"] .sidebar-right .form-control {
  background: #fff;
  color: #000;
  border-color: #EEEEEE;
}

[data-theme-version="dark"] .sidebar-right .default-select .list {
  background: #fff;
}

[data-theme-version="dark"] .sidebar-right .default-select .list .option.selected, [data-theme-version="dark"] .sidebar-right .default-select .list .option.focus, [data-theme-version="dark"] .sidebar-right .default-select .list .option:hover {
  background: rgba(0, 0, 0, 0.05) !important;
}

[data-theme-version="dark"] .sidebar-right .sidebar-right-inner>h4, [data-theme-version="dark"] .sidebar-right .sidebar-right-inner>.h4 {
  color: #000 !important;
}

[data-theme-version="dark"] .sidebar-right .nice-select .option {
  background: #fff;
}

[data-theme-version="dark"] .footer {
  background-color: transparent;
}

[data-theme-version="dark"] .footer .copyright {
  background-color: transparent;
}

[data-theme-version="dark"] .footer .copyright p {
  color: #fff;
}

/* =============
   Calendar
============= */
.app-fullcalender button {
  border-radius: 0rem;
  color: #6e6e6e;
}

.app-fullcalender td {
  border-color: #DBDBDB;
}

.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-view {
  margin-top: 1.875rem;
}

.fc-toolbar {
  margin-bottom: 0.3125rem;
  margin-top: 0.9375rem;
}

@media (max-width: 575.98px) {
  .fc-toolbar .fc-left {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.625rem;
    float: none;
  }
}

@media (max-width: 575.98px) {
  .fc-toolbar .fc-right {
    display: flex;
    justify-content: center;
    float: none;
    margin-bottom: 0.3125rem;
  }
}

@media (max-width: 575.98px) {
  .fc-toolbar .fc-center {
    display: flex;
    justify-content: center;
  }

  .fc-toolbar .fc-center * {
    float: none;
  }
}

.fc-toolbar h2, .fc-toolbar .h2 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.875rem;
  text-transform: uppercase;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar .ui-state-hover,
.fc-toolbar button:focus,
.fc-toolbar button:hover {
  z-index: 0;
  box-shadow: none;
}

.fc-widget-header {
  border: 0.0625rem solid #DBDBDB;
  border-bottom: 0 !important;
}

.fc th.fc-widget-header {
  background: #DBDBDB !important;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.625rem 0;
  text-transform: uppercase;
}

.fc-button {
  border: 0.0625rem solid #DBDBDB;
  text-transform: capitalize;
}

.fc-button.active {
  box-shadow: none !important;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem;
}

.fc-event,
.external-event {
  border-radius: 0.125rem;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 0.3125rem 0.4375rem;
  padding: 0.3125rem;
  text-align: center;
}

.external-event {
  cursor: move;
  margin: 0.625rem 0;
  padding: 0.125rem 0;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 0.3125rem;
}

.fc-basic-view td.fc-day-number {
  padding-right: 0.3125rem;
}

#drop-remove {
  margin-right: 0.5rem;
  top: 0.1875rem;
}

#add-category .modal-dialog,
#event-modal .modal-dialog {
  max-width: 37.5rem;
}

.fc-content {
  color: #fff;
}

.fc th.fc-widget-header {
  background: transparent !important;
}

.fc-button {
  background: #fff;
}

.fc-state-hover {
  background: #fff !important;
}

.fc-state-highlight {
  background: #f2f4fa !important;
}

[data-theme-version="dark"] .fc-state-highlight {
  color: #fff !important;
}

.fc-cell-overlay {
  background: #fff !important;
}

.fc-unthemed .fc-today {
  background: #f2f4fa !important;
}

.fc-day-top {
  color: #6e6e6e !important;
}

[data-theme-version="dark"] .fc-day-top {
  color: #fff !important;
}

.external-event {
  color: #fff;
}

[data-theme-version="dark"] .external-event {
  color: #fff !important;
}

.fc-basic-view .fc-body .fc-row {
  min-height: 1rem;
}

.fc-scroller.fc-day-grid-container {
  height: 30.625rem !important;
}

.fc-row.fc-week.fc-widget-content.fc-rigid {
  height: 5.0625rem !important;
}

@media only screen and (max-width: 90rem) {
  .email_left_pane {
    display: none;
  }
}

#external-events .external-event:before {
  content: "";
  display: block;
  width: 0.875rem;
  min-width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
  margin-right: .9rem;
  position: relative;
  top: 0.125rem;
}

[data-theme-version="dark"] #external-events [data-class="bg-primary"] {
  color: #fff !important;
}

#external-events [data-class="bg-primary"]::before {
  background: var(--primary);
}

#external-events [data-class="bg-success"]::before {
  background: #56c760;
}

#external-events [data-class="bg-warning"]::before {
  background: #f0a901;
}

#external-events [data-class="bg-dark"]::before {
  background: #6e6e6e;
}

#external-events [data-class="bg-danger"]::before {
  background: #f75a5b;
}

#external-events [data-class="bg-info"]::before {
  background: #58bad7;
}

#external-events [data-class="bg-pink"]::before {
  background: #e83e8c;
}

#external-events [data-class="bg-secondary"]::before {
  background: #4CBC9A;
}

.fc .fc-row .fc-content-skeleton table,
.fc .fc-row .fc-content-skeleton td,
.fc .fc-row .fc-helper-skeleton td {
  border-color: #DBDBDB;
}

[data-theme-version="dark"] .fc .fc-row .fc-content-skeleton table, [data-theme-version="dark"] .fc .fc-row .fc-content-skeleton td, [data-theme-version="dark"] .fc .fc-row .fc-helper-skeleton td {
  border-color: #333754;
}

[data-theme-version="dark"] .fc-unthemed .fc-content, [data-theme-version="dark"] .fc-unthemed .fc-divider, [data-theme-version="dark"] .fc-unthemed .fc-list-heading td, [data-theme-version="dark"] .fc-unthemed .fc-list-view, [data-theme-version="dark"] .fc-unthemed .fc-popover, [data-theme-version="dark"] .fc-unthemed .fc-row, [data-theme-version="dark"] .fc-unthemed tbody, [data-theme-version="dark"] .fc-unthemed td, [data-theme-version="dark"] .fc-unthemed th, [data-theme-version="dark"] .fc-unthemed thead {
  border-color: #333754;
}

.email-left-box .intro-title {
  background: var(--rgba-primary-1);
  padding: 1rem;
  margin: 1.875rem 0 1.25rem 0;
}

.email-left-box .intro-title h5, .email-left-box .intro-title .h5 {
  margin-bottom: 0;
  color: #6a707e;
  font-size: 0.875rem;
}

.email-left-box .intro-title h5 i, .email-left-box .intro-title .h5 i {
  font-size: 0.75rem;
  position: relative;
  bottom: 0.0625rem;
}

.email-left-box .intro-title i {
  color: var(--primary);
}

.email-right-box .right-box-border {
  border-right: 0.125rem solid var(--rgba-primary-1);
}

@media (min-width: 106.25rem) {
  .email-right-box .right-box-padding {
    padding-left: 0.9375rem;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .email-right-box .right-box-padding {
    padding-left: 0;
  }
}

.toolbar .btn-group .btn {
  border: 0;
}

.toolbar .btn-group input {
  position: relative;
  top: 0.125rem;
}

.read-content textarea {
  height: 9.375rem;
  padding: 0.9375rem 1.25rem;
}

.read-content-email {
  font-size: 0.875rem;
}

.read-content h5, .read-content .h5 {
  color: #6a707e;
}

.read-content p strong {
  color: #6a707e;
}

.read-content-body p {
  margin-bottom: 1.875rem;
}

.read-content-attachment {
  padding: 0.5rem 0;
}

.read-content-attachment h6, .read-content-attachment .h6 {
  font-size: 1.125rem;
  color: #6a707e;
}

.read-content-attachment h6 i, .read-content-attachment .h6 i {
  padding-right: 0.3125rem;
}

.read-content-attachment .attachment>div:not(:last-child) {
  border-right: 0.0625rem solid #DDDFE1;
}

.compose-content .wysihtml5-toolbar {
  border-color: #eaeaea;
}

.compose-content .dropzone {
  background: #f2f4fa !important;
}

.compose-content h5, .compose-content .h5 {
  font-size: 1.0625rem;
  color: #6a707e;
}

.compose-content h5 i, .compose-content .h5 i {
  font-size: 1.125rem;
  transform: rotate(90deg);
}

.compose-content .dropzone {
  border: 0.0625rem dashed #DDDFE1;
  min-height: 13.125rem;
  position: relative;
  border-radius: 0.625rem;
}

.compose-content .dropzone .huge-message {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.email-list {
  display: block;
  padding-left: 0;
}

.email-list .message {
  position: relative;
  display: block;
  height: 3.125rem;
  line-height: 3.125rem;
  cursor: default;
  transition-duration: 0.3s;
}

.email-list .message a {
  color: #828690;
}

.email-list .message-single .custom-checkbox {
  margin-top: 0.125rem;
}

.email-list .message-single i {
  color: #89879f;
  font-size: 1.125rem;
  padding-left: .4rem;
}

.email-list .message:hover {
  transition-duration: 0.05s;
  background: rgba(152, 166, 173, 0.15);
}

.email-list .message .col-mail {
  float: left;
  position: relative;
}

.email-list .message .col-mail-1 {
  width: 5.625rem;
}

.email-list .message .col-mail-1 .star-toggle {
  display: block;
  float: left;
  margin-top: 1.125rem;
  font-size: 1rem;
  margin-left: 0.3125rem;
}

.email-list .message .col-mail-1 .email-checkbox {
  display: block;
  float: left;
  margin: 0.9375rem 0.625rem 0 1.25rem;
}

.email-list .message .col-mail-1 .dot {
  display: block;
  float: left;
  border: .4rem solid transparent;
  border-radius: 6.25rem;
  margin: 1.375rem 1.625rem 0;
  height: 0;
  width: 0;
  line-height: 0;
  font-size: 0;
}

.email-list .message .col-mail-2 {
  position: absolute;
  top: 0;
  left: 5.625rem;
  right: 0;
  bottom: 0;
}

.email-list .message .col-mail-2 .subject {
  position: absolute;
  top: 0;
  left: 0;
  right: 5.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.email-list .message .col-mail-2 .date {
  position: absolute;
  top: 0;
  right: 0;
}

.email-checkbox {
  cursor: pointer;
  height: 0.9375rem;
  width: 0.9375rem;
  position: relative;
  display: inline-block;
  border-radius: .1rem;
  position: relative;
  top: 0.3125rem;
  box-shadow: inset 0 0 0 0.1rem #828690;
}

.email-checkbox input {
  opacity: 0;
  cursor: pointer;
}

.email-checkbox input:checked label {
  opacity: 1;
}

.email-checkbox label {
  position: absolute;
  top: .3rem;
  left: .3rem;
  right: .3rem;
  bottom: .3rem;
  cursor: pointer;
  opacity: 0;
  margin-bottom: 0 !important;
  transition-duration: 0.05s;
}

.mail-list a {
  vertical-align: middle;
  padding: 0.625rem 0.9375rem;
  display: block;
  background: transparent;
  color: #464a53;
  font-weight: 600;
}

.mail-list .list-group-item {
  color: #6a707e;
  padding: 0.75rem 1.0625rem;
}

.mail-list .list-group-item i {
  font-size: 1rem;
  padding-right: 0.625rem;
  color: #cccccc;
}

.mail-list .list-group-item.active {
  color: #fff;
}

.mail-list .list-group-item.active i {
  color: #fff;
}

.chat-wrap {
  padding: 1.0625rem 1.875rem;
}

.chat-wrap .media .media-body h6, .chat-wrap .media .media-body .h6 {
  font-size: 1.0625rem;
  color: #6a707e;
}

.chat-wrap .media .media-body p {
  font-size: 0.875rem;
}

@media (min-width: 40.5rem) {
  .email-filter {
    padding-left: 1.25rem;
  }
}

@media (min-width: 106.25rem) {
  .email-filter {
    padding-left: 1.875rem;
  }
}

.email-filter .input-group-prepend i {
  font-size: 0.875rem;
  color: #89879f;
}

.email-filter .input-group-prepend .input-group-text {
  border: 0;
  border-bottom: 0.0625rem solid #DDDFE1 !important;
  background: transparent;
}

.email-filter .input-group .form-control {
  padding: 0 0 0 0.3125rem;
  border: 0;
  font-size: 0.875rem;
  height: 1.875rem;
  color: #89879f;
  border-bottom: 0.0625rem solid #DDDFE1;
}

.email-filter .input-group .form-control::placeholder {
  font-size: 0.875rem;
  color: #89879f;
}

.email-filter .input-group>.form-control {
  min-height: 1.875rem;
}

.single-mail {
  display: block;
  padding: 1.5625rem 0;
}

.single-mail .media {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

@media (min-width: 106.25rem) {
  .single-mail .media {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}

.single-mail .media img {
  width: 3.4375rem;
  height: 3.4375rem;
  border-radius: 50%;
  margin-right: 0.9375rem;
}

@media (min-width: 106.25rem) {
  .single-mail .media img {
    margin-right: 1.875rem;
  }
}

.single-mail .media-body h6, .single-mail .media-body .h6 {
  color: #abafb3;
}

.single-mail .media-body h4, .single-mail .media-body .h4 {
  font-size: 1rem;
  color: #6a707e;
}

.single-mail .media-body h4 button i, .single-mail .media-body .h4 button i {
  font-size: 1.125rem;
  color: #abafb3;
  font-weight: bold;
  transform: rotate(90deg);
}

.single-mail .media-body p {
  font-size: 0.875rem;
  color: #abafb3;
}

.single-mail.active {
  background: var(--primary);
}

.single-mail.active h6, .single-mail.active .h6, .single-mail.active h4, .single-mail.active .h4, .single-mail.active p, .single-mail.active i {
  color: #fff !important;
}

.bookmark-btn {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0;
  font-family: 'Font Awesome 6 Free';
}

.bookmark-btn .checkmark {
  font-weight: 900;
  line-height: 1;
}

.bookmark-btn .checkmark:before {
  content: "\f005";
  font-size: 1.125rem;
  color: #89879f;
  font-weight: 900;
}

.bookmark-btn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  display: none;
}

.bookmark-btn input:checked~.checkmark:before {
  color: #ff9838;
  font-weight: 900;
}

.photo-content {
  position: relative;
}

.photo-content .cover-photo {
  background: url(../images/profile/cover.jpg);
  background-size: cover;
  background-position: center;
  min-height: 15.625rem;
  width: 100%;
}

.profile .profile-photo {
  max-width: 6.25rem;
  position: relative;
  z-index: 1;
  margin-top: -4.5rem;
  margin-right: 0.625rem;
}

@media only screen and (max-width: 35.9375rem) {
  .profile .profile-photo {
    width: 5rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.25rem;
  }
}

[direction="rtl"] .profile .profile-photo {
  left: auto;
  right: 0;
  margin-right: 0;
  margin-left: 0.9375rem;
}

@media only screen and (max-width: 74.9375rem) {
  [direction="rtl"] .profile .profile-photo {
    right: 0.9375rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .profile .profile-photo {
    width: 5rem;
    right: calc(50% - 2.5rem);
    top: -6.25rem;
  }
}

.profile-info {
  padding: 0.9375rem 1.25rem;
}

@media only screen and (max-width: 35.9375rem) {
  .profile-info {
    padding: 0 0 1.25rem;
    text-align: center;
  }
}

.profile-info h4, .profile-info .h4 {
  color: #464a53 !important;
}

.profile-info h4.text-primary, .profile-info .text-primary.h4 {
  color: var(--primary) !important;
}

.profile-info p {
  color: #828690;
}

.profile-info .prf-col {
  min-width: 15.625rem;
  padding: 0.625rem 3.125rem 0;
}

.profile-interest .row {
  margin: 0 -0.0437rem;
}

.profile-interest .row .int-col {
  padding: 0 0.0437rem;
}

.profile-interest .row .int-col .interest-cat {
  margin-bottom: 0.0875rem;
  position: relative;
  display: block;
}

.profile-interest .row .int-col .interest-cat:after {
  background: #000;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

.profile-interest .row .int-col .interest-cat p {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0.3125rem;
  left: 0;
  margin: 0;
  z-index: 1;
  color: white;
  font-size: 0.075rem;
}

.profile-tab .nav-item .nav-link {
  font-size: 1rem;
  margin-right: 1.875rem;
  transition: all 0.5s ease-in-out;
  border: none;
  border-bottom: 0.0125rem solid transparent;
  color: #828690;
}

.profile-tab .nav-item .nav-link:hover, .profile-tab .nav-item .nav-link.active {
  border: 0;
  background: transparent;
  background: transparent;
  border-bottom: 0.0125rem solid var(--primary);
  color: var(--primary);
}

@media only screen and (max-width: 35.9375rem) {
  .profile-tab .nav-item .nav-link {
    margin-right: 0rem;
  }
}

.profile-info {
  display: flex;
}

@media only screen and (max-width: 35.9375rem) {
  .profile-info {
    display: block;
  }
}

.profile-info .profile-details {
  display: flex;
  width: 100%;
}

@media only screen and (max-width: 35.9375rem) {
  .profile-info .profile-details {
    display: block;
  }

  .profile-info .profile-details .dropdown {
    position: absolute;
    top: 1.875rem;
    right: 1.875rem;
  }
}

.post-input {
  margin-bottom: 1.875rem;
}

.post-input .form-control {
  height: 4.6875rem;
  font-weight: 400;
  margin: 0.9375rem 0;
}

.post-input .btn-social {
  font-size: 1.25rem;
  height: 3.4375rem;
  display: inline-block;
  padding: 0;
  text-align: center;
  border-radius: 0.625rem;
  color: #fff;
  width: 3.4375rem;
  line-height: 3.375rem;
}

.post-input .btn-social.facebook {
  background-color: #3B5998;
}

.post-input .btn-social.google-plus {
  background-color: #DE4E43;
}

.post-input .btn-social.linkedin {
  background-color: #007BB6;
}

.post-input .btn-social.instagram {
  background-color: #8A5A4E;
}

.post-input .btn-social.twitter {
  background-color: #1EA1F3;
}

.post-input .btn-social.youtube {
  background-color: #CE201F;
}

.post-input .btn-social.whatsapp {
  background-color: #01C854;
}

.post-input .btn-social i {
  margin: 0 !important;
}

.profile-uoloaded-post img {
  margin-bottom: 1.25rem;
}

.profile-uoloaded-post a h4, .profile-uoloaded-post a .h4 {
  margin-bottom: 0.625rem;
  color: #464a53;
}

.profile-page {
  height: 60px;
}

.event-chat-ryt .chat-area .chat-reciver,
.event-chat-ryt .chat-area .chat-sender {
  margin-bottom: 1.875rem;
  padding: 0;
}

.event-chat-ryt .chat-area .chat-reciver img,
.event-chat-ryt .chat-area .chat-sender img {
  border-radius: 1.875rem;
}

.event-chat-ryt .chat-area .media {
  position: relative;
}

.event-chat-ryt .chat-area .media-body p {
  margin: 0;
  max-width: 100%;
  display: inline-block;
  position: relative;
}

.event-chat-ryt .chat-area .media-body p span {
  padding: 1rem;
  display: inline-block;
  top: 103%;
  position: relative;
  border: 0.0625rem solid #DBDBDB;
}

.event-chat-ryt .chat-reciver {
  padding: .5rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.event-chat-ryt .chat-reciver .media {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.event-chat-ryt .chat-reciver .media .media-body {
  margin-right: 2rem;
  text-align: right;
}

[direction="rtl"] .event-chat-ryt .chat-reciver .media .media-body {
  text-align: left;
  margin-left: 2rem;
  margin-right: auto;
}

.event-chat-ryt .chat-reciver .media .media-body p {
  background: #fff;
  margin-bottom: 0;
  border-radius: 0.3125rem 0.3125rem 0 0.3125rem;
}

.event-chat-ryt .chat-reciver .media .media-body p span {
  text-align: left;
  border: 0.0625rem solid #DBDBDB;
}

.event-chat-ryt .chat-reciver .media .media-body p span::after {
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  border-bottom: 0.0625rem solid #DBDBDB;
  border-right: 0.0625rem solid #DBDBDB;
  position: absolute;
  right: 0;
  bottom: 0;
  background: #fff;
  -webkit-transform: rotate(-45deg) translateX(15px);
  transform: rotate(-45deg) translateX(15px);
}

[direction="rtl"] .event-chat-ryt .chat-reciver .media .media-body p span::after {
  left: 0;
  right: auto;
  -webkit-transform: rotate(135deg) translateY(15px);
  transform: rotate(135deg) translateY(15px);
}

.event-chat-ryt .chat-reciver .media .media-body p .time {
  position: absolute;
  font-size: 0.75rem;
  color: #777777;
  font-weight: 400;
  bottom: 0;
  left: -5rem;
}

[direction="rtl"] .event-chat-ryt .chat-reciver .media .media-body p .time {
  right: -5rem;
  left: auto;
}

.event-chat-ryt .chat-sender {
  text-align: left;
  padding: .5rem 1rem;
}

.event-chat-ryt .chat-sender .media .media-body {
  margin-left: 2rem;
}

[direction="rtl"] .event-chat-ryt .chat-sender .media .media-body {
  text-align: right;
  margin-right: 2rem;
  margin-left: auto;
}

.event-chat-ryt .chat-sender .media .media-body p {
  background-color: #fff;
  margin-bottom: 0;
}

.event-chat-ryt .chat-sender .media .media-body p span::after {
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  border-bottom: 0.0625rem solid #DBDBDB;
  border-left: 0.0625rem solid #DBDBDB;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
  -webkit-transform: rotate(45deg) translateX(-15px);
  transform: rotate(45deg) translateX(-15px);
}

[direction="rtl"] .event-chat-ryt .chat-sender .media .media-body p span::after {
  left: auto;
  right: 0;
  -webkit-transform: rotate(-135deg) translateY(15px);
  transform: rotate(-135deg) translateY(15px);
}

.event-chat-ryt .chat-sender .media .media-body p .time {
  position: absolute;
  font-size: 0.625rem;
  color: #777777;
  font-weight: 400;
  bottom: 0;
  right: -5rem;
}

[direction="rtl"] .event-chat-ryt .chat-sender .media .media-body p .time {
  left: -5rem;
  right: auto;
}

.char-type {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}

.char-type form .form-control {
  height: 2.8125rem;
  padding-left: 1.125rem;
  background: #F0F0F0;
  border-right: 0;
}

.char-type form .input-group-append i {
  color: #898989;
  font-size: 1.125rem;
}

.char-type form .input-group-append .input-group-text {
  padding-left: .7rem;
  padding-right: .7rem;
  background: #F0F0F0;
  border-color: #DBDBDB;
  border-left: 0;
}

.char-type form .input-group-append .input-group-text:last-child {
  padding-right: 1.8rem;
}

.media-avatar {
  padding: 1.5625rem 0;
  border-bottom: 0.0625rem solid #DBDBDB;
}

.media-avatar:last-child {
  border-bottom: 0rem;
}

.media-avatar p {
  margin-bottom: 0;
}

.media-avatar .avatar-status {
  position: relative;
}

.media-avatar .avatar-status i {
  position: absolute;
  right: 0;
  bottom: 0;
}

.ct-golden-section:before {
  float: none;
}

.ct-chart {
  max-height: 15.7rem;
}

.ct-chart .ct-label {
  fill: #a3afb7;
  color: #a3afb7;
  font-size: 0.75rem;
  line-height: 1;
}

.ct-grid {
  stroke: rgba(49, 58, 70, 0.1);
}

.ct-chart.simple-pie-chart-chartist .ct-label {
  color: #ffffff;
  fill: #ffffff;
  font-size: 0.625rem;
}

.ct-chart .ct-series.ct-series-a .ct-bar,
.ct-chart .ct-series.ct-series-a .ct-line,
.ct-chart .ct-series.ct-series-a .ct-point,
.ct-chart .ct-series.ct-series-a .ct-slice-donut {
  stroke: var(--primary);
}

.ct-chart .ct-series.ct-series-b .ct-bar,
.ct-chart .ct-series.ct-series-b .ct-line,
.ct-chart .ct-series.ct-series-b .ct-point,
.ct-chart .ct-series.ct-series-b .ct-slice-donut {
  stroke: #56c760;
}

.ct-chart .ct-series.ct-series-c .ct-bar,
.ct-chart .ct-series.ct-series-c .ct-line,
.ct-chart .ct-series.ct-series-c .ct-point,
.ct-chart .ct-series.ct-series-c .ct-slice-donut {
  stroke: #f0a901;
}

.ct-chart .ct-series.ct-series-d .ct-bar,
.ct-chart .ct-series.ct-series-d .ct-line,
.ct-chart .ct-series.ct-series-d .ct-point,
.ct-chart .ct-series.ct-series-d .ct-slice-donut {
  stroke: #f75a5b;
}

.ct-chart .ct-series.ct-series-e .ct-bar,
.ct-chart .ct-series.ct-series-e .ct-line,
.ct-chart .ct-series.ct-series-e .ct-point,
.ct-chart .ct-series.ct-series-e .ct-slice-donut {
  stroke: #58bad7;
}

.ct-chart .ct-series.ct-series-f .ct-bar,
.ct-chart .ct-series.ct-series-f .ct-line,
.ct-chart .ct-series.ct-series-f .ct-point,
.ct-chart .ct-series.ct-series-f .ct-slice-donut {
  stroke: #6e6e6e;
}

.ct-chart .ct-series.ct-series-g .ct-bar,
.ct-chart .ct-series.ct-series-g .ct-line,
.ct-chart .ct-series.ct-series-g .ct-point,
.ct-chart .ct-series.ct-series-g .ct-slice-donut {
  stroke: #8d6e63;
}

.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
  fill: #4CBC9A;
}

.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
  fill: #00A2FF;
}

.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
  fill: #ff9800;
}

.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie {
  fill: #ff9800;
}

.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 0.625rem;
  padding: 0.125rem 0.625rem;
  border-radius: 0.125rem;
  background: #313a46;
  color: #ffffff;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity .2s linear;
  -moz-transition: opacity .2s linear;
  -o-transition: opacity .2s linear;
  transition: opacity .2s linear;
}

.chartist-tooltip.tooltip-show {
  opacity: 1;
}

#donught_graph .ct-series.ct-series-a .ct-slice-donut {
  stroke: #3FC6D4;
}

#donught_graph .ct-series.ct-series-b .ct-slice-donut {
  stroke: #333333;
}

#donught_graph .ct-series.ct-series-c .ct-slice-donut {
  stroke: #F63465;
}

@media only screen and (max-width: 47.9375rem) {
  #pie-chart .ct-label {
    color: #fff;
    fill: #fff;
  }
}

#visitor_graph {
  height: 15.8125rem !important;
}

#user_rating_graph {
  height: 17.5rem !important;
}

#activity {
  height: 16.875rem !important;
}

#visitorOnline {
  height: 4.5rem !important;
}

#trendMeter {
  height: 4.5rem !important;
}

#widget-revenue1,
#widget-revenue2,
#widget-revenue3 {
  height: 7.3125rem !important;
}

#widget-profit1,
#widget-profit2,
#widget-profit3 {
  height: 10rem !important;
}

#comparison-rate {
  height: 14.375rem;
}

#session_day {
  height: 10.9375rem;
  width: auto !important;
  margin: 0 auto;
}

#walet-status {
  height: 8.75rem;
}

#bar1 {
  height: 9.375rem;
}

#sold-product {
  height: 14.375rem;
}

#chart-venue-expenses,
#chart-online-sale,
#chart-gross-sale {
  height: 9.375rem !important;
}

#areaChart_3 {
  height: 18.4375rem !important;
}

.chart-point {
  display: flex;
  align-items: center;
}

.chart-point .check-point-area {
  width: 6.25rem;
  height: 6.25rem;
  margin-top: -0.625rem;
  margin-left: -0.625rem;
}

.chart-point .chart-point-list {
  margin: 0;
  padding-left: 0.3125rem;
}

.chart-point .chart-point-list li {
  list-style: none;
  font-size: 0.8125rem;
  padding: 0.125rem 0;
}

.chart-point .chart-point-list li i {
  margin-right: 0.3125rem;
  font-size: 0.6875rem;
  position: relative;
  top: -0.0625rem;
}

.c3 {
  height: 15.625rem;
}

.c3-legend-item {
  fill: #9fabb1;
}

.c3 .c3-axis-x path,
.c3 .c3-axis-x line,
.c3 .c3-axis-y path,
.c3 .c3-axis-y line,
.tick text {
  stroke: #fff;
}

.flot-chart {
  height: 15.7rem;
}

.tooltipflot {
  background-color: transparent;
  font-size: 1.4rem;
  padding: .5rem 1rem;
  color: rgba(255, 255, 255, 0.7);
  border-radius: .2rem;
}

.legendColorBox>div {
  border: 0 !important;
  padding: 0 !important;
}

.legendLabel {
  font-size: 0.825rem;
  padding-left: .5rem;
  color: #fff;
}

.flotTip {
  background: #000;
  border: 0.0625rem solid #000;
  color: #fff;
}

.legend>div {
  background: transparent !important;
}

#balance_graph {
  height: 16.25rem;
}

.morris-hover {
  position: absolute;
  z-index: 1;
  background: var(--primary);
  color: #fff;
}

.morris-hover .morris-hover-point {
  color: #fff !important;
  margin: 0.1875rem 0;
  text-align: center;
  padding: 0 1.5625rem;
}

.morris-hover .morris-hover-row-label {
  background-color: #6e6e6e;
  text-align: center;
  padding: 0.3125rem;
  margin-bottom: 0.3125rem;
}

.morris-hover.morris-default-style {
  border-radius: 0.3125rem;
  padding: 0;
  margin: 0;
  border: none;
  overflow: hidden;
}

/* svg text {
    font-weight: 600 !important;
} */
#morris_donught,
#morris_donught_2,
#line_chart_2,
#morris_bar,
#morris_bar_stalked,
#morris_bar_2,
#morris_area_2,
#morris_area {
  height: 15rem !important;
}

#morris_line {
  height: 17.375rem !important;
}

#crypto-btc-card,
#crypto-eth-card,
#crypto-rpl-card,
#crypto-ltc-card {
  height: 9.375rem;
}

#daily-sales, #comparison-rate, #usage-chart, #walet-status {
  width: 100%;
  display: block;
}

#daily-sales canvas, #comparison-rate canvas, #usage-chart canvas, #walet-status canvas {
  max-width: 100% !important;
  width: 100% !important;
}

#sparkline-composite-chart canvas,
#composite-bar canvas,
#sparkline11 canvas,
#StackedBarChart canvas,
#spark-bar canvas,
#tristate canvas {
  height: 6.25rem !important;
}

#sparkline11 canvas {
  width: 6.25rem !important;
}

.easy-pie-chart {
  position: relative;
  text-align: center;
}

.easy-pie-chart .inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
}

.easy-pie-chart img {
  width: 4.6875rem;
  height: 4.6875rem;
  border-radius: 50%;
}

.easy-pie-chart canvas {
  display: block;
  margin: 0 auto;
}

#revenue-chart {
  height: 27.7rem;
}

#duration-value-axis {
  height: 27.6rem;
  width: 100%;
}

#combined-bullet {
  height: 28.125rem;
  width: 100%;
}

#zoomable-chart {
  height: 28.125rem;
  width: 100%;
}

#chartMap {
  height: 28.125rem;
  width: 100%;
}

#professional-candlesticks {
  width: 100%;
  height: 28.125rem;
}

#comparing-stock-indices {
  width: 100%;
  height: 28.125rem;
}

#multiple-panel-data {
  width: 100%;
  height: 28.125rem;
}

#depth-chart {
  width: 100%;
  height: 28.125rem;
}

.amcharts-export-menu {
  display: none;
}

.amcharts-data-set-selector-div {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  width: 16.875rem;
  margin: 0 auto;
}

.amcharts-data-set-selector-div select {
  border: 0;
  margin-left: 0.625rem;
  background: #ddd;
  color: #000;
}

.amChartsInputField {
  border: 0;
  background: var(--primary);
  color: #fff;
  padding: 0.3125rem 0.9375rem;
  margin: 0 0.9375rem;
}

.amcharts-data-set-select {
  border: 0;
  background: #ddd;
  color: #000;
}

.amcharts-period-input,
.amcharts-period-input-selected {
  border: 0;
  margin-left: 0.625rem;
  background: var(--primary);
  color: #fff;
  padding: 0.3125rem 0.9375rem;
}

.amcharts-graph-g2 .amcharts-graph-stroke {
  stroke-dasharray: 0.1875rem 0.1875rem;
  stroke-linejoin: round;
  stroke-linecap: round;
  -webkit-animation: am-moving-dashes 1s linear infinite;
  animation: am-moving-dashes 1s linear infinite;
}

@-webkit-keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -1.9375rem;
  }
}

@keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -1.9375rem;
  }
}

.lastBullet {
  -webkit-animation: am-pulsating 1s ease-out infinite;
  animation: am-pulsating 1s ease-out infinite;
}

@-webkit-keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0px;
  }

  100% {
    stroke-opacity: 0;
    stroke-width: 3.125rem;
  }
}

@keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0px;
  }

  100% {
    stroke-opacity: 0;
    stroke-width: 3.125rem;
  }
}

.amcharts-graph-column-front {
  -webkit-transition: all .3s .3s ease-out;
  transition: all .3s .3s ease-out;
}

.amcharts-graph-column-front:hover {
  fill: #496375;
  stroke: #496375;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

@-webkit-keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .amChartsPeriodSelector>fieldset>div {
    float: none !important;
    display: block !important;
    margin-bottom: 0.625rem;
  }
}

.highcharts-root text {
  font-weight: 300 !important;
}

.highcharts-credits {
  display: none;
}

#chart_employee_gender, #chart_employee_status {
  width: auto;
  height: 21.875rem;
}

.form-control {
  background: #fff;
  color: #6e6e6e;
  line-height: 2.4;
  font-size: 1rem;
  border-radius: 0.625rem;
}

.form-control:hover, .form-control:focus, .form-control.active {
  box-shadow: none;
  background: #fff;
  color: #6e6e6e;
}

.form-control:focus {
  border-color: var(--primary);
}

.form-control.solid {
  background: #F0F0F0;
  border-color: #ececec;
  color: #000;
}

.form-control.solid[type="file"] {
  padding: 6px 10px;
}

.input-rounded {
  border-radius: 6.25rem;
}

[data-theme-version="light"] .input-primary .form-control,
[data-theme-version="dark"] .input-primary .form-control {
  border-color: var(--primary);
}

[data-theme-version="light"] .input-primary .input-group-text,
[data-theme-version="dark"] .input-primary .input-group-text {
  background-color: var(--primary);
  color: #fff;
}

[data-theme-version="light"] .input-danger .form-control,
[data-theme-version="dark"] .input-danger .form-control {
  border-color: #f75a5b;
}

[data-theme-version="light"] .input-danger .input-group-text,
[data-theme-version="dark"] .input-danger .input-group-text {
  background-color: #f75a5b;
  color: #fff;
}

[data-theme-version="light"] .input-info .form-control,
[data-theme-version="dark"] .input-info .form-control {
  border-color: #58bad7;
}

[data-theme-version="light"] .input-info .input-group-text,
[data-theme-version="dark"] .input-info .input-group-text {
  background-color: #58bad7;
  color: #fff;
}

[data-theme-version="light"] .input-success .form-control,
[data-theme-version="dark"] .input-success .form-control {
  border-color: #56c760;
}

[data-theme-version="light"] .input-success .input-group-text,
[data-theme-version="dark"] .input-success .input-group-text {
  background-color: #56c760;
  color: #fff;
}

[data-theme-version="light"] .input-warning .form-control,
[data-theme-version="dark"] .input-warning .form-control {
  border-color: #f0a901;
}

[data-theme-version="light"] .input-warning .input-group-text,
[data-theme-version="dark"] .input-warning .input-group-text {
  background-color: #f0a901;
  color: #fff;
}

[data-theme-version="light"] .input-primary-o .form-control,
[data-theme-version="dark"] .input-primary-o .form-control {
  border-color: var(--primary);
}

[data-theme-version="light"] .input-primary-o .input-group-text,
[data-theme-version="dark"] .input-primary-o .input-group-text {
  background-color: transparent;
  border-color: var(--primary);
  color: var(--primary);
}

[data-theme-version="light"] .input-danger-o .form-control,
[data-theme-version="dark"] .input-danger-o .form-control {
  border-color: #f75a5b;
}

[data-theme-version="light"] .input-danger-o .input-group-text,
[data-theme-version="dark"] .input-danger-o .input-group-text {
  background-color: transparent;
  border-color: #f75a5b;
  color: #f75a5b;
}

[data-theme-version="light"] .input-info-o .form-control,
[data-theme-version="dark"] .input-info-o .form-control {
  border-color: #58bad7;
}

[data-theme-version="light"] .input-info-o .input-group-text,
[data-theme-version="dark"] .input-info-o .input-group-text {
  background-color: transparent;
  border-color: #58bad7;
  color: #58bad7;
}

[data-theme-version="light"] .input-success-o .form-control,
[data-theme-version="dark"] .input-success-o .form-control {
  border-color: #56c760;
}

[data-theme-version="light"] .input-success-o .input-group-text,
[data-theme-version="dark"] .input-success-o .input-group-text {
  background-color: transparent;
  border-color: #56c760;
  color: #56c760;
}

[data-theme-version="light"] .input-warning-o .form-control,
[data-theme-version="dark"] .input-warning-o .form-control {
  border-color: #f0a901;
}

[data-theme-version="light"] .input-warning-o .input-group-text,
[data-theme-version="dark"] .input-warning-o .input-group-text {
  background-color: transparent;
  border-color: #f0a901;
  color: #f0a901;
}

.form-control[type="file"] {
  line-height: 44px;
  padding-left: 12px;
}

.form-control-sm[type="file"] {
  line-height: 32px;
  padding: 4px;
  padding-left: 8px;
}

.form-control-lg[type="file"] {
  line-height: 52px;
  padding-left: 10px;
}

.input-group-text {
  min-width: 3.125rem;
  justify-content: center;
  border-radius: 0.5rem;
}

.form-file-label {
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
}

.input-group-prepend .btn,
.input-group-append .btn {
  z-index: 0;
}

.custom-select {
  background: none;
  border-color: #DBDBDB;
  color: #6e6e6e;
}

.custom-select:focus {
  box-shadow: none;
  border-color: var(--primary);
  color: var(--primary);
}

.form-file-label {
  background: #656C73;
  white-space: nowrap;
  color: #fff;
}

[data-theme-version="dark"] .form-file-label {
  background: #333754;
  border-color: #333754;
  color: #777777;
}

.custom_file_input .form-file-label::after {
  height: 100%;
}

.form-control:disabled, .form-control[readonly] {
  background: #fff;
  opacity: 1;
}

.form-file {
  border: 0.0625rem solid #DBDBDB;
  background: #fff;
}

[data-theme-version="dark"] .form-file {
  background: #20203c;
  border-color: #333754;
}

.input-group {
  /* &> .form-file {
		display: flex;
		align-items: center;

		&:not(:last-child) .form-file-label,
		&:not(:last-child) .form-file-label::after { @include border-right-radius(0); }
		&:not(:first-child) .form-file-label { @include border-left-radius(0); }
	  } */
}

.input-group>.form-control-plaintext,
.input-group>.form-select,
.input-group>.form-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  border-radius: 0.625rem;
}

.form-file .form-control {
  margin: 0;
  border-radius: 0;
  border: 0;
  height: auto;
}

#validationCustomUsername {
  border-radius: 0 0.5rem 0.5rem 0;
}

.show-pass {
  border-radius: 0 0.5rem 0.5rem 0 !important;
}

.form-file .form-control {
  margin: 0;
  border-radius: 0;
  border: 0;
  height: auto;
}

.input-group .input-group-text+.form-file {
  border-radius: 0 0.5rem 0.5rem 0;
}

.input-group .input-group-text+.form-file .form-control {
  border-radius: inherit;
}

.custom_file_input .form-file {
  border-radius: 0.5rem;
}

.custom_file_input .form-file .form-control {
  margin: 0;
  border: 0;
  line-height: 2.125rem;
  border-radius: inherit;
  font-size: 16px;
  font-weight: 600;
}

.custom_file_input .form-control {
  padding-left: 12px;
  line-height: 45px;
}

#username {
  border-radius: 0 0.5rem 0.5rem 0;
}

.form-control-lg {
  min-height: 3.75rem;
  padding: 0.5rem 1.1rem;
  font-size: 1.09375rem;
  border-radius: 0.5rem;
  height: calc(1.5em + 1rem + 2px);
}

.form-control-sm {
  min-height: 2.5rem;
  padding: 0.25rem 1.1rem;
  font-size: 0.76563rem;
  border-radius: 0.5rem;
  height: calc(1.5em + 0.5rem + 2px);
}

.ck.ck-editor__main>.ck-editor__editable {
  background: #fafafa !important;
}

.modal-grid {
  background: #F0F0F0;
}

.nice-select.wide.form-control-lg {
  line-height: 2.7125rem;
}

.nice-select.wide.form-control-sm {
  line-height: 1.8125rem;
}

.inline-check {
  line-height: 1.8rem !important;
}

.form-check {
  font-size: 14px;
  font-weight: 600;
}

.input-full {
  border: 0;
}

.border-1 {
  border-radius: 0.5rem 0 0 0.5rem !important;
}

.border3 {
  border-radius: 0 0.5rem 0.5rem 0 !important;
}

.custome-input .input-group-text {
  border-color: transparent !important;
}

.right-radius {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.left-radius {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

:root {
  --primary: #453773;
  --secondary: #4CBC9A;
  --secondary-dark: #3a9b7e;
  --primary-hover: #ff3c26;
  --primary-dark: #bf1400;
  --rgba-primary-1: rgba(255, 106, 89, 0.1);
  --rgba-primary-2: rgba(255, 106, 89, 0.2);
  --rgba-primary-3: rgba(255, 106, 89, 0.3);
  --rgba-primary-4: rgba(255, 106, 89, 0.4);
  --rgba-primary-5: rgba(255, 106, 89, 0.5);
  --rgba-primary-6: rgba(255, 106, 89, 0.6);
  --rgba-primary-7: rgba(255, 106, 89, 0.7);
  --rgba-primary-8: rgba(255, 106, 89, 0.8);
  --rgba-primary-9: rgba(255, 106, 89, 0.9);
  --rgba-secondary-1: rgba(76, 188, 154, 0.1);
  --rgba-secondary-2: rgba(76, 188, 154, 0.2);
  --rgba-secondary-3: rgba(76, 188, 154, 0.3);
  --rgba-secondary-4: rgba(76, 188, 154, 0.4);
  --rgba-secondary-5: rgba(76, 188, 154, 0.5);
  --rgba-secondary-6: rgba(76, 188, 154, 0.6);
  --rgba-secondary-7: rgba(76, 188, 154, 0.7);
  --rgba-secondary-8: rgba(76, 188, 154, 0.8);
  --rgba-secondary-9: rgba(76, 188, 154, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #374557;
}

/* input[type="checkbox"] {
    &:after{
        content: '';
        display: block;
        width: 1rem;
        height: 1rem;
        margin-top: 0px;
        margin-left: -1px;
        border: 1px solid transparent;
        border-radius: 3px;
        background: #d4d7da;
        line-height: 1.3;
    }

    &:checked:after{
        width: 1rem;
        height: 1rem;
        display: block;
        content: "\f00c";
        font-family: 'FontAwesome';
        color: $white;
        font-weight: 100;
        font-size: 12px;
        text-align: center;
        border-radius: 3px;
        background: $primary;
    }

} */
.form-check-label {
  margin-left: 0.3125rem;
  margin-top: 0.1875rem;
}

.form-check-inline .form-check-input {
  margin-right: 0.625rem;
}

.form-check-input {
  top: 0.125rem;
  border-width: 0.125rem;
  width: 1.25rem;
  height: 1.25rem;
  border-color: #e7e7e7;
}

.rtl .form-check-label:before, .rtl .form-check-label:after {
  right: -1.5rem !important;
  left: inherit;
}

.form-check {
  line-height: normal;
}

.toggle-switch {
  padding-left: 3.125rem;
  line-height: 1.25;
  display: inline-block;
  color: #000;
  font-weight: 600;
}

.toggle-switch .form-check-input {
  border: 0;
  cursor: pointer;
  background: #d8d8d8;
  width: 2.3125rem;
  border-radius: 1.25rem !important;
  height: 0.875rem;
  position: relative;
  left: -0.3125rem;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  background-image: none;
}

.toggle-switch .form-check-input:focus {
  background-image: none !important;
}

.toggle-switch .form-check-input:checked {
  background: var(--rgba-primary-2);
  background-image: none !important;
}

.toggle-switch .form-check-input:checked:after {
  left: 1.25rem;
  background: var(--primary);
}

.toggle-switch .form-check-input:focus {
  box-shadow: none;
}

.toggle-switch .form-check-input:after {
  width: 1.25rem;
  background: #909090;
  height: 1.25rem;
  content: "";
  position: absolute;
  border-radius: 1.5rem;
  top: -0.1875rem;
  left: 0;
  box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.toggle-switch.text-end {
  padding-right: 0rem;
  padding-left: 0;
}

.toggle-switch.text-end .form-check-input {
  left: auto;
  margin-left: 0;
  float: right;
  right: 0rem;
}

.toggle-switch.text-end .form-check-label {
  margin-right: 0.9375rem;
  margin-left: 0;
}

.toggle-switch .form-check-label {
  cursor: pointer;
}

.form-check-input:focus~.form-check-label::before {
  box-shadow: none !important;
}

.form-check-label::before {
  background-color: transparent;
  border-color: #c8c8c8;
  border-width: 0.125rem;
  border-radius: 0.125rem !important;
}

[data-theme-version="dark"] .form-check-label::before {
  background-color: transparent;
  border-color: #333754;
}

.check-xs .form-check-input {
  width: 1.125rem;
  height: 1.125rem;
}

.check-lg .form-check-input {
  width: 1.5rem;
  height: 1.5rem;
}

.check-xl .form-check-input {
  width: 1.75rem;
  height: 1.75rem;
}

.checkbox-info .form-check-input:focus {
  border-color: #58bad7;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(88, 186, 215, 0.25);
}

.checkbox-info .form-check-input:checked {
  background-color: #58bad7;
  border-color: #58bad7;
}

[data-theme-version="dark"] .checkbox-info .form-check-input:checked {
  background-color: rgba(88, 186, 215, 0.1);
  border-color: transparent;
}

.checkbox-danger .form-check-input:focus {
  border-color: #f75a5b;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(247, 90, 91, 0.25);
}

.checkbox-danger .form-check-input:checked {
  background-color: #f75a5b;
  border-color: #f75a5b;
}

[data-theme-version="dark"] .checkbox-danger .form-check-input:checked {
  background-color: rgba(247, 90, 91, 0.15);
  border-color: transparent;
}

.checkbox-success .form-check-input:focus {
  border-color: #56c760;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(86, 199, 96, 0.25);
}

.checkbox-success .form-check-input:checked {
  background-color: #56c760;
  border-color: #56c760;
}

[data-theme-version="dark"] .checkbox-success .form-check-input:checked {
  background-color: rgba(86, 199, 96, 0.1);
  border-color: transparent;
}

.checkbox-warning .form-check-input:focus {
  border-color: #f0a901;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(240, 169, 1, 0.25);
}

.checkbox-warning .form-check-input:checked {
  background-color: #f0a901;
  border-color: #f0a901;
}

[data-theme-version="dark"] .checkbox-warning .form-check-input:checked {
  background-color: rgba(240, 169, 1, 0.1);
  border-color: transparent;
}

.checkbox-secondary .form-check-input:focus {
  border-color: #4CBC9A;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(76, 188, 154, 0.25);
}

.checkbox-secondary .form-check-input:checked {
  background-color: #4CBC9A;
  border-color: #4CBC9A;
}

[data-theme-version="dark"] .checkbox-secondary .form-check-input:checked {
  background-color: rgba(76, 188, 154, 0.5);
  border-color: transparent;
}

.check-switch {
  padding-left: 2.5rem;
  /* .form-check-input:checked ~ .form-check-label::after{
		background-image:url('../images/svg/check.svg');
	} */
}

.check-switch .form-check-label {
  line-height: 1.875rem;
  font-weight: 500;
}

.check-switch .form-check-label span {
  line-height: 1;
}

.check-switch .form-check-label:after, .check-switch .form-check-label:before {
  height: 1.5rem;
  width: 1.5rem;
  left: -2rem;
  border-radius: 3rem !important;
  border-color: var(--rgba-primary-3);
}

.check-switch .form-check-input:checked~.form-check-label::before {
  background: #fff;
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.form-check-input:focus {
  border-color: var(--primary);
  box-shadow: var(--rgba-primary-1);
}

.js-switch+.switchery {
  border-radius: 3.125rem;
  margin-right: 4rem;
}

@media (max-width: 767.98px) {
  .js-switch+.switchery {
    margin-right: 1rem;
  }
}

.js-switch+.switchery>small, .js-switch+.switchery>.small {
  top: 0.125rem;
}

.js-switch.js-switch-lg+.switchery {
  height: 2rem;
  width: 4.5rem;
}

.js-switch.js-switch-lg+.switchery>small, .js-switch.js-switch-lg+.switchery>.small {
  width: 1.75rem;
  height: 1.75rem;
}

.js-switch.js-switch-md+.switchery {
  height: 1.5rem;
  width: 3.5rem;
}

.js-switch.js-switch-md+.switchery>small, .js-switch.js-switch-md+.switchery>.small {
  width: 1.25rem;
  height: 1.25rem;
}

.js-switch.js-switch-sm+.switchery {
  height: 1rem;
  width: 2.2rem;
}

.js-switch.js-switch-sm+.switchery>small, .js-switch.js-switch-sm+.switchery>.small {
  width: 0.875rem;
  height: 0.875rem;
  top: 0.0625rem;
}

.js-switch-square+.switchery {
  border-radius: 0;
}

.js-switch-square+.switchery>small, .js-switch-square+.switchery>.small {
  border-radius: 0;
  top: 0.125rem;
}

.js-switch-square.js-switch-lg+.switchery {
  height: 2rem;
  width: 4.5rem;
}

.js-switch-square.js-switch-lg+.switchery>small, .js-switch-square.js-switch-lg+.switchery>.small {
  width: 1.75rem;
  height: 1.75rem;
}

.js-switch-square.js-switch-md+.switchery {
  height: 1.5rem;
  width: 3.5rem;
}

.js-switch-square.js-switch-md+.switchery>small, .js-switch-square.js-switch-md+.switchery>.small {
  width: 1.25rem;
  height: 1.25rem;
}

.js-switch-square.js-switch-sm+.switchery {
  height: 1rem;
  width: 2.2rem;
}

.js-switch-square.js-switch-sm+.switchery>small, .js-switch-square.js-switch-sm+.switchery>.small {
  width: 0.875rem;
  height: 0.875rem;
  top: 0.0625rem;
}

.form-control.is-valid {
  border-color: #56c760 !important;
  border-right: 0rem !important;
}

.form-control.is-valid:focus {
  box-shadow: none;
}

.form-control.is-warning {
  border-color: #f0a901 !important;
  border-right: 0rem !important;
}

.form-control.is-warning:focus {
  box-shadow: none;
}

.form-control.is-invalid {
  border-color: #f75a5b !important;
  border-right: 0rem !important;
}

.form-control.is-invalid:focus {
  box-shadow: none;
}

.is-valid .input-group-prepend .input-group-text i {
  color: #56c760;
}

.is-invalid .input-group-prepend .input-group-text i {
  color: var(--rgba-primary-2);
}

.show-pass {
  cursor: pointer;
}

.show-pass .fa-eye {
  display: none;
}

.show-pass.active .fa-eye-slash {
  display: none;
}

.show-pass.active .fa-eye {
  display: inline-block;
}

.asColorPicker-dropdown {
  max-width: 26rem;
}

.asColorPicker-trigger {
  border: 0 none;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 2.1875rem;
}

[direction="rtl"] .asColorPicker-trigger {
  left: 0;
  right: auto;
}

.asColorPicker-clear {
  display: none;
  position: absolute;
  right: 1rem;
  text-decoration: none;
  top: .5rem;
}

.daterangepicker td.active {
  background-color: var(--primary);
}

.daterangepicker td.active:hover {
  background-color: var(--primary);
}

.daterangepicker button.applyBtn {
  background-color: var(--primary);
  border-color: var(--primary);
}

.datepicker.datepicker-dropdown {
  background: #f2f4fa;
  border-radius: 0.0625rem;
  border: 0.0625rem solid #EEEEEE;
}

.datepicker.datepicker-dropdown td.day, .datepicker.datepicker-dropdown th.next, .datepicker.datepicker-dropdown th.prev {
  height: 1.875rem;
  width: 1.875rem !important;
  padding: 0;
  text-align: center;
  font-weight: 300;
  border-radius: 3.125rem;
}

.datepicker.datepicker-dropdown td.day:hover, .datepicker.datepicker-dropdown th.next:hover, .datepicker.datepicker-dropdown th.prev:hover {
  box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
  color: #fff;
}

.datepicker.datepicker-dropdown th.datepicker-switch, .datepicker.datepicker-dropdown th.next, .datepicker.datepicker-dropdown th.prev {
  font-weight: 300;
  color: #333;
}

.datepicker.datepicker-dropdown th.dow {
  font-weight: 300;
}

.datepicker table tr td.selected, .datepicker table tr td.active.active {
  box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
  border: 0;
}

.datepicker table tr td.today {
  box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
  color: #ffffff;
}

.datepicker table tr td.today:hover {
  box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
  color: #ffffff;
}

.datepicker table tr td.today.disabled {
  box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
  color: #ffffff;
}

.datepicker table tr td.today.disabled:hover {
  box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
  color: #ffffff;
}

.picker__select--month, .picker__select--year {
  height: 2.5em;
}

.picker__input {
  background-color: transparent !important;
}

[data-theme-version="dark"] .picker__input {
  background-color: transparent !important;
  border: 0.0625rem solid #333754;
}

.asColorPicker-wrap .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.as_colorpicker.form-control {
  height: 3.5rem;
}

.color-gradian-tixia .gpw {
  padding: 0;
}

.color-gradian-tixia .gpw .trigger .inner {
  height: 3rem;
  width: 5rem;
}

.picker-data .color-time-picker .react-time-picker {
  width: 100%;
}

.picker-data .color-time-picker .react-time-picker__wrapper {
  color: #7e7e7e;
  border: 0;
}

.picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__clear-button {
  display: none;
}

.picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__inputGroup {
  border: 0.0625rem solid #DBDBDB;
  padding: 0rem 1.25rem;
  height: 3.5rem;
  border-radius: 1rem 0 0 1rem;
}

.picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__inputGroup__input {
  color: #7e7e7e;
}

.picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__inputGroup__amPm {
  color: #7e7e7e;
  appearance: none;
}

.picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__clock-button {
  background: #453773;
  color: #b7b7b7;
  padding: .532rem .75rem;
  border-radius: 0 0.75rem 0.75rem 0;
  min-width: 50px;
  text-align: center;
  line-height: 1;
}

.picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__clock-button svg {
  stroke: #fff;
  display: inline-block;
}

.picker-data .color-time-picker .react-clock__face {
  background: #ededed;
  padding: 10px 10px;
  border: 10px solid #ededed;
}

.picker-data .color-time-picker .react-clock__hand__body {
  background-color: #453773;
}

.picker-data .color-time-picker .react-time-picker__clock {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  border: 0 !important;
}

.picker-data .color-time-picker.style-1 .react-time-picker__wrapper .react-time-picker__clock-button {
  display: none;
}

.picker-data .color-time-picker.style-1 .react-time-picker__wrapper .react-time-picker__inputGroup {
  border-radius: 1rem;
}

#image {
  max-width: 100%;
}

.docs-options .dropdown-menu {
  padding: 1.5rem;
}

.docs-preview {
  margin-bottom: 3rem;
}

.docs-preview .img-preview {
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  overflow: hidden;
  max-width: 100% !important;
}

.docs-preview .img-preview>img {
  max-width: 100% !important;
}

.docs-preview .img-preview.preview-lg {
  width: 16rem;
  height: 9rem;
}

.docs-preview .img-preview.preview-md {
  width: 8rem;
  height: 4.5rem;
}

.docs-preview .img-preview.preview-sm {
  width: 4rem;
  height: 2.25rem;
}

.docs-preview .img-preview.preview-xs {
  width: 2rem;
  height: 1.125rem;
  margin-right: 0;
}

.select2-container {
  width: 100% !important;
}

.select2-container--default .select2-selection--single {
  border-radius: 0.625rem;
  border: 0.0625rem solid #c8c8c8;
  height: 2.5rem;
  background: #fff;
}

[data-theme-version="dark"] .select2-container--default .select2-selection--single {
  background: #20203c;
  border-color: #333754;
}

.select2-container--default .select2-selection--single:hover, .select2-container--default .select2-selection--single:focus, .select2-container--default .select2-selection--single.active {
  box-shadow: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 2.5rem;
  color: #777777;
  padding-left: 0.9375rem;
  min-height: 2.5rem;
}

.select2-container--default .select2-selection--multiple {
  border-color: #DBDBDB;
  border-radius: 0;
}

.select2-dropdown {
  border-radius: 0;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: var(--primary);
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #c8c8c8;
  background: #fff;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 0.375rem;
  right: 0.9375rem;
}

.select2-container .select2-selection--multiple {
  min-height: 2.5rem;
  color: #777777;
  border-radius: 0.625rem;
  border: 0.0625rem solid #c8c8c8;
}

[data-theme-version="dark"] .select2-container .select2-selection--multiple {
  background: #20203c;
  border-color: #333754;
}

[data-theme-version="dark"] .select2-search--dropdown .select2-search__field {
  background: #292d4a;
  border-color: #333754;
}

.select2-dropdown {
  border-color: #c8c8c8;
}

[data-theme-version="dark"] .select2-dropdown {
  background: #20203c;
  border-color: #333754;
}

.swal2-popup .swal2-content {
  color: #777777;
}

:root {
  --primary: #453773;
  --secondary: #4CBC9A;
  --secondary-dark: #3a9b7e;
  --primary-hover: #ff3c26;
  --primary-dark: #bf1400;
  --rgba-primary-1: rgba(255, 106, 89, 0.1);
  --rgba-primary-2: rgba(255, 106, 89, 0.2);
  --rgba-primary-3: rgba(255, 106, 89, 0.3);
  --rgba-primary-4: rgba(255, 106, 89, 0.4);
  --rgba-primary-5: rgba(255, 106, 89, 0.5);
  --rgba-primary-6: rgba(255, 106, 89, 0.6);
  --rgba-primary-7: rgba(255, 106, 89, 0.7);
  --rgba-primary-8: rgba(255, 106, 89, 0.8);
  --rgba-primary-9: rgba(255, 106, 89, 0.9);
  --rgba-secondary-1: rgba(76, 188, 154, 0.1);
  --rgba-secondary-2: rgba(76, 188, 154, 0.2);
  --rgba-secondary-3: rgba(76, 188, 154, 0.3);
  --rgba-secondary-4: rgba(76, 188, 154, 0.4);
  --rgba-secondary-5: rgba(76, 188, 154, 0.5);
  --rgba-secondary-6: rgba(76, 188, 154, 0.6);
  --rgba-secondary-7: rgba(76, 188, 154, 0.7);
  --rgba-secondary-8: rgba(76, 188, 154, 0.8);
  --rgba-secondary-9: rgba(76, 188, 154, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #374557;
}

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
.form-wizard {
  border: 0;
}

.form-wizard .nav-wizard {
  box-shadow: none !important;
  margin-bottom: 2rem;
}

.form-wizard .nav-wizard #RFS-ConnectorContainer {
  display: none;
}

.form-wizard .nav-wizard .nav-link {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent !important;
}

.form-wizard .nav-wizard .nav-link span {
  border-radius: 3.125rem;
  width: 3rem;
  height: 3rem;
  border: 0.125rem solid var(--primary);
  display: block;
  line-height: 3rem;
  color: var(--primary);
  font-size: 1.125rem;
  margin: auto;
  background-color: #fff;
  position: relative;
  z-index: 1;
}

.form-wizard .nav-wizard .nav-link:after {
  content: "" !important;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 0.1875rem;
  transform: translateY(-50%);
  background: #eeeeee !important;
  z-index: 0;
  width: 100%;
}

.form-wizard .nav-wizard .nav-link.active span {
  background: var(--primary);
  color: #fff;
}

.form-wizard .nav-wizard .nav-link.active:after {
  background: var(--primary) !important;
}

.form-wizard .nav-wizard .nav-link.completed span {
  background-color: var(--primary);
  color: #fff;
}

.form-wizard .nav-wizard .nav-link.completed:after {
  background: var(--primary) !important;
}

.form-wizard .nav-wizard>div:last-child .nav-link:after {
  display: none;
}

.form-wizard .toolbar-bottom .btn {
  border: 0;
  padding: 0.75rem 1.125rem;
}

.form-wizard .tab-content .tab-pane {
  padding: 0;
}

.form-wizard .emial-setup label.mailclinet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: #EEF5F9;
  text-align: center;
  margin: auto;
}

[data-theme-version="dark"] .form-wizard .emial-setup label.mailclinet {
  background-color: #20203c;
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .emial-setup label.mailclinet {
    width: 7rem;
    height: 7rem;
  }
}

.form-wizard .emial-setup label.mailclinet .mail-icon {
  font-size: 3rem;
  display: inline-block;
  line-height: 1;
  margin-top: -1rem;
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .emial-setup label.mailclinet .mail-icon {
    font-size: 2rem;
  }
}

.form-wizard .emial-setup label.mailclinet .mail-text {
  font-size: 1rem;
  text-align: center;
  margin-top: .5rem;
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .emial-setup label.mailclinet .mail-text {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.form-wizard .emial-setup label.mailclinet input[type="radio"] {
  display: none;
}

.form-wizard .emial-setup label.mailclinet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: #EEF5F9;
  text-align: center;
  margin: auto;
}

[data-theme-version="dark"] .form-wizard .emial-setup label.mailclinet {
  background-color: #20203c;
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .emial-setup label.mailclinet {
    width: 7rem;
    height: 7rem;
  }
}

.form-wizard .emial-setup label.mailclinet .mail-icon {
  font-size: 3rem;
  display: inline-block;
  line-height: 1;
  margin-top: -1rem;
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .emial-setup label.mailclinet .mail-icon {
    font-size: 2rem;
  }
}

.form-wizard .emial-setup label.mailclinet .mail-text {
  font-size: 1rem;
  text-align: center;
  margin-top: .5rem;
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .emial-setup label.mailclinet .mail-text {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.form-wizard .emial-setup label.mailclinet input[type="radio"] {
  display: none;
}

@media only screen and (max-width: 47.9375rem) {
  .form-wizard .nav-wizard {
    flex-direction: unset !important;
  }

  .form-wizard .tab-content {
    height: 100% !important;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .nav-wizard li .nav-link {
    padding: 0;
  }
}

.wizard-style-one {
  border: 0;
  padding: 0 100px;
}

.wizard-style-one .nav-wizard {
  margin-bottom: 40px;
  box-shadow: none;
}

.wizard-style-one label {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.wizard-style-one .tab-content {
  overflow: visible;
}

.custom-ekeditor ul {
  padding-left: 1.25rem;
}

.custom-ekeditor ul li {
  list-style: unset;
}

.custom-ekeditor ol li {
  list-style: decimal;
}

.ql-container {
  height: 25rem;
}

#world-datamap {
  padding-bottom: 46% !important;
}

.datamaps-hoverover {
  background: #fff;
  padding: 0.3125rem;
  border-radius: 0.3125rem;
  font-family: 'Roboto' !important;
  color: var(--primary);
  border: 0.0625rem solid var(--rgba-primary-3);
}

@media only screen and (max-width: 90rem) {
  .world_map_card ul.list-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 2.1875rem;
  }
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  height: 1.25rem;
  width: 1.25rem;
  line-height: 0.875rem;
  background-color: var(--primary);
  color: #fff;
}

.jqvmap-zoomout {
  top: 2.1875rem;
}

#world-map, #usa {
  height: 25rem;
}

@media only screen and (max-width: 61.9375rem) {
  #world-map, #usa {
    height: 21.875rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  #world-map, #usa {
    height: 19.375rem;
  }
}

.blockUI.blockMsg.blockPage {
  border: 0 !important;
}

#loginForm {
  cursor: auto;
}

.blockMsg {
  border: 0rem;
  width: 20% !important;
}

.blockMsg h1, .blockMsg .h1 {
  font-size: 1rem;
  padding: 0.5rem 0;
  margin-bottom: 0;
}

.bootstrap-select {
  margin-bottom: 0;
}

.bootstrap-select .btn {
  border: 1px solid #DBDBDB !important;
  background-color: transparent !important;
  font-weight: 400;
  color: #777777 !important;
}

[data-theme-version="dark"] .bootstrap-select .btn {
  border-color: #333754 !important;
  background: #20203c !important;
}

.bootstrap-select .btn:active, .bootstrap-select .btn:focus, .bootstrap-select .btn:hover {
  outline: none !important;
  outline-offset: 0;
}

[data-theme-version="dark"] .bootstrap-select .btn:active, [data-theme-version="dark"] .bootstrap-select .btn:focus, [data-theme-version="dark"] .bootstrap-select .btn:hover {
  color: #777777 !important;
}

.bootstrap-select .dropdown-menu {
  border-color: #DBDBDB !important;
  box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
}

.bootstrap-select .dropdown-menu .dropdown-item {
  padding: 0.25rem 1rem;
}

[data-theme-version="dark"] .bootstrap-select .dropdown-menu {
  border-color: #DBDBDB !important;
}

.input-group>.bootstrap-select:not(:first-child) .dropdown-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group>.bootstrap-select:not(:last-child) .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

:root {
  --primary: #453773;
  --secondary: #4CBC9A;
  --secondary-dark: #3a9b7e;
  --primary-hover: #ff3c26;
  --primary-dark: #bf1400;
  --rgba-primary-1: rgba(255, 106, 89, 0.1);
  --rgba-primary-2: rgba(255, 106, 89, 0.2);
  --rgba-primary-3: rgba(255, 106, 89, 0.3);
  --rgba-primary-4: rgba(255, 106, 89, 0.4);
  --rgba-primary-5: rgba(255, 106, 89, 0.5);
  --rgba-primary-6: rgba(255, 106, 89, 0.6);
  --rgba-primary-7: rgba(255, 106, 89, 0.7);
  --rgba-primary-8: rgba(255, 106, 89, 0.8);
  --rgba-primary-9: rgba(255, 106, 89, 0.9);
  --rgba-secondary-1: rgba(76, 188, 154, 0.1);
  --rgba-secondary-2: rgba(76, 188, 154, 0.2);
  --rgba-secondary-3: rgba(76, 188, 154, 0.3);
  --rgba-secondary-4: rgba(76, 188, 154, 0.4);
  --rgba-secondary-5: rgba(76, 188, 154, 0.5);
  --rgba-secondary-6: rgba(76, 188, 154, 0.6);
  --rgba-secondary-7: rgba(76, 188, 154, 0.7);
  --rgba-secondary-8: rgba(76, 188, 154, 0.8);
  --rgba-secondary-9: rgba(76, 188, 154, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #374557;
}

.clipboard-btn {
  transition: all .1s ease-in-out;
}

.clipboard-btn:hover {
  background-color: var(--primary);
  color: #fff;
}

:root {
  --primary: #453773;
  --secondary: #4CBC9A;
  --secondary-dark: #3a9b7e;
  --primary-hover: #ff3c26;
  --primary-dark: #bf1400;
  --rgba-primary-1: rgba(255, 106, 89, 0.1);
  --rgba-primary-2: rgba(255, 106, 89, 0.2);
  --rgba-primary-3: rgba(255, 106, 89, 0.3);
  --rgba-primary-4: rgba(255, 106, 89, 0.4);
  --rgba-primary-5: rgba(255, 106, 89, 0.5);
  --rgba-primary-6: rgba(255, 106, 89, 0.6);
  --rgba-primary-7: rgba(255, 106, 89, 0.7);
  --rgba-primary-8: rgba(255, 106, 89, 0.8);
  --rgba-primary-9: rgba(255, 106, 89, 0.9);
  --rgba-secondary-1: rgba(76, 188, 154, 0.1);
  --rgba-secondary-2: rgba(76, 188, 154, 0.2);
  --rgba-secondary-3: rgba(76, 188, 154, 0.3);
  --rgba-secondary-4: rgba(76, 188, 154, 0.4);
  --rgba-secondary-5: rgba(76, 188, 154, 0.5);
  --rgba-secondary-6: rgba(76, 188, 154, 0.6);
  --rgba-secondary-7: rgba(76, 188, 154, 0.7);
  --rgba-secondary-8: rgba(76, 188, 154, 0.8);
  --rgba-secondary-9: rgba(76, 188, 154, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #374557;
}

.bootstrap-tagsinput {
  flex: 1;
  padding: 0;
  padding-left: .5rem;
  border-radius: 0;
  border-color: #DBDBDB;
  background-color: #fff;
  width: 100%;
}

.bootstrap-tagsinput .tag {
  font-size: 0.875rem;
  background-color: var(--primary);
  border-radius: 0;
  align-items: center;
  border: 0.0625rem solid #DBDBDB;
}

.bootstrap-tagsinput input[type="text"] {
  flex: 1;
  height: 2.1rem;
  border-radius: 0;
  border-color: #DBDBDB;
}

.bootstrap-tagsinput label {
  margin-bottom: .5rem;
}

:root {
  --primary: #453773;
  --secondary: #4CBC9A;
  --secondary-dark: #3a9b7e;
  --primary-hover: #ff3c26;
  --primary-dark: #bf1400;
  --rgba-primary-1: rgba(255, 106, 89, 0.1);
  --rgba-primary-2: rgba(255, 106, 89, 0.2);
  --rgba-primary-3: rgba(255, 106, 89, 0.3);
  --rgba-primary-4: rgba(255, 106, 89, 0.4);
  --rgba-primary-5: rgba(255, 106, 89, 0.5);
  --rgba-primary-6: rgba(255, 106, 89, 0.6);
  --rgba-primary-7: rgba(255, 106, 89, 0.7);
  --rgba-primary-8: rgba(255, 106, 89, 0.8);
  --rgba-primary-9: rgba(255, 106, 89, 0.9);
  --rgba-secondary-1: rgba(76, 188, 154, 0.1);
  --rgba-secondary-2: rgba(76, 188, 154, 0.2);
  --rgba-secondary-3: rgba(76, 188, 154, 0.3);
  --rgba-secondary-4: rgba(76, 188, 154, 0.4);
  --rgba-secondary-5: rgba(76, 188, 154, 0.5);
  --rgba-secondary-6: rgba(76, 188, 154, 0.6);
  --rgba-secondary-7: rgba(76, 188, 154, 0.7);
  --rgba-secondary-8: rgba(76, 188, 154, 0.8);
  --rgba-secondary-9: rgba(76, 188, 154, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #374557;
}

.twitter-typeahead {
  width: 100%;
}

.twitter-typeahead .tt-dataset.tt-dataset-states {
  border: 1px solid #DBDBDB;
}

.twitter-typeahead .tt-menu {
  width: 100%;
  background-color: #fff;
}

.twitter-typeahead .tt-menu .tt-suggestion {
  padding: 0.625rem;
  cursor: pointer;
}

.twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: var(--primary);
  color: #fff;
}

.weather-one i {
  font-size: 8rem;
  position: relative;
  top: .5rem;
}

.weather-one h2, .weather-one .h2 {
  display: inline-block;
  float: right;
  font-size: 4.8rem;
}

.weather-one .city {
  position: relative;
  text-align: right;
  top: -2.5rem;
}

.weather-one .currently {
  font-size: 1.6rem;
  font-weight: 400;
  position: relative;
  top: 2.5rem;
}

.weather-one .celcious {
  text-align: right;
  font-size: 2rem;
}

:root {
  --primary: #453773;
  --secondary: #4CBC9A;
  --secondary-dark: #3a9b7e;
  --primary-hover: #ff3c26;
  --primary-dark: #bf1400;
  --rgba-primary-1: rgba(255, 106, 89, 0.1);
  --rgba-primary-2: rgba(255, 106, 89, 0.2);
  --rgba-primary-3: rgba(255, 106, 89, 0.3);
  --rgba-primary-4: rgba(255, 106, 89, 0.4);
  --rgba-primary-5: rgba(255, 106, 89, 0.5);
  --rgba-primary-6: rgba(255, 106, 89, 0.6);
  --rgba-primary-7: rgba(255, 106, 89, 0.7);
  --rgba-primary-8: rgba(255, 106, 89, 0.8);
  --rgba-primary-9: rgba(255, 106, 89, 0.9);
  --rgba-secondary-1: rgba(76, 188, 154, 0.1);
  --rgba-secondary-2: rgba(76, 188, 154, 0.2);
  --rgba-secondary-3: rgba(76, 188, 154, 0.3);
  --rgba-secondary-4: rgba(76, 188, 154, 0.4);
  --rgba-secondary-5: rgba(76, 188, 154, 0.5);
  --rgba-secondary-6: rgba(76, 188, 154, 0.6);
  --rgba-secondary-7: rgba(76, 188, 154, 0.7);
  --rgba-secondary-8: rgba(76, 188, 154, 0.8);
  --rgba-secondary-9: rgba(76, 188, 154, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #374557;
}

.noUi-target {
  border-color: transparent;
  border-radius: 0;
}

.noUi-connect {
  background-color: var(--primary);
}

.noUi-connects {
  background-color: #D2D6DE;
}

.noUi-connect.c-1-color {
  background-color: #56c760;
}

.noUi-connect.c-2-color {
  background-color: #58bad7;
}

.noUi-connect.c-3-color {
  background-color: var(--primary);
}

.noUi-connect.c-4-color {
  background-color: #f0a901;
}

.noUi-vertical {
  width: 0.375rem;
}

.noUi-horizontal {
  height: 0.125rem;
  border: 0;
  margin-bottom: 0.625rem;
}

.noUi-horizontal .noUi-handle, .noUi-vertical .noUi-handle {
  height: 0.9375rem;
  width: 0.9375rem;
  border-radius: 3.125rem;
  box-shadow: none;
  border: none;
  background-color: var(--primary);
}

.noUi-horizontal .noUi-handle::after, .noUi-horizontal .noUi-handle::before, .noUi-vertical .noUi-handle::after, .noUi-vertical .noUi-handle::before {
  display: none;
}

.noUi-vertical .noUi-handle {
  left: -0.25rem;
  top: -0.375rem;
}

.noUi-horizontal .noUi-handle {
  top: -0.4375rem;
  cursor: pointer;
}

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -0.375rem;
}

#slider-toggle {
  height: 3.125rem;
}

#slider-toggle.off .noUi-handle {
  border-color: var(--primary);
}

.colorpicker-slider .sliders.noUi-target#red, .colorpicker-slider .sliders.noUi-target#green, .colorpicker-slider .sliders.noUi-target#blue {
  margin: 0.625rem;
  display: inline-block;
  height: 12.5rem;
}

.colorpicker-slider .sliders.noUi-target#red .noUi-connect {
  background: #c0392b;
}

.colorpicker-slider .sliders.noUi-target#green .noUi-connect {
  background: #27ae60;
}

.colorpicker-slider .sliders.noUi-target#blue .noUi-connect {
  background: #2980b9;
}

.colorpicker-slider #result {
  margin: 3.75rem 1.625rem;
  height: 6.25rem;
  width: 6.25rem;
  display: inline-block;
  vertical-align: top;
  color: #7f7f7f;
  background: #7f7f7f;
  border: 0.0625rem solid #fff;
  box-shadow: 0 0 0.625rem;
}

.slider-vertical {
  height: 18rem;
}

.nestable-cart {
  overflow: hidden;
}

.dd-handle {
  color: #fff;
  background: var(--secondary);
  border-radius: 0.3125rem;
  padding: 0.5rem 1rem;
  height: auto;
  border: 1px solid var(--secondary);
}

.dd-handle:hover {
  color: #fff;
  background: var(--secondary);
}

.dd3-content:hover {
  color: #fff;
  background: var(--primary);
}

.dd3-content {
  color: #fff;
}

.dd-item>button {
  line-height: 28px;
  color: #fff;
}

.pignose-calendar {
  box-shadow: none;
  width: 100%;
  max-width: none;
  border-color: var(--primary);
}

.pignose-calendar .pignose-calendar-top-date {
  background-color: var(--primary);
}

.pignose-calendar .pignose-calendar-top-date .pignose-calendar-top-month {
  color: #fff;
}

.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: var(--primary);
  box-shadow: none;
}

.pignose-calendar .pignose-calendar-top {
  box-shadow: none;
  border-bottom: 0;
}

.pignose-calendar.pignose-calendar-blue {
  background-color: rgba(0, 0, 0, 0.15);
}

.pignose-calendar .pignose-calendar-unit {
  height: 4.8em;
}

.cd-h-timeline {
  opacity: 0;
  transition: opacity 0.2s;
}

.cd-h-timeline--loaded {
  opacity: 1;
}

.cd-h-timeline__container {
  position: relative;
  height: 6.25rem;
  max-width: 50rem;
}

.cd-h-timeline__dates {
  position: relative;
  height: 100%;
  margin: 0 2.5rem;
  overflow: hidden;
}

.cd-h-timeline__dates::after, .cd-h-timeline__dates::before {
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  height: 100%;
  width: 1.25rem;
}

.cd-h-timeline__dates::before {
  left: 0;
  background: var(--primary);
}

.cd-h-timeline__dates::after {
  right: 0;
  background: var(--primary);
}

.cd-h-timeline__line {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 3.0625rem;
  height: 0.125rem;
  background-color: var(--primary);
  transition: transform 0.4s;
}

.cd-h-timeline__filling-line {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #56c760;
  transform: scaleX(0);
  transform-origin: left center;
  transition: transform 0.3s;
}

.cd-h-timeline__date {
  position: absolute;
  bottom: 0;
  z-index: 2;
  text-align: center;
  font-size: 0.8em;
  padding-bottom: var(--space-sm);
  color: var(--cd-color-1);
  user-select: none;
  text-decoration: none;
}

.cd-h-timeline__date::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.3125rem;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
  border-width: 0.125rem;
  border-style: solid;
  border-color: var(--rgba-primary-6);
  background-color: var(--primary);
  transition: background-color 0.3s, border-color .3s;
}

.cd-h-timeline__date:hover::after {
  background-color: #56c760;
  border-color: #56c760;
}

.cd-h-timeline__date--selected {
  pointer-events: none;
}

.cd-h-timeline__date--selected::after {
  background-color: #56c760;
  border-color: #56c760;
}

.cd-h-timeline__date--older-event::after {
  border-color: #56c760;
}

.cd-h-timeline__navigation {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  height: 2.125rem;
  width: 2.125rem;
  border-radius: 50%;
  border-width: 0.125rem;
  border-style: solid;
  border-color: var(--rgba-primary-6);
  transition: border-color 0.3s;
}

.cd-h-timeline__navigation::after {
  content: '';
  position: absolute;
  height: 1rem;
  width: 1rem;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.cd-h-timeline__navigation:hover {
  border-color: #56c760;
}

.cd-h-timeline__navigation--prev {
  left: 0;
  transform: translateY(-50%) rotate(180deg);
}

.cd-h-timeline__navigation--next {
  right: 0;
}

.cd-h-timeline__navigation--inactive {
  cursor: not-allowed;
}

.cd-h-timeline__navigation--inactive::after {
  background-position: 0 -1rem;
}

.cd-h-timeline__navigation--inactive:hover {
  border-color: var(--rgba-primary-6);
}

.cd-h-timeline__events {
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: height .4s;
}

.cd-h-timeline__event {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  transform: translateX(-100%);
  padding: 0.0625rem 5%;
  opacity: 0;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
}

.cd-h-timeline__event--selected {
  position: relative;
  z-index: 2;
  opacity: 1;
  transform: translateX(0);
}

.cd-h-timeline__event--enter-right,
.cd-h-timeline__event--leave-right {
  animation-name: cd-enter-right;
}

.cd-h-timeline__event--enter-left,
.cd-h-timeline__event--leave-left {
  animation-name: cd-enter-left;
}

.cd-h-timeline__event--leave-right,
.cd-h-timeline__event--leave-left {
  animation-direction: reverse;
}

.cd-h-timeline__event-content {
  max-width: 50rem;
}

.cd-h-timeline__event-title {
  color: var(--cd-color-1);
  font-family: var(--font-secondary);
  font-weight: 700;
  font-size: var(--text-xxxl);
}

.cd-h-timeline__event-date {
  display: block;
  font-style: italic;
  margin: var(--space-xs) auto;
}

.cd-h-timeline__event-date::before {
  content: '- ';
}

@keyframes cd-enter-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes cd-enter-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.toast-success {
  background-color: var(--primary);
}

.toast-info {
  background-color: #58bad7;
}

.toast-warning {
  background-color: #f0a901;
}

.toast-error {
  background-color: #f75a5b;
}

#toast-container>div {
  box-shadow: none;
  border-radius: 0;
  width: auto;
  max-width: 15.625rem;
  opacity: 1;
}

[direction="rtl"] #toast-container>div {
  padding: 0.9375rem 3.125rem 0.9375rem 0.9375rem;
  background-position: calc(100% - 0.9375rem);
  text-align: right;
}

#toast-container>div:hover {
  box-shadow: none;
}

#toast-container .toast-title {
  margin-bottom: 0.3125rem;
  font-weight: 600;
}

#toast-container .toast-message {
  font-size: 0.75rem;
}

#toast-container .toast-close-button {
  opacity: 1;
  font-size: 1.25rem;
  font-weight: normal;
  text-shadow: none;
}

[direction="rtl"] .toast-top-right.demo_rtl_class {
  left: 0.75rem;
  right: auto;
}

/* Light Gallery */
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar {
  background-color: rgba(30, 30, 30, 0.6);
}

.lg-outer .lg-toogle-thumb,
.lg-outer .lg-thumb-outer,
.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  background-color: transparent;
}

.lg-thumb-outer.lg-grab,
.lg-toogle-thumb.lg-icon {
  background-color: rgba(30, 30, 30, 0.6);
}

.lg-backdrop {
  background-color: rgba(30, 30, 30, 0.9);
}

.lg-outer .lg-toogle-thumb,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-toolbar .lg-icon,
#lg-counter {
  color: #fff;
}

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
  border-color: var(--primary);
}

.lightimg {
  cursor: pointer;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  position: absolute;
  left: 0.625rem;
  -webkit-border-radius: 0.1875rem;
  -moz-border-radius: 0.1875rem;
  border-radius: 0.1875rem;
  background: #000000;
  padding: 0.1875rem;
  color: white;
  width: 1.0625rem;
  height: 1.0625rem;
  cursor: pointer;
  line-height: 0.625rem;
  text-align: center;
}

.jqvmap-zoomin {
  top: 0.625rem;
}

.jqvmap-zoomout {
  top: 1.875rem;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus,
.ps__rail-x.ps--clicking,
.ps__rail-y.ps--clicking {
  background-color: transparent;
  opacity: 0.9;
}

.ps__rail-y:hover>.ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #DAE2F3;
  width: 0.25rem;
}

.ps__thumb-y {
  background-color: #DAE2F3;
  width: 0.25rem;
}

.total-average {
  position: relative;
  height: 18.75rem;
}

.widget-chat {
  position: relative;
  height: 15.625rem;
}

.widget-todo {
  position: relative;
  height: 13.125rem;
}

.widget-team {
  position: relative;
  height: 17.8125rem;
}

.widget-timeline {
  position: relative;
}

.widget-comments {
  position: relative;
  height: 25rem;
}

.sidebar-right-inner {
  position: relative;
  height: 100%;
}

.widget-team .ps .ps__rail-x:hover,
.widget-team .ps .ps__rail-y:hover,
.widget-team .ps .ps__rail-x:focus,
.widget-team .ps .ps__rail-y:focus,
.widget-team .ps .ps__rail-x.ps--clicking,
.widget-team .ps .ps__rail-y.ps--clicking {
  background-color: transparent !important;
  opacity: 0.9;
}

.fc-h-event, .fc-v-event {
  background: var(--primary);
  border-radius: .42rem;
}

.fc-h-event .fc-event-title {
  color: #fff;
}

.fc-theme-standard td, .fc-theme-standard th {
  border-color: #ebedf3;
}

.fc-unthemed .fc-h-event, .fc-unthemed .fc-event-dot {
  padding: 0;
  border-radius: .42rem;
}

.fc-theme-standard th {
  padding: .75rem .5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #b5b5c3;
}

@media only screen and (max-width: 35.9375rem) {
  .fc-theme-standard th {
    font-size: 14px;
    font-weight: 400;
    padding: 3px 0px;
  }
}

.fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid, .fc-scrollgrid, table {
  border-color: #ebedf3;
}

.fc-daygrid-dot-event {
  background: #fff;
  border: 1px solid #ebedf3;
  -webkit-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
}

.fc-daygrid-dot-event .fc-daygrid-event-dot {
  border-color: var(--primary);
}

.fc-daygrid-dot-event .fc-event-title {
  font-weight: 500;
}

.fc-event.bg-primary, .fc-event.bg-success, .fc-event.bg-warning, .fc-event.bg-secondary, .fc-event.bg-dark, .fc-event.bg-info {
  color: #fff !important;
  border-radius: 8px;
}

.fc-event.bg-primary .fc-daygrid-event-dot, .fc-event.bg-success .fc-daygrid-event-dot, .fc-event.bg-warning .fc-daygrid-event-dot, .fc-event.bg-secondary .fc-daygrid-event-dot, .fc-event.bg-dark .fc-daygrid-event-dot, .fc-event.bg-info .fc-daygrid-event-dot {
  border-color: #fff;
}

.fc .fc-scroller-liquid-absolute, .fc-scroller {
  position: relative;
  overflow: visible !important;
}

.fc .fc-button-group>.fc-button {
  color: #b5b5c3;
  background: 0 0;
  border: 1px solid #ebedf3;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.fc .fc-button-group>.fc-button:focus, .fc .fc-button-group>.fc-button:active, .fc .fc-button-group>.fc-button:hover, .fc .fc-button-group>.fc-button.fc-button-active {
  background: var(--primary);
  color: #fff;
  border-color: var(--primary);
}

.fc-button.fc-button-primary.fc-today-button {
  background: var(--primary);
  color: #fff;
  border: 0;
  opacity: 1;
}

.fc-unthemed .fc-toolbar .fc-button.fc-button-active, .fc-unthemed .fc-toolbar .fc-button:active, .fc-unthemed .fc-toolbar .fc-button:focus {
  background: var(--primary);
  color: #fff;
  border: 1px solid var(--primary);
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
}

.fc .fc-toolbar-title {
  font-size: 20px;
  margin: 0;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: -0.5em;
}

.external-event {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.external-event:hover:before {
  background: #fff !important;
}

.fc-event {
  overflow: hidden;
}

.fc .fc-view-harness {
  height: 800px !important;
  overflow-y: auto;
}

@media only screen and (max-width: 35.9375rem) {
  .fc .fc-view-harness {
    height: 450px !important;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .fc .fc-toolbar.fc-header-toolbar {
    display: block;
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk {
    display: flex;
    justify-content: center;
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:first-child {
    justify-content: space-between;
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-title {
    margin-bottom: 8px;
  }
}

#external-events .external-event {
  text-align: left;
  font-size: 16px;
}

@media only screen and (max-width: 35.9375rem) {
  .fc.app-fullcalendar .fc-timegrid-slot-label {
    width: 40px !important;
    font-size: 10px;
  }

  .fc.app-fullcalendar .fc-event, .fc.app-fullcalendar .external-event {
    font-size: 10px;
    margin: 0;
    padding: 2px 0;
    text-align: center;
    line-height: 1.3;
  }

  .fc.app-fullcalendar .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 2px 4px;
    font-size: 10px;
  }
}

.ck.ck-editor .ck.ck-toolbar__items .ck.ck-toolbar__separator {
  display: none;
}

.ck.ck-editor .ck.ck-button {
  padding: 0.375rem 0.5rem;
  background-color: #fff;
  color: #374557;
  font-weight: 900;
  border: 0.0625rem solid #F0F0F1;
}

.ck.ck-editor .ck.ck-toolbar {
  background-color: #f7f7f7;
  border: 0;
  padding: 0.3125rem 0.625rem;
}

.ck.ck-editor .ck.ck-toolbar__separator {
  display: none;
}

.ck.ck-editor .ck-content {
  background-color: #f7f7f7;
  border-width: 0.0625rem 0 0;
  border-color: rgba(0, 0, 0, 0.1);
  min-height: 12.5rem;
  border: 0.0625rem solid #F0F0F1 !important;
}

.ck-editor__editable {
  background-color: #f7f7f7 !important;
}

.ck.ck-reset.ck-editor {
  border: 0.0625rem solid #F0F0F1;
}

/*  */
.accordion-button:not(.collapsed) {
  color: inherit;
  background: inherit;
}

.accordion-button.collapsed {
  border-bottom-width: 1px;
}

.accordion-primary .accordion-button {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
  box-shadow: 0 0.9375rem 1.25rem 0 var(--rgba-primary-1);
}

.accordion-primary .accordion-button.collapsed {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
  box-shadow: none;
}

[data-theme-version="dark"] .accordion-primary .accordion-button.collapsed {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: #777777;
}

.accordion-primary-solid .accordion-button {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
  box-shadow: 0 -0.625rem 1.25rem 0 var(--rgba-primary-1);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-primary-solid .accordion-button.collapsed {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
  box-shadow: none;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

[data-theme-version="dark"] .accordion-primary-solid .accordion-button.collapsed {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: #777777;
}

.accordion-primary-solid .accordion__body {
  border: 0.125rem solid var(--primary);
  border-top: none;
  box-shadow: 0 0.9375rem 1.25rem 0 var(--rgba-primary-1);
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.accordion-danger .accordion-button {
  background: #f75a5b;
  border-color: #f75a5b;
  color: #fff;
  box-shadow: 0 0.9375rem 1.25rem 0 rgba(247, 90, 91, 0.15);
}

.accordion-danger .accordion-button.collapsed {
  background: #fcbbbc;
  border-color: #fcbbbc;
  color: #211c37;
  box-shadow: none;
}

.accordion-danger-solid .accordion-button {
  background: #f75a5b;
  border-color: #f75a5b;
  color: #fff;
  box-shadow: 0 -0.625rem 1.25rem 0 rgba(247, 90, 91, 0.15);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-danger-solid .accordion-button.collapsed {
  background: #fcbbbc;
  border-color: #fcbbbc;
  color: #211c37;
  box-shadow: none;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

[data-theme-version="dark"] .accordion-danger-solid .accordion-button.collapsed {
  background: rgba(247, 90, 91, 0.15);
  border-color: rgba(247, 90, 91, 0.15);
  color: #777777;
}

.accordion-danger-solid .accordion__body {
  border: 0.125rem solid #f75a5b;
  border-top: none;
  box-shadow: 0 0.9375rem 1.25rem 0 rgba(247, 90, 91, 0.15);
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.accordion-item {
  margin-bottom: 1.25rem;
}

.accordion-button {
  padding: 1rem 1.75rem;
  border: 0.0625rem solid #DBDBDB;
  cursor: pointer;
  position: relative;
  color: #333;
  font-weight: 400;
  border-radius: 0.625rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

[data-theme-version="dark"] .accordion-button {
  color: #fff !important;
  border-color: #333754;
}

.accordion-button-indicator.indicator_bordered {
  display: inline-block;
  width: 1.5625rem;
  text-align: center;
  height: 1.5625rem;
  border: 0.0625rem solid #DBDBDB;
  border-radius: 50%;
  line-height: 1.5625rem;
}

.accordion-button:not(.collapsed)::after {
  content: "\e622";
  background-image: none;
  transform: rotate(0) translateY(-50%);
}

.accordion-button:not(.collapsed).style_two::after {
  content: "\e648";
}

.accordion-button::after {
  content: "\e61a";
  font-family: 'themify';
  position: absolute;
  right: 1.5625rem;
  top: 50%;
  transform: translateY(-50%);
  background-image: none !important;
  width: auto;
  height: auto;
}

[direction="rtl"] .accordion-button::after {
  right: auto;
  left: 1.5625rem;
}

.accordion-button.collapsed.style_two::after {
  content: "\e64b";
}

.accordion-body {
  padding: 0.875rem 1.25rem;
}

.accordion-collapse {
  border: 0;
}

.accordion-bordered .accordion__body {
  border: 0.0625rem solid #DBDBDB;
  border-top: none;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

[data-theme-version="dark"] .accordion-bordered .accordion__body {
  border-color: #333754;
}

.accordion-bordered .accordion-button.collapsed {
  border-radius: 0.625rem;
}

.accordion-bordered .accordion-button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-no-gutter .accordion-item {
  margin-bottom: 0;
}

.accordion-no-gutter .accordion-item .accordion-button.collapsed {
  border-bottom: none;
}

.accordion-no-gutter .accordion-item:last-child .accordion-button {
  border-bottom: 0.0625rem solid #DBDBDB;
}

[data-theme-version="dark"] .accordion-no-gutter .accordion-item:last-child .accordion-button {
  border-color: #333754;
}

.accordion-no-gutter.accordion__bordered .accordion-item:not(:last-child) .accordion__body {
  border-bottom: none;
}

.accordion-left-indicator .accordion-button-text {
  padding-left: 2.5rem;
}

.accordion-left-indicator .accordion-button-indicator {
  right: auto;
  left: 1.5625rem;
}

.accordion-with-icon .accordion-button-text {
  padding-left: 2.5rem;
}

[direction="rtl"] .accordion-with-icon .accordion-button-text {
  padding-left: 0;
  padding-right: 2.5rem;
}

.accordion-with-icon .accordion-button-icon {
  position: absolute;
  right: auto;
  left: 1.5625rem;
  font-family: 'themify';
}

[direction="rtl"] .accordion-with-icon .accordion-button-icon {
  left: auto;
  right: 1.5625rem;
}

.accordion-with-icon .accordion-button-icon::before {
  content: "\e645";
}

.accordion-header-bg .accordion-button {
  background-color: #c8c8c8;
}

[data-theme-version="dark"] .accordion-header-bg .accordion-button {
  background-color: #20203c;
  color: #fff;
}

.accordion-header-bg .accordion-button-primary {
  background-color: var(--primary);
  color: #fff;
  border-color: var(--primary);
}

[data-theme-version="dark"] .accordion-header-bg .accordion-button-primary {
  background-color: var(--primary);
}

.accordion-header-bg .accordion-button-info {
  background-color: #58bad7;
  color: #fff;
  border-color: #58bad7;
}

[data-theme-version="dark"] .accordion-header-bg .accordion-button-info {
  background-color: #58bad7;
}

.accordion-header-bg .accordion-button-success {
  background-color: #56c760;
  color: #fff;
  border-color: #56c760;
}

[data-theme-version="dark"] .accordion-header-bg .accordion-button-success {
  background-color: #56c760;
}

.accordion-header-bg.accordion-no-gutter .accordion-button {
  border-color: transparent;
  border-radius: 0;
}

.accordion-header-bg.accordion-no-gutter .accordion-item:first-child .accordion-button {
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}

.accordion-header-bg.accordion-no-gutter .accordion-item:last-child .accordion-button {
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.accordion.accordion-no-gutter .accordion-button {
  border-radius: 0;
}

.accordion.accordion-no-gutter .accordion-button.collapsed {
  border-radius: 0;
}

.accordion.accordion-no-gutter .accordion__body {
  border-radius: 0;
}

.accordion.accordion-no-gutter .accordion-item:first-child .accordion-button {
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}

.accordion.accordion-no-gutter .accordion-item:last-child .accordion-button.collapsed {
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.accordion.accordion-no-gutter .accordion-item:last-child .accordion__body {
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.accordion-solid-bg .accordion-button {
  border-color: transparent;
  background-color: var(--rgba-primary-1);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

[data-theme-version="dark"] .accordion-solid-bg .accordion-button {
  background-color: #20203c;
}

.accordion-solid-bg .accordion-button.collapsed {
  border-radius: 0.625rem;
}

.accordion-solid-bg .accordion__body {
  border-color: transparent;
  background-color: var(--rgba-primary-1);
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

[data-theme-version="dark"] .accordion-solid-bg .accordion__body {
  background-color: #20203c;
}

.accordion-active-header .accordion-button:not(.collapsed) {
  background-color: #58bad7;
  border-color: #58bad7;
  color: #fff;
}

.accordion-header-shadow .accordion-button {
  border: none;
  box-shadow: 0 0 0.9375rem -0.1875rem rgba(0, 0, 0, 0.3);
}

.accordion-rounded-stylish .accordion-button {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.accordion-rounded-stylish .accordion__body {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.accordion-rounded .accordion-button {
  border-radius: 0.3125rem;
}

.accordion-gradient .accordion-button {
  color: #fff;
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
  border-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-gradient .accordion-button.collapsed {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.accordion-gradient .accordion__body {
  color: #fff;
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
  border-color: transparent;
}

.alert {
  border-radius: 0.625rem;
  padding: 1rem 1.5rem;
}

.alert p {
  line-height: 1.5;
}

.alert-square {
  border-radius: 0;
}

.alert-rounded {
  border-radius: 1.875rem;
}

.alert-primary {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
}

[data-theme-version="dark"] .alert-primary {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
}

.alert-secondary {
  background: #ecf8f4;
  border-color: #ecf8f4;
  color: #4CBC9A;
}

[data-theme-version="dark"] .alert-secondary {
  background: rgba(76, 188, 154, 0.5);
  border-color: rgba(76, 188, 154, 0.5);
  color: #fff;
}

.alert-success {
  background: #c9edcc;
  border-color: #c9edcc;
  color: #56c760;
}

[data-theme-version="dark"] .alert-success {
  background: rgba(86, 199, 96, 0.1);
  border-color: rgba(86, 199, 96, 0.1);
}

.alert-warning {
  background: #fff8e7;
  border-color: #fff8e7;
  color: #f0a901;
}

[data-theme-version="dark"] .alert-warning {
  background: rgba(240, 169, 1, 0.1);
  border-color: rgba(240, 169, 1, 0.1);
}

.alert-danger {
  background: #fcbbbc;
  border-color: #fcbbbc;
  color: #f75a5b;
}

[data-theme-version="dark"] .alert-danger {
  background: rgba(247, 90, 91, 0.15);
  border-color: rgba(247, 90, 91, 0.15);
}

.alert-info {
  background: #e8f6f9;
  border-color: #e8f6f9;
  color: #58bad7;
}

[data-theme-version="dark"] .alert-info {
  background: rgba(88, 186, 215, 0.1);
  border-color: rgba(88, 186, 215, 0.1);
}

.alert-dark {
  background: #eeeeee;
  border-color: #eeeeee;
  color: #6e6e6e;
}

[data-theme-version="dark"] .alert-dark {
  background: rgba(110, 110, 110, 0.35);
  border-color: rgba(110, 110, 110, 0.35);
  color: #fff;
}

.alert-light {
  background: #c8c8c8;
  border-color: #c8c8c8;
  color: #6e6e6e;
}

.alert-alt.alert-primary {
  border-left: 0.25rem solid var(--primary);
}

.alert-alt.alert-secondary {
  border-left: 0.25rem solid #4CBC9A;
}

.alert-alt.alert-success {
  border-left: 0.25rem solid #56c760;
}

.alert-alt.alert-warning {
  border-left: 0.25rem solid #f0a901;
}

.alert-alt.alert-danger {
  border-left: 0.25rem solid #f75a5b;
}

.alert-alt.alert-info {
  border-left: 0.25rem solid #58bad7;
}

.alert-alt.alert-dark {
  border-left: 0.25rem solid #6e6e6e;
}

.alert-alt.alert-light {
  border-left: 0.25rem solid #a2a2a2;
}

.alert-alt.alert-primary.solid {
  border-left: 0.25rem solid var(--primary-dark) !important;
}

.alert-alt.alert-secondary.solid {
  border-left: 0.25rem solid #256350 !important;
}

.alert-alt.alert-success.solid {
  border-left: 0.25rem solid #27762e !important;
}

.alert-alt.alert-warning.solid {
  border-left: 0.25rem solid #715000 !important;
}

.alert-alt.alert-danger.solid {
  border-left: 0.25rem solid #c80a0b !important;
}

.alert-alt.alert-info.solid {
  border-left: 0.25rem solid #22758e !important;
}

.alert-alt.alert-dark.solid {
  border-left: 0.25rem solid #2e2e2e !important;
}

.alert-alt.alert-light.solid {
  border-left: 0.25rem solid #888888 !important;
}

.alert-dismissible.solid .close:hover {
  color: #fff;
  opacity: 1;
}

.alert.alert-primary.solid {
  background: var(--primary);
  color: #fff;
  border-color: var(--primary);
}

.alert.alert-secondary.solid {
  background: #4CBC9A;
  color: #fff;
  border-color: #4CBC9A;
}

.alert.alert-success.solid {
  background: #56c760;
  color: #fff;
  border-color: #56c760;
}

.alert.alert-warning.solid {
  background: #f0a901;
  color: #fff;
  border-color: #f0a901;
}

.alert.alert-danger.solid {
  background: #f75a5b;
  color: #fff;
  border-color: #f75a5b;
}

.alert.alert-info.solid {
  background: #58bad7;
  color: #fff;
  border-color: #58bad7;
}

.alert.alert-dark.solid {
  background: #6e6e6e;
  color: #fff;
  border-color: #6e6e6e;
}

.alert.alert-light.solid {
  background: #c8c8c8;
  color: #6e6e6e;
  border-color: #c8c8c8;
}

.alert-right-icon>span i {
  font-size: 1.125rem;
  margin-right: 0.3125rem;
}

.alert-right-icon .close i {
  font-size: 1rem;
}

.alert.alert-outline-primary {
  background: transparent;
  color: var(--primary);
  border-color: var(--primary);
}

.alert.alert-outline-secondary {
  background: transparent;
  color: #777777;
  border-color: #4CBC9A;
}

.alert.alert-outline-success {
  background: transparent;
  color: #56c760;
  border-color: #56c760;
}

.alert.alert-outline-info {
  background: transparent;
  color: #58bad7;
  border-color: #58bad7;
}

.alert.alert-outline-warning {
  background: transparent;
  color: #f0a901;
  border-color: #f0a901;
}

.alert.alert-outline-danger {
  background: transparent;
  color: #f75a5b;
  border-color: #f75a5b;
}

.alert.alert-outline-dark {
  background: transparent;
  color: #777777;
  border-color: #6e6e6e;
}

.alert.alert-outline-light {
  background: transparent;
  color: #6e6e6e;
  border-color: #c8c8c8;
}

.alert-social {
  color: #fff;
}

.alert-social .alert-social-icon {
  align-self: center;
  margin-right: 0.9375rem;
}

.alert-social .alert-social-icon i {
  font-size: 2.625rem;
}

.alert-social.facebook {
  background-color: #3b5998;
}

.alert-social.twitter {
  background-color: #1da1f2;
}

.alert-social.linkedin {
  background-color: #007bb6;
}

.alert-social.google-plus {
  background-color: #db4439;
}

.alert-social .close:hover {
  opacity: 1 !important;
  color: #fff !important;
}

.left-icon-big .alert-left-icon-big {
  align-self: center;
  margin-right: 0.9375rem;
}

.left-icon-big .alert-left-icon-big i {
  font-size: 2.1875rem;
  line-height: 1;
}

[direction="rtl"] .left-icon-big .alert-left-icon-big,
[direction="rtl"] .alert-social .alert-social-icon {
  margin-right: 0;
  margin-left: 0.9375rem;
}

.badge {
  line-height: 1.5;
  border-radius: 0.625rem;
  font-weight: 600;
  padding: 0.25rem 0.625rem;
  border: 0.0625rem solid transparent;
}

.badge-rounded {
  border-radius: 1.25rem;
  padding: 0.1875rem 0.8125rem;
}

.badge-circle {
  border-radius: 6.25rem;
  padding: 0.1875rem 0.4375rem;
}

.light.badge-default {
  background: #ADB6C7;
}

.light.badge-primary {
  background-color: #ffdcd9 !important;
  border-color: #fff3f2;
  color: #fff;
}

.light.badge-success {
  background-color: #c9edcc;
  border-color: #c9edcc;
  color: #56c760;
}

.light.badge-info {
  background-color: #d3edf5;
  border-color: #d3edf5;
  color: #58bad7;
}

.light.badge-secondary {
  background-color: #ecf8f4;
  border-color: #84d1b9;
  color: #4CBC9A;
}

.light.badge-warning {
  background-color: #fff8e7;
  border-color: #fffbf1;
  color: #f0a901;
}

.light.badge-danger {
  background-color: #fcbbbc;
  border-color: #feecec;
  color: #f75a5b;
}

.light.badge-dark {
  background-color: #eeeeee;
  border-color: #bbbbbb;
  color: #6e6e6e;
}

.light.badge-light {
  background-color: #d0d0d0;
  border-color: #c8c8c8;
  color: #6e6e6e;
}

.badge-outline-primary {
  border: 0.0625rem solid var(--primary);
  color: var(--primary);
}

.badge-outline-secondary {
  border: 0.0625rem solid #4CBC9A;
  color: #4CBC9A;
}

[data-theme-version="dark"] .badge-outline-secondary {
  color: #777777;
}

.badge-outline-success {
  border: 0.0625rem solid #56c760;
  color: #56c760;
}

.badge-outline-info {
  border: 0.0625rem solid #58bad7;
  color: #58bad7;
}

.badge-outline-warning {
  border: 0.0625rem solid #f0a901;
  color: #f0a901;
}

.badge-outline-danger {
  border: 0.0625rem solid #f75a5b;
  color: #f75a5b;
}

.badge-outline-light {
  border: 0.0625rem solid #DBDBDB;
  color: #6e6e6e;
}

[data-theme-version="dark"] .badge-outline-light {
  color: #777777;
}

.badge-outline-dark {
  border: 0.0625rem solid #6e6e6e;
  color: #6e6e6e;
}

[data-theme-version="dark"] .badge-outline-dark {
  color: #777777;
}

.badge-xs {
  font-size: 0.625rem;
  padding: 0rem 0.3125rem;
  line-height: 1.125rem;
}

.badge-sm {
  font-size: 0.6875rem;
  padding: 0.3125rem 0.5rem;
  line-height: 0.6875rem;
}

.badge-lg {
  font-size: 0.875rem;
  padding: 0rem 0.625rem;
  line-height: 1.875rem;
}

.badge-xl {
  font-size: 16px;
  padding: 0.3rem 0.9375rem;
  line-height: 2.1875rem;
  font-weight: 400;
}

.badge-default {
  background: #ADB6C7;
}

.badge-success {
  background-color: #56c760;
}

.badge-secondary {
  background-color: #4CBC9A;
}

.badge-info {
  background-color: #58bad7;
}

.badge-primary {
  background-color: var(--primary);
}

.badge-warning {
  background-color: #f0a901;
}

.badge-danger {
  background-color: #f75a5b;
}

.badge-dark {
  background-color: #6e6e6e;
}

.badge-light {
  background-color: #c8c8c8;
}

.light.badge-default {
  background: #ADB6C7;
}

.light.badge-success {
  background-color: #c9edcc;
  color: #56c760;
}

[data-theme-version="dark"] .light.badge-success {
  background-color: rgba(86, 199, 96, 0.1);
}

.light.badge-info {
  background-color: #d3edf5;
  color: #58bad7;
}

[data-theme-version="dark"] .light.badge-info {
  background-color: rgba(88, 186, 215, 0.1);
}

.light.badge-primary {
  background-color: var(--rgba-primary-1);
  color: var(--primary);
}

[data-theme-version="dark"] .light.badge-primary {
  background-color: var(--rgba-primary-1);
}

.light.badge-secondary {
  background-color: #ecf8f4;
  color: #4CBC9A;
}

[data-theme-version="dark"] .light.badge-secondary {
  background-color: rgba(76, 188, 154, 0.5);
  color: #fff;
}

.light.badge-warning {
  background-color: #fff8e7;
  color: #f0a901;
}

[data-theme-version="dark"] .light.badge-warning {
  background-color: rgba(240, 169, 1, 0.1);
}

.light.badge-danger {
  background-color: #fcbbbc;
  color: #f75a5b;
}

[data-theme-version="dark"] .light.badge-danger {
  background-color: rgba(247, 90, 91, 0.15);
}

.light.badge-dark {
  background-color: #eeeeee;
  color: #6e6e6e;
}

[data-theme-version="dark"] .light.badge-dark {
  background-color: rgba(110, 110, 110, 0.35);
  color: #777777;
}

.bootstrap-label .label {
  display: inline-block;
  margin-right: 1rem;
}

.bootstrap-label .label:last-child {
  margin-right: 0;
}

.badge-demo .badge {
  margin-right: 0.3125rem;
  margin-bottom: 0.3125rem;
}

.badge-demo .badge:last-child {
  margin-right: 0;
}

.bootstrap-badge-buttons button {
  margin-right: .2rem;
  margin-bottom: 1rem;
}

.bootstrap-badge-buttons button:last-child {
  margin-right: 0;
}

.breadcrumb {
  font-size: 1.125rem;
}

.breadcrumb .breadcrumb-item a svg {
  margin-top: 2px;
  margin-right: 5px;
}

@media only screen and (max-width: 100rem) {
  .breadcrumb .breadcrumb-item a svg {
    margin-top: 0;
  }
}

.breadcrumb .breadcrumb-item.active a {
  color: #000;
}

.breadcrumb .breadcrumb-item+.breadcrumb-item::before {
  color: var(--primary);
}

.page-titles {
  padding: 0.9375rem 1rem;
  background: #fff;
  margin-bottom: 1.875rem;
  border-radius: 0.625rem;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

[data-theme-version="dark"] .page-titles {
  background: #292d4a;
}

@media only screen and (max-width: 74.9375rem) {
  .page-titles {
    margin-top: 0;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .page-titles {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    margin-bottom: 0.9375rem;
    padding: 0.9375rem 1.85rem;
  }
}

.page-titles .justify-content-sm-end {
  align-items: center;
}

.page-titles h4, .page-titles .h4 {
  margin-bottom: 0;
  margin-top: 0;
  color: var(--primary);
  font-size: 1.25rem;
}

.page-titles h4 span, .page-titles .h4 span {
  font-size: 0.875rem;
  font-weight: 400;
}

.page-titles .breadcrumb {
  margin-bottom: 0;
  padding: 0;
  background: transparent;
}

.page-titles .breadcrumb li {
  margin-top: 0;
  margin-bottom: 0;
}

.page-titles .breadcrumb li a {
  color: #828690;
}

.page-titles .breadcrumb li.active {
  color: var(--primary);
  font-weight: 600;
}

.page-titles .breadcrumb li.active a {
  color: var(--primary);
}

.page-titles .breadcrumb .breadcrumb-item+.breadcrumb-item:before {
  /* font-family: 'simple-line-icons';
                font-weight: 400;
				position:absolute;
                font-size: 10px; */
  color: var(--primary);
}

.page-titles .breadcrumb-datepicker {
  font-size: 0.75rem;
  color: #89879f;
}

.page-titles .breadcrumb-datepicker__icon {
  font-size: 0.875rem;
}

.page-titles .breadcrumb-widget .border-dark {
  border-color: #dee2e6 !important;
}

.page-titles .breadcrumb-widget h4, .page-titles .breadcrumb-widget .h4 {
  color: #646c9a;
  font-weight: 600;
}

@media only screen and (max-width: 35.9375rem) {
  .page-titles .breadcrumb-widget {
    text-align: left !important;
    margin-bottom: 0.9375rem;
  }
}

button {
  cursor: pointer;
}

button:focus {
  outline: 0;
  box-shadow: none;
}

.btn {
  padding: 0.6875rem 1.319rem;
  border-radius: 0.5rem;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
}

.btn:hover, .btn:focus, .btn:active, .btn.active {
  outline: 0 !important;
}

@media only screen and (max-width: 87.5rem) {
  .btn {
    padding: 0.625rem 1rem;
    font-size: 0.813rem;
  }
}

.btn.btn-success, .btn.btn-secondary, .btn.btn-warning, .btn.btn-primary, .btn.btn-danger, .btn.btn-info {
  color: #fff;
}

.btn-transparent {
  background-color: transparent;
}

.btn-primary {
  border-color: var(--primary);
  background-color: var(--primary);
}

.btn-primary:active, .btn-primary:focus, .btn-primary:hover {
  border-color: var(--primary-hover);
  background-color: var(--primary-hover);
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.25rem var(--rgba-primary-5);
}

.btn-primary:disabled, .btn-primary.disabled {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-link {
  color: var(--primary);
  text-decoration: none;
}

.btn-link:hover {
  color: var(--primary-hover);
}

.btn-outline-primary {
  color: var(--primary);
  border-color: var(--primary);
}

.btn-outline-primary:hover {
  border-color: var(--primary-hover);
  background-color: var(--primary-hover);
}

.sharp {
  min-width: 2.5rem;
  padding: 0.4375rem;
  height: 2.5rem;
  min-height: 2.5rem;
}

.sharp.btn-xs {
  padding: 0.1875rem;
  width: 1.625rem;
  height: 1.625rem;
  min-width: 1.625rem;
  min-height: 1.625rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.light.tp-btn {
  background-color: transparent;
}

.light.btn-default {
  background: #ADB6C7;
}

.light.btn-success {
  background-color: #c9edcc;
  border-color: #c9edcc;
  color: #56c760;
}

.light.btn-success g [fill] {
  fill: #56c760;
}

[data-theme-version="dark"] .light.btn-success {
  background-color: rgba(86, 199, 96, 0.1);
  border-color: transparent;
}

.light.btn-success:hover {
  background-color: #56c760;
  border-color: #56c760;
  color: #fff;
}

.light.btn-success:hover g [fill] {
  fill: #fff;
}

.light.btn-info {
  background-color: #d3edf5;
  border-color: #d3edf5;
  color: #58bad7;
}

.light.btn-info g [fill] {
  fill: #58bad7;
}

[data-theme-version="dark"] .light.btn-info {
  background-color: rgba(88, 186, 215, 0.1);
  border-color: transparent;
}

.light.btn-info:hover {
  background-color: #58bad7;
  border-color: #58bad7;
  color: #fff;
}

.light.btn-info:hover g [fill] {
  fill: #fff;
}

.light.btn-primary {
  background-color: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
}

.light.btn-primary g [fill] {
  fill: var(--primary);
}

[data-theme-version="dark"] .light.btn-primary {
  background-color: var(--rgba-primary-1);
  border-color: transparent;
  color: #fff;
}

.light.btn-primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.light.btn-primary:hover g [fill] {
  fill: #fff;
}

.light.btn-secondary {
  background-color: #ecf8f4;
  border-color: #ecf8f4;
  color: #4CBC9A;
}

.light.btn-secondary g [fill] {
  fill: #4CBC9A;
}

[data-theme-version="dark"] .light.btn-secondary {
  background-color: rgba(76, 188, 154, 0.5);
  border-color: transparent;
  color: #fff;
}

.light.btn-secondary:hover {
  background-color: #4CBC9A;
  border-color: #4CBC9A;
  color: #fff;
}

.light.btn-secondary:hover g [fill] {
  fill: #fff;
}

.light.btn-warning {
  background-color: #fff8e7;
  border-color: #fff8e7;
  color: #f0a901;
}

.light.btn-warning g [fill] {
  fill: #f0a901;
}

[data-theme-version="dark"] .light.btn-warning {
  background-color: rgba(240, 169, 1, 0.1);
  border-color: transparent;
}

.light.btn-warning:hover {
  background-color: #f0a901;
  border-color: #f0a901;
  color: #fff;
}

.light.btn-warning:hover g [fill] {
  fill: #fff;
}

.light.btn-danger {
  background-color: #fcbbbc;
  border-color: #fcbbbc;
  color: #f75a5b;
}

.light.btn-danger g [fill] {
  fill: #f75a5b;
}

[data-theme-version="dark"] .light.btn-danger {
  background-color: rgba(247, 90, 91, 0.15);
  border-color: transparent;
}

.light.btn-danger:hover {
  background-color: #f75a5b;
  border-color: #f75a5b;
  color: #fff;
}

.light.btn-danger:hover g [fill] {
  fill: #fff;
}

.light.btn-dark {
  background-color: #eeeeee;
  border-color: #eeeeee;
  color: #6e6e6e;
}

.light.btn-dark g [fill] {
  fill: #6e6e6e;
}

[data-theme-version="dark"] .light.btn-dark {
  background-color: rgba(110, 110, 110, 0.35);
  border-color: transparent;
  color: #fff;
}

.light.btn-dark:hover {
  background-color: #6e6e6e;
  border-color: #6e6e6e;
  color: #fff;
}

.light.btn-dark:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn {
  background-color: transparent;
  border-color: transparent;
}

.btn.tp-btn.btn-default {
  background: #ADB6C7;
}

.btn.tp-btn.btn-success {
  color: #56c760;
}

.btn.tp-btn.btn-success g [fill] {
  fill: #56c760;
}

.btn.tp-btn.btn-success:hover {
  background-color: #56c760;
  border-color: #56c760;
  color: #fff;
}

.btn.tp-btn.btn-success:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-info {
  color: #58bad7;
}

.btn.tp-btn.btn-info g [fill] {
  fill: #58bad7;
}

.btn.tp-btn.btn-info:hover {
  background-color: #58bad7;
  border-color: #58bad7;
  color: #fff;
}

.btn.tp-btn.btn-info:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-primary {
  color: var(--primary);
}

.btn.tp-btn.btn-primary g [fill] {
  fill: var(--primary);
}

.btn.tp-btn.btn-primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.btn.tp-btn.btn-primary:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-secondary {
  color: #4CBC9A;
}

.btn.tp-btn.btn-secondary g [fill] {
  fill: #4CBC9A;
}

.btn.tp-btn.btn-secondary:hover {
  background-color: #4CBC9A;
  border-color: #4CBC9A;
  color: #fff;
}

.btn.tp-btn.btn-secondary:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-warning {
  color: #f0a901;
}

.btn.tp-btn.btn-warning g [fill] {
  fill: #f0a901;
}

.btn.tp-btn.btn-warning:hover {
  background-color: #f0a901;
  border-color: #f0a901;
  color: #fff;
}

.btn.tp-btn.btn-warning:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-danger {
  color: #f75a5b;
}

.btn.tp-btn.btn-danger g [fill] {
  fill: #f75a5b;
}

.btn.tp-btn.btn-danger:hover {
  background-color: #f75a5b;
  border-color: #f75a5b;
  color: #fff;
}

.btn.tp-btn.btn-danger:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-light {
  color: #6e6e6e;
}

.btn.tp-btn.btn-light g [fill] {
  fill: #6e6e6e;
}

.btn.tp-btn.btn-light:hover {
  background-color: #c8c8c8;
  border-color: #c8c8c8;
  color: #6e6e6e;
}

.btn.tp-btn.btn-light:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-dark {
  color: #6e6e6e;
}

.btn.tp-btn.btn-dark g [fill] {
  fill: #6e6e6e;
}

.btn.tp-btn.btn-dark:hover {
  background-color: #6e6e6e;
  border-color: #6e6e6e;
  color: #fff;
}

.btn.tp-btn.btn-dark:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn-light {
  background-color: transparent;
  border-color: transparent;
}

.btn.tp-btn-light.btn-success {
  color: #56c760;
}

.btn.tp-btn-light.btn-success g [fill] {
  fill: #56c760;
}

.btn.tp-btn-light.btn-success:hover {
  background-color: #c9edcc;
  border-color: #c9edcc;
  color: #56c760;
}

.btn.tp-btn-light.btn-success:hover g [fill] {
  fill: #56c760;
}

.btn.tp-btn-light.btn-info {
  color: #58bad7;
}

.btn.tp-btn-light.btn-info g [fill] {
  fill: #58bad7;
}

.btn.tp-btn-light.btn-info:hover {
  background-color: #d3edf5;
  border-color: #d3edf5;
  color: #58bad7;
}

.btn.tp-btn-light.btn-info:hover g [fill] {
  fill: #58bad7;
}

.btn.tp-btn-light.btn-primary {
  color: var(--primary);
}

.btn.tp-btn-light.btn-primary g [fill] {
  fill: var(--primary);
}

.btn.tp-btn-light.btn-primary:hover {
  background-color: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
}

.btn.tp-btn-light.btn-primary:hover g [fill] {
  fill: var(--primary);
}

.btn.tp-btn-light.btn-secondary {
  color: #4CBC9A;
}

.btn.tp-btn-light.btn-secondary g [fill] {
  fill: #4CBC9A;
}

.btn.tp-btn-light.btn-secondary:hover {
  background-color: #ecf8f4;
  border-color: #ecf8f4;
  color: #4CBC9A;
}

.btn.tp-btn-light.btn-secondary:hover g [fill] {
  fill: #4CBC9A;
}

.btn.tp-btn-light.btn-warning {
  color: #f0a901;
}

.btn.tp-btn-light.btn-warning g [fill] {
  fill: #f0a901;
}

.btn.tp-btn-light.btn-warning:hover {
  background-color: #fff8e7;
  border-color: #fff8e7;
  color: #f0a901;
}

.btn.tp-btn-light.btn-warning:hover g [fill] {
  fill: #f0a901;
}

.btn.tp-btn-light.btn-danger {
  color: #f75a5b;
}

.btn.tp-btn-light.btn-danger g [fill] {
  fill: #f75a5b;
}

.btn.tp-btn-light.btn-danger:hover {
  background-color: #fcbbbc;
  border-color: #fcbbbc;
  color: #f75a5b;
}

.btn.tp-btn-light.btn-danger:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn-light.btn-dark {
  color: #6e6e6e;
}

.btn.tp-btn-light.btn-dark g [fill] {
  fill: #6e6e6e;
}

.btn.tp-btn-light.btn-dark:hover {
  background-color: #eeeeee;
  border-color: #eeeeee;
  color: #6e6e6e;
}

.btn.tp-btn-light.btn-dark:hover g [fill] {
  fill: #fff;
}

.shadow.btn-primary {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 var(--rgba-primary-2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 var(--rgba-primary-2) !important;
}

.shadow.btn-secondary {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(76, 188, 154, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(76, 188, 154, 0.2) !important;
}

.shadow.btn-warning {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(240, 169, 1, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(240, 169, 1, 0.2) !important;
}

.shadow.btn-danger {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(247, 90, 91, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(247, 90, 91, 0.2) !important;
}

.shadow.btn-info {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(88, 186, 215, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(88, 186, 215, 0.2) !important;
}

.shadow.btn-success {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(86, 199, 96, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(86, 199, 96, 0.2) !important;
}

.btn-xxs {
  padding: 0.375rem 0.9375rem;
  font-size: 0.6875rem;
  line-height: 1.3;
}

.btn-xs {
  font-size: 0.75rem;
  padding: 0.438rem 1rem;
  font-weight: 600;
}

.btn-sm, .btn-group-sm>.btn {
  font-size: 0.813rem !important;
  padding: 0.563rem 1.494rem;
}

.btn-md {
  font-size: 0.875rem !important;
  padding: 0.875rem 1.25rem;
}

.btn-lg, .btn-group-lg>.btn {
  padding: 1rem 2rem;
  font-size: 1.125rem !important;
}

@media only screen and (max-width: 35.9375rem) {
  .btn-lg, .btn-group-lg>.btn {
    padding: 0.75rem 1.25rem;
  }
}

.btn-xl {
  padding: 0.6rem 1rem;
}

.btn-xl.btn-default {
  font-weight: 600;
}

.btn-square {
  border-radius: 0;
}

.btn-rounded {
  border-radius: 2.5rem !important;
}

.btn-icon-end {
  border-left: 0.0625rem solid white;
  display: inline-block;
  margin: -.8rem 0 -.8rem 1rem;
  padding: 0.4375rem 0 0.4375rem 1rem;
  margin: -1rem -0.25rem -1rem 1rem;
  padding: 1rem 0 1rem 1.25rem;
}

.btn-icon-start {
  background: #fff;
  border-radius: 0.425rem;
  display: inline-block;
  margin: -0.3rem 0.35rem -0.2rem -0.888rem;
  padding: 0.3rem 0.6rem 0.2rem;
  float: left;
}

@media only screen and (max-width: 87.5rem) {
  .btn-icon-start {
    margin: -0.5rem 0.75rem -0.5rem -0.880rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .btn-icon-start {
    margin: -0.2rem 0.45rem -0.2rem -0.58rem;
  }
}

[direction="rtl"] .btn-icon-start {
  margin: -.5rem 0.5rem -0.5rem -1rem;
}

@media only screen and (max-width: 87.5rem) {
  [direction="rtl"] .btn-icon-start {
    margin: -0.5rem 0.75rem -0.5rem -0.880rem;
  }
}

[direction="rtl"] .btn-icon-end {
  border-left: 0rem solid white;
  display: inline-block;
  margin: -.8rem 1rem -.8rem 0;
  padding: 0.4375rem 1rem 0.4375rem 0;
  border-right: 0.0625rem solid white;
}

.toggle-dropdown::after {
  margin-left: 0.755em;
}

.social-btn-icon .btn {
  min-width: 7.5rem;
  margin-bottom: 1.5rem;
}

.social-icon .btn {
  padding: .7rem 1.4rem;
}

.btn-circle {
  height: 5rem;
  width: 5rem;
  border-radius: 50% !important;
}

.btn-circle-sm {
  width: 4.5rem;
  height: 4.5rem;
  font-size: 1.8rem;
}

.btn-circle-md {
  width: 6rem;
  height: 6rem;
  font-size: 2.5rem;
}

.btn-circle-md i {
  font-size: 2.4rem;
}

.btn-circle-lg {
  width: 8rem;
  height: 8rem;
  font-size: 3.2rem;
}

.btn-circle-lg i {
  font-size: 3.1rem;
}

.btn-page .btn {
  min-width: 6.875rem;
  margin-right: 0.25rem;
  margin-bottom: 0.5rem;
}

.size-1 {
  min-width: 10rem !important;
  font-size: 1.5rem;
  padding: 0.68rem 0.75rem;
}

.size-2 {
  font-size: 1.25rem;
  min-width: 8.125rem !important;
  padding: 0.57rem 0.75rem;
}

.size-3 {
  font-size: 0.875rem;
  min-width: 6.875rem !important;
  padding: 0.536rem 0.75rem;
}

.size-4 {
  font-size: 0.875rem;
  min-width: 6.25rem !important;
}

.size-5 {
  font-size: 0.875rem;
  min-width: 5.625rem !important;
  padding: .22rem 0.75rem;
}

.size-6 {
  font-size: 0.8125rem;
  min-width: 5rem !important;
  padding: 0.097rem 0.75rem;
}

.size-7 {
  font-size: 0.75rem;
  min-width: 3.75rem !important;
  padding: 0.001rem 0.75rem;
}

.btn-light {
  background: #c8c8c8;
  border-color: #c8c8c8;
  color: #fff;
}

.btn-light:active, .btn-light:focus, .btn-light:hover {
  background: white;
  color: #6e6e6e;
  border-color: white;
}

.btn-outline-primary:hover {
  color: #fff;
}

.btn-outline-warning:hover {
  color: #fff;
}

.btn-outline-light {
  color: #6e6e6e;
}

[data-theme-version="dark"] .btn-outline-light {
  color: #777777;
}

[data-theme-version="dark"] .btn-outline-secondary {
  color: #777777;
}

[data-theme-version="dark"] .btn-outline-dark {
  color: #777777;
}

.btn-dark {
  background: #6e6e6e;
  border-color: #6e6e6e;
  color: #fff;
}

.btn-dark:active, .btn-dark:hover {
  background: #888888;
  color: #fff;
  border-color: #555555;
}

.btn-dark:focus {
  background: #888888;
}

.btn-group.btn-rounded .btn:first-child {
  border-top-left-radius: 1.875rem;
  border-bottom-left-radius: 1.875rem;
}

.btn-group.btn-rounded .btn:last-child {
  border-top-right-radius: 1.875rem;
  border-bottom-right-radius: 1.875rem;
}

.btn-facebook {
  background: #3b5998;
  border-color: #3b5998;
  color: #fff;
}

.btn-facebook:active, .btn-facebook:focus, .btn-facebook:hover {
  background: #2d4373;
  color: #fff;
  border-color: #2d4373;
}

.btn-twitter {
  background: #1da1f2;
  border-color: #1da1f2;
  color: #fff;
}

.btn-twitter:active, .btn-twitter:focus, .btn-twitter:hover {
  background: #0c85d0;
  color: #fff;
  border-color: #0c85d0;
}

.btn-youtube {
  background: #FF0000;
  border-color: #FF0000;
  color: #fff;
}

.btn-youtube:active, .btn-youtube:focus, .btn-youtube:hover {
  background: #cc0000;
  color: #fff;
  border-color: #cc0000;
}

.btn-instagram {
  background: #c32aa3;
  border-color: #c32aa3;
  color: #fff;
}

.btn-instagram:active, .btn-instagram:focus, .btn-instagram:hover {
  background: #992180;
  color: #fff;
  border-color: #992180;
}

.btn-pinterest {
  background: #bd081c;
  border-color: #bd081c;
  color: #fff;
}

.btn-pinterest:active, .btn-pinterest:focus, .btn-pinterest:hover {
  background: #8c0615;
  color: #fff;
  border-color: #8c0615;
}

.btn-linkedin {
  background: #007bb6;
  border-color: #007bb6;
  color: #fff;
}

.btn-linkedin:active, .btn-linkedin:focus, .btn-linkedin:hover {
  background: #005983;
  color: #fff;
  border-color: #005983;
}

.btn-google-plus {
  background: #db4439;
  border-color: #db4439;
  color: #fff;
}

.btn-google-plus:active, .btn-google-plus:focus, .btn-google-plus:hover {
  background: #be2d23;
  color: #fff;
  border-color: #be2d23;
}

.btn-google {
  background: #4285f4;
  border-color: #4285f4;
  color: #fff;
}

.btn-google:active, .btn-google:focus, .btn-google:hover {
  background: #1266f1;
  color: #fff;
  border-color: #1266f1;
}

.btn-snapchat {
  background: #fffc00;
  border-color: #fffc00;
  color: #000;
}

.btn-snapchat:active, .btn-snapchat:focus, .btn-snapchat:hover {
  background: #ccca00;
  color: #000;
  border-color: #ccca00;
}

.btn-whatsapp {
  background: #25d366;
  border-color: #25d366;
  color: #fff;
}

.btn-whatsapp:active, .btn-whatsapp:focus, .btn-whatsapp:hover {
  background: #1da851;
  color: #fff;
  border-color: #1da851;
}

.btn-tumblr {
  background: #35465d;
  border-color: #35465d;
  color: #fff;
}

.btn-tumblr:active, .btn-tumblr:focus, .btn-tumblr:hover {
  background: #222e3d;
  color: #fff;
  border-color: #222e3d;
}

.btn-reddit {
  background: #ff4500;
  border-color: #ff4500;
  color: #fff;
}

.btn-reddit:active, .btn-reddit:focus, .btn-reddit:hover {
  background: #cc3700;
  color: #fff;
  border-color: #cc3700;
}

.btn-spotify {
  background: #1ed760;
  border-color: #1ed760;
  color: #fff;
}

.btn-spotify:active, .btn-spotify:focus, .btn-spotify:hover {
  background: #18aa4c;
  color: #fff;
  border-color: #18aa4c;
}

.btn-yahoo {
  background: #430297;
  border-color: #430297;
  color: #fff;
}

.btn-yahoo:active, .btn-yahoo:focus, .btn-yahoo:hover {
  background: #2d0165;
  color: #fff;
  border-color: #2d0165;
}

.btn-dribbble {
  background: #ea4c89;
  border-color: #ea4c89;
  color: #fff;
}

.btn-dribbble:active, .btn-dribbble:focus, .btn-dribbble:hover {
  background: #e51e6b;
  color: #fff;
  border-color: #e51e6b;
}

.btn-skype {
  background: #00aff0;
  border-color: #00aff0;
  color: #fff;
}

.btn-skype:active, .btn-skype:focus, .btn-skype:hover {
  background: #008abd;
  color: #fff;
  border-color: #008abd;
}

.btn-quora {
  background: #aa2200;
  border-color: #aa2200;
  color: #fff;
}

.btn-quora:active, .btn-quora:focus, .btn-quora:hover {
  background: #771800;
  color: #fff;
  border-color: #771800;
}

.btn-vimeo {
  background: #1ab7ea;
  border-color: #1ab7ea;
  color: #fff;
}

.btn-vimeo:active, .btn-vimeo:focus, .btn-vimeo:hover {
  background: #1295bf;
  color: #fff;
  border-color: #1295bf;
}

.btn-check:checked+.btn-outline-primary,
.btn-check:active+.btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.btn-check:checked+.btn-outline-primary:focus,
.btn-check:active+.btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem var(--rgba-primary-5);
}

.btn-check:focus+.btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem var(--rgba-primary-5);
}

.btn-close:focus {
  box-shadow: 0 0 0 0.25rem var(--rgba-primary-5);
}

.card {
  margin-bottom: 1.875rem;
  background-color: #fff;
  transition: all .5s ease-in-out;
  position: relative;
  border: 0rem solid transparent;
  border-radius: 0.625rem;
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
  height: calc(100% - 30px);
}

@media only screen and (max-width: 35.9375rem) {
  .card {
    margin-bottom: 0.938rem;
    height: calc(100% - 0.938rem);
  }
}

.card-body {
  padding: 1.875rem;
}

@media only screen and (max-width: 35.9375rem) {
  .card-body {
    padding: 1rem;
  }
}

.card-title {
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
  text-transform: capitalize;
}

.card-title--large {
  font-size: 1.5rem;
}

.card-title--medium {
  font-size: 1rem;
}

.card-title--small {
  font-size: 0.875rem;
}

.card-header {
  border-color: #DBDBDB;
  position: relative;
  background: transparent;
  padding: 1.5rem 1.875rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 35.9375rem) {
  .card-header {
    padding: 1.25rem 1rem 1.25rem;
  }
}

[data-theme-version="dark"] .card-header {
  border-color: #333754;
}

.card-header .card-title {
  margin-bottom: 0rem;
}

.card-header .subtitle {
  padding-top: 0.3125rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.card-footer {
  border-color: #DBDBDB;
  background: transparent;
  padding: 1.25rem 1.875rem 1.25rem;
}

[data-theme-version="dark"] .card-footer {
  border-color: #333754;
}

@media only screen and (max-width: 35.9375rem) {
  .card-footer {
    padding: 1.25rem 1rem 1.25rem;
  }
}

.transparent-card.card {
  background: transparent;
  border: 0.0625rem solid transparent;
  box-shadow: none;
}

.card-action>a {
  display: inline-block;
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  border-radius: 0.3125rem;
  border-color: transparent;
  text-align: center;
  background: var(--primary-dark);
  color: #fff;
  margin-right: 0.5rem;
}

[data-theme-version="dark"] .card-action>a {
  background: #20203c;
}

.card-action>a:last-child {
  margin-right: 0;
}

.card-action>a:hover, .card-action>a:focus {
  background: var(--primary-dark);
}

[data-theme-version="dark"] .card-action>a:hover, [data-theme-version="dark"] .card-action>a:focus {
  background: #20203c;
}

.card-action>a i, .card-action>a span {
  font-size: 1rem;
}

.card-action .dropdown {
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 0.3125rem;
  border-color: transparent;
  text-align: center;
  margin-right: 0.5rem;
  top: -0.125rem;
  position: relative;
  display: inline-block;
  background: var(--primary-dark);
  color: var(--primary);
}

[data-theme-version="dark"] .card-action .dropdown {
  background: #20203c;
}

.card-action .dropdown:hover, .card-action .dropdown:focus {
  background: var(--primary-dark);
}

[data-theme-version="dark"] .card-action .dropdown:hover, [data-theme-version="dark"] .card-action .dropdown:focus {
  background: #20203c;
}

.card-action .dropdown .btn {
  padding: 0;
  line-height: 1.6875rem;
  color: #fff;
}

.card-action .dropdown .btn:focus {
  box-shadow: none;
}

.card-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  overflow: auto;
}

.card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

.card-loader i {
  margin: 0 auto;
  background: var(--primary-dark);
  padding: 0.625rem;
  border-radius: 50%;
  color: #fff;
  font-size: 1rem;
}

.rotate-refresh {
  -webkit-animation: mymove 0.8s infinite linear;
  animation: mymove 0.8s infinite linear;
  display: inline-block;
}

.card-header .date_picker {
  display: inline-block;
  padding: 0.5rem;
  border: 0.0625rem solid #DBDBDB;
  cursor: pointer;
  border-radius: .375rem;
}

.card-header .border-0 {
  padding-bottom: 0;
}

@-webkit-keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.carousel-custom-prev, .carousel-custom-next {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  text-align: center;
}

.carousel-custom-prev i, .carousel-custom-next i {
  font-size: 1rem;
}

.carousel-custom-prev:hover, .carousel-custom-next:hover {
  background: linear-gradient(to right, rgba(245, 60, 121, 0.99) 0%, rgba(246, 104, 47, 0.99) 100%);
  color: #fff;
}

.carousel-custom-next {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}

.carousel-custom-prev {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
}

.carousel-caption {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.78);
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
}

.carousel-caption h5, .carousel-caption .h5 {
  color: #fff;
  font-size: 1.8rem;
}

.carousel-caption p {
  margin-bottom: 0;
}

.carousel-indicators {
  z-index: 1;
}

.dropdown-toggle:focus {
  box-shadow: none !important;
}

.dropdown-outline {
  border: 0.1rem solid var(--primary);
}

.dropdown-menu {
  font-size: inherit;
  border: 0;
  z-index: 4;
  overflow: hidden;
  border-radius: 0.625rem;
  box-shadow: 0 0 3.125rem 0 rgba(82, 63, 105, 0.15);
  margin-top: 0;
}

.dropdown-menu .dropdown-item {
  font-size: 1rem;
  color: #777777;
  padding: 0.5rem 1.75rem;
}

@media only screen and (max-width: 87.5rem) {
  .dropdown-menu .dropdown-item {
    padding: 0.375rem 1rem;
    font-size: 0.875rem;
  }
}

.dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:active, .dropdown-menu .dropdown-item.active {
  color: #514e5f;
}

.dropdown-menu .dropdown-item.active, .dropdown-menu .dropdown-item:active {
  color: var(--primary);
  background: var(--rgba-primary-1);
}

[direction="rtl"] .dropdown-menu {
  right: auto !important;
}

.dropdown-toggle-split {
  padding: 0 0.625rem;
  opacity: 0.85;
}

.dropdown-toggle-split:after {
  margin-left: 0 !important;
}

.dropdown-toggle-split:active, .dropdown-toggle-split:focus, .dropdown-toggle-split:hover {
  opacity: 1;
}

/* .dropleft .dropdown-toggle:before,
.dropright .dropdown-toggle:before,
.dropup .dropdown-toggle:after,
.dropdown-toggle:after {
	content:"\f107";
	font-family:FontAwesome;
	border: 0;
    vertical-align: middle;
    margin-left: 0.25em;
	line-height: 1;
} */
/* .dropup .dropdown-toggle:after{
	content:"\f106";
} */
/* .dropleft .dropdown-toggle::before {
	content:"\f104";
}
.dropright .dropdown-toggle::before {
	content:"\f105";
} */
.dropright .dropdown-toggle::after {
  content: none;
}

.custom-dropdown {
  display: inline-block;
  margin-bottom: 1rem;
}

.custom-dropdown .dropdown-menu {
  border: 0rem;
  min-width: 10rem;
}

.card-action .custom-dropdown {
  margin: 0rem;
  background: var(--rgba-primary-1);
}

.card-action .custom-dropdown.show, .card-action .custom-dropdown:focus, .card-action .custom-dropdown:hover {
  background: var(--primary);
  color: #fff;
}

.card-action .custom-dropdown i {
  display: inline-block;
  padding-top: 0.5625rem;
}

.dropdown .dropdown-dots {
  position: relative;
  height: 0.3125rem;
  width: 0.3125rem;
  background: rgba(110, 110, 110, 0.4);
  border-radius: 0.3125rem;
  display: block;
}

.dropdown .dropdown-dots:after, .dropdown .dropdown-dots:before {
  content: "";
  height: 0.3125rem;
  width: 0.3125rem;
  background: rgba(110, 110, 110, 0.4);
  position: absolute;
  border-radius: 0.3125rem;
}

.dropdown .dropdown-dots:after {
  right: -0.5rem;
}

.dropdown .dropdown-dots:before {
  left: -0.5rem;
}

.dropdown .dropdown-dots.text-white {
  background: rgba(255, 255, 255, 0.7);
}

.dropdown .dropdown-dots.text-white:after, .dropdown .dropdown-dots.text-white:before {
  background: rgba(255, 255, 255, 0.7);
}

.grid-col {
  padding: 0.5rem !important;
  background: #f2f4fa;
}

.row.grid {
  margin-bottom: 1.5rem;
  text-align: center;
}

.row.grid .grid-col:first-child {
  text-align: left;
}

.label {
  display: inline-block;
  text-align: center;
  font-size: 0.75rem;
  padding: .2rem .8rem;
}

.label-fixed {
  width: 7.5rem;
  padding: .6rem 0;
}

.label-fixed-lg {
  width: 9.5rem;
  padding: .6rem 0;
}

.label-big {
  width: 16.8rem;
  font-size: 1.4rem;
  padding: 1.1rem 0;
}

.label-xl {
  width: 10.5rem;
  padding: 1.1rem 0;
  font-size: 1.5rem;
}

.label-lg {
  width: 9.5rem;
  padding: 1.1rem 0;
}

.label-md {
  width: 8.5rem;
  padding: 1.1rem 0;
}

.label-sm {
  width: 7.5rem;
  padding: 1.1rem 0;
}

.label-default {
  background: #ADB6C7;
}

.label-primary {
  background: var(--primary);
  color: #fff;
}

.label-secondary {
  background: #4CBC9A;
  color: #fff;
}

.label-info {
  background: #58bad7;
  color: #fff;
}

.label-success {
  background: #56c760;
  color: #fff;
}

.label-warning {
  background: #f0a901;
  color: #fff;
}

.label-danger {
  background: #f75a5b;
  color: #fff;
}

.label-light {
  background: #c8c8c8;
  color: #000;
}

.label-dark {
  background: #6e6e6e;
  color: #fff;
}

code {
  word-break: break-word;
  padding: 0.125rem 0.3125rem;
  border-radius: 0.1875rem;
  background: #fffbfb;
  color: #f75a5b;
}

[data-theme-version="dark"] code {
  background: rgba(247, 90, 91, 0.1);
}

.heading-labels {
  color: #333333;
}

.heading-labels>* {
  margin-bottom: .8rem;
}

.heading-labels h1 .label, .heading-labels .h1 .label {
  font-size: 1.125rem;
  font-weight: normal;
  padding: .4rem .9rem;
}

.heading-labels h2 .label, .heading-labels .h2 .label {
  font-size: 1rem;
  font-weight: normal;
  padding: 0.3rem .9rem;
}

.heading-labels h3 .label, .heading-labels .h3 .label {
  font-size: 0.875rem;
  font-weight: normal;
}

.heading-labels h4 .label, .heading-labels .h4 .label {
  font-size: 0.75rem;
  font-weight: normal;
}

.heading-labels h5 .label, .heading-labels .h5 .label {
  font-size: 0.75rem;
  font-weight: normal;
}

.heading-labels h6 .label, .heading-labels .h6 .label {
  font-size: 0.75rem;
  font-weight: normal;
}

.list-group-item {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #DBDBDB;
  padding: 1rem 1.5rem;
}

.list-group-item.active {
  background-color: var(--primary);
  border-color: var(--primary);
}

[data-theme-version="dark"] .list-group-item {
  border-color: #333754;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #fff;
  background-color: #4CBC9A;
  border-color: #4CBC9A;
}

[class*="bg-"] .list-group-item {
  border-color: rgba(255, 255, 255, 0.05);
}

.bg-warning .list-group-item {
  border-color: rgba(0, 0, 0, 0.05);
}

/*  */
.vertical-card__menu:hover {
  box-shadow: none;
}

.vertical-card__menu--image {
  text-align: center;
}

.vertical-card__menu--image img {
  width: 100%;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.vertical-card__menu--status.closed {
  background: #f75a5b;
}

.vertical-card__menu--status.closed:after {
  border-top: 0.625rem solid #f75a5b;
}

.vertical-card__menu--status.closed .ribbon-curve {
  border-top: 0.6875rem solid #f75a5b;
  border-bottom: 0.625rem solid #f75a5b;
}

.vertical-card__menu--desc p {
  margin-bottom: 0.625rem;
}

.vertical-card__menu--price {
  font-size: 1.25rem;
  margin: 0;
  font-weight: 700;
}

.vertical-card__menu--rating {
  font-size: 0.75rem;
}

.vertical-card__menu--title {
  font-weight: 700;
}

.vertical-card__menu--button button {
  font-size: 0.75rem;
}

.vertical-card__menu .card-footer {
  padding: 0.9375rem 1.25rem;
  background-color: #DBDBDB;
  border-top: none;
}

@media only screen and (min-width: 75rem) and (max-width: 90rem) {
  .vertical-card__menu .card-footer {
    padding: 0.9375rem;
  }
}

.vertical-card__menu .card-footer span {
  color: #6e6e6e;
  margin-right: 0.3125rem;
}

.horizontal-card__menu {
  box-shadow: 0 0 0.4375rem rgba(173, 173, 173, 0.32);
  display: flex;
  flex-direction: row;
}

@media (max-width: 575.98px) {
  .horizontal-card__menu {
    display: block;
  }
}

.horizontal-card__menu .card-body {
  padding: 0;
  padding: 1.25rem 1.875rem;
}

.horizontal-card__menu--image {
  flex-basis: 30%;
  max-width: 30%;
}

.horizontal-card__menu--image img {
  height: 100%;
  width: 100%;
}

@media (max-width: 575.98px) {
  .horizontal-card__menu--image {
    max-width: unset;
    flex-basis: 100%;
  }
}

.horizontal-card__menu--title {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.3125rem;
}

.horizontal-card__menu--fav {
  margin-right: 0.5rem;
}

.horizontal-card__menu--price {
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
}

.horizontal-card__menu--rating {
  font-size: 0.625rem;
}

.horizontal-card__menu--footer {
  margin-top: 0.625rem;
}

.prev_price {
  text-decoration: line-through;
  font-size: 80%;
  opacity: 0.5;
}

.modal-header {
  padding: 1rem 1.875rem;
}

.modal-header .close {
  padding: 0.875rem 1.815rem;
  margin: 0;
  position: absolute;
  right: 0;
  float: none;
  top: 0;
  font-size: 1.875rem;
  font-weight: 100;
}

.modal-body {
  padding: 1.875rem;
}

.modal-footer {
  padding: 1rem 1.875rem;
}

.modal-content {
  border-radius: 0.625rem;
}

.modal-backdrop {
  z-index: 10 !important;
}

.pagination .page-item .page-link:hover {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.pagination .page-item.active .page-link {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.pagination {
  margin-bottom: 1.25rem;
}

.pagination .page-item.page-indicator .page-link {
  padding: .65rem .8rem;
  font-size: 0.875rem;
}

.pagination .page-item.page-indicator:hover .page-link {
  color: #6e6e6e;
}

.pagination .page-item .page-link {
  text-align: center;
  z-index: 1;
  padding: 0.55rem 1rem;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.15);
  color: #6e6e6e;
  border: 0.0625rem solid #DBDBDB;
}

[data-theme-version="dark"] .pagination .page-item .page-link {
  border-color: #333754;
  color: #828690;
  background: rgba(255, 255, 255, 0);
}

.pagination .page-item .page-link:hover i, .pagination .page-item .page-link span {
  color: #fff;
}

.pagination .page-item .page-link:focus {
  outline: 0;
  box-shadow: none;
}

.pagination .page-item .page-link:hover {
  background: var(--primary);
  color: #fff;
  border-color: var(--primary);
}

.pagination .page-item.active .page-link {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
  box-shadow: 0 0.625rem 1.25rem 0rem var(--rgba-primary-2);
}

[data-theme-version="dark"] .pagination .page-item.active .page-link {
  color: #fff;
}

.pagination .page-item .page-link {
  color: #6e6e6e;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.pagination .page-item:last-child .page-link {
  margin-right: 0;
}

[direction="rtl"] .pagination .page-item:first-child .page-link {
  margin-right: 0;
}

.pagination.no-bg li:not(.page-indicator):not(.active) .page-link {
  background: transparent;
  border-color: transparent;
}

.pagination.no-bg.pagination-primary li:not(.page-indicator):not(.active):hover .page-link {
  background: var(--primary);
  border-color: var(--primary);
}

[data-theme-version="dark"] .pagination.no-bg.pagination-primary li:not(.page-indicator):not(.active):hover .page-link {
  background: var(--primary);
  border-color: var(--primary);
}

.pagination.no-bg.pagination-danger li:not(.page-indicator):not(.active):hover .page-link {
  background: #f75a5b;
  border-color: #f75a5b;
}

[data-theme-version="dark"] .pagination.no-bg.pagination-danger li:not(.page-indicator):not(.active):hover .page-link {
  background: #f75a5b;
  border-color: #f75a5b;
}

.pagination.no-bg.pagination-info li:not(.page-indicator):not(.active):hover .page-link {
  background: #58bad7;
  border-color: #58bad7;
}

[data-theme-version="dark"] .pagination.no-bg.pagination-info li:not(.page-indicator):not(.active):hover .page-link {
  background: #58bad7;
  border-color: #58bad7;
}

.pagination.no-bg.pagination-warning li:not(.page-indicator):not(.active):hover .page-link {
  background: #f0a901;
  border-color: #f0a901;
}

[data-theme-version="dark"] .pagination.no-bg.pagination-warning li:not(.page-indicator):not(.active):hover .page-link {
  background: #f0a901;
  border-color: #f0a901;
}

.pagination-primary .page-item .page-link {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
}

[data-theme-version="dark"] .pagination-primary .page-item .page-link {
  background: var(--rgba-primary-1);
  border-color: transparent;
  color: var(--primary);
}

.pagination-primary .page-item:hover .page-link, .pagination-primary .page-item.active .page-link {
  background: var(--primary);
  border-color: var(--primary);
  box-shadow: 0 0.625rem 1.25rem 0rem var(--rgba-primary-2);
}

[data-theme-version="dark"] .pagination-primary .page-item:hover .page-link, [data-theme-version="dark"] .pagination-primary .page-item.active .page-link {
  color: #fff;
}

.pagination-danger .page-item .page-link {
  background: #fcbbbc;
  border-color: #fcbbbc;
  color: #f75a5b;
}

[data-theme-version="dark"] .pagination-danger .page-item .page-link {
  background: rgba(247, 90, 91, 0.15);
  border-color: transparent;
  color: #f75a5b;
}

.pagination-danger .page-item:hover .page-link, .pagination-danger .page-item.active .page-link {
  background: #f75a5b;
  border-color: #f75a5b;
  box-shadow: 0 0.625rem 1.25rem 0rem rgba(247, 90, 91, 0.2);
}

[data-theme-version="dark"] .pagination-danger .page-item:hover .page-link, [data-theme-version="dark"] .pagination-danger .page-item.active .page-link {
  color: #fff;
}

.pagination-info .page-item .page-link {
  background: #d3edf5;
  border-color: #d3edf5;
  color: #58bad7;
}

[data-theme-version="dark"] .pagination-info .page-item .page-link {
  background: rgba(88, 186, 215, 0.1);
  border-color: transparent;
  color: #58bad7;
}

.pagination-info .page-item:hover .page-link, .pagination-info .page-item.active .page-link {
  background: #58bad7;
  border-color: #58bad7;
  box-shadow: 0 0.625rem 1.25rem 0rem rgba(88, 186, 215, 0.2);
}

.pagination-warning .page-item .page-link {
  background: #fff8e7;
  border-color: #fff8e7;
  color: #f0a901;
}

[data-theme-version="dark"] .pagination-warning .page-item .page-link {
  background: rgba(240, 169, 1, 0.1);
  border-color: transparent;
  color: #f0a901;
}

.pagination-warning .page-item:hover .page-link, .pagination-warning .page-item.active .page-link {
  background: #f0a901;
  border-color: #f0a901;
  box-shadow: 0 0.625rem 1.25rem 0rem rgba(240, 169, 1, 0.2);
}

.pagination-gutter .page-item {
  margin-right: 0.4375rem;
}

.pagination-gutter .page-item .page-link {
  border-radius: 0.625rem !important;
}

.pagination-circle .page-item {
  margin-right: 0.4375rem;
}

.pagination-circle .page-item .page-link, .pagination-circle .page-item .page-link {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  line-height: 2.5rem;
  border-radius: 50% !important;
  padding: 0;
}

.pagination-circle .page-item.page-indicator .page-link {
  width: 2.6rem;
  border: 1px solid var(--primary);
  border-radius: 22px !important;
  line-height: 1.3rem;
  height: 2.5rem;
  background: var(--rgba-primary-1);
  color: var(--primary);
}

.pagination-circle .page-item.page-indicator .page-link:hover {
  color: #fff;
  background: var(--primary);
}

.pagination.pagination-md .page-item .page-link {
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  font-size: 0.875rem;
}

.pagination.pagination-sm .page-item.page-indicator .page-link {
  font-size: 0.75rem;
}

.pagination.pagination-sm .page-item .page-link {
  padding: 0;
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  font-size: 0.875rem;
}

.pagination.pagination-xs .page-item.page-indicator .page-link {
  font-size: 0.625rem;
}

.pagination.pagination-xs .page-item .page-link {
  padding: 0;
  width: 1.5625rem;
  height: 1.5625rem;
  line-height: 1.5625rem;
  font-size: 0.75rem;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  border-bottom-color: #4CBC9A;
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  border-right-color: #4CBC9A;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  border-top-color: #4CBC9A;
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  border-left-color: #4CBC9A;
}

.popover {
  border: 0.125rem solid #4CBC9A;
  min-width: 13.125rem;
  box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.1);
}

[data-theme-version="dark"] .popover {
  background-color: #20203c;
}

.popover-header {
  background: #4CBC9A;
  color: #fff;
  font-weight: 300;
}

.popover-header::before {
  border-bottom: 0 !important;
}

.popover-body {
  font-size: 0.75rem;
}

[data-theme-version="dark"] .popover .popover-header {
  border-color: #292d4a;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

@media only screen and (max-width: 47.9375rem) {
  .popover {
    z-index: 1;
  }
}

.bootstrap-popover-wrapper .bootstrap-popover:not(:last-child) {
  margin-right: 0.5rem;
}

.bootstrap-popover-wrapper .bootstrap-popover {
  margin-bottom: .5rem;
}

.bootstrap-popover-wrapper .bootstrap-popover button:hover, .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: var(--primary);
  color: #fff;
  box-shadow: none;
}

/* .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 0rem;
    border-top-color: $secondary;
}


.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 0rem;
    border-left-color: $secondary;
}

.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 0rem;
    border-right-color: $secondary;
}

.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 0rem;
    border-bottom-color: $secondary;
} */
.note-popover {
  display: none;
}

.bs-popover-bottom>.arrow, .bs-popover-auto[data-popper-placement^="bottom"]>.arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom>.arrow::before, .bs-popover-auto[data-popper-placement^="bottom"]>.arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom>.arrow::after, .bs-popover-auto[data-popper-placement^="bottom"]>.arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-top>.arrow, .bs-popover-auto[data-popper-placement^="top"]>.arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top>.arrow::before, .bs-popover-auto[data-popper-placement^="top"]>.arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top>.arrow::after, .bs-popover-auto[data-popper-placement^="top"]>.arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right>.arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-right>.arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right>.arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-left>.arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-left>.arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left>.arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.toltip-popover .tooltip-inner {
  background: #ffffff;
  padding: 0;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  border: 0.125rem solid #4CBC9A;
}

.toltip-popover .tooltip-inner strong {
  color: #777777;
}

.progress-bar {
  background-color: var(--primary);
}

.progress-bar-primary {
  background-color: var(--primary);
}

.progress-bar-success {
  background-color: #56c760;
}

.progress-bar-info {
  background-color: #58bad7;
}

.progress-bar-danger {
  background-color: #f75a5b;
}

.progress-bar-warning {
  background-color: #f0a901;
}

.progress-bar-pink {
  background-color: #e83e8c;
}

.progress {
  height: 6px;
  background-color: #f6f6f6;
  overflow: hidden;
}

[data-theme-version="dark"] .progress {
  background-color: #20203c;
}

.progress-bar {
  border-radius: 0.625rem;
}

.progress-vertical {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2rem;
  min-height: 17rem;
  position: relative;
}

@media (max-width: 991.98px) {
  .progress-vertical {
    margin-right: 1rem;
  }
}

.progress-vertical-bottom {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2rem;
  min-height: 17rem;
  position: relative;
  transform: rotate(180deg);
}

@media (max-width: 991.98px) {
  .progress-vertical-bottom {
    margin-right: 1rem;
  }
}

.progress-animated {
  animation-duration: 5s;
  animation-name: myanimation;
  transition: all 5s ease 0s;
}

@keyframes myanimation {
  0% {
    width: 0;
  }
}

@keyframes myanimation {
  0% {
    width: 0;
  }
}

.ribbon {
  position: absolute;
  z-index: 1;
  text-transform: uppercase;
}

.ribbon__one {
  top: 0.9375rem;
  left: -0.6875rem;
  min-height: 1.25rem;
  min-width: 3.25rem;
  text-align: center;
  padding: 0.1875rem 0.625rem;
  background: #3AB54B;
  color: #fff;
  font-size: 0.625rem;
}

.ribbon__one::after {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 0.625rem solid #239132;
  border-left: 0.6875rem solid transparent;
  left: 0;
  content: "";
  bottom: -0.625rem;
}

.ribbon__two {
  width: 3.125rem;
  height: 3.125rem;
  display: inline-block;
  background: #f0a901;
  line-height: 3.125rem;
  text-align: center;
  font-size: 1rem;
  color: #fff;
  right: 0.9375rem;
  top: 0.9375rem;
  border-radius: 0.1875rem;
}

.ribbon__three {
  left: -1.875rem;
  top: 0.875rem;
  width: 6.875rem;
  height: 1.5625rem;
  background-color: #f75a5b;
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
  transform: rotate(-45deg);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
}

.ribbon__four {
  left: 0.5rem;
  top: -0.5rem;
  width: 6.875rem;
  height: 3.125rem;
  background-color: var(--primary);
  z-index: auto;
  color: #fff;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ribbon__four:after {
  right: -0.3125rem;
}

.ribbon__four:before {
  left: -0.3125rem;
}

.ribbon__four:after, .ribbon__four:before {
  z-index: -1;
  background-color: var(--rgba-primary-1);
  top: 0.1875rem;
  transform: rotate(45deg);
  content: "";
  height: 0.625rem;
  width: 0.625rem;
  position: absolute;
}

.ribbon__five {
  left: -1.875rem;
  top: 0.625rem;
  width: 6.875rem;
  height: 1.875rem;
  background-color: var(--primary);
  transform: rotate(-45deg);
  font-size: 0.75rem;
  color: #fff;
  padding-bottom: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.ribbon__five::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 3.125rem 3.125rem 3.125rem;
  border-color: transparent transparent var(--primary) transparent;
  left: 0.25rem;
  top: -2.8125rem;
}

.ribbon__six {
  left: 0;
  top: 1.125rem;
  width: 6.875rem;
  height: 2.375rem;
  background-color: var(--primary);
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 0, 85% 50%, 100% 100%, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0%, 100% 0, 85% 50%, 100% 100%, 100% 100%, 0 100%);
  display: flex;
  font-size: 1.25rem;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.ribbon-curve {
  position: absolute;
  top: 0;
  right: -0.375rem;
  width: 0.625rem;
  height: 0.6875rem;
  border-top: 0.6875rem solid #3AB54B;
  border-bottom: 0.625rem solid #3AB54B;
  border-right: 0.3125rem solid transparent;
}

.jsgrid-grid-body::-webkit-scrollbar,
.dataTables_scrollBody::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar {
  background-color: #f5f5f5;
  width: 0.5rem;
  height: 0.5rem;
}

.jsgrid-grid-body::-webkit-scrollbar-track,
.dataTables_scrollBody::-webkit-scrollbar-track,
.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  border-radius: 0.625rem;
  background-color: #f5f5f5;
}

.jsgrid-grid-body::-webkit-scrollbar-thumb,
.dataTables_scrollBody::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 0.625rem;
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  background-color: var(--primary);
}

.multi-steps>li.is-active:before, .multi-steps>li.is-active~li:before {
  content: counter(stepNum);
  font-family: inherit;
  font-weight: 400;
}

.multi-steps>li.is-active:after, .multi-steps>li.is-active~li:after {
  background-color: #DBDBDB;
}

.multi-steps {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.multi-steps>li {
  counter-increment: stepNum;
  text-align: center;
  display: table-cell;
  position: relative;
  color: var(--primary);
}

@media (max-width: 575.98px) {
  .multi-steps>li {
    font-size: 0.75rem;
  }
}

.multi-steps>li:before {
  content: '\2713';
  display: block;
  margin: 0 auto 0.25rem;
  background-color: #fff;
  width: 1.5625rem;
  height: 1.5625rem;
  line-height: 1.375rem;
  text-align: center;
  font-weight: bold;
  position: relative;
  z-index: 1;
  border-width: 0.125rem;
  border-style: solid;
  border-color: var(--primary);
  border-radius: 0.3125rem;
}

@media (max-width: 575.98px) {
  .multi-steps>li:before {
    width: 1.5625rem;
    height: 1.5625rem;
    line-height: 1.3125rem;
  }
}

.multi-steps>li:after {
  content: '';
  height: 0.125rem;
  width: 100%;
  background-color: var(--primary);
  position: absolute;
  top: 0.75rem;
  left: 50%;
}

[direction="rtl"] .multi-steps>li:after {
  left: auto;
  right: 50%;
}

@media (max-width: 575.98px) {
  .multi-steps>li:after {
    top: 0.75rem;
  }
}

.multi-steps>li:last-child:after {
  display: none;
}

.multi-steps>li.is-active:before {
  background-color: #fff;
  border-color: var(--primary);
}

.multi-steps>li.is-active~li {
  color: #777777;
}

.multi-steps>li.is-active~li:before {
  background-color: #DBDBDB;
  border-color: #DBDBDB;
}

.nav-pills .nav-link {
  border-radius: 0.625rem;
  padding: 0.75rem 1.25rem;
  cursor: pointer;
}

.default-tab .nav-link {
  background: transparent;
  border-radius: 0rem;
  font-weight: 500;
  cursor: pointer;
}

.default-tab .nav-link i {
  display: inline-block;
  transform: scale(1.5);
  color: var(--primary);
}

.default-tab .nav-link:focus, .default-tab .nav-link:hover, .default-tab .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff #ebeef6;
  border-radius: 0.625rem 0.625rem 0 0;
  color: var(--primary);
}

[data-theme-version="dark"] .default-tab .nav-link:focus, [data-theme-version="dark"] .default-tab .nav-link:hover, [data-theme-version="dark"] .default-tab .nav-link.active {
  background-color: var(--rgba-primary-1);
  border-color: transparent transparent #333754 transparent;
}

.custom-tab-1 .nav-link {
  background: transparent;
  border-radius: 0rem;
  font-weight: 500;
  border-bottom: 0.1875rem solid transparent;
  border-width: 0 0rem 0.1875rem 0;
  cursor: pointer;
}

.custom-tab-1 .nav-link i {
  display: inline-block;
  transform: scale(1.5);
  color: var(--primary);
}

.custom-tab-1 .nav-link:focus, .custom-tab-1 .nav-link:hover, .custom-tab-1 .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: var(--primary);
  border-radius: 0;
  color: var(--primary);
  border-width: 0 0rem 0.1875rem 0;
}

[data-theme-version="dark"] .custom-tab-1 .nav-link:focus, [data-theme-version="dark"] .custom-tab-1 .nav-link:hover, [data-theme-version="dark"] .custom-tab-1 .nav-link.active {
  background-color: var(--rgba-primary-1);
}

.nav-pills.light .nav-link.active,
.nav-pills.light .show>.nav-link {
  background: var(--rgba-primary-1);
  color: var(--primary);
  cursor: pointer;
  box-shadow: none;
}

[data-theme-version="dark"] .nav-pills.light .nav-link.active, [data-theme-version="dark"] .nav-pills.light .show>.nav-link {
  background: var(--rgba-primary-1);
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: var(--primary);
  box-shadow: 0 0 1.25rem 0 var(--rgba-primary-2);
}

.tooltip-wrapper button:not(:last-child) {
  margin-right: 0.5rem;
}

.tooltip-wrapper button:hover {
  background: var(--primary);
  color: #fff;
}

.tooltip-wrapper button {
  margin-bottom: .5rem;
}

.tooltip-wrapper button:focus {
  box-shadow: none;
}

.tooltip-inner {
  border-radius: 0;
  background: #333333;
  font-size: 0.75rem;
  font-weight: 300;
  padding: 0.35rem 0.7rem;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #333333;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #333333;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #333333;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #333333;
}

.widget-timeline .timeline {
  list-style: none;
  position: relative;
}

.widget-timeline .timeline:before {
  top: 1.25rem;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 0.1875rem;
  left: 0.5625rem;
  margin-right: -0.0938rem;
  background: #c8c8c8;
}

[data-theme-version="dark"] .widget-timeline .timeline:before {
  background: #333754;
}

[direction="rtl"] .widget-timeline .timeline:before {
  left: auto;
  right: 0.5625rem;
  margin-right: auto;
  margin-left: -0.0938rem;
}

.widget-timeline .timeline>li {
  margin-bottom: 0.9375rem;
  position: relative;
}

.widget-timeline .timeline>li:before {
  content: " ";
  display: table;
}

.widget-timeline .timeline>li:after {
  content: " ";
  display: table;
  clear: both;
}

.widget-timeline .timeline>li>.timeline-panel {
  border-radius: 0.625rem;
  padding: 0.9375rem 1.25rem;
  position: relative;
  display: block;
  margin-left: 2.5rem;
  border-width: 0.0625rem;
  border-style: solid;
}

.widget-timeline .timeline>li>.timeline-panel span {
  font-size: 0.75rem;
  display: block;
  margin-bottom: 0.3125rem;
  opacity: 0.8;
  letter-spacing: 0.0625rem;
}

.widget-timeline .timeline>li>.timeline-panel p {
  font-size: 0.875rem;
}

.widget-timeline .timeline>li>.timeline-panel:after {
  content: "";
  width: 0.625rem;
  height: 0.625rem;
  background: inherit;
  border-color: inherit;
  border-style: solid;
  border-width: 0rem 0rem 0.0625rem 0.0625rem;
  display: block;
  position: absolute;
  left: -0.3125rem;
  transform: rotate(45deg);
  top: 0.9375rem;
}

.widget-timeline .timeline>li>.timeline-badge {
  border-radius: 50%;
  height: 1.375rem;
  left: 0;
  position: absolute;
  top: 0.625rem;
  width: 1.375rem;
  border-width: 0.125rem;
  border-style: solid;
  background: #fff;
  padding: 0.25rem;
}

[data-theme-version="dark"] .widget-timeline .timeline>li>.timeline-badge {
  background-color: #292d4a;
}

.widget-timeline .timeline>li>.timeline-badge:after {
  content: "";
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 100%;
  display: block;
}

[direction="rtl"] .widget-timeline .timeline>li>.timeline-badge {
  left: auto;
  right: 1.1875rem;
}

.widget-timeline .timeline-body>p {
  font-size: 0.75rem;
}

.widget-timeline .timeline-badge.primary {
  border-color: var(--rgba-primary-1);
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.primary {
  border-color: var(--rgba-primary-1);
}

.widget-timeline .timeline-badge.primary:after {
  background-color: var(--primary);
  box-shadow: 0 0.3125rem 0.625rem 0 var(--rgba-primary-2);
}

.widget-timeline .timeline-badge.primary+.timeline-panel {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.primary+.timeline-panel {
  border-color: transparent;
  background-color: var(--rgba-primary-1);
}

.widget-timeline .timeline-badge.success {
  border-color: #c9edcc;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.success {
  border-color: rgba(86, 199, 96, 0.1);
}

.widget-timeline .timeline-badge.success:after {
  background-color: #56c760 !important;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(86, 199, 96, 0.2);
}

.widget-timeline .timeline-badge.success+.timeline-panel {
  background: #c9edcc;
  border-color: #c9edcc;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.success+.timeline-panel {
  background-color: rgba(86, 199, 96, 0.1);
  border-color: transparent;
}

.widget-timeline .timeline-badge.warning {
  border-color: #fff8e7;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.warning {
  border-color: rgba(240, 169, 1, 0.1);
}

.widget-timeline .timeline-badge.warning:after {
  background-color: #f0a901 !important;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(240, 169, 1, 0.2);
}

.widget-timeline .timeline-badge.warning+.timeline-panel {
  background: #fff8e7;
  border-color: #fff8e7;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.warning+.timeline-panel {
  background-color: rgba(240, 169, 1, 0.1);
  border-color: transparent;
}

.widget-timeline .timeline-badge.danger {
  border-color: #fcbbbc;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.danger {
  border-color: rgba(247, 90, 91, 0.15);
}

.widget-timeline .timeline-badge.danger:after {
  background-color: #f75a5b !important;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(247, 90, 91, 0.2);
}

.widget-timeline .timeline-badge.danger+.timeline-panel {
  background: #fcbbbc;
  border-color: #fcbbbc;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.danger+.timeline-panel {
  background-color: rgba(247, 90, 91, 0.15);
  border-color: transparent;
}

.widget-timeline .timeline-badge.info {
  border-color: #d3edf5;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.info {
  border-color: rgba(88, 186, 215, 0.1);
}

.widget-timeline .timeline-badge.info:after {
  background-color: #58bad7 !important;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(88, 186, 215, 0.2);
}

.widget-timeline .timeline-badge.info+.timeline-panel {
  background: #d3edf5;
  border-color: #d3edf5;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.info+.timeline-panel {
  background-color: rgba(88, 186, 215, 0.1);
  border-color: transparent;
}

.widget-timeline .timeline-badge.dark {
  border-color: #eeeeee;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.dark {
  border-color: rgba(110, 110, 110, 0.35);
}

.widget-timeline .timeline-badge.dark:after {
  background-color: #6e6e6e !important;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(110, 110, 110, 0.2);
}

.widget-timeline .timeline-badge.dark+.timeline-panel {
  background: #eeeeee;
  border-color: #eeeeee;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.dark+.timeline-panel {
  background-color: rgba(110, 110, 110, 0.35);
  border-color: transparent;
}

.widget-timeline.style-1 .timeline-panel {
  background: transparent;
}

.widget-timeline.style-1 .timeline .timeline-badge.timeline-badge+.timeline-panel {
  background: transparent !important;
  border-style: solid;
  border-width: 0 0 0 0.3125rem;
  border-radius: 0;
  padding: 0.3125rem 0.625rem 0.3125rem 0.9375rem;
}

.widget-timeline.style-1 .timeline .timeline-badge.timeline-badge+.timeline-panel:after {
  border: 0;
  left: -0.5625rem;
  width: 0.4375rem;
  height: 0.4375rem;
}

.widget-timeline.style-1 .timeline .timeline-badge.primary+.timeline-panel {
  border-color: var(--primary);
}

.widget-timeline.style-1 .timeline .timeline-badge.primary+.timeline-panel:after {
  background: var(--primary);
}

.widget-timeline.style-1 .timeline .timeline-badge.success+.timeline-panel {
  border-color: #56c760;
}

.widget-timeline.style-1 .timeline .timeline-badge.success+.timeline-panel:after {
  background: #56c760;
}

.widget-timeline.style-1 .timeline .timeline-badge.warning+.timeline-panel {
  border-color: #f0a901;
}

.widget-timeline.style-1 .timeline .timeline-badge.warning+.timeline-panel:after {
  background: #f0a901;
}

.widget-timeline.style-1 .timeline .timeline-badge.danger+.timeline-panel {
  border-color: #f75a5b;
}

.widget-timeline.style-1 .timeline .timeline-badge.danger+.timeline-panel:after {
  background: #f75a5b;
}

.widget-timeline.style-1 .timeline .timeline-badge.info+.timeline-panel {
  border-color: #58bad7;
}

.widget-timeline.style-1 .timeline .timeline-badge.info+.timeline-panel:after {
  background: #58bad7;
}

.widget-timeline.style-1 .timeline .timeline-badge.dark+.timeline-panel {
  border-color: #6e6e6e;
}

.widget-timeline.style-1 .timeline .timeline-badge.dark+.timeline-panel:after {
  background: #6e6e6e;
}

#chart_widget_4 {
  height: 15.9375rem !important;
}

#chart_widget_5 .ct-series-a .ct-line,
#chart_widget_5 .ct-series-a .ct-point {
  stroke: #46ffc8;
}

#chart_widget_5 .ct-line {
  stroke-width: 0.0625rem;
}

#chart_widget_5 .ct-point {
  stroke-width: 0.125rem;
}

#chart_widget_5 .ct-series-a .ct-area {
  fill: #20dea6;
}

#chart_widget_5 .ct-area {
  fill-opacity: 1;
}

#chart_widget_6 .ct-series-a .ct-line,
#chart_widget_6 .ct-series-a .ct-point {
  stroke: #58bad7;
}

#chart_widget_6 .ct-line {
  stroke-width: 0.125rem;
}

#chart_widget_6 .ct-point {
  stroke-width: 0.3125rem;
}

#chart_widget_6 .ct-series-a .ct-area {
  fill: #58bad7;
}

#chart_widget_6 .ct-area {
  fill-opacity: .5;
}

#chart_widget_8 {
  height: 15.9375rem;
}

#chart_widget_8 .ct-series-a .ct-line,
#chart_widget_8 .ct-series-a .ct-point {
  stroke: #58bad7;
}

#chart_widget_8 .ct-line {
  stroke-width: 0.125rem;
}

#chart_widget_8 .ct-point {
  stroke-width: 0.3125rem;
}

#chart_widget_8 .ct-series-a .ct-area {
  fill: #58bad7;
}

#chart_widget_8 .ct-area {
  fill-opacity: .5;
}

#chart_widget_9, #chart_widget_10 {
  height: 15.625rem !important;
}

#chart_widget_11 .ct-slice-donut, #chart_widget_12 .ct-slice-donut, #chart_widget_13 .ct-slice-donut {
  stroke-width: 1.5625rem !important;
}

#chart_widget_11 {
  height: 16.875rem !important;
}

#chart_widget_17 {
  height: 9.375rem !important;
}

.chart_widget_tab_one .nav-link {
  border: 0.0625rem solid #ddd;
}

.chart_widget_tab_one .nav-link.active {
  background-color: var(--primary);
  border: 0.0625rem solid var(--primary);
  color: #fff;
}

.chart_widget_tab_one .nav-link.active:hover {
  border: 0.0625rem solid var(--primary);
}

.chart_widget_tab_one .nav-link:hover {
  border: 0.0625rem solid #ddd;
}

[data-theme-version="dark"] .ccc-widget>div {
  background: #292d4a !important;
  border-color: #333754 !important;
}

.social-icon {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 0.25rem;
  text-align: center;
  background: #F0F0F0;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}

.social-icon i {
  color: #fff;
}

.social-icon.youtube {
  background: #FF0000;
}

.social-icon.facebook {
  background: #3b5998;
}

.social-icon.twitter {
  background: #1da1f2;
}

.social-graph-wrapper {
  text-align: center;
  padding: 1.25rem;
  position: relative;
  color: #fff;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.social-graph-wrapper.widget-facebook {
  background-color: #3b5998;
}

.social-graph-wrapper.widget-twitter {
  background-color: #1da1f2;
}

.social-graph-wrapper.widget-linkedin {
  background-color: #007bb6;
}

.social-graph-wrapper.widget-googleplus {
  background-color: #db4439;
}

.social-graph-wrapper .s-icon {
  font-size: 1.5rem;
  position: relative;
  padding: 0 0.625rem;
}

.social-icon2 a {
  border: 0.1rem solid var(--primary);
  display: block;
  padding: 1rem;
  margin-top: 1.5rem;
  margin-bottom: .5rem;
  border-radius: .3rem;
  font-size: 1.6rem;
}

.social-icon2 i {
  font-size: 0.75rem;
  color: var(--primary);
}

.social-icon3 ul li {
  display: inline-block;
}

.social-icon3 ul li a {
  display: block;
}

.social-icon3 ul li a i {
  font-size: 1.4rem;
  padding: 1rem .7rem;
  color: #464a53;
}

.social-icon3 ul li a:hover i {
  color: var(--primary);
}

.social-icons-muted ul li a i {
  color: #89879f;
}

.social-links a {
  padding: .5rem;
}

.widget-stat .media {
  padding: 0rem 0;
  align-items: center;
}

.widget-stat .media>span {
  height: 5.3125rem;
  width: 5.3125rem;
  border-radius: 3.125rem;
  padding: 0.625rem 0.75rem;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #464a53;
  min-width: 5.3125rem;
}

.widget-stat .media .media-body p {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.875rem;
}

[data-theme-version="dark"] .widget-stat .media .media-body p {
  color: #fff;
}

.widget-stat .media .media-body h3, .widget-stat .media .media-body .h3 {
  font-size: 2.5rem;
  font-weight: 600;
  margin: 0;
  line-height: 1.2;
}

.widget-stat .media .media-body h4, .widget-stat .media .media-body .h4 {
  font-size: 1.5rem;
  display: inline-block;
  vertical-align: middle;
}

.widget-stat .media .media-body span {
  margin-left: 0.3125rem;
}

.widget-stat[class*="bg-"] .media>span {
  background-color: rgba(255, 255, 255, 0.25);
  color: #fff;
}

.widget-stat[class*="bg-"] .progress {
  background-color: rgba(255, 255, 255, 0.25) !important;
}

[direction="rtl"] .widget-stat .media .media-body span {
  margin-left: 0;
  margin-right: 0.625rem;
}

.dez-widget-1 .card {
  background: #FFE7DB;
}

.dez-widget-1 .card .card-body p {
  color: #f87533;
}

.dez-widget-1 .icon {
  float: right;
  width: 3.125rem;
  height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  font-size: 1.75rem;
}

.bgl-primary {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
}

[data-theme-version="dark"] .bgl-primary {
  background-color: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
}

.bgl-secondary {
  background: #ecf8f4;
  border-color: #ecf8f4;
}

[data-theme-version="dark"] .bgl-secondary {
  background-color: rgba(76, 188, 154, 0.5);
  border-color: rgba(76, 188, 154, 0.5);
}

.bgl-success {
  background: #c9edcc;
  border-color: #c9edcc;
}

[data-theme-version="dark"] .bgl-success {
  background-color: rgba(86, 199, 96, 0.1);
  border-color: rgba(86, 199, 96, 0.1);
}

.bgl-warning {
  background: #fff8e7;
  border-color: #fff8e7;
}

[data-theme-version="dark"] .bgl-warning {
  background-color: rgba(240, 169, 1, 0.1);
  border-color: rgba(240, 169, 1, 0.1);
}

.bgl-danger {
  background: #fcbbbc;
  border-color: #fcbbbc;
}

[data-theme-version="dark"] .bgl-danger {
  background-color: rgba(247, 90, 91, 0.15);
  border-color: rgba(247, 90, 91, 0.15);
}

.bgl-info {
  background: #d3edf5;
  border-color: #d3edf5;
}

[data-theme-version="dark"] .bgl-info {
  background-color: rgba(88, 186, 215, 0.1);
  border-color: rgba(88, 186, 215, 0.1);
}

.bg-primary-light {
  background: var(--rgba-primary-5);
}

[data-theme-version="dark"] .bg-primary-light {
  background-color: var(--rgba-primary-1);
}

.bg-secondary-light {
  background: rgba(236, 248, 244, 0.5);
}

[data-theme-version="dark"] .bg-secondary-light {
  background-color: rgba(76, 188, 154, 0.05);
}

.bg-success-light {
  background: rgba(201, 237, 204, 0.5);
}

[data-theme-version="dark"] .bg-success-light {
  background-color: rgba(86, 199, 96, 0.05);
}

.bg-warning-light {
  background: rgba(255, 248, 231, 0.5);
}

[data-theme-version="dark"] .bg-warning-light {
  background-color: rgba(240, 169, 1, 0.05);
}

.bg-danger-light {
  background: rgba(252, 187, 188, 0.5);
}

[data-theme-version="dark"] .bg-danger-light {
  background-color: rgba(247, 90, 91, 0.05);
}

.bg-info-light {
  background: rgba(211, 237, 245, 0.5);
}

[data-theme-version="dark"] .bg-info-light {
  background-color: rgba(88, 186, 215, 0.05);
}

.bgl-dark {
  background: #eeeeee;
  border-color: #eeeeee;
}

.bgl-light {
  background: #c8c8c8;
  border-color: #c8c8c8;
}

.overlay-box {
  position: relative;
  z-index: 1;
}

.overlay-box:after {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  opacity: 0.85;
  background: var(--primary);
  z-index: -1;
}

.rating-bar {
  font-size: 0.8125rem;
}

.tdl-holder {
  margin: 0 auto;
}

.tdl-holder ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tdl-holder li {
  background-color: #fff;
  border-bottom: 0.0625rem solid #DBDBDB;
  list-style: none none;
  margin: 0;
  padding: 0;
}

.tdl-holder li span {
  margin-left: 2.1875rem;
  font-size: 1rem;
  vertical-align: middle;
  transition: all 0.2s linear;
}

[direction="rtl"] .tdl-holder li span {
  margin-left: auto;
  margin-right: 2.1875rem;
}

.tdl-holder label {
  cursor: pointer;
  display: block;
  line-height: 3.125rem;
  padding-left: 1.5rem;
  position: relative;
  margin: 0 !important;
}

[direction="rtl"] .tdl-holder label {
  padding-left: 0;
  padding-right: 1.5rem;
}

.tdl-holder label:hover {
  background-color: #eef1f6;
  color: #6e6e6e;
}

.tdl-holder label:hover a {
  color: #f75a5b;
}

.tdl-holder label a {
  color: #fff;
  display: inline-block;
  line-height: normal;
  height: 100%;
  text-align: center;
  text-decoration: none;
  width: 3.125rem;
  -webkit-transition: all .2s linear;
  -moz-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all 0.2s linear;
  padding: 1.125rem 0rem;
  font-size: 1.125rem;
  position: absolute;
  right: 0;
}

[direction="rtl"] .tdl-holder label a {
  right: auto;
  left: 0;
}

.tdl-holder input[type=checkbox] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.tdl-holder input[type=checkbox]+i {
  background-color: #fff;
  border: 0.0625rem solid #e7e7e7;
  display: block;
  height: 1.25rem;
  position: absolute;
  top: 0.9375rem;
  width: 1.25rem;
  z-index: 1;
  border-radius: 0rem;
  font-size: 0.6875rem;
  border-radius: 0.25rem;
}

.tdl-holder input[type=checkbox]:checked+i {
  background: var(--primary);
  border-color: transparent;
}

.tdl-holder input[type=checkbox]:checked+i::after {
  content: "\f00c";
  font-family: 'fontAwesome';
  display: block;
  left: 0.125rem;
  position: absolute;
  top: -0.875rem;
  z-index: 2;
  color: #fff;
}

.tdl-holder input[type=checkbox]:checked~span {
  text-decoration: line-through;
  position: relative;
}

.tdl-holder input[type=text] {
  background-color: #fff;
  height: 3.125rem;
  margin-top: 1.8125rem;
  border-radius: 0.1875rem;
}

.tdl-holder input[type=text]::placeholder {
  color: #6e6e6e;
}

.widget-message p {
  font-size: 0.875rem;
  line-height: 1.5;
}

.picker .picker__frame {
  min-width: 28.125rem;
  max-width: 28.125rem;
}

.picker .picker__frame .picker__box {
  padding: 1.25rem;
  border: 0;
  box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.1);
}

.picker .picker__frame .picker__box .picker__header {
  margin-top: 0;
}

.picker .picker__frame .picker__box .picker__header .picker__year {
  color: var(--primary);
  font-style: inherit;
  font-size: 1.25rem;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev,
.picker .picker__frame .picker__box .picker__header .picker__nav--next {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  line-height: 2.5rem;
  border-radius: 0.125rem;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:before,
.picker .picker__frame .picker__box .picker__header .picker__nav--next:before {
  content: none !important;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:after,
.picker .picker__frame .picker__box .picker__header .picker__nav--next:after {
  font-family: fontawesome;
  border: 0;
  margin: 0 auto;
  font-size: 1.375rem;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:hover,
.picker .picker__frame .picker__box .picker__header .picker__nav--next:hover {
  background-color: var(--primary);
  color: #fff;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev {
  left: 0;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:after {
  content: "\f0d9";
}

.picker .picker__frame .picker__box .picker__header .picker__nav--next {
  right: 0;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--next:after {
  content: "\f0da";
}

.picker .picker__frame .picker__box .picker__table .picker__weekday {
  padding: 0.625rem 0;
  font-size: 1rem;
}

.picker .picker__frame .picker__box .picker__table .picker__day {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 3.125rem;
  padding: 0 !important;
  font-size: 1rem;
  line-height: 2.5rem;
  margin: auto;
  border: 0 !important;
}

.picker .picker__frame .picker__box .picker__table .picker__day.picker__day--today:before {
  content: none !important;
}

.picker .picker__frame .picker__box .picker__table .picker__day.picker__day--highlighted {
  border: 0 !important;
  padding: 0;
  background-color: var(--primary);
  color: #fff !important;
}

.picker .picker__frame .picker__box .picker__table .picker__day:hover {
  background-color: var(--primary);
  color: #fff !important;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--today {
  background-color: var(--primary);
  color: #fff !important;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--today:before {
  content: none !important;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--clear,
.picker .picker__frame .picker__box .picker__footer .picker__button--close,
.picker .picker__frame .picker__box .picker__footer .picker__button--today {
  border: 0;
  border-radius: 0.125rem;
  font-size: 1rem;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--clear:hover,
.picker .picker__frame .picker__box .picker__footer .picker__button--close:hover,
.picker .picker__frame .picker__box .picker__footer .picker__button--today:hover {
  background-color: var(--primary);
  color: #fff !important;
}

@media only screen and (max-width: 35.9375rem) {
  .picker .picker__frame {
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .picker .picker__frame .picker__box {
    padding: 0.9375rem;
    margin: 0 0.625rem;
  }
}

.card-list {
  overflow: unset;
  height: calc(100% - 50px);
}

.card-list .card-header {
  padding-top: 0;
  padding-bottom: 0;
}

.card-list .card-header .photo {
  overflow: hidden;
  border-radius: 0.3125rem;
  width: 100%;
}

@media only screen and (max-width: 74.9375rem) {
  .card-list {
    height: calc(100% - 40px);
  }
}

.card-list.list-left {
  margin-top: 0.9375rem;
  margin-left: 0.9375rem;
}

.card-list.list-left .card-header {
  padding-left: 0;
}

.card-list.list-left .card-header .photo {
  margin: -0.9375rem 0.9375rem 0 -0.9375rem;
}

.card-list.list-right {
  margin-top: 0.9375rem;
  margin-right: 0.9375rem;
  text-align: right;
}

.card-list.list-right .card-header {
  padding-right: 0;
}

.card-list.list-right .card-header .photo {
  margin: -0.9375rem -0.9375rem 0 0.9375rem;
}

.card-list.list-right .card-header .photo img {
  width: 100%;
}

.card-list.list-center {
  margin-top: 0.9375rem;
}

.card-list.list-center .card-header .photo {
  margin: -0.9375rem 0 0 0;
}

.card-list .photo img {
  width: 100%;
}

.card-profile .profile-photo {
  margin-top: -1.25rem;
}

.widget-media .timeline .timeline-panel {
  display: flex;
  align-items: center;
  border-bottom: 0.0625rem solid #eaeaea;
  padding-bottom: 0.9375rem;
  margin-bottom: 0.9375rem;
}

.widget-media .timeline .timeline-panel .media {
  width: 3.125rem;
  height: 3.125rem;
  background: #eee;
  border-radius: 0.75rem;
  overflow: hidden;
  font-size: 1.25rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  align-self: start;
}

.widget-media .timeline .timeline-panel .media-primary {
  background: var(--rgba-primary-1);
  color: var(--primary);
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-primary {
  background-color: var(--rgba-primary-1);
}

.widget-media .timeline .timeline-panel .media-info {
  background: #d3edf5;
  color: #58bad7;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-info {
  background-color: rgba(88, 186, 215, 0.1);
}

.widget-media .timeline .timeline-panel .media-warning {
  background: #fff8e7;
  color: #f0a901;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-warning {
  background-color: rgba(240, 169, 1, 0.1);
}

.widget-media .timeline .timeline-panel .media-danger {
  background: #fcbbbc;
  color: #f75a5b;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-danger {
  background-color: rgba(247, 90, 91, 0.15);
}

.widget-media .timeline .timeline-panel .media-success {
  background: #c9edcc;
  color: #56c760;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-success {
  background-color: rgba(86, 199, 96, 0.1);
}

.widget-media .timeline .timeline-panel .media-body p {
  font-size: 0.875rem;
  line-height: 1.5;
}

.widget-media .timeline .timeline-panel .dropdown {
  align-self: self-end;
  margin-top: 0.3125rem;
}

.widget-media .timeline li:last-child .timeline-panel {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.card[class*="bg-"] .timeline .timeline-panel {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.table {
  color: strong;
  color-color: #6e6e6e;
}

.table th, .table td {
  border-color: #DBDBDB;
  padding: 0.9375rem 0.625rem;
}

[data-theme-version="dark"] .table th, [data-theme-version="dark"] .table td {
  border-color: #333754;
  color: #fff;
}

.table.table-striped tbody tr:nth-of-type(odd),
.table.table-hover tr:hover {
  background-color: #f2f4fa;
}

[data-theme-version="dark"] .table.table-striped tbody tr:nth-of-type(odd), [data-theme-version="dark"] .table.table-hover tr:hover {
  background-color: #20203c;
}

.table.shadow-hover tbody tr:hover {
  background-color: #fff;
  box-shadow: 0 0rem 1.875rem var(--rgba-primary-2);
}

[data-theme-version="dark"] .table.shadow-hover tbody tr:hover {
  background-color: #20203c;
}

.table.tr-rounded tr td:first-child, .table.tr-rounded tr th:first-child {
  border-radius: 2.8125rem 0 0 2.8125rem;
}

.table.tr-rounded tr td:last-child, .table.tr-rounded tr th:last-child {
  border-radius: 0 2.8125rem 2.8125rem 0;
}

.table.border-hover tr td {
  border-width: 0.0625rem 0 0.0625rem 0;
  border-bottom: 0.0625rem solid transparent;
  border-color: transparent;
}

.table.border-hover tr td:first-child {
  border-width: 0.0625rem 0 0.0625rem 0.0625rem;
}

.table.border-hover tr td:last-child {
  border-width: 0.0625rem 0.0625rem 0.0625rem 0;
}

.table.border-hover tr:hover td {
  border-color: #EEEEEE;
}

.table.bg-primary-hover th, .table.bg-primary-hover td {
  border: none;
  font-weight: 500;
}

.table.bg-primary-hover td {
  color: #000;
}

.table.bg-primary-hover th {
  color: #6C6C6C;
}

.table.bg-primary-hover tr:hover td, .table.bg-primary-hover tr:hover th {
  background: var(--primary);
  color: #fff;
}

.table.bg-secondary-hover th, .table.bg-secondary-hover td {
  border: none;
  font-weight: 500;
}

.table.bg-secondary-hover td {
  color: #000;
}

.table.bg-secondary-hover th {
  color: #6C6C6C;
}

.table.bg-secondary-hover tr:hover td, .table.bg-secondary-hover tr:hover th {
  background: #4CBC9A;
  color: #fff !important;
}

.table.bg-info-hover th, .table.bg-info-hover td {
  border: none;
  font-weight: 500;
}

.table.bg-info-hover td {
  color: #000;
}

.table.bg-info-hover th {
  color: #6C6C6C;
}

.table.bg-info-hover tr:hover td, .table.bg-info-hover tr:hover th {
  background: #58bad7;
  color: #fff !important;
}

.table.bg-warning-hover th, .table.bg-warning-hover td {
  border: none;
  font-weight: 500;
}

.table.bg-warning-hover td {
  color: #000;
}

.table.bg-warning-hover th {
  color: #6C6C6C;
}

.table.bg-warning-hover tr:hover td, .table.bg-warning-hover tr:hover th {
  background: #f0a901;
  color: #fff !important;
}

.table.border-no td {
  border: 0;
}

.table.short-one tr td:first-child, .table.short-one tr th:first-child {
  width: 3.75rem;
}

.table thead th {
  border-bottom: 0.0625rem solid #EEEEEE;
  text-transform: capitalize;
  font-size: 1.125rem;
  white-space: nowrap;
  font-weight: 500;
  letter-spacing: 0.0313rem;
  border-color: #DBDBDB !important;
}

[data-theme-version="dark"] .table thead th {
  border-color: #333754 !important;
}

.table tbody tr td {
  vertical-align: middle;
  border-color: #DBDBDB;
  white-space: nowrap;
}

[data-theme-version="dark"] .table tbody tr td {
  /*   border-color: $d-border; */
}

.table:not(.table-bordered) thead th {
  border-top: none;
}

.table .thead-primary th {
  background-color: var(--primary);
  color: #fff;
}

.table .thead-info th {
  background-color: #58bad7;
  color: #fff;
}

.table .thead-warning th {
  background-color: #f0a901;
  color: #fff;
}

.table .thead-danger th {
  background-color: #f75a5b;
  color: #fff;
}

.table .thead-success th {
  background-color: #56c760;
  color: #fff;
}

.table.primary-table-bordered {
  border: 0.0625rem solid #DBDBDB;
}

[data-theme-version="dark"] .table.primary-table-bordered {
  border-color: #333754;
}

.table.primary-table-bg-hover thead th {
  background-color: var(--primary-dark);
  color: #fff;
  border-bottom: none;
}

.table.primary-table-bg-hover tbody tr {
  background-color: var(--primary);
  color: #fff;
  transition: all .1s ease;
}

.table.primary-table-bg-hover tbody tr:hover {
  background-color: var(--rgba-primary-8);
}

.table.primary-table-bg-hover tbody tr:not(:last-child) td, .table.primary-table-bg-hover tbody tr:not(:last-child) th {
  border-bottom: 0.0625rem solid var(--primary-dark);
}

.table-responsive-tiny {
  min-width: 18.75rem;
}

.table-responsive-sm {
  min-width: 30rem !important;
}

.table-responsive-md {
  min-width: 36rem !important;
}

.table-responsive-lg {
  min-width: 60.9375rem !important;
}

.table-responsive-xl {
  min-width: 70.9375rem !important;
}

.table-primary,
.table-primary>th,
.table-primary>td {
  background-color: var(--rgba-primary-1);
  color: var(--primary);
}

[data-theme-version="dark"] .table-primary, [data-theme-version="dark"] .table-primary>th, [data-theme-version="dark"] .table-primary>td {
  background-color: var(--rgba-primary-1);
}

.table-success,
.table-success>th,
.table-success>td {
  background-color: #c9edcc;
  color: #56c760;
}

[data-theme-version="dark"] .table-success, [data-theme-version="dark"] .table-success>th, [data-theme-version="dark"] .table-success>td {
  background-color: rgba(86, 199, 96, 0.1);
}

.table-info,
.table-info>th,
.table-info>td {
  background-color: #d3edf5;
  color: #58bad7;
}

[data-theme-version="dark"] .table-info, [data-theme-version="dark"] .table-info>th, [data-theme-version="dark"] .table-info>td {
  background-color: rgba(88, 186, 215, 0.1);
}

.table-warning,
.table-warning>th,
.table-warning>td {
  background-color: #fff8e7;
  color: #f0a901;
}

[data-theme-version="dark"] .table-warning, [data-theme-version="dark"] .table-warning>th, [data-theme-version="dark"] .table-warning>td {
  background-color: rgba(240, 169, 1, 0.1);
}

.table-danger,
.table-danger>th,
.table-danger>td {
  background-color: #fcbbbc;
  color: #f75a5b;
}

[data-theme-version="dark"] .table-danger, [data-theme-version="dark"] .table-danger>th, [data-theme-version="dark"] .table-danger>td {
  background-color: rgba(247, 90, 91, 0.15);
}

.table-active,
.table-active>th,
.table-active>td {
  background-color: #f2f4fa;
}

[data-theme-version="dark"] .table-active, [data-theme-version="dark"] .table-active>th, [data-theme-version="dark"] .table-active>td {
  background-color: #20203c;
}

.card-table th:first-child, .card-table td:first-child {
  padding-left: 1.875rem;
}

@media only screen and (max-width: 35.9375rem) {
  .card-table th:first-child, .card-table td:first-child {
    padding-left: 0.9375rem;
  }
}

.card-table th:last-child, .card-table td:last-child {
  padding-right: 1.875rem;
}

@media only screen and (max-width: 35.9375rem) {
  .card-table th:last-child, .card-table td:last-child {
    padding-right: 0.9375rem;
  }
}

.bootgrid-header {
  padding: 0 !important;
  margin: 0;
}

@media only screen and (max-width: 35.9375rem) {
  .bootgrid-header .actionBar {
    padding: 0;
  }

  .bootgrid-header .search {
    margin: 0 0 0.625rem 0;
  }
}

table#example {
  padding: 0rem 0 2rem 0;
}

table.dataTable {
  font-size: 0.875rem;
}

#example2_wrapper .dataTables_scrollBody {
  max-height: 33.25rem !important;
}

#employees, #custommers {
  padding: .5rem 0 1rem 0;
}

.dataTables_wrapper .dataTables_paginate {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

table.dataTable thead th, table.dataTable thead td {
  border-bottom: 0.125rem solid #EEEEEE;
  border-top: 0;
}

table.dataTable tfoot th, table.dataTable tfoot td {
  border-top: 0;
}

table.dataTable tbody tr, table.dataTable tbody td {
  background: transparent !important;
}

table.dataTable thead th {
  color: #374557;
  white-space: nowrap;
  font-size: 1.125rem;
  text-transform: capitalize;
  font-weight: 600;
  padding: 1.25rem 0.9375rem;
}

[data-theme-version="dark"] table.dataTable thead th {
  color: #fff;
}

@media only screen and (max-width: 87.5rem) {
  table.dataTable thead th {
    font-size: 1rem;
  }
}

table.dataTable tbody td {
  padding: 1.25rem 0.9375rem;
  font-size: 1rem;
  font-weight: 400;
  border-bottom: 0;
}

@media only screen and (max-width: 35.9375rem) {
  table.dataTable tbody td {
    padding: 0.5rem 0.3125rem;
  }
}

@media only screen and (max-width: 87.5rem) {
  table.dataTable tbody td {
    font-size: 0.875rem;
    padding: 0.5rem 0.9375rem;
  }
}

table.dataTable tr.selected {
  color: var(--primary);
}

table.dataTable tfoot th {
  color: #6e6e6e;
  font-weight: 600;
}

[data-theme-version="dark"] table.dataTable tfoot th {
  color: #fff;
}

/* .dataTables_wrapper .dataTables_paginate .paginate_button{
	border-radius: 12px;
    padding: 12px 20px;
	background:$primary-light;
	color:$primary;
	&:focus,
	&:active,
	&:hover{
		color: $white !important;
		background: $primary;
		border: 0 !important;
		box-shadow: none;
	}
	&.current{
		color: $white !important;
		background: $primary;
		border: 0 !important;
	}
	&.current:hover,
	&.previous:hover,
	&.next:hover{
		background: $primary;
		color: $white !important;
	}
} */
td.text-ov {
  white-space: nowrap;
}

.dataTables_wrapper .dataTables_paginate {
  align-items: center;
  display: flex;
  flex-flow: wrap;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous, .dataTables_wrapper .dataTables_paginate .paginate_button.next {
  font-size: 1.125rem;
  height: 50px;
  width: 50px;
  background: transparent;
  border-radius: 0.625rem;
  padding: 0 0.45rem;
  line-height: 3.125rem;
  margin: 0 0.625rem;
  display: inline-block;
  color: var(--primary) !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous.current:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.previous.previous:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.previous.next:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.next.current:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.next.previous:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.next.next:hover {
  color: #fff !important;
  background: var(--primary) !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.next.disabled {
  color: var(--primary) !important;
}

@media only screen and (max-width: 35.9375rem) {
  .dataTables_wrapper .dataTables_paginate .paginate_button.previous, .dataTables_wrapper .dataTables_paginate .paginate_button.next {
    height: 2.125rem;
    width: fit-content;
    line-height: 2.125rem;
    padding: 0 0.45rem;
  }
}

.dataTables_wrapper .dataTables_paginate span .paginate_button {
  height: 51px;
  width: 51px;
  padding: 0;
  margin-left: 0rem;
  margin: 0 0.125rem;
  line-height: 3.125rem;
  text-align: center;
  font-size: 1.125rem;
  border-radius: 0.625rem;
  border: 0 !important;
  color: var(--primary) !important;
  background: var(--rgba-primary-1) !important;
}

@media only screen and (max-width: 35.9375rem) {
  .dataTables_wrapper .dataTables_paginate span .paginate_button {
    height: 2.125rem;
    width: 2.125rem;
    line-height: 2.325rem;
  }
}

.dataTables_wrapper .dataTables_paginate span .paginate_button.current, .dataTables_wrapper .dataTables_paginate span .paginate_button:hover {
  color: var(--primary) !important;
}

.dataTables_wrapper .dataTables_paginate span .paginate_button.current:hover, .dataTables_wrapper .dataTables_paginate span .paginate_button:hover:hover {
  color: #fff !important;
  background: var(--primary) !important;
}

.dataTables_wrapper input[type="search"], .dataTables_wrapper input[type="text"], .dataTables_wrapper select {
  border: 0.0625rem solid #e2e2e2;
  padding: .3rem 0.5rem;
  color: #715d5d;
  border-radius: 0.3125rem;
}

[data-theme-version="dark"] .dataTables_wrapper input[type="search"], [data-theme-version="dark"] .dataTables_wrapper input[type="text"], [data-theme-version="dark"] .dataTables_wrapper select {
  background: #20203c;
  border-color: #333754;
  color: #fff;
}

.dataTables_wrapper .dataTables_length {
  margin-bottom: 0.9375rem;
}

.dataTables_wrapper .dataTables_length .bootstrap-select {
  width: 5rem;
  margin: 0 0.3125rem;
}

table.dataTable.no-footer {
  border-bottom: 0;
}

.rounded-lg {
  min-width: 1.875rem;
}

.dataTables_scroll {
  padding: 1rem 0;
}

.dataTables_scrollFoot {
  padding-top: 1rem;
}

.dataTablesCard {
  background-color: #fff;
  border-radius: 0.625rem;
}

.dataTablesCard.border-no td {
  border-top: 0 !important;
}

@media (max-width: 991.98px) {
  .dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter {
    text-align: left;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  color: #777777 !important;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: #777777;
  border-radius: 1rem;
  padding: 0.125rem 0;
  margin-bottom: 1.25rem;
}

.paging_simple_numbers.dataTables_paginate {
  padding: 0.3125rem 0.3125rem;
}

@media only screen and (max-width: 35.9375rem) {
  .paging_simple_numbers.dataTables_paginate {
    padding: 0.8125rem 0.3125rem;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  color: #777777 !important;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
  border-color: #EEEEEE;
}

[data-theme-version="dark"] table.dataTable.row-border tbody th, [data-theme-version="dark"] table.dataTable.row-border tbody td, [data-theme-version="dark"] table.dataTable.display tbody th, [data-theme-version="dark"] table.dataTable.display tbody td {
  border-color: #333754;
}

.dataTables_wrapper .dataTables_length .bootstrap-select .dropdown-toggle {
  font-size: 0.813rem !important;
  padding: 0.625rem 1rem;
}

.fooicon {
  font-size: 1.25rem;
  color: #6e6e6e;
}

.jsgrid-table .jsgrid-header-row>.jsgrid-header-cell {
  color: #6e6e6e;
}

.jsgrid-table>tbody>tr>td {
  padding: 1.2em;
}

.jsgrid-table .jsgrid-insert-row input, .jsgrid-table .jsgrid-insert-row select, .jsgrid-table .jsgrid-edit-row input, .jsgrid-table .jsgrid-edit-row select {
  border: 0.0625rem solid #DDDFE1;
}

.jsgrid .jsgrid-button {
  border: 0 !important;
  margin-left: 0.625rem;
}

.error-page .error-text {
  font-size: 9.375rem;
  line-height: 1;
}

@media only screen and (max-width: 35.9375rem) {
  .error-page .error-text {
    font-size: 5rem;
  }
}

.error-page h4, .error-page .h4 {
  font-size: 2.5rem;
  margin-bottom: 0.3125rem;
}

@media only screen and (max-width: 35.9375rem) {
  .error-page h4, .error-page .h4 {
    font-size: 1.25rem;
  }
}

.error-page p {
  font-size: 1rem;
}

@media only screen and (max-width: 35.9375rem) {
  .error-page p {
    font-size: 0.875rem;
  }
}

.flex-row-fluid {
  -webkit-box-flex: 1;
  flex: 1 auto;
  -ms-flex: 1 0 0rem;
  min-width: 0;
}

.authincation {
  background: var(--rgba-primary-1);
  display: flex;
  min-height: 100vh;
}

.authincation .login-aside {
  background: #fff;
  padding-top: 5rem;
  max-width: 35rem;
  width: 100%;
  z-index: 1;
  position: relative;
}

.authincation .login-aside:after {
  content: "";
  clip-path: polygon(0% 100%, 100% 0%, 0% 0%);
  width: 8.75rem;
  height: 100%;
  position: absolute;
  right: -8.75rem;
  z-index: -1;
  top: 0;
  background: #fff;
  box-shadow: 0.125rem 0rem 1.875rem rgba(0, 0, 0, 0.15);
}

.authincation .login-aside .aside-image {
  min-height: 28.125rem;
  margin: auto 0;
  min-width: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-width: 87.5rem) {
  .authincation .login-aside {
    max-width: 22.5rem;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .authincation .login-aside {
    max-width: 100%;
    padding-top: 0;
  }

  .authincation .login-aside:after {
    content: none;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .authincation .login-aside .aside-image {
    min-height: 18.75rem;
  }
}

.authincation-content {
  background: #fff;
  box-shadow: 0 0 2.1875rem 0 rgba(154, 161, 171, 0.15);
  border-radius: 0.3125rem;
}

[data-theme-version="dark"] .authincation-content {
  background: #292d4a;
  box-shadow: none;
}

.authincation-content.style-1 {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(1.25rem);
}

.authincation-content.style-1 .form-control {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 0.3125rem;
}

.authincation-content.style-1 .user-icon {
  height: 6.25rem;
  background: var(--primary);
  width: 6.25rem;
  text-align: center;
  border-radius: 6.25rem;
  line-height: 6.25rem;
  margin-left: auto;
  font-size: 3.75rem;
  text-align: center;
  color: white;
  margin-right: auto;
  margin-top: -6.25rem;
  margin-bottom: 1.25rem;
}

.authincation-content.style-2 {
  background: transparent;
  box-shadow: none;
  max-width: 33.125rem;
  width: 100%;
}

.authincation-content.style-2 .form-control {
  border: 0;
  border-radius: 0.3125rem;
  box-shadow: 0rem 0rem 0.9375rem rgba(0, 0, 0, 0.08);
}

@media only screen and (max-width: 35.9375rem) {
  .authincation-content.style-2 .auth-form {
    padding: 1.875rem 0rem;
  }
}

.auth-form img {
  width: 220px;
}

.welcome-content {
  background: url("./../images/1.jpg");
  background-size: cover;
  background-position: center;
  height: 100%;
  padding: 4.6875rem 3.125rem;
  position: relative;
  z-index: 1;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.welcome-content::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: var(--primary);
  opacity: 0.75;
  z-index: -1;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.welcome-content .welcome-title {
  color: #fff;
  font-weight: 500;
  font-size: 1.25rem;
}

.welcome-content p {
  color: #fff;
}

.welcome-content .brand-logo a {
  display: inline-block;
  margin-bottom: 1.25rem;
  font-weight: 700;
  color: #fff;
  font-size: 1.25rem;
}

.welcome-content .brand-logo a img {
  width: 6.25rem;
}

.welcome-content .intro-social {
  position: absolute;
  bottom: 4.6875rem;
}

.welcome-content .intro-social ul {
  margin-bottom: 0;
}

.welcome-content .intro-social ul li {
  display: inline-block;
}

.welcome-content .intro-social ul li a {
  color: #fff;
  font-size: 0.875rem;
  padding: 0rem 0.4375rem;
}

.auth-form {
  padding: 3.125rem 3.125rem;
}

@media only screen and (max-width: 35.9375rem) {
  .auth-form {
    padding: 1.875rem;
  }
}

.auth-form .btn {
  height: 3.125rem;
  font-weight: 700;
}

.auth-form .page-back {
  display: inline-block;
  margin-bottom: 0.9375rem;
}

.login-main-page {
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.login-main-page .login-wrapper {
  flex: 1;
}

.heart-blast {
  background-position: -1680px 0 !important;
  transition: background 1s steps(28);
}

.heart {
  width: 60px;
  height: 60px;
  display: inline-block;
  background: url("../images/like.png");
  cursor: pointer;
  margin: -25px -15px;
}

.plus-box {
  background: var(--rgba-primary-1);
  font-size: 0.875rem;
  padding: 2rem 2rem;
  overflow: hidden;
  margin: 0 1.5rem;
  border-radius: 1rem;
  align-items: center;
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
  position: relative;
}

.plus-box:before {
  position: absolute;
  content: "";
  top: 1.2rem;
  left: 1rem;
  background-image: url(../images/vector.png);
  background-repeat: no-repeat;
  height: 3rem;
  width: 3rem;
}

.plus-box h5, .plus-box .h5 {
  font-size: 1.125rem;
  font-weight: 700;
}

@media only screen and (max-width: 74.9375rem) {
  .plus-box h5, .plus-box .h5 {
    font-size: 1rem;
  }
}

.plus-box p {
  font-size: 0.875rem;
  font-weight: 400;
  color: #A098AE;
  margin-bottom: 1.5625rem;
}

@media only screen and (max-width: 87.5rem) {
  .plus-box {
    margin: 1.25rem 1.25rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .plus-box img {
    width: 38px;
  }
}

.search-coundry .dashboard-select {
  font-size: 0.875rem;
  font-weight: 500;
  color: #000;
  background-color: transparent;
  border: 0;
  padding-left: 0.625rem;
}

.search-coundry .dashboard-select:after {
  right: 0;
  border-bottom: 0.125rem solid #000000;
  border-right: 0.125rem solid #000000;
}

.dlab-join-card {
  position: relative;
  background-image: linear-gradient(to right, var(--secondary) 0, var(--secondary-dark) 30%, var(--secondary) 100%);
  margin-top: 1rem;
  z-index: 1;
  box-shadow: 0 0 1.875rem var(--rgba-secondary-1);
}

@media only screen and (max-width: 106.25rem) {
  .dlab-join-card {
    margin-top: 0;
  }
}

.dlab-join-card .dlab-media {
  padding: 0.75rem 0;
}

.dlab-join-card .dlab-media .dlab-content {
  width: 60%;
}

.dlab-join-card .dlab-media .dlab-content h4, .dlab-join-card .dlab-media .dlab-content .h4 {
  font-size: 1.75rem;
  font-weight: 700;
  color: #fff;
}

.dlab-join-card .dlab-media .dlab-content p {
  font-size: 0.9375rem;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 35.9375rem) {
  .dlab-join-card .dlab-media .dlab-content {
    width: 100%;
    padding-right: 9rem;
  }

  .dlab-join-card .dlab-media .dlab-content h4, .dlab-join-card .dlab-media .dlab-content .h4 {
    font-size: .85rem;
  }

  .dlab-join-card .dlab-media .dlab-content p {
    font-size: 0.75rem;
    line-height: 1.5;
    margin-bottom: 0;
  }
}

.dlab-join-card .dlab-media .dlab-img {
  width: 39%;
  position: absolute;
  right: 2rem;
  bottom: 0;
  text-align: center;
}

.dlab-join-card .dlab-media .dlab-img img {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  width: 97%;
}

@media only screen and (max-width: 74.9375rem) {
  .dlab-join-card .dlab-media .dlab-img img {
    width: 82%;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .dlab-join-card .dlab-media .dlab-img {
    width: 9rem;
    z-index: 1;
    right: 0;
  }

  .dlab-join-card .dlab-media .dlab-img img {
    width: 100%;
  }
}

.dlab-join-card .dlab-media .dlab-icon .cal-img {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  position: absolute;
  right: 0.8125rem;
  transform: translateY(2.3125rem);
}

.dlab-join-card .dlab-media .dlab-icon .book-img {
  position: absolute;
  top: 0;
  right: 47%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dlab-join-card:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  background-image: url(../images/vector/ellipse.png);
  height: 100%;
  width: 100%;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: left bottom;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dlab-join-card:hover .dlab-media .dlab-icon img {
  transform: scale(1.3);
}

.dlab-join-card:hover .dlab-media .dlab-img img {
  filter: grayscale(1);
}

@media only screen and (max-width: 100rem) {
  .dlab-join-card.h-auto {
    height: 91% !important;
  }
}

.dlab-cource {
  position: relative;
  padding: 1.125rem 0.8125rem;
  border-radius: 0.625rem;
  background: #21262E;
  margin-bottom: 1.875rem;
  height: calc(100% - 1.875rem);
  display: flex;
  overflow: hidden;
  align-items: center;
}

@media only screen and (max-width: 35.9375rem) {
  .dlab-cource {
    margin-bottom: 0.938rem;
    height: auto;
  }
}

.dlab-cource img {
  position: absolute;
  top: 0.9375rem;
  right: 0;
  opacity: 0.3;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dlab-cource .bookmarks {
  opacity: 1;
  top: 0.5rem;
  transform: scale(1);
}

.dlab-cource .bulb {
  top: 0.4375rem;
}

.dlab-cource h4, .dlab-cource .h4 {
  font-size: 1.75rem;
  color: white;
  margin: 0;
  font-weight: 700;
  line-height: 1.2;
}

.dlab-cource span {
  font-size: 0.875rem;
  font-weight: 600;
  color: white;
}

.dlab-cource .course-icon {
  height: 3.4375rem;
  width: 3.4375rem;
  background: white;
  border-radius: 0.625rem;
  text-align: center;
  line-height: 3.4375rem;
  display: block;
}

@media only screen and (max-width: 106.25rem) {
  .dlab-cource .course-icon {
    height: 3.125rem;
    width: 3.125rem;
    line-height: 3.125rem;
  }
}

.dlab-cource:hover img {
  transform: scale(1.3);
}

.score-active ul li {
  font-size: 0.875rem;
  font-weight: 400;
  color: #A098AE;
  padding: 0 0.9375rem;
  white-space: nowrap;
  padding-left: 0;
  cursor: pointer;
}

.score-active .dashboard-select {
  margin-right: 1.25rem;
  font-size: 0.875rem;
  font-weight: 400;
  background-color: transparent;
  border: 0;
  color: black;
  border-radius: 0.625rem;
  padding-right: 2.5rem;
  min-width: auto;
}

.score-active .dashboard-select:after {
  border-bottom: 0.125rem solid #A098AE;
  border-right: 0.125rem solid #A098AE;
  margin-top: -0.5rem;
}

.score-active.style-1 .dashboard-select {
  background: #fafafa;
  border-radius: 0.625rem;
}

.custome-tooltip .apexcharts-theme-light .apexcharts-tooltip.apexcharts-theme-light {
  background: black;
  color: white;
  border: 0;
  font-size: 1.125rem;
  font-weight: 600;
  overflow: visible;
  border-radius: 0.5rem;
  padding: 0.625rem 0rem;
  width: 6rem;
}

.custome-tooltip .apexcharts-theme-light .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: #000000;
  text-align: center;
  border: 0;
}

.custome-tooltip .apexcharts-theme-light .apexcharts-tooltip.apexcharts-theme-light:after {
  content: "";
  position: absolute;
  height: 0.8125rem;
  width: 0.8125rem;
  background: #000;
  bottom: -0.3125rem;
  left: 44%;
  transform: rotate(45deg);
}

.custome-tooltip.style-1 .apexcharts-theme-light .apexcharts-tooltip.apexcharts-theme-light {
  background: #4CBC9A;
}

.custome-tooltip.style-1 .apexcharts-theme-light .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: #4CBC9A;
}

.custome-tooltip.style-1 .apexcharts-theme-light .apexcharts-tooltip.apexcharts-theme-light:after {
  background: #4CBC9A;
}

.redial-content {
  text-align: center;
  margin-top: 15px;
}

.card-calendar {
  background: var(--primary);
  border-radius: inherit;
}

.card-calendar .bootstrap-datetimepicker-widget {
  width: 100%;
}

.card-calendar .bootstrap-datetimepicker-widget table .picker-switch {
  font-size: 1.125rem;
  font-weight: 500;
  color: #fff;
  padding: 0;
  pointer-events: none;
}

.card-calendar .bootstrap-datetimepicker-widget table .picker-switch:hover {
  background: transparent;
  color: #fff !important;
}

.card-calendar .bootstrap-datetimepicker-widget table .month {
  color: #fff;
}

.card-calendar .bootstrap-datetimepicker-widget table .month.active {
  background: black;
}

.card-calendar .bootstrap-datetimepicker-widget .picker-switch td {
  display: none;
}

.card-calendar .bootstrap-datetimepicker-widget .table-condensed .dow {
  color: #fff;
  font-size: 1.125rem;
  font-weight: 500;
  padding-top: 1.25rem;
}

.card-calendar .bootstrap-datetimepicker-widget .table-condensed .prev {
  position: absolute;
  left: 20%;
  top: 4%;
  color: #fff;
  height: 2.8125rem;
  width: 2.8125rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-calendar .bootstrap-datetimepicker-widget .table-condensed .prev:hover {
  background: white;
}

.card-calendar .bootstrap-datetimepicker-widget .table-condensed .prev:hover i {
  color: var(--primary);
}

.card-calendar .bootstrap-datetimepicker-widget .table-condensed .next {
  position: absolute;
  right: 20%;
  top: 4%;
  color: #fff;
  height: 2.8125rem;
  width: 2.8125rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-calendar .bootstrap-datetimepicker-widget .table-condensed .next:hover {
  background: white;
}

.card-calendar .bootstrap-datetimepicker-widget .table-condensed .next:hover i {
  color: var(--primary);
}

.card-calendar .bootstrap-datetimepicker-widget .table-condensed td.day,
.card-calendar .bootstrap-datetimepicker-widget .table-condensed td.month,
.card-calendar .bootstrap-datetimepicker-widget .table-condensed td.year {
  font-size: 1.125rem;
  font-weight: 400;
  color: #fff !important;
  height: 2.8125rem;
  width: 2.8125rem;
}

.card-calendar .bootstrap-datetimepicker-widget .table-condensed td.day.today,
.card-calendar .bootstrap-datetimepicker-widget .table-condensed td.month.today,
.card-calendar .bootstrap-datetimepicker-widget .table-condensed td.year.today {
  background: #fff !important;
  color: black !important;
}

.card-calendar .bootstrap-datetimepicker-widget .table-condensed td.day.active,
.card-calendar .bootstrap-datetimepicker-widget .table-condensed td.month.active,
.card-calendar .bootstrap-datetimepicker-widget .table-condensed td.year.active {
  background: #fff !important;
  color: var(--primary) !important;
}

.card-calendar .bootstrap-datetimepicker-widget .table-condensed td.day:hover,
.card-calendar .bootstrap-datetimepicker-widget .table-condensed td.month:hover,
.card-calendar .bootstrap-datetimepicker-widget .table-condensed td.year:hover {
  color: var(--primary) !important;
}

.card-calendar.style-1 {
  background: transparent;
}

.card-calendar.style-1 .bootstrap-datetimepicker-widget table .picker-switch {
  color: #374557;
}

.card-calendar.style-1 .bootstrap-datetimepicker-widget table .picker-switch:hover {
  background: transparent;
  color: #777777 !important;
}

.card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed {
  position: relative;
}

.card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed .prev {
  color: #777777;
  left: 0%;
  top: -4%;
}

.card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed .prev:hover {
  background: var(--primary);
}

.card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed .prev:hover i {
  color: #fff;
}

@media only screen and (max-width: 35.9375rem) {
  .card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed .prev {
    left: 19%;
    top: -4%;
  }
}

.card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed .next {
  color: #777777;
  right: 0%;
  top: -4%;
}

.card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed .next:hover {
  background: var(--primary);
}

.card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed .next:hover i {
  color: #fff;
}

@media only screen and (max-width: 35.9375rem) {
  .card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed .next {
    left: 19%;
    top: -4%;
  }
}

.card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed td.day {
  color: #374557 !important;
  border-radius: 0.625rem;
}

.card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed td.day.today {
  background: #4CBC9A;
  color: black;
}

.card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed td.day.active, .card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed td.day.today {
  background: var(--secondary) !important;
  box-shadow: none;
  color: #fff !important;
}

.card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed td.day.active:before, .card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed td.day.today:before {
  height: 0.8125rem;
  width: 0.8125rem;
  border-radius: 0.8125rem;
  background-color: #374557;
  border: 0.125rem solid #fff;
  top: -0.0625rem;
  right: -0.0625rem;
}

.card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed td.day:hover {
  color: black;
}

.card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed .dow {
  color: #A098AE;
  font-weight: 300;
}

.card-calendar.active-cal .bootstrap-datetimepicker-widget .table-condensed .dow {
  font-size: 1rem;
  padding-top: 1rem;
}

.card-calendar.active-cal .bootstrap-datetimepicker-widget .table-condensed td.day {
  height: 1.875rem;
  width: 1.875rem;
  font-size: 1rem;
}

#main-wrapper .ellipse .green-line {
  position: absolute;
  top: 0;
  right: 0;
  opacity: .6;
  z-index: -1;
}

#main-wrapper .ellipse .red-line {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: .3;
  z-index: -1;
}

#main-wrapper:after {
  background: var(--primary);
  right: -5rem;
  bottom: -5rem;
}

#main-wrapper:before {
  background: var(--secondary);
  top: -8.375rem;
  right: 35%;
}

#main-wrapper:after, #main-wrapper:before {
  content: "";
  position: fixed;
  filter: blur(12.5rem);
  width: 17.125rem;
  height: 17.125rem;
  border-radius: 100%;
  z-index: -3;
  opacity: .5;
}

.dlab-side-menu {
  position: fixed;
  background: white;
  text-align: center;
  height: auto;
  bottom: 1.25rem;
  top: 1.25rem;
  right: 0;
  border-radius: 1.875rem 0 0 1.875rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  padding: 1.5625rem 0.75rem;
  height: calc(100vh - 2.5rem);
  width: 5.625rem;
}

.dlab-side-menu .search-coundry {
  flex-direction: column;
}

.dlab-side-menu .search-coundry .list {
  top: -3.875rem;
  left: -4.8125rem;
}

@media only screen and (max-width: 47.9375rem) {
  .dlab-side-menu .search-coundry .list {
    top: unset;
    left: 0rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .dlab-side-menu {
    position: unset;
    flex-direction: row;
    height: auto;
    width: auto;
    background: none;
    padding: 0;
    align-items: center;
  }

  .dlab-side-menu .sidebar-social-link>ul {
    display: flex;
  }

  .dlab-side-menu .search-coundry {
    flex-direction: row;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .dlab-side-menu .search-coundry {
    display: none !important;
  }
}

@media only screen and (max-width: 100rem) {
  .bt-order {
    order: 1;
  }
}

.facebook-log {
  color: #1b74e4;
}

.login-social a {
  background: var(--rgba-primary-1);
}

.sign-in-your {
  padding: 3.125rem 1.875rem;
}

.sign {
  background: var(--rgba-primary-1);
  position: relative;
}

.sign .education-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

@media only screen and (max-width: 47.9375rem) {
  .sign .education-img {
    position: unset;
  }
}

.modal-header .close {
  border: 0;
}

.ps--active-x>.ps__rail-x, .ps--active-y>.ps__rail-y {
  z-index: -1;
}

.nice-select .list {
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0rem 0.4375rem 1.8125rem 0rem;
}

.nice-select .option {
  color: #737B8B;
}

.nice-select .option.selected {
  color: #000;
  font-weight: 400;
}

.flex-1 {
  flex: 1;
}

.skil-list {
  display: flex;
  flex-wrap: wrap;
}

.skil-list li {
  width: 50%;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  margin-bottom: .25rem;
}

.skil-list li svg {
  margin-right: .5rem;
}

.dropzone .dlab-message {
  margin: 5em 0;
}

.home-calendar .react-calendar {
  background: inherit;
}

.home-calendar .react-calendar .react-calendar__navigation {
  height: auto;
}

.home-calendar .react-calendar .react-calendar__navigation:hover {
  color: var(--primary) !important;
}

.home-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__label__labelText,
.home-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__label__labelText--from {
  font-size: 1.125rem;
  font-weight: 500;
  color: #fff;
  padding: 0;
  pointer-events: none;
}

.home-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow {
  color: #fff;
}

.home-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__label {
  pointer-events: none;
}

.home-calendar .react-calendar .react-calendar__month-view__weekdays {
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
}

.home-calendar .react-calendar .react-calendar__month-view__days__day {
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
}

.home-calendar .react-calendar .react-calendar__month-view__days__day.react-calendar__tile--now {
  background-color: #fff !important;
  color: #000;
}

.home-calendar .react-calendar .react-calendar__tile:hover {
  color: var(--primary) !important;
}

.home-calendar .react-calendar .react-calendar__tile.react-calendar__tile--now:before, .home-calendar .react-calendar .react-calendar__tile.react-calendar__tile--active:before, .home-calendar .react-calendar .react-calendar__tile.react-calendar__tile--range:before {
  content: none !important;
}

.home-calendar .react-calendar button.react-calendar__tile.react-calendar__month-view__days__day {
  height: 53px;
}

@media only screen and (max-width: 87.5rem) {
  .home-calendar .react-calendar button.react-calendar__tile.react-calendar__month-view__days__day {
    height: 40px;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .home-calendar .react-calendar .react-calendar__month-view__weekdays {
    font-size: 0.875rem;
  }
}

.home-calendar button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  background: white !important;
  color: var(--primary) !important;
}

.select-dropdown {
  transition: all 0.5s;
}

.select-dropdown .selectBtn {
  cursor: pointer;
  height: 42px;
  line-height: 42px;
  padding-left: 15px;
  padding-right: 0;
  background-color: transparent !important;
  color: #000 !important;
}

.select-dropdown.show {
  box-shadow: 0 0 0 0.25rem rgba(132, 132, 132, 0.5);
  border-radius: 0.625rem;
}

.select-dropdown .dropdown-menu {
  min-width: 7rem;
  inset: 8px auto auto 0px !important;
}

.select-dropdown .dropdown-menu .dropdown-item {
  padding: 6px 20px;
}

.widget-heading {
  margin-bottom: 0.8125rem;
  flex-flow: wrap;
}

.widget-heading a {
  font-size: 1.125rem;
  font-weight: 400;
}

.widget-courses h4, .widget-courses .h4 {
  font-size: 1.125rem;
  font-weight: 500;
}

.widget-courses .schedule-icon {
  height: 4rem;
  width: 4rem;
  display: block;
  text-align: center;
  line-height: 4rem;
  border-radius: 50%;
  color: #374557;
  font-size: 1.125rem;
  font-weight: 600;
  margin-right: 1.25rem;
}

@media only screen and (max-width: 35.9375rem) {
  .widget-courses .schedule-icon {
    margin-bottom: 0.625rem;
    height: 3.125rem;
    width: 3.125rem;
    line-height: 3.125rem;
    font-size: 0.875rem;
  }
}

.widget-courses i {
  transform: scale(1.2);
}

.widget-courses.style-1 h4, .widget-courses.style-1 .h4 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0;
}

@media only screen and (max-width: 47.9375rem) {
  .widget-courses.style-1 h4, .widget-courses.style-1 .h4 {
    font-size: 1.125rem;
  }
}

.widget-courses.style-1 img {
  width: 3.125rem;
}

.widget-courses.style-1 span {
  font-size: 1.125rem;
}

@media only screen and (max-width: 47.9375rem) {
  .widget-courses.style-1 span {
    font-size: 0.875rem;
  }
}

@media only screen and (max-width: 106.25rem) {
  .widget-courses.style-1 img {
    width: 3.125rem;
  }
}

.widget-courses.style-2 h4, .widget-courses.style-2 .h4 {
  color: #fff;
}

.widget-courses.style-2 span {
  color: #fff;
}

.widget-courses.style-2:hover .peity {
  transform: scale(1.3);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.courses-bx .dlab-media img {
  width: 100%;
  height: 15rem;
  border-radius: 0.625rem;
  object-fit: cover;
}

.courses-bx .dlab-info .dlab-title {
  margin: 1.25rem 0;
}

.courses-bx .dlab-info .dlab-title h4, .courses-bx .dlab-info .dlab-title .h4 {
  margin-bottom: 0;
}

.courses-bx .dlab-info .dlab-title h4 a, .courses-bx .dlab-info .dlab-title .h4 a {
  color: #000;
}

.courses-bx .dlab-info .dlab-title p span {
  padding: 0 0.375rem;
  align-items: center;
}

.courses-bx .dlab-info .dlab-title p span svg {
  margin-left: 0.3125rem;
  margin-top: -0.3125rem;
}

.courses-bx .dlab-info .content {
  font-size: 0.875rem;
  font-weight: 400;
  flex-flow: wrap;
}

.pagination-down h4, .pagination-down .h4 {
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0;
}

.pagination-down h4 span, .pagination-down .h4 span {
  color: var(--primary);
}

.pagination-down ul {
  display: flex;
  align-items: center;
}

.pagination-down ul li a {
  height: 3.1875rem;
  width: 3.1875rem;
  border-radius: 0.5rem;
  text-align: center;
  background: #fff;
  display: block;
  line-height: 3.1875rem;
  margin: 0 0.625rem;
  font-size: 1.125rem;
  color: var(--primary);
}

@media only screen and (max-width: 35.9375rem) {
  .pagination-down ul li a {
    height: 2.1875rem;
    width: 2.1875rem;
    font-size: 0.9375rem;
    line-height: 2.1875rem;
  }
}

.pagination-down ul li a:last-child {
  margin-right: 0;
}

.pagination-down ul li a.active, .pagination-down ul li a:hover {
  background: var(--primary);
  color: #fff;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 35.9375rem) {
  .pagination-down ul li:first-child a {
    margin-left: 0;
  }
}

/* course details */
.course-dedails-bx h2, .course-dedails-bx .h2 {
  font-size: 1.5rem;
  width: 70%;
}

@media only screen and (max-width: 35.9375rem) {
  .course-dedails-bx h2, .course-dedails-bx .h2 {
    font-size: 1.125rem;
  }
}

.course-dedails-bx .description p {
  font-weight: 400;
  margin: 0;
}

@media only screen and (max-width: 35.9375rem) {
  .course-dedails-bx .description p {
    font-size: 0.875rem;
  }
}

.course-dedails-bx .description .user-pic {
  display: flex;
  align-items: center;
}

.course-dedails-bx .description .user-pic img {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 0.5rem;
  margin-right: 0.9375rem;
}

.course-dedails-bx .description .user-pic span {
  font-size: 1rem;
  font-weight: 400;
  color: #374557;
}

.star-orange {
  color: #FFD687;
}

.course-details-tab {
  padding: 1.5625rem 0;
}

.course-details-tab h4, .course-details-tab .h4 {
  font-size: 1.125rem;
}

@media only screen and (max-width: 35.9375rem) {
  .course-details-tab p {
    font-size: 0.875rem;
  }
}

.course-details-tab .nav-tabs {
  border-bottom: 0.125rem solid #DBDBDB;
}

.course-details-tab .nav-tabs .nav-link {
  font-size: 1.125rem;
  font-weight: 600;
  background: transparent;
  padding: 1rem 0.875rem;
  border: 0;
}

.course-details-tab .nav-tabs .nav-link:first-child {
  margin-left: 0;
}

.course-details-tab .nav-tabs .nav-link.active {
  color: var(--primary);
  border-bottom: 0.1875rem solid var(--primary) !important;
  padding-bottom: 0.875rem;
}

.course-details-tab .about-content {
  margin-top: 1.875rem;
}

.course-details-tab .user-pic2 {
  padding-top: 2.375rem;
}

.course-details-tab .user-pic2 p {
  margin: 0;
  margin-top: 1rem;
  color: #374557;
}

.course-details-tab .user-pic2 img {
  height: 3.75rem;
  width: 3.75rem;
  border-radius: 0.5rem;
}

.course-details-tab .user-pic2:hover h4, .course-details-tab .user-pic2:hover .h4 {
  color: var(--primary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.course-details-tab.style-1 .nav-tabs {
  border-bottom: 0;
}

.course-details-tab.style-1 .nav-tabs .nav-link {
  border-bottom: 0.125rem solid #DBDBDB;
}

@media only screen and (max-width: 35.9375rem) {
  .course-details-tab.style-1 .nav-tabs .nav-link {
    padding: 1rem 0.875rem;
    font-size: 1rem;
  }
}

.course-details-tab.style-2 {
  padding: 0;
}

.course-details-tab.style-2.tab-lg .nav-tabs .nav-link {
  font-size: 1.125rem;
}

@media only screen and (max-width: 35.9375rem) {
  .course-details-tab.style-2.tab-lg .nav-tabs .nav-link {
    font-size: .85rem;
    padding: 8px 8px;
  }
}

.course-details-tab.style-2 .nav-tabs .nav-link {
  font-size: 0.875rem;
  color: #A098AE;
  margin-bottom: 0;
  border: 0 !important;
  flex: 1;
  position: relative;
  padding: 0.75rem 1rem;
}

.course-details-tab.style-2 .nav-tabs .nav-link:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.125rem;
  height: 0.1875rem;
  width: 0;
  background: var(--primary);
  border-radius: 0.1875rem;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.course-details-tab.style-2 .nav-tabs .nav-link.active {
  color: var(--primary);
}

.course-details-tab.style-2 .nav-tabs .nav-link.active:after {
  width: 100%;
}

@media only screen and (max-width: 106.25rem) {
  .course-details-tab.style-2 .nav-tabs .nav-link {
    padding: 1rem 1rem;
  }
}

.course-details-tab.style-3 .nav-tabs .nav-link {
  padding: 1rem 1.125rem;
  position: relative;
}

.course-details-tab.style-3 .nav-tabs .nav-link:after {
  content: none;
  position: absolute;
  top: 0.75rem;
  right: 0;
  height: 0.5rem;
  width: 0.5rem;
  background: var(--primary);
  border-radius: 3.125rem;
}

.course-details-tab.style-3 .nav-tabs .nav-link.active:after {
  content: "";
}

.course-details-tab .nav-tabs .nav-link:hover {
  color: var(--rgba-primary-9);
}

.raiting {
  margin: 1.25rem 0;
}

.raiting li {
  font-weight: 400;
  padding: 0 1.125rem;
  padding-left: 1.125rem;
  border-right: 0.125rem solid #DBDBDB;
}

@media only screen and (max-width: 35.9375rem) {
  .raiting li {
    padding: 0 0.8125rem;
  }
}

.raiting li:first-child {
  padding-left: 0;
}

.raiting li:last-child {
  border-right: 0;
}

@media only screen and (max-width: 35.9375rem) {
  .raiting li:last-child {
    padding-left: 6px;
  }
}

.video-img .view-demo {
  position: relative;
  overflow: hidden;
}

.video-img .view-demo img {
  width: 100%;
  border-radius: 0.625rem;
  object-fit: cover;
  height: 25rem;
}

@media only screen and (max-width: 35.9375rem) {
  .video-img .view-demo img {
    height: 12rem;
  }
}

.video-img .view-demo:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(84, 124, 175, 0.25);
  z-index: 1;
  border-radius: 0.625rem;
}

.video-img .view-demo a {
  height: 4.375rem;
  width: 4.375rem;
  display: block;
  position: relative;
  z-index: 2;
  margin: auto;
}

.video-img .view-demo a svg path fill {
  fill: #fff;
}

.video-img .view-demo .play-button {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -3.125rem);
  z-index: 2;
  width: 100%;
}

.video-img .view-demo .play-button h4, .video-img .view-demo .play-button .h4 {
  font-size: 1.5rem;
  color: white;
  position: relative;
  z-index: 2;
  margin-top: 0.625rem;
}

@media only screen and (max-width: 35.9375rem) {
  .video-img .view-demo .play-button svg {
    width: 3.125rem;
  }

  .video-img .view-demo .play-button h4, .video-img .view-demo .play-button .h4 {
    font-size: 1rem;
  }
}

.video-img.style-1 .view-demo img {
  height: 35rem;
}

@media only screen and (max-width: 35.9375rem) {
  .video-img.style-1 .view-demo img {
    height: 13.125rem;
  }
}

.course-prise {
  padding: 2.1875rem 0;
}

.course-prise h4, .course-prise .h4 {
  font-size: 1.5rem;
  align-items: center;
  display: flex;
}

.course-prise h4 small, .course-prise .h4 small, .course-prise h4 .small, .course-prise .h4 .small {
  font-size: 1.125rem;
  font-weight: 400;
  color: #777777;
  margin-left: 0.625rem;
}

.course-prise span {
  font-size: 0.875rem;
  font-weight: 500;
}

.course-prise span:hover svg {
  transform: scale(1.2);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.course-learn h4, .course-learn .h4 {
  font-size: 1.125rem;
  margin-bottom: 0.875rem;
}

.course-learn ul li {
  width: 50%;
  display: flex;
  float: left;
  color: #374557;
  padding: 0.5625rem 0rem;
}

.course-learn ul li svg {
  margin-right: 0.625rem;
  margin-top: .1rem;
}

@media only screen and (max-width: 35.9375rem) {
  .course-learn ul li {
    width: 100%;
  }
}

/* Course-details 2 */
.course-content {
  margin-bottom: 1.875rem;
}

.course-content h3, .course-content .h3 {
  font-size: 1.5rem;
}

@media only screen and (max-width: 35.9375rem) {
  .course-content h3, .course-content .h3 {
    font-size: 1.125rem;
  }
}

.course-content svg {
  margin: 0 0.625rem;
  cursor: pointer;
}

.course-content svg:hover path {
  fill: var(--primary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.progress-box h4, .progress-box .h4 {
  font-size: 1.5rem;
}

.progress-box .progress {
  height: 0.75rem;
  border-radius: 0.25rem;
  margin: 0.5625rem 0;
}

.custome-accordion .accordion-item {
  margin-bottom: 1rem;
}

.custome-accordion .accordion-header {
  padding: 0.3rem 1.75rem;
  border: 0;
}

@media only screen and (max-width: 35.9375rem) {
  .custome-accordion .accordion-header {
    padding: 0.3rem 1.05rem;
  }
}

.custome-accordion .accordion-header .acc-heading {
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: 600;
  color: #374557;
  margin-right: auto;
}

.custome-accordion .accordion-header span {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #777777;
}

.custome-accordion .accordion-header:hover .acc-heading {
  color: var(--primary);
}

.custome-accordion .accordion-button {
  background: white;
  border: 0;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  align-items: center;
}

.custome-accordion .accordion-button.collapsed:after {
  background-color: var(--rgba-primary-5);
}

.custome-accordion .accordion-button:after {
  content: "";
  height: 2rem;
  width: 2rem;
  background-color: var(--primary);
  background-position: center;
  border-radius: 50%;
  margin-left: 0.625rem;
  display: block;
  position: unset;
  transform: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.custome-accordion .accordion-button:not(.collapsed)::after {
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  transform: rotate(180deg);
}

.custome-accordion .accordion-collapse {
  border: 0;
}

.custome-accordion .acc-courses {
  background: #EEE;
  padding: 1.1rem 1.25rem;
  border-radius: 0.625rem;
  cursor: pointer;
}

@media only screen and (max-width: 35.9375rem) {
  .custome-accordion .acc-courses {
    padding: 0.625rem 1.25rem;
  }
}

.custome-accordion .acc-courses:not(:first-child):not(:last-child) {
  margin: 1.25rem 0;
}

.custome-accordion .acc-courses.active {
  background: var(--primary);
}

.custome-accordion .acc-courses.active h4, .custome-accordion .acc-courses.active .h4, .custome-accordion .acc-courses.active span {
  color: white;
}

.custome-accordion .acc-courses h4, .custome-accordion .acc-courses .h4, .custome-accordion .acc-courses span {
  font-size: 1rem;
  font-weight: 400;
}

.custome-accordion .acc-courses .acc-icon {
  overflow: hidden;
  height: 2rem;
  width: 2rem;
  background: #ffffff;
  text-align: center;
  line-height: 2rem;
  border-radius: 50%;
  margin-right: 0.9375rem;
}

.custome-accordion .acc-courses:hover:not(.active) {
  background: var(--rgba-primary-1);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.custome-accordion .acc-courses:hover:not(.active) .acc-icon {
  background: var(--primary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.custome-accordion .acc-courses:hover:not(.active) .acc-icon svg path {
  fill: #fff;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.custome-accordion.style-1 .accordion-header {
  padding: 0;
}

.custome-accordion.style-1 .accordion-header .acc-header h4, .custome-accordion.style-1 .accordion-header .acc-header .h4 {
  font-size: 1rem;
}

.custome-accordion.style-1 .accordion-header .accordion-button {
  padding: 0;
}

.custome-accordion.style-1 .acc-courses {
  background: transparent;
  border-bottom: 0.0625rem solid #DBDBDB;
  border-radius: 0;
  padding: 1rem 0;
  margin: 0 !important;
}

.custome-accordion.style-1 .acc-courses h4, .custome-accordion.style-1 .acc-courses .h4 {
  color: #000;
}

.custome-accordion.style-1 .acc-icon {
  background: var(--primary);
}

.nt-unseen {
  position: relative;
}

.nt-unseen:before {
  content: "";
  position: absolute;
  top: 3px;
  right: 5px;
  height: 8px;
  width: 8px;
  background-color: #FC6B57;
  border-radius: 8px;
}

.students1 {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 87.5rem) {
  .students1 {
    display: block;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .students1 {
    display: flex;
  }
}

.course-details-tab.style-2 .nav-tabs.tab-auto .nav-link {
  flex: unset;
}

.profile-widget:hover {
  transform: translateY(-10px);
}

.all-crs-wid:hover .courses-bx h4 a, .all-crs-wid:hover .courses-bx .h4 a {
  color: var(--primary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.course-slider .swiper-slide .card:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.course-slider .swiper-slide .card:hover .widget-courses img {
  -webkit-animation-name: bounce;
  opacity: 1;
  animation-duration: 1.5s;
}

.card-schedule {
  position: relative;
}

.card-schedule .side-label {
  height: 100%;
  width: 10px;
  display: block;
  position: absolute;
  border-radius: 0.625rem 0 0 0.625rem;
}

.card-schedule .up-comming-schedule {
  display: flex;
  align-items: center;
  border-radius: 0 0.625rem 0.625rem 0;
  background: white;
  padding: 19px 30px;
  margin-left: 12px;
  margin-top: 20px;
  justify-content: space-between;
  flex-flow: wrap;
}

.card-schedule .up-comming-schedule h4, .card-schedule .up-comming-schedule .h4 {
  font-size: 18px;
  font-weight: 600;
}

.card-schedule .up-comming-schedule img {
  height: 24px;
  width: 24px;
  border-radius: 8px;
}

.card-schedule .up-comming-schedule p {
  margin: 0;
}

.card-schedule .up-comming-schedule i {
  transform: scale(1.5);
}

.card-schedule .up-comming-schedule .date-box {
  height: 50px;
  width: 50px;
  background: #fff;
  border-radius: 5px;
  padding: 5px 5px;
  display: flex;
  margin-right: .75rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #374557;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
}

.card-schedule .up-comming-schedule .date-box span {
  display: block;
  font-weight: 500;
  text-align: center;
  line-height: 1.2;
}

.card-schedule .up-comming-schedule.style-1 {
  padding: 7px 14px;
  background: #f5f5f5;
  flex-flow: wrap;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  justify-content: unset;
}

.card-schedule .up-comming-schedule.style-1 h4, .card-schedule .up-comming-schedule.style-1 .h4 {
  font-size: 16px;
}

.card-schedule .up-comming-schedule.style-1:hover {
  background: var(--rgba-primary-1);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.card-schedule .up-comming-schedule.style-1:hover h4, .card-schedule .up-comming-schedule.style-1:hover .h4 {
  color: var(--primary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.card-schedule .up-comming-schedule.style-1:hover .date-box {
  background: var(--primary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.card-schedule .up-comming-schedule.style-1:hover .date-box span {
  color: #fff;
}

@media only screen and (max-width: 35.9375rem) {
  .card-schedule .up-comming-schedule {
    padding: 19px 10px;
  }
}

/* Instructors Page */
.instructors-box .card-header {
  position: absolute;
  top: 0;
  right: 0;
}

.instructors-box .instructors-media h4, .instructors-box .instructors-media .h4 {
  margin: 10px 0;
}

.instructors-box .instructors-media img {
  height: 120px;
  width: 120px;
  border-radius: 0.625rem;
}

@media only screen and (max-width: 35.9375rem) {
  .instructors-box .instructors-media img {
    height: 80px;
    width: 80px;
  }
}

.instructors-box .instructors-media .custome-badge {
  margin: 25px 0;
}

.instructors-box .instructors-media .custome-badge .badge {
  color: black;
  background: rgba(219, 219, 219, 0.25);
  margin: 0 4px;
  margin-bottom: 3px;
}

@media only screen and (min-width: 100rem) and (max-width: 106.25rem) {
  .instructors-box .instructors-media .custome-badge .badge {
    margin: 0 2px;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .instructors-box .instructors-media .custome-badge .badge {
    font-size: 12px;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .instructors-box .instructors-media .custome-badge .badge {
    margin-bottom: 10px;
  }
}

.instructors-box .instructors-media .custome-badge .badge:hover {
  background: var(--rgba-primary-1);
}

.instructors-box .instructors-media .info-box {
  background: #FAFAFA;
  padding: 17px 20px;
  margin: 0 10px;
  border-radius: 0.625rem;
}

@media only screen and (min-width: 100rem) and (max-width: 106.25rem) {
  .instructors-box .instructors-media .info-box {
    padding: 17px 11px;
    margin: 0 5px;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .instructors-box .instructors-media .info-box {
    padding: 11px 7px;
    margin: 0 6px;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .instructors-box .instructors-media .info-box {
    padding: 11px 20px;
  }
}

.instructors-box .instructors-media .info-box span {
  font-size: 18px;
  font-weight: 400;
  white-space: nowrap;
}

@media only screen and (min-width: 100rem) and (max-width: 106.25rem) {
  .instructors-box .instructors-media .info-box span {
    font-size: 16px;
  }
}

@media only screen and (max-width: 87.5rem) {
  .instructors-box .instructors-media .info-box span {
    font-size: 14px;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .instructors-box .instructors-media .info-box span {
    font-size: 12px;
  }
}

.instructors-box .instructors-media .info-box span img {
  width: 24px;
  height: 24px;
}

@media only screen and (max-width: 61.9375rem) {
  .instructors-box .instructors-media .info-box span img {
    width: 16px;
    height: 16px;
  }
}

.instructors-box .instructors-media .info-box h4, .instructors-box .instructors-media .info-box .h4 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

@media only screen and (max-width: 87.5rem) {
  .instructors-box .instructors-media .info-box h4, .instructors-box .instructors-media .info-box .h4 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .instructors-box .instructors-media .info-box h4, .instructors-box .instructors-media .info-box .h4 {
    font-size: 16px;
  }
}

.instructors-box .instructors-media .info-box.style-1 {
  width: 194px;
  padding: 11px 17px;
}

.instructors-box .instructors-media .info-box:hover {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background: var(--primary);
}

.instructors-box .instructors-media .info-box:hover span, .instructors-box .instructors-media .info-box:hover h4, .instructors-box .instructors-media .info-box:hover .h4 {
  color: #fff;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.achievements {
  margin-top: 42px;
}

.achievements .achievements-content {
  display: flex;
  justify-content: space-between;
}

.achievements .achievements-content span {
  height: 60px;
  width: 60px;
  text-align: center;
  background: #fafafa;
  display: block;
  line-height: 60px;
  margin: 0 3px;
  border-radius: 0.625rem;
  margin-bottom: 10px;
}

@media only screen and (max-width: 35.9375rem) {
  .achievements .achievements-content span {
    height: 40px;
    width: 40px;
    line-height: 40px;
  }

  .achievements .achievements-content span img {
    width: 22px;
  }
}

.achievements .achievements-content span img {
  width: 30px;
}

.achievements .achievements-content span:hover img {
  -webkit-animation-name: rotateIn;
  opacity: 1;
  animation-duration: 1.5s;
}

.bio .bio-content {
  padding: 25px;
  background: #fafafa;
  border-radius: 0.625rem;
}

.donut-chart-sale small, .donut-chart-sale .small, .donut-chart-sale .small {
  font-size: 18px;
  position: absolute;
  width: 100%;
  height: 100%;
  font-weight: 700;
  left: 0;
  display: flex;
  align-items: center;
  top: 0;
  justify-content: center;
  font-weight: bold;
  color: #ff6175;
}

.app-fullcalendar1 {
  height: 488px;
}

.app-fullcalendar1 .fc-toolbar {
  margin-bottom: 1.3125rem !important;
}

.upcomming-task-widget {
  border-radius: 0.625rem;
  background: #f9f9f9;
  padding: 12px 12px;
  margin: 18px 0;
  flex-flow: wrap;
}

.upcomming-task-widget img {
  height: 40px;
  width: 40px;
  border-radius: 0.625rem;
  margin-right: 10px;
}

.upcomming-task-widget span {
  display: block;
}

.upcomming-task-widget h5, .upcomming-task-widget .h5 {
  font-size: 14px;
  margin: 0;
}

.upcomming-task-widget:hover {
  background: var(--rgba-primary-1);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.upcomming-task-widget:hover h5, .upcomming-task-widget:hover .h5 {
  color: var(--primary);
}

.app-fullcalendar1 .fc .fc-view-harness {
  height: 100% !important;
  min-height: 476px !important;
}

.process-calendar .react-calendar .react-calendar__navigation {
  margin-bottom: 0.5rem;
}

.process-calendar .react-calendar .react-calendar__tile {
  padding: 9px;
}

.process-calendar .react-calendar .react-calendar__tile.react-calendar__month-view__days__day {
  height: unset;
}

.process-calendar .react-calendar .react-calendar__tile.react-calendar__tile--now, .process-calendar .react-calendar .react-calendar__tile.react-calendar__tile--active, .process-calendar .react-calendar .react-calendar__tile.react-calendar__tile--range {
  background: var(--secondary) !important;
}

.process-calendar .react-calendar .react-calendar__tile.react-calendar__tile--now:before, .process-calendar .react-calendar .react-calendar__tile.react-calendar__tile--active:before, .process-calendar .react-calendar .react-calendar__tile.react-calendar__tile--range:before {
  background: #000 !important;
}

.chat-sidebar {
  height: calc(100vh - 11.25rem);
}

@media only screen and (max-width: 74.9375rem) {
  .chat-sidebar {
    height: calc(100vh - 9.25rem);
  }
}

@media only screen and (max-width: 47.9375rem) {
  .chat-sidebar {
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.05);
  }
}

.message-body {
  min-height: auto !important;
}

.message-box {
  border-bottom: 0.0625rem solid #DBDBDB;
  background: white;
  padding-bottom: 1.25rem;
}

.message-box img {
  height: 5rem;
  width: 5rem;
  border-radius: 0.625rem;
}

.message-box h4, .message-box .h4 {
  font-weight: 700;
}

.message-box span {
  font-size: 1.125rem;
}

.message-box.style-1 {
  padding: 1rem;
  border-radius: 0.625rem;
  border: 0;
}

.message-box.style-1 h4, .message-box.style-1 .h4 {
  margin-bottom: .25rem;
}

@media only screen and (max-width: 35.9375rem) {
  .message-box.style-1 h4, .message-box.style-1 .h4 {
    margin-bottom: 0;
  }
}

.message-box.style-1 span {
  font-size: 14px;
}

.message-box.style-1 img {
  height: 4rem;
  width: 4rem;
}

@media only screen and (max-width: 35.9375rem) {
  .message-box.style-1 img {
    height: 3rem;
    width: 3rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .message-box img {
    height: 3rem;
    width: 3rem;
  }

  .message-box h3, .message-box .h3 {
    font-size: 1rem;
  }

  .message-box span {
    font-size: .85rem;
  }
}

.chat-people {
  border-bottom: 0.0625rem solid #DBDBDB;
  padding: 1.25rem 0;
}

.chat-tabs>h4, .chat-tabs>.h4 {
  font-size: 1rem;
  padding: 1.5625rem 0;
  margin: 0;
  padding-bottom: 0.6875rem;
}

.chat-tabs .chat-bx {
  padding: 1rem 0;
  position: relative;
  border-bottom: 0.0625rem solid #DBDBDB;
  z-index: 1;
  display: flex;
  cursor: pointer;
  margin: 10px 0;
  align-items: center;
}

.chat-tabs .chat-bx .right-info {
  width: 4.375rem;
  text-align: right;
}

.chat-tabs .chat-bx .right-info p {
  font-size: .8rem;
  margin-top: 0;
  margin-bottom: .5rem;
}

@media only screen and (max-width: 87.5rem) {
  .chat-tabs .chat-bx .right-info {
    width: 4rem;
  }
}

.chat-tabs .chat-bx .mid-info {
  width: calc(100% - 9rem);
}

.chat-tabs .chat-bx .mid-info h4, .chat-tabs .chat-bx .mid-info .h4 {
  font-size: 1rem;
  margin-bottom: .25rem;
}

.chat-tabs .chat-bx .mid-info span {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

@media only screen and (max-width: 87.5rem) {
  .chat-tabs .chat-bx .mid-info {
    width: calc(100% - 8rem);
  }
}

.chat-tabs .chat-bx .chat-img {
  position: relative;
}

.chat-tabs .chat-bx .chat-img img {
  height: 3.75rem;
  width: 3.75rem;
  border-radius: 0.625rem;
  margin-right: 1rem;
}

@media only screen and (max-width: 87.5rem) {
  .chat-tabs .chat-bx .chat-img img {
    height: 3rem;
    width: 3rem;
  }
}

.chat-tabs .chat-bx .chat-img .active {
  height: 1rem;
  width: 1rem;
  border: 0.125rem solid #fff;
  border-radius: 0.25rem;
  background: #4CBC9A;
  position: absolute;
  bottom: -0.0625rem;
  right: 1rem;
}

.chat-tabs .chat-bx .badge {
  border-radius: 0.375rem;
}

.chat-tabs .chat-bx:last-child {
  border-bottom: 0;
}

@media only screen and (max-width: 87.5rem) {
  .chat-tabs .chat-bx {
    padding: .75rem 0;
  }
}

.chat-tabs .chat-bx:after {
  content: none;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% + 4px);
  width: calc(100% + 30px);
  z-index: -1;
  background-color: #f0f0f0;
  border-radius: 0.625rem;
  margin: -2px -15px;
}

.chat-tabs .chat-bx:hover:after, .chat-tabs .chat-bx.active:after {
  content: "";
}

.chat-tabs .chat-bx:hover .mid-info h4, .chat-tabs .chat-bx:hover .mid-info .h4, .chat-tabs .chat-bx.active .mid-info h4, .chat-tabs .chat-bx.active .mid-info .h4 {
  color: var(--primary);
}

.chat-box-area {
  height: calc(100vh - 21.8rem);
}

.chat-box-area .message-received {
  width: 62%;
  text-align: left;
}

.chat-box-area .message-received p {
  background: #fff;
  text-align: left;
  border-radius: 0.625rem;
  padding: 0.625rem 1.25rem;
  color: #000;
}

@media only screen and (max-width: 35.9375rem) {
  .chat-box-area .message-received {
    width: 100%;
  }
}

.chat-box-area .message-sent {
  width: 62%;
  text-align: right;
  max-width: 83%;
}

@media only screen and (max-width: 61.9375rem) {
  .chat-box-area .message-sent {
    width: 84%;
  }
}

.chat-box-area .message-sent span {
  display: block;
}

.chat-box-area .message-sent p {
  background: var(--secondary);
  text-align: left;
  border-radius: 0.625rem;
  padding: 0.625rem 1.25rem;
  color: #fff;
  display: inline-block;
}

.chat-box-area.style-1 {
  height: 21.5rem;
}

.chat-box-area.style-1 .message-received p {
  background: #eeee;
  font-size: 0.875rem;
}

.chat-box-area.style-1 .message-sent p {
  background: var(--primary);
  font-size: 0.875rem;
  color: #fff;
}

@media only screen and (max-width: 74.9375rem) {
  .chat-box-area {
    height: calc(100vh - 19.8rem);
  }
}

@media only screen and (max-width: 35.9375rem) {
  .chat-box-area {
    height: calc(100vh - 17.8rem);
  }
}

.type-massage {
  padding: 0.625rem;
  border-radius: 0.625rem;
  background: #fff;
}

.type-massage textarea {
  border: 0;
  height: 3.4375rem !important;
  resize: none;
  padding: 0.4375rem 0.9375rem;
  background: transparent;
}

.type-massage textarea:hover, .type-massage textarea:focus {
  background: transparent;
}

@media only screen and (max-width: 35.9375rem) {
  .type-massage textarea {
    height: 2.5rem !important;
    line-height: 1.5;
  }
}

.type-massage .input-group {
  align-items: center;
}

.type-massage.style-1 {
  border: 0;
  margin: 0;
  padding: 0.375rem;
  background: rgba(219, 219, 219, 0.25);
}

.type-massage.style-1 textarea {
  border: 0;
  height: 2.5rem !important;
  resize: none;
  padding: 0.0625rem 0.9375rem;
  background: transparent;
}

.type-massage .btn {
  height: 3.125rem;
  width: 3.125rem;
  text-align: center;
  padding: 0;
}

@media only screen and (max-width: 35.9375rem) {
  .type-massage .btn {
    height: 2.5rem;
    width: 2.5rem;
  }
}

.type-massage .share-btn {
  height: 2.5rem;
  width: 2.5rem;
  background: #eee;
  margin-right: 1.0625rem;
  border-radius: 3.125rem;
  padding: 0;
}

.chat-icon {
  border-bottom: 0.0625rem solid #F0F0F0;
  padding-bottom: 1.5625rem;
}

.chat-icon ul {
  display: flex;
  justify-content: space-around;
}

.chat-icon ul li a {
  height: 3rem;
  width: 3rem;
  background: #F0F0F0;
  line-height: 3rem;
  display: block;
  border-radius: 0.625rem;
  text-align: center;
}

.chat-icon ul li a:hover {
  background: var(--primary);
}

.chat-icon ul li a:hover svg path {
  fill: #fff;
}

.share-files {
  border-bottom: 0.0625rem solid #F0F0F0;
  padding-bottom: 1.125rem;
}

.share-files:last-child {
  border-bottom: 0;
}

.share-files ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5625rem 0;
  cursor: pointer;
}

.share-files ul li:first-child {
  padding-top: 0;
}

.share-files ul li h4, .share-files ul li .h4 {
  white-space: nowrap;
}

.share-files ul li>div {
  flex: 1;
}

.file {
  height: 2.5rem;
  width: 2.5rem;
  min-width: 2.5rem;
  line-height: 2.4rem;
  text-align: center;
  background: #F0F0F0;
  display: block;
  border-radius: 0.625rem;
}

.file svg {
  height: 1.5rem;
  width: 1.5rem;
}

.share-widget {
  height: calc(100vh - 11.25rem);
  overflow: hidden;
}

@media only screen and (max-width: 87.5rem) {
  .share-widget {
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.05);
  }
}

@media only screen and (max-width: 74.9375rem) {
  .share-widget {
    height: calc(100vh - 9.25rem);
  }
}

.file-list li>div {
  width: calc(100% - 3.5rem);
}

.file-list li>div>div {
  width: calc(100% - 3rem);
}

.file-list li h4, .file-list li .h4 {
  width: 3.5rem;
  text-align: right;
}

.file-list li:hover .file {
  background: var(--primary);
}

.file-list li:hover .file svg path {
  fill: #fff;
}

.file-list .fl-info {
  flex: 1;
}

.file-list .fl-info span {
  font-size: 0.8rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.contact-list li {
  width: 16%;
  padding: 0 .35rem;
}

.contact-list li img {
  width: 100%;
  border-radius: 0.625rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 87.5rem) {
  .contact-list li {
    padding: 0 .15rem;
  }
}

.contact-list li:hover img {
  filter: blur(1px);
}

@media only screen and (max-width: 74.9375rem) {
  .chat-left-area {
    width: 22rem;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .chat-left-area {
    width: 21rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .chat-left-area {
    width: 22rem;
    position: absolute;
    left: -21rem;
    z-index: 1;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
  }

  .chat-left-area.active {
    left: -1.5rem;
  }
}

.chat-right-area .chat-rightarea-btn {
  display: none;
}

@media only screen and (max-width: 87.5rem) {
  .chat-right-area {
    width: 23rem;
    position: absolute;
    right: -16.5rem;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
  }

  .chat-right-area.active {
    right: 4.2rem;
  }

  .chat-right-area .chat-rightarea-btn {
    position: absolute;
    top: 7rem;
    left: -1.1rem;
    width: 2.2rem;
    height: 3rem;
    background: var(--primary);
    display: inline-flex;
    color: #fff;
    font-size: 1.2rem;
    align-items: center;
    justify-content: center;
    border-radius: .5rem 0 0 .5rem;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
  }

  .chat-right-area .chat-rightarea-btn.active i {
    transform: rotateY(180deg);
  }
}

@media only screen and (max-width: 47.9375rem) {
  .chat-right-area {
    right: -22rem;
  }

  .chat-right-area.active {
    right: -1.5rem;
  }
}

@media only screen and (max-width: 87.5rem) {
  .chat-mid-area {
    width: auto;
    flex: 1;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .chat-mid-area .chat-hamburger {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    height: 45px;
    width: 45px;
    line-height: 45px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    position: relative;
  }

  .chat-mid-area .chat-hamburger span {
    display: block;
    height: 2px;
    margin: 2px auto;
    width: 20px;
    border-radius: 3px;
    background-color: #000;
  }
}

.dashboard-select.style-1 {
  background: transparent;
  border-color: #A098AE;
  font-weight: 500;
  color: #374557;
  margin: 0 1rem;
}

.dashboard-select.style-1:after {
  border-color: #A098AE;
  right: 0;
}

@media only screen and (max-width: 35.9375rem) {
  .dashboard-select.style-1 {
    height: 2.5rem;
    padding-left: .75rem;
    line-height: 2.8;
    font-size: .85rem;
  }
}

.timeline-active li {
  position: relative;
  padding: 1.25rem 0;
}

.timeline-active li .time {
  padding: 0.5rem 0rem;
  background: #fff;
  display: block;
  position: relative;
  z-index: 1;
  color: #A098AE;
  font-size: 14px;
}

@media only screen and (max-width: 35.9375rem) {
  .timeline-active li .time {
    font-size: 0.75rem !important;
  }
}

.timeline-active li:before {
  top: 3.875rem;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 0.125rem;
  height: calc(100% - 1.875rem);
  left: 1.4625rem;
  margin-right: -0.0938rem;
  background: #EEEE;
}

.timeline-active li:last-child:before {
  content: none;
}

@media only screen and (max-width: 35.9375rem) {
  .timeline-active li {
    padding: 0.5rem 0;
  }

  .timeline-active li:before {
    top: 3rem;
  }
}

.timeline-active .panel {
  margin-left: 1.875rem;
  flex: 1;
}

.timeline-active .panel .badge {
  margin-right: 0.625rem;
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 35.9375rem) {
  .timeline-active .panel .badge {
    height: 2.5rem;
    width: 2.5rem;
    min-width: 2.5rem;
    font-size: 1rem;
  }
}

.timeline-active .panel .timeline-panel h4, .timeline-active .panel .timeline-panel .h4 {
  font-size: 1rem;
  font-weight: 500;
}

@media only screen and (max-width: 35.9375rem) {
  .timeline-active .panel .timeline-panel h4, .timeline-active .panel .timeline-panel .h4 {
    font-size: 0.875rem;
  }
}

.timeline-active .modulel {
  display: inline-flex;
}

@media only screen and (max-width: 35.9375rem) {
  .timeline-active .modulel {
    padding: 0.5rem 0.5rem;
  }

  .timeline-active .modulel>div {
    align-items: center;
  }
}

.timeline-active .modulel .sub-module {
  background: #f9f9f9;
  padding: 0.8125rem 1.0625rem;
  border-radius: 0.625rem;
  margin: 1.0625rem 0;
}

@media only screen and (max-width: 35.9375rem) {
  .timeline-active .modulel .sub-module {
    margin: 0.4625rem 0;
  }
}

.timeline-active .modulel .sub-module h4, .timeline-active .modulel .sub-module .h4 {
  line-height: 1;
  font-size: 0.75rem !important;
}

.timeline-active .modulel .sub-module span {
  font-size: .7rem;
}

.timeline-active .modulel .sub-module:hover {
  background: var(--primary);
}

.timeline-active .modulel .sub-module:hover span, .timeline-active .modulel .sub-module:hover h4, .timeline-active .modulel .sub-module:hover .h4 {
  color: #fff;
}

.timeline-active .modulel .sub-module:hover span svg path {
  fill: var(--primary);
}

.timeline-active.style-4 li {
  padding: 0.375rem 0;
}

.timeline-active.style-4 li:before {
  content: none;
}

.timeline-active.style-4 li .time-1 {
  color: #A098AE;
}

.timeline-active.style-4 li:hover .timeline-panel h4, .timeline-active.style-4 li:hover .timeline-panel .h4 {
  color: var(--primary);
}

.timeline-active.style-4 .panel {
  display: flex;
  align-items: center;
}

.timeline-active.style-4 .panel .line-color {
  width: 0.25rem;
  height: 65%;
  border-radius: 4px;
  position: absolute;
}

.timeline-active.style-4 .panel .timeline-panel {
  margin-left: 1.25rem;
  display: block;
}

.widget-timeline .side-border {
  position: relative;
}

.widget-timeline .side-border:after {
  position: absolute;
  content: "";
  width: 100%;
  border-top: 0.0625rem solid #DBDBDB;
  top: 0.75rem;
  left: 0;
}

.widget-timeline .side-border h4, .widget-timeline .side-border .h4 {
  background: white;
  position: relative;
  z-index: 2;
  padding-right: 1.25rem;
}

.widget-timeline .side-border .custom-dropdown {
  background: white;
  position: relative;
  z-index: 2;
  padding-left: 1.25rem;
  margin-top: -7px;
}

.courses-chart .ic-icon {
  height: 2rem;
  width: 2rem;
  border-radius: 0.625rem;
  background: #4CBC9A;
  text-align: center;
  line-height: 2rem;
}

@media only screen and (max-width: 1600px) {
  .courses-chart .ic-icon {
    height: 1.5rem;
    width: 1.5rem;
    line-height: 1.3rem;
    border-radius: 3px;
  }
}

.pt-line {
  margin-top: 1.5rem;
}

.work-ic {
  height: 3.75rem;
  width: 3.75rem;
  border: 0.125rem solid #DBDBDB;
  display: block;
  text-align: center;
  line-height: 3.4375rem;
  border-radius: 0.625rem;
}

@media only screen and (max-width: 35.9375rem) {
  .work-ic {
    height: 2.8125rem;
    width: 2.8125rem;
    line-height: 2.5rem;
  }
}

.work-ic:hover {
  background: var(--rgba-primary-1);
  border-color: var(--primary);
}

#earningtBar {
  margin-top: -1.4rem;
}

.instructor-calendar .react-calendar .react-calendar__tile {
  padding: 7px;
}

.instructor-calendar .react-calendar .react-calendar__tile.react-calendar__month-view__days__day {
  height: unset;
}

.instructor-calendar .react-calendar .react-calendar__tile.react-calendar__tile--now {
  background: var(--secondary) !important;
}

.instructor-calendar .react-calendar .react-calendar__tile.react-calendar__tile--now:before {
  background: #000 !important;
}

.instructor-calendar button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day,
.instructor-calendar button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  background: var(--secondary) !important;
}

.review-box {
  padding: 20px 25px;
  border-radius: 0.625rem;
  margin-bottom: 1.875rem;
}

.review-box img {
  height: 56px;
  width: 56px !important;
  border-radius: 0.625rem;
}

.review-box p {
  margin: 0;
  padding-top: 10px;
}

.review-box .raiting li i {
  font-size: 14px;
}

.courses-chart {
  padding: 13px 0;
}

.courses-chart .book-icon {
  height: 56px;
  width: 56px;
  line-height: 56px;
  text-align: center;
  display: block;
  border-radius: 0.625rem;
}

.bg-secondary .student-box h4, .bg-secondary .student-box .h4 {
  color: #fff;
}

.student-box .student-icon {
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 0.625rem;
  line-height: 4.5rem;
  background: #fff;
  display: block;
  text-align: center;
  margin-bottom: 1.25rem;
}

.student-box h4, .student-box .h4 {
  font-size: 1.5rem;
  margin: 0;
}

.student-box span {
  font-size: 1.125rem;
  color: #fff;
}

.students-list .search-area .form-control {
  background: #FAFAFA;
  border-left: 0;
}

.students-list .search-area .form-control:focus {
  border-color: #DBDBDB;
}

.students-list .search-area .input-group-text {
  background: #FAFAFA;
}

.students-list table thead tr th {
  font-weight: 500;
  font-size: 1rem;
}

.students-list table thead tr th:first-child {
  padding-left: 0;
}

.students-list table tbody tr td {
  white-space: nowrap;
}

.students-list table tbody tr td img {
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 0.625rem;
  margin-right: 0.625rem;
}

.students-list table tbody tr td:first-child {
  padding-left: 0;
}

.students-list table tbody tr td:last-child {
  text-align: end;
}

.students-list table tbody tr:last-child td {
  padding-bottom: 0;
}

.blog-bx {
  padding: 0.9375rem 0;
}

.blog-bx img {
  width: 100%;
  height: 12.8125rem;
  object-fit: cover;
  border-radius: 0.625rem;
  margin-right: 1.5rem;
}

@media only screen and (max-width: 100rem) {
  .blog-bx img {
    margin-bottom: 0.9375rem;
  }
}

.blog-bx .blog-content p {
  font-size: 1rem;
}

.blog-bx:hover .blog-content h4, .blog-bx:hover .blog-content .h4 {
  color: var(--primary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.blog-card {
  color: #fff;
}

.blog-card ul li {
  padding: 0.9375rem 0;
  display: flex;
  align-items: baseline;
}

.blog-card ul li p {
  font-size: 1rem;
  margin: 0;
}

.blog-card ul li:hover span {
  -webkit-animation-name: bounce;
  opacity: 1;
  animation-duration: 1.5s;
}

@media only screen and (max-width: 35.9375rem) {
  .blog-card ul li {
    padding: 0.6375rem 0;
  }

  .blog-card ul li span svg {
    width: 20px;
  }
}

.wish-list {
  position: relative;
  cursor: pointer;
}

.wish-list .form-check-input {
  position: absolute;
  left: 0;
  opacity: 0;
}

.wish-list .form-check-input:checked+i {
  color: var(--primary);
}

.wish-list i {
  transform: scale(1.5);
  margin-right: 8px;
}

.rae-chart {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.student-earning {
  margin-top: 25px;
}

.student-earning h4, .student-earning .h4 {
  font-size: 20px;
}

.total-order h4, .total-order .h4 {
  font-size: 20px;
}

.live-img img {
  height: 604px;
  width: 100%;
  object-fit: cover;
  border-radius: 0.625rem;
}

@media only screen and (max-width: 35.9375rem) {
  .live-img img {
    height: 195px;
  }
}

.live-img .live-tag {
  height: 48px;
  width: 90px;
  border-radius: 8px;
  background: #37455780;
  text-align: center;
  line-height: 50px;
  color: white;
  position: absolute;
  top: 30px;
  left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 35.9375rem) {
  .live-img .live-tag {
    height: 30px;
    line-height: 30px;
    left: 8px;
    width: 60px;
  }
}

.profile-img img,
.profile-img span {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 0.625rem;
  border: 4px solid #ffff;
  line-height: 34px;
  text-align: center;
  margin-left: -7px;
}

.profile-img img:first-child,
.profile-img span:first-child {
  margin-left: 0;
}

.profile-img img:hover,
.profile-img span:hover {
  -webkit-animation-name: zoomIn;
  opacity: 1;
  animation-duration: 1.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.miac {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 46px;
  border-radius: 0.625rem;
  border: 1px solid #DBDBDB;
  margin-left: 16px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
}

.miac:first-child {
  margin-left: 0;
}

.miac svg {
  width: 18px;
}

@media only screen and (max-width: 100rem) {
  .miac {
    width: 40px;
    height: 40px;
    line-height: 33px;
  }

  .miac svg {
    width: 16px;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .miac:first-child {
    margin-left: 0;
  }
}

.miac:hover {
  background: var(--primary);
  border-color: var(--primary);
}

.miac:hover svg path {
  fill: #fff;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

/*
===================================
    Product detail
===================================*/
.produtct-detail-tag {
  display: inline-block;
}

.produtct-detail-tag a {
  font-style: 0.8125rem;
  color: #6e6e6e;
}

.product-detail-content .item-tag {
  background: #828690;
  border-radius: 0.375rem;
  display: inline-block;
  font-size: 0.75rem;
  margin-right: 0.25rem;
  padding: 0.125rem 0.75rem;
  color: #fff;
}

.filtaring-area h4, .filtaring-area .h4 {
  color: #1d1d1d;
  font-size: 1rem;
  font-weight: 400;
  text-transform: lowercase;
}

.plus-minus-input .input-icon {
  font-size: 0.8125rem;
  color: #6e6e6e;
}

.plus-minus-input {
  display: flex;
  width: 7.5rem;
}

.plus-minus-input .custom-btn {
  border-radius: 0;
  height: 2.5rem;
  padding: 0.75rem 0.5rem;
  background: #fff;
  border: 0.0625rem solid #DBDBDB;
}

.plus-minus-input .form-control:hover, .plus-minus-input .form-control:focus, .plus-minus-input .form-control:active {
  border: 0.0625rem solid #DBDBDB;
}

.btn-reveal-trigger .avatar-xl {
  min-width: 1.875rem;
}

.share-view {
  display: inline-block;
}

.share-view ul li {
  display: inline-block;
}

.share-view .share-icon {
  width: 2.5rem;
  height: 2.5rem;
  display: inline-block;
  border: 0.0625rem solid #DBDBDB;
  text-align: center;
  line-height: 2.5rem;
  font-style: 1rem;
  color: #DBDBDB;
  margin-right: 0.5rem;
}

/*--------tab----------*/
.veritical-line {
  padding: 1.25rem 1.875rem;
  border-top: 0.0625rem solid #DBDBDB;
  border-right: 0.0625rem solid #DBDBDB;
  border-bottom: 0.0625rem solid #DBDBDB;
  position: relative;
}

.veritical-line:before {
  background: #DBDBDB;
  bottom: 0;
  content: "";
  height: 100%;
  left: -0.0625rem;
  max-height: 40%;
  position: absolute;
  width: 0.0625rem;
}

.tab-content-text p {
  color: #6e6e6e;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 1.5625rem;
}

.tab-item-list li a {
  background: #fff;
  border-top: 0.0625rem solid #DBDBDB;
  border-left: 0.0625rem solid #DBDBDB;
  border-right: 0.0625rem solid #DBDBDB;
  color: #6e6e6e;
  display: block;
  font-size: 1rem;
  padding: 1rem;
  text-transform: uppercase;
}

.tab-item-list li a:hover, .tab-item-list li a:focus {
  background: #fff;
  color: #6e6e6e;
  border-right: 0rem;
}

.tab-item-list li:last-child {
  border-bottom: 0.0625rem solid #DBDBDB;
}

.tab-list li {
  margin-bottom: 0.4375rem;
  font-size: 0.8125rem;
}

.tab-list li i {
  font-size: 0.8125rem;
  margin-right: 0.875rem;
}

.slide-item-list {
  text-align: center;
  margin: 0 -0.3125rem;
}

.slide-item-list li {
  display: inline-block;
  flex: 0 0 25%;
  width: 25%;
  padding: 0 0.3125rem;
}

.slide-item-list li a {
  display: inline-block;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  background: transparent;
  padding-bottom: 0rem;
}

.slide-item-list li a:hover, .slide-item-list li a:focus {
  background: transparent;
}

.slide-item-list li a img {
  width: 100%;
}

.product-detail-text {
  padding-top: 1.75rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  padding-bottom: 4.375rem;
}

.star-rating .product-review {
  font-style: 0.8125rem;
  color: #6e6e6e;
  font-weight: 400;
  text-decoration: underline !important;
}

.product-detail .tab-content img {
  display: inline-block;
  width: 100%;
}

.popular-tag ul {
  margin: 0rem;
  padding: 0rem;
}

.popular-tag ul li {
  display: inline-block;
  padding: 0.5rem 0.9375rem;
  background: #f8f8f8;
  font-size: 0.8125rem;
  color: #fff;
  margin-right: 0.625rem;
  margin-bottom: 0.625rem;
}

.size-filter ul li {
  display: inline-block;
}

.intro {
  border: 0.0625rem solid red;
  color: #1d1d1d;
}

#listResults .slider {
  margin: 1.5625rem 0;
}

#listResults .slider-box {
  width: 90%;
  margin: 1.5625rem auto;
}

#listResults input {
  width: 10%;
}

#listResults label {
  border: none;
  display: inline-block;
  margin-right: -0.25rem;
  vertical-align: top;
  width: 30%;
}

.plus-minus-input .input-icon {
  font-size: 0.8125rem;
  color: #aaaaaa;
}

.plus-minus-input .custom-btn {
  border-radius: 0;
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
  background: #ffffff;
  border: 0.0625rem solid #c8c8c8;
}

.plus-minus-input .custom-btn:hover, .plus-minus-input .custom-btn:focus, .plus-minus-input .custom-btn.active {
  box-shadow: none;
  outline: none;
}

.plus-minus-input .form-control {
  height: 2.5rem;
  border-top: 0.0625rem solid #c8c8c8;
  border-bottom: 0.0625rem solid #c8c8c8;
  border-left: 0rem solid #c8c8c8;
  border-right: 0.0625rem solid #c8c8c8;
}

.plus-minus-input .form-control:hover, .plus-minus-input .form-control:focus, .plus-minus-input .form-control:active {
  border-top: 0.0625rem solid #c8c8c8;
  border-bottom: 0.0625rem solid #c8c8c8;
  border-left: 0rem solid #c8c8c8;
  border-right: 0rem solid #c8c8c8;
}

.new-arrival-product .new-arrivals-img-contnent {
  overflow: hidden;
  position: relative;
}

.new-arrival-product .new-arrivals-img-contnent img {
  width: 100%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.new-arrival-product .new-arrivals-img-contnent:after {
  content: "";
  position: absolute;
  top: 0;
  left: -85%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.new-arrival-product:hover .new-arrivals-img-contnent:after {
  webkit-animation: shine .75s;
  animation: shine .75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.input-number {
  line-height: 1.8;
}

/*
===================================
    list view
===================================*/
.new-arrival-content .item {
  font-size: 0.75rem;
  color: #6e6e6e;
}

.new-arrival-content h4, .new-arrival-content .h4 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.625rem;
}

.new-arrival-content h4 a, .new-arrival-content .h4 a {
  color: #000;
}

.new-arrival-content .price {
  font-weight: 600;
  color: var(--primary);
  font-size: 1.5rem;
  margin-bottom: 0;
  float: right;
}

@media only screen and (max-width: 35.9375rem) {
  .new-arrival-content .price {
    float: none;
    margin-top: 0.625rem;
  }
}

.new-arrival-content p {
  font-size: 0.875rem;
  color: #828690;
  margin-bottom: 0.375rem;
  line-height: 1.5rem;
}

.new-arrival-content .text-content {
  margin-top: 1.125rem;
}

.new-arrival-content.text-center .price {
  float: unset !important;
}

.success-icon {
  color: #56c760;
  font-size: 1rem;
}

.comment-review {
  margin-bottom: 0.9375rem;
  display: table;
  width: 100%;
}

.comment-review .client-review {
  color: #828690;
  padding-right: 1.25rem;
  text-decoration: underline !important;
  font-size: 0.875rem;
}

.comment-review .span {
  color: #828690;
  font-size: 0.875rem;
}

@media only screen and (max-width: 35.9375rem) {
  .comment-review {
    margin-bottom: 0;
  }
}

.star-rating li {
  display: inline-block;
}

.star-rating li i {
  color: gold;
}

.rtl {
  text-align: right;
  direction: rtl;
}

.rtl .nav {
  padding-right: 0;
}

.rtl .navbar-nav .nav-item {
  float: right;
}

.rtl .navbar-nav .nav-item+.nav-item {
  margin-right: 1rem;
  margin-left: inherit;
}

.rtl th {
  text-align: right;
}

.rtl .alert-dismissible {
  padding-right: 1.25rem;
  padding-left: 4rem;
}

.rtl .dropdown-menu {
  right: 0;
  text-align: right;
}

.rtl .checkbox label {
  padding-right: 1.25rem;
  padding-left: inherit;
}

.rtl .btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-radius: 0 0.75rem 0.75rem 0;
}

.rtl .btn-group>.btn:last-child:not(:first-child),
.rtl .btn-group>.dropdown-toggle:not(:first-child) {
  border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child {
  border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .custom-control-label::after,
.rtl .custom-control-label::before {
  right: 0;
  left: inherit;
}

.rtl .custom-select {
  padding: 0.375rem 0.75rem 0.375rem 1.75rem;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat left 0.75rem center;
  background-size: 8px 10px;
}

.rtl .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.rtl .input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.rtl .input-group>.input-group-append:not(:last-child)>.btn,
.rtl .input-group>.input-group-append:not(:last-child)>.input-group-text,
.rtl .input-group>.input-group-prepend>.btn,
.rtl .input-group>.input-group-prepend>.input-group-text {
  border-radius: 0 0.75rem 0.75rem 0;
}

.rtl .input-group>.input-group-append>.btn,
.rtl .input-group>.input-group-append>.input-group-text,
.rtl .input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.rtl .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.rtl .input-group>.input-group-prepend:not(:first-child)>.btn,
.rtl .input-group>.input-group-prepend:not(:first-child)>.input-group-text {
  border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .input-group>.custom-select:not(:first-child),
.rtl .input-group>.form-control:not(:first-child) {
  border-radius: 0.75rem 0 0 0.75rem;
}

.rtl .input-group>.custom-select:not(:last-child),
.rtl .input-group>.form-control:not(:last-child) {
  border-radius: 0 0.75rem 0.75rem 0;
}

.rtl .input-group>.custom-select:not(:last-child):not(:first-child),
.rtl .input-group>.form-control:not(:last-child):not(:first-child) {
  border-radius: 0;
}

.rtl .custom-control {
  padding-right: 1.5rem;
  padding-left: inherit;
  margin-right: inherit;
  margin-left: 1rem;
}

.rtl .custom-control-indicator {
  right: 0;
  left: inherit;
}

.rtl .custom-file-label::after {
  right: initial;
  left: -1px;
  border-radius: .25rem 0 0 .25rem;
}

.rtl .radio input,
.rtl .radio-inline,
.rtl .checkbox input,
.rtl .checkbox-inline input {
  margin-right: -1.25rem;
  margin-left: inherit;
}

.rtl .list-group {
  padding-right: 0;
  padding-left: 40px;
}

.rtl .close {
  float: left;
}

.rtl .modal-header .close {
  margin: -15px auto -15px -15px;
}

.rtl .modal-footer> :not(:first-child) {
  margin-right: .25rem;
}

.rtl .alert-dismissible .close {
  right: inherit;
  left: 0;
}

.rtl .dropdown-toggle::after {
  margin-right: .255em;
  margin-left: 0;
}

.rtl .form-check-input {
  margin-right: -1.25rem;
  margin-left: inherit;
}

.rtl .form-check-label {
  padding-right: 1.25rem;
  padding-left: inherit;
}

.rtl .offset-1 {
  margin-right: 8.33333%;
  margin-left: 0;
}

.rtl .offset-2 {
  margin-right: 16.66667%;
  margin-left: 0;
}

.rtl .offset-3 {
  margin-right: 25%;
  margin-left: 0;
}

.rtl .offset-4 {
  margin-right: 33.33333%;
  margin-left: 0;
}

.rtl .offset-5 {
  margin-right: 41.66667%;
  margin-left: 0;
}

.rtl .offset-6 {
  margin-right: 50%;
  margin-left: 0;
}

.rtl .offset-7 {
  margin-right: 58.33333%;
  margin-left: 0;
}

.rtl .offset-8 {
  margin-right: 66.66667%;
  margin-left: 0;
}

.rtl .offset-9 {
  margin-right: 75%;
  margin-left: 0;
}

.rtl .offset-10 {
  margin-right: 83.33333%;
  margin-left: 0;
}

.rtl .offset-11 {
  margin-right: 91.66667%;
  margin-left: 0;
}

@media (min-width: 576px) {
  .rtl .offset-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .rtl .offset-sm-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }

  .rtl .offset-sm-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }

  .rtl .offset-sm-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .rtl .offset-sm-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }

  .rtl .offset-sm-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }

  .rtl .offset-sm-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .rtl .offset-sm-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }

  .rtl .offset-sm-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }

  .rtl .offset-sm-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .rtl .offset-sm-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }

  .rtl .offset-sm-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .rtl .offset-md-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .rtl .offset-md-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }

  .rtl .offset-md-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }

  .rtl .offset-md-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .rtl .offset-md-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }

  .rtl .offset-md-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }

  .rtl .offset-md-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .rtl .offset-md-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }

  .rtl .offset-md-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }

  .rtl .offset-md-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .rtl .offset-md-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }

  .rtl .offset-md-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .rtl .offset-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .rtl .offset-lg-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }

  .rtl .offset-lg-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }

  .rtl .offset-lg-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .rtl .offset-lg-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }

  .rtl .offset-lg-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }

  .rtl .offset-lg-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .rtl .offset-lg-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }

  .rtl .offset-lg-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }

  .rtl .offset-lg-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .rtl .offset-lg-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }

  .rtl .offset-lg-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  .rtl .offset-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .rtl .offset-xl-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }

  .rtl .offset-xl-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }

  .rtl .offset-xl-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .rtl .offset-xl-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }

  .rtl .offset-xl-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }

  .rtl .offset-xl-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .rtl .offset-xl-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }

  .rtl .offset-xl-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }

  .rtl .offset-xl-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .rtl .offset-xl-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }

  .rtl .offset-xl-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

@media (min-width: 1440) {
  .rtl .offset-xxl-0 {
    margin-right: 0;
    margin-left: 0;
  }

  .rtl .offset-xxl-1 {
    margin-right: 8.33333%;
    margin-left: 0;
  }

  .rtl .offset-xxl-2 {
    margin-right: 16.66667%;
    margin-left: 0;
  }

  .rtl .offset-xxl-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .rtl .offset-xxl-4 {
    margin-right: 33.33333%;
    margin-left: 0;
  }

  .rtl .offset-xxl-5 {
    margin-right: 41.66667%;
    margin-left: 0;
  }

  .rtl .offset-xxl-6 {
    margin-right: 50%;
    margin-left: 0;
  }

  .rtl .offset-xxl-7 {
    margin-right: 58.33333%;
    margin-left: 0;
  }

  .rtl .offset-xxl-8 {
    margin-right: 66.66667%;
    margin-left: 0;
  }

  .rtl .offset-xxl-9 {
    margin-right: 75%;
    margin-left: 0;
  }

  .rtl .offset-xxl-10 {
    margin-right: 83.33333%;
    margin-left: 0;
  }

  .rtl .offset-xxl-11 {
    margin-right: 91.66667%;
    margin-left: 0;
  }
}

.rtl .mr-0,
.rtl .mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.rtl .ml-0,
.rtl .mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.rtl .mr-1,
.rtl .mx-1 {
  margin-right: 0 !important;
  margin-left: 0.25rem !important;
}

.rtl .ml-1,
.rtl .mx-1 {
  margin-left: 0 !important;
  margin-right: 0.25rem !important;
}

.rtl .mr-2,
.rtl .mx-2 {
  margin-right: 0 !important;
  margin-left: 0.5rem !important;
}

.rtl .ml-2,
.rtl .mx-2 {
  margin-left: 0 !important;
  margin-right: 0.5rem !important;
}

.rtl .mr-3,
.rtl .mx-3 {
  margin-right: 0 !important;
  margin-left: 1rem !important;
}

.rtl .ml-3,
.rtl .mx-3 {
  margin-left: 0 !important;
  margin-right: 1rem !important;
}

.rtl .mr-4,
.rtl .mx-4 {
  margin-right: 0 !important;
  margin-left: 1.5rem !important;
}

.rtl .ml-4,
.rtl .mx-4 {
  margin-left: 0 !important;
  margin-right: 1.5rem !important;
}

.rtl .mr-5,
.rtl .mx-5 {
  margin-right: 0 !important;
  margin-left: 3rem !important;
}

.rtl .ml-5,
.rtl .mx-5 {
  margin-left: 0 !important;
  margin-right: 3rem !important;
}

.rtl .pr-0,
.rtl .px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.rtl .pl-0,
.rtl .px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.rtl .pr-1,
.rtl .px-1 {
  padding-right: 0 !important;
  padding-left: 0.25rem !important;
}

.rtl .pl-1,
.rtl .px-1 {
  padding-left: 0 !important;
  padding-right: 0.25rem !important;
}

.rtl .pr-2,
.rtl .px-2 {
  padding-right: 0 !important;
  padding-left: 0.5rem !important;
}

.rtl .pl-2,
.rtl .px-2 {
  padding-left: 0 !important;
  padding-right: 0.5rem !important;
}

.rtl .pr-3,
.rtl .px-3 {
  padding-right: 0 !important;
  padding-left: 1rem !important;
}

.rtl .pl-3,
.rtl .px-3 {
  padding-left: 0 !important;
  padding-right: 1rem !important;
}

.rtl .pr-4,
.rtl .px-4 {
  padding-right: 0 !important;
  padding-left: 1.5rem !important;
}

.rtl .pl-4,
.rtl .px-4 {
  padding-left: 0 !important;
  padding-right: 1.5rem !important;
}

.rtl .pr-5,
.rtl .px-5 {
  padding-right: 0 !important;
  padding-left: 3rem !important;
}

.rtl .pl-5,
.rtl .px-5 {
  padding-left: 0 !important;
  padding-right: 3rem !important;
}

.rtl .mr-auto {
  margin-right: 0 !important;
  margin-left: auto !important;
}

.rtl .ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important;
}

.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

@media (min-width: 576px) {

  .rtl .mr-sm-0,
  .rtl .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .rtl .ml-sm-0,
  .rtl .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rtl .mr-sm-1,
  .rtl .mx-sm-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }

  .rtl .ml-sm-1,
  .rtl .mx-sm-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }

  .rtl .mr-sm-2,
  .rtl .mx-sm-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  .rtl .ml-sm-2,
  .rtl .mx-sm-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }

  .rtl .mr-sm-3,
  .rtl .mx-sm-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }

  .rtl .ml-sm-3,
  .rtl .mx-sm-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .rtl .mr-sm-4,
  .rtl .mx-sm-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }

  .rtl .ml-sm-4,
  .rtl .mx-sm-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }

  .rtl .mr-sm-5,
  .rtl .mx-sm-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }

  .rtl .ml-sm-5,
  .rtl .mx-sm-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }

  .rtl .pr-sm-0,
  .rtl .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .rtl .pl-sm-0,
  .rtl .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .rtl .pr-sm-1,
  .rtl .px-sm-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }

  .rtl .pl-sm-1,
  .rtl .px-sm-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }

  .rtl .pr-sm-2,
  .rtl .px-sm-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }

  .rtl .pl-sm-2,
  .rtl .px-sm-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }

  .rtl .pr-sm-3,
  .rtl .px-sm-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }

  .rtl .pl-sm-3,
  .rtl .px-sm-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }

  .rtl .pr-sm-4,
  .rtl .px-sm-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .rtl .pl-sm-4,
  .rtl .px-sm-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }

  .rtl .pr-sm-5,
  .rtl .px-sm-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }

  .rtl .pl-sm-5,
  .rtl .px-sm-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }

  .rtl .mr-sm-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .ml-sm-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {

  .rtl .mr-md-0,
  .rtl .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .rtl .ml-md-0,
  .rtl .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rtl .mr-md-1,
  .rtl .mx-md-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }

  .rtl .ml-md-1,
  .rtl .mx-md-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }

  .rtl .mr-md-2,
  .rtl .mx-md-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  .rtl .ml-md-2,
  .rtl .mx-md-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }

  .rtl .mr-md-3,
  .rtl .mx-md-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }

  .rtl .ml-md-3,
  .rtl .mx-md-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .rtl .mr-md-4,
  .rtl .mx-md-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }

  .rtl .ml-md-4,
  .rtl .mx-md-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }

  .rtl .mr-md-5,
  .rtl .mx-md-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }

  .rtl .ml-md-5,
  .rtl .mx-md-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }

  .rtl .pr-md-0,
  .rtl .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .rtl .pl-md-0,
  .rtl .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .rtl .pr-md-1,
  .rtl .px-md-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }

  .rtl .pl-md-1,
  .rtl .px-md-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }

  .rtl .pr-md-2,
  .rtl .px-md-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }

  .rtl .pl-md-2,
  .rtl .px-md-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }

  .rtl .pr-md-3,
  .rtl .px-md-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }

  .rtl .pl-md-3,
  .rtl .px-md-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }

  .rtl .pr-md-4,
  .rtl .px-md-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .rtl .pl-md-4,
  .rtl .px-md-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }

  .rtl .pr-md-5,
  .rtl .px-md-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }

  .rtl .pl-md-5,
  .rtl .px-md-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }

  .rtl .mr-md-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .ml-md-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {

  .rtl .mr-lg-0,
  .rtl .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .rtl .ml-lg-0,
  .rtl .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rtl .mr-lg-1,
  .rtl .mx-lg-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }

  .rtl .ml-lg-1,
  .rtl .mx-lg-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }

  .rtl .mr-lg-2,
  .rtl .mx-lg-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  .rtl .ml-lg-2,
  .rtl .mx-lg-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }

  .rtl .mr-lg-3,
  .rtl .mx-lg-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }

  .rtl .ml-lg-3,
  .rtl .mx-lg-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .rtl .mr-lg-4,
  .rtl .mx-lg-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }

  .rtl .ml-lg-4,
  .rtl .mx-lg-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }

  .rtl .mr-lg-5,
  .rtl .mx-lg-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }

  .rtl .ml-lg-5,
  .rtl .mx-lg-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }

  .rtl .pr-lg-0,
  .rtl .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .rtl .pl-lg-0,
  .rtl .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .rtl .pr-lg-1,
  .rtl .px-lg-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }

  .rtl .pl-lg-1,
  .rtl .px-lg-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }

  .rtl .pr-lg-2,
  .rtl .px-lg-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }

  .rtl .pl-lg-2,
  .rtl .px-lg-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }

  .rtl .pr-lg-3,
  .rtl .px-lg-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }

  .rtl .pl-lg-3,
  .rtl .px-lg-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }

  .rtl .pr-lg-4,
  .rtl .px-lg-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .rtl .pl-lg-4,
  .rtl .px-lg-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }

  .rtl .pr-lg-5,
  .rtl .px-lg-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }

  .rtl .pl-lg-5,
  .rtl .px-lg-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }

  .rtl .mr-lg-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .ml-lg-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {

  .rtl .mr-xl-0,
  .rtl .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .rtl .ml-xl-0,
  .rtl .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rtl .mr-xl-1,
  .rtl .mx-xl-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }

  .rtl .ml-xl-1,
  .rtl .mx-xl-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }

  .rtl .mr-xl-2,
  .rtl .mx-xl-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  .rtl .ml-xl-2,
  .rtl .mx-xl-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }

  .rtl .mr-xl-3,
  .rtl .mx-xl-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }

  .rtl .ml-xl-3,
  .rtl .mx-xl-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .rtl .mr-xl-4,
  .rtl .mx-xl-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }

  .rtl .ml-xl-4,
  .rtl .mx-xl-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }

  .rtl .mr-xl-5,
  .rtl .mx-xl-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }

  .rtl .ml-xl-5,
  .rtl .mx-xl-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }

  .rtl .pr-xl-0,
  .rtl .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .rtl .pl-xl-0,
  .rtl .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .rtl .pr-xl-1,
  .rtl .px-xl-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }

  .rtl .pl-xl-1,
  .rtl .px-xl-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }

  .rtl .pr-xl-2,
  .rtl .px-xl-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }

  .rtl .pl-xl-2,
  .rtl .px-xl-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }

  .rtl .pr-xl-3,
  .rtl .px-xl-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }

  .rtl .pl-xl-3,
  .rtl .px-xl-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }

  .rtl .pr-xl-4,
  .rtl .px-xl-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .rtl .pl-xl-4,
  .rtl .px-xl-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }

  .rtl .pr-xl-5,
  .rtl .px-xl-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }

  .rtl .pl-xl-5,
  .rtl .px-xl-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }

  .rtl .mr-xl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .ml-xl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1440) {

  .rtl .mr-xxl-0,
  .rtl .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .rtl .ml-xxl-0,
  .rtl .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rtl .mr-xxl-1,
  .rtl .mx-xxl-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }

  .rtl .ml-xxl-1,
  .rtl .mx-xxl-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }

  .rtl .mr-xxl-2,
  .rtl .mx-xxl-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  .rtl .ml-xxl-2,
  .rtl .mx-xxl-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }

  .rtl .mr-xxl-3,
  .rtl .mx-xxl-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }

  .rtl .ml-xxl-3,
  .rtl .mx-xxl-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .rtl .mr-xxl-4,
  .rtl .mx-xxl-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }

  .rtl .ml-xxl-4,
  .rtl .mx-xxl-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }

  .rtl .mr-xxl-5,
  .rtl .mx-xxl-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }

  .rtl .ml-xxl-5,
  .rtl .mx-xxl-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }

  .rtl .pr-xxl-0,
  .rtl .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .rtl .pl-xxl-0,
  .rtl .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .rtl .pr-xxl-1,
  .rtl .px-xxl-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }

  .rtl .pl-xxl-1,
  .rtl .px-xxl-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }

  .rtl .pr-xxl-2,
  .rtl .px-xxl-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }

  .rtl .pl-xxl-2,
  .rtl .px-xxl-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }

  .rtl .pr-xxl-3,
  .rtl .px-xxl-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }

  .rtl .pl-xxl-3,
  .rtl .px-xxl-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }

  .rtl .pr-xxl-4,
  .rtl .px-xxl-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .rtl .pl-xxl-4,
  .rtl .px-xxl-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }

  .rtl .pr-xxl-5,
  .rtl .px-xxl-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }

  .rtl .pl-xxl-5,
  .rtl .px-xxl-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }

  .rtl .mr-xxl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .ml-xxl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

.rtl .text-right {
  text-align: left !important;
}

.rtl .text-left {
  text-align: right !important;
}

@media (min-width: 576px) {
  .rtl .text-sm-right {
    text-align: left !important;
  }

  .rtl .text-sm-left {
    text-align: right !important;
  }
}

@media (min-width: 768px) {
  .rtl .text-md-right {
    text-align: left !important;
  }

  .rtl .text-md-left {
    text-align: right !important;
  }
}

@media (min-width: 992px) {
  .rtl .text-lg-right {
    text-align: left !important;
  }

  .rtl .text-lg-left {
    text-align: right !important;
  }
}

@media (min-width: 1200px) {
  .rtl .text-xl-right {
    text-align: left !important;
  }

  .rtl .text-xl-left {
    text-align: right !important;
  }
}

@media (min-width: 1440) {
  .rtl .text-xxl-right {
    text-align: left !important;
  }

  .rtl .text-xxl-left {
    text-align: right !important;
  }
}

.rtl .mx-0 {
  margin-right: auto;
  margin-left: 0 !important;
}

.rtl .mx-0 {
  margin-left: auto;
  margin-right: 0 !important;
}

.rtl .mx-1 {
  margin-right: auto;
  margin-left: 0.25rem !important;
}

.rtl .mx-1 {
  margin-left: auto;
  margin-right: 0.25rem !important;
}

.rtl .mx-2 {
  margin-right: auto;
  margin-left: 0.5rem !important;
}

.rtl .mx-2 {
  margin-left: auto;
  margin-right: 0.5rem !important;
}

.rtl .mx-3 {
  margin-right: auto;
  margin-left: 1rem !important;
}

.rtl .mx-3 {
  margin-left: auto;
  margin-right: 1rem !important;
}

.rtl .mx-4 {
  margin-right: auto;
  margin-left: 1.5rem !important;
}

.rtl .mx-4 {
  margin-left: auto;
  margin-right: 1.5rem !important;
}

.rtl .mx-5 {
  margin-right: auto;
  margin-left: 3rem !important;
}

.rtl .mx-5 {
  margin-left: auto;
  margin-right: 3rem !important;
}

.rtl .px-0 {
  padding-right: auto;
  padding-left: 0 !important;
}

.rtl .px-0 {
  padding-left: auto;
  padding-right: 0 !important;
}

.rtl .px-1 {
  padding-right: auto;
  padding-left: 0.25rem !important;
}

.rtl .px-1 {
  padding-left: auto;
  padding-right: 0.25rem !important;
}

.rtl .px-2 {
  padding-right: auto;
  padding-left: 0.5rem !important;
}

.rtl .px-2 {
  padding-left: auto;
  padding-right: 0.5rem !important;
}

.rtl .px-3 {
  padding-right: auto;
  padding-left: 1rem !important;
}

.rtl .px-3 {
  padding-left: auto;
  padding-right: 1rem !important;
}

.rtl .px-4 {
  padding-right: auto;
  padding-left: 1.5rem !important;
}

.rtl .px-4 {
  padding-left: auto;
  padding-right: 1.5rem !important;
}

.rtl .px-5 {
  padding-right: auto;
  padding-left: 3rem !important;
}

.rtl .px-5 {
  padding-left: auto;
  padding-right: 3rem !important;
}

.rtl .mr-auto {
  margin-right: 0 !important;
  margin-left: auto !important;
}

.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.rtl .ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important;
}

.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .rtl .mx-sm-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }

  .rtl .mx-sm-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }

  .rtl .mx-sm-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }

  .rtl .mx-sm-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }

  .rtl .mx-sm-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }

  .rtl .mx-sm-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }

  .rtl .mx-sm-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }

  .rtl .mx-sm-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }

  .rtl .mx-sm-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }

  .rtl .mx-sm-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }

  .rtl .mx-sm-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }

  .rtl .mx-sm-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }

  .rtl .px-sm-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }

  .rtl .px-sm-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }

  .rtl .px-sm-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }

  .rtl .px-sm-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }

  .rtl .px-sm-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }

  .rtl .px-sm-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }

  .rtl .px-sm-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }

  .rtl .px-sm-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }

  .rtl .px-sm-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }

  .rtl .px-sm-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }

  .rtl .px-sm-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }

  .rtl .px-sm-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }

  .rtl .mr-sm-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .rtl .ml-sm-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .rtl .mx-md-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }

  .rtl .mx-md-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }

  .rtl .mx-md-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }

  .rtl .mx-md-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }

  .rtl .mx-md-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }

  .rtl .mx-md-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }

  .rtl .mx-md-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }

  .rtl .mx-md-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }

  .rtl .mx-md-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }

  .rtl .mx-md-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }

  .rtl .mx-md-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }

  .rtl .mx-md-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }

  .rtl .px-md-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }

  .rtl .px-md-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }

  .rtl .px-md-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }

  .rtl .px-md-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }

  .rtl .px-md-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }

  .rtl .px-md-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }

  .rtl .px-md-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }

  .rtl .px-md-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }

  .rtl .px-md-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }

  .rtl .px-md-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }

  .rtl .px-md-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }

  .rtl .px-md-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }

  .rtl .mr-md-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .rtl .ml-md-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .rtl .mx-lg-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }

  .rtl .mx-lg-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }

  .rtl .mx-lg-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }

  .rtl .mx-lg-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }

  .rtl .mx-lg-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }

  .rtl .mx-lg-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }

  .rtl .mx-lg-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }

  .rtl .mx-lg-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }

  .rtl .mx-lg-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }

  .rtl .mx-lg-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }

  .rtl .mx-lg-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }

  .rtl .mx-lg-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }

  .rtl .px-lg-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }

  .rtl .px-lg-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }

  .rtl .px-lg-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }

  .rtl .px-lg-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }

  .rtl .px-lg-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }

  .rtl .px-lg-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }

  .rtl .px-lg-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }

  .rtl .px-lg-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }

  .rtl .px-lg-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }

  .rtl .px-lg-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }

  .rtl .px-lg-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }

  .rtl .px-lg-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }

  .rtl .mr-lg-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .rtl .ml-lg-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .rtl .mx-xl-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }

  .rtl .mx-xl-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }

  .rtl .mx-xl-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }

  .rtl .mx-xl-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }

  .rtl .mx-xl-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }

  .rtl .mx-xl-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }

  .rtl .mx-xl-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }

  .rtl .mx-xl-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }

  .rtl .mx-xl-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }

  .rtl .mx-xl-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }

  .rtl .mx-xl-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }

  .rtl .mx-xl-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }

  .rtl .px-xl-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }

  .rtl .px-xl-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }

  .rtl .px-xl-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }

  .rtl .px-xl-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }

  .rtl .px-xl-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }

  .rtl .px-xl-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }

  .rtl .px-xl-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }

  .rtl .px-xl-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }

  .rtl .px-xl-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }

  .rtl .px-xl-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }

  .rtl .px-xl-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }

  .rtl .px-xl-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }

  .rtl .mr-xl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .rtl .ml-xl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 1440) {
  .rtl .mx-xxl-0 {
    margin-right: auto;
    margin-left: 0 !important;
  }

  .rtl .mx-xxl-0 {
    margin-left: auto;
    margin-right: 0 !important;
  }

  .rtl .mx-xxl-1 {
    margin-right: auto;
    margin-left: 0.25rem !important;
  }

  .rtl .mx-xxl-1 {
    margin-left: auto;
    margin-right: 0.25rem !important;
  }

  .rtl .mx-xxl-2 {
    margin-right: auto;
    margin-left: 0.5rem !important;
  }

  .rtl .mx-xxl-2 {
    margin-left: auto;
    margin-right: 0.5rem !important;
  }

  .rtl .mx-xxl-3 {
    margin-right: auto;
    margin-left: 1rem !important;
  }

  .rtl .mx-xxl-3 {
    margin-left: auto;
    margin-right: 1rem !important;
  }

  .rtl .mx-xxl-4 {
    margin-right: auto;
    margin-left: 1.5rem !important;
  }

  .rtl .mx-xxl-4 {
    margin-left: auto;
    margin-right: 1.5rem !important;
  }

  .rtl .mx-xxl-5 {
    margin-right: auto;
    margin-left: 3rem !important;
  }

  .rtl .mx-xxl-5 {
    margin-left: auto;
    margin-right: 3rem !important;
  }

  .rtl .px-xxl-0 {
    padding-right: auto;
    padding-left: 0 !important;
  }

  .rtl .px-xxl-0 {
    padding-left: auto;
    padding-right: 0 !important;
  }

  .rtl .px-xxl-1 {
    padding-right: auto;
    padding-left: 0.25rem !important;
  }

  .rtl .px-xxl-1 {
    padding-left: auto;
    padding-right: 0.25rem !important;
  }

  .rtl .px-xxl-2 {
    padding-right: auto;
    padding-left: 0.5rem !important;
  }

  .rtl .px-xxl-2 {
    padding-left: auto;
    padding-right: 0.5rem !important;
  }

  .rtl .px-xxl-3 {
    padding-right: auto;
    padding-left: 1rem !important;
  }

  .rtl .px-xxl-3 {
    padding-left: auto;
    padding-right: 1rem !important;
  }

  .rtl .px-xxl-4 {
    padding-right: auto;
    padding-left: 1.5rem !important;
  }

  .rtl .px-xxl-4 {
    padding-left: auto;
    padding-right: 1.5rem !important;
  }

  .rtl .px-xxl-5 {
    padding-right: auto;
    padding-left: 3rem !important;
  }

  .rtl .px-xxl-5 {
    padding-left: auto;
    padding-right: 3rem !important;
  }

  .rtl .mr-xxl-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .rtl .ml-xxl-auto {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

.rtl .float-left {
  float: right !important;
}

.rtl .float-right {
  float: left !important;
}

.rtl .float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .rtl .float-sm-left {
    float: right !important;
  }

  .rtl .float-sm-right {
    float: left !important;
  }

  .rtl .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .rtl .float-md-left {
    float: right !important;
  }

  .rtl .float-md-right {
    float: left !important;
  }

  .rtl .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .rtl .float-lg-left {
    float: right !important;
  }

  .rtl .float-lg-right {
    float: left !important;
  }

  .rtl .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .rtl .float-xl-left {
    float: right !important;
  }

  .rtl .float-xl-right {
    float: left !important;
  }

  .rtl .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1440) {
  .rtl .float-xxl-left {
    float: right !important;
  }

  .rtl .float-xxl-right {
    float: left !important;
  }

  .rtl .float-xxl-none {
    float: none !important;
  }
}

[direction="rtl"][data-theme-version="dark"] .border, [direction="rtl"][data-theme-version="dark"] .border-left, [direction="rtl"][data-theme-version="dark"] .border-right {
  border-color: #333754 !important;
}

[direction="rtl"] .text-right {
  text-align: left !important;
}

[direction="rtl"] .text-left {
  text-align: right !important;
}

[direction="rtl"] .border-right {
  border-left: 0.0625rem solid #DBDBDB !important;
  border-right: 0 !important;
}

[direction="rtl"] .border-left {
  border-right: 0.0625rem solid #DBDBDB !important;
  border-left: 0 !important;
}

[direction="rtl"] .dropdown-menu {
  left: auto;
}

[direction="rtl"] .dropdown-menu-right {
  left: 0;
  right: auto;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .dropdown-menu-right {
    left: 0.9375rem;
  }
}

[direction="rtl"] .notification_dropdown .dropdown-menu-right .media>span {
  margin-left: 0.625rem;
  margin-right: 0;
}

[direction="rtl"]:not([data-container="boxed"]) .nav-header {
  left: auto;
  right: 0;
}

[direction="rtl"][data-container="wide-boxed"] .nav-header {
  left: auto;
  right: auto;
}

[direction="rtl"] .nav-header {
  text-align: right;
  right: auto;
}

[direction="rtl"] .nav-header .brand-title {
  margin-left: 0;
  margin-right: 0.9375rem;
}

[direction="rtl"] .nav-header .brand-logo {
  padding-left: 0;
  padding-right: 1.75rem;
}

[data-sidebar-style="compact"][direction="rtl"] .nav-header .brand-logo {
  padding-right: 0;
}

[data-sidebar-style="compact"][direction="rtl"] .nav-header .brand-logo[data-layout="horizontal"] {
  padding-right: 1.875rem;
}

[data-sidebar-style="mini"][direction="rtl"] .nav-header .brand-logo {
  padding-right: 0;
}

[data-sidebar-style="modern"][direction="rtl"] .nav-header .brand-logo {
  padding-right: 0;
}

[data-layout="horizontal"][data-sidebar-style="modern"][direction="rtl"] .nav-header .brand-logo {
  padding-right: 1.875rem;
}

@media (max-width: 767.98px) {
  [direction="rtl"] .nav-header .brand-logo {
    padding-right: 0;
  }
}

[direction="rtl"] .nav-control {
  right: auto;
  left: -4.0625rem;
}

@media (max-width: 767.98px) {
  [direction="rtl"] .nav-control {
    left: -4.0625rem;
  }
}

@media (max-width: 575.98px) {
  [direction="rtl"] .nav-control {
    left: -2.0625rem;
  }
}

[direction="rtl"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active {
  right: 0;
}

[direction="rtl"][data-sidebar-style="compact"][data-layout="horizontal"] .nav-header .brand-logo {
  padding-right: 2.5rem;
}

[direction="rtl"][data-sidebar-style="modern"][data-layout="horizontal"] .nav-header {
  width: 16rem;
}

[direction="rtl"] .header {
  padding: 0;
  padding-right: 21.563rem;
}

@media (max-width: 767.98px) {
  [direction="rtl"] .header {
    padding-right: 5rem;
    padding-left: 0;
  }
}

[direction="rtl"] .header .header-content {
  padding-left: 1.875rem;
  padding-right: 5.3125rem;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .header .header-content {
    padding-right: 3.5rem;
    padding-left: 0.938rem;
  }
}

[data-sidebar-style="compact"][direction="rtl"] .header .header-content {
  padding-right: 1.875rem;
}

[data-sidebar-style="modern"][direction="rtl"] .header .header-content {
  padding-right: 5.3125rem;
}

[data-sidebar-style="overlay"][direction="rtl"] .header .header-content {
  padding-right: 5.3125rem;
}

@media only screen and (max-width: 35.9375rem) {
  [data-sidebar-style="overlay"][direction="rtl"] .header .header-content {
    padding-right: 0.5rem;
  }
}

[direction="rtl"] .header .nav-control {
  right: 0.4375rem;
  left: auto;
}

[direction="rtl"] .header-right>li:not(:first-child) {
  padding-left: 0;
  padding-right: 1.25rem;
  margin-right: 0 !important;
}

@media only screen and (max-width: 47.9375rem) {
  [direction="rtl"] .header-right>li:not(:first-child) {
    padding-right: 0.5rem;
  }
}

[direction="rtl"] .header-right .search-area .input-group-append .input-group-text {
  padding-right: auto;
  padding-left: 1.25rem;
}

[direction="rtl"] .header-right .search-area .form-control {
  padding-left: auto;
  padding-right: 1.25rem;
}

[direction="rtl"] .header-right .header-profile>a.nav-link {
  margin-left: auto;
  padding-left: auto;
  margin-right: 0.9375rem;
  padding-right: 1.875rem;
  border-right: 0.0625rem solid #EEEEEE;
  border-left: 0;
}

[direction="rtl"] .header-right .header-profile>a.nav-link .header-info {
  padding-right: 1.25rem;
  padding-left: auto;
  text-align: right;
}

@media only screen and (max-width: 87.5rem) {
  [direction="rtl"] .header-right .header-profile>a.nav-link .header-info {
    padding-right: 0.625rem;
  }
}

@media only screen and (max-width: 87.5rem) {
  [direction="rtl"] .header-right .header-profile>a.nav-link {
    margin-right: 0.625rem;
    padding-right: 1.25rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .header-right .header-profile>a.nav-link {
    margin-right: 0rem;
    padding-right: 0rem;
    border-right: 0;
  }
}

[direction="rtl"] .header-left .search_bar .dropdown-menu,
[direction="rtl"] .header-left .search_bar .dropdown-menu.show {
  right: 2.5rem !important;
}

@media only screen and (max-width: 35.9375rem) {

  [direction="rtl"] .header-left .search_bar .dropdown-menu,
  [direction="rtl"] .header-left .search_bar .dropdown-menu.show {
    right: -6.25rem !important;
  }
}

[direction="rtl"] .header-left .search_bar .search_icon {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .header-left .search_bar .search_icon {
    border-radius: 5rem;
  }
}

[direction="rtl"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 21.563rem;
}

[direction="rtl"][data-layout="horizontal"] .header .header-content {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

[direction="rtl"][data-layout="horizontal"][data-sidebar-style="full"] .nav-header .brand-logo {
  padding-right: 2.5rem;
}

[direction="rtl"][data-layout="horizontal"][data-sidebar-style="mini"] .header {
  padding-right: 7.75rem;
}

[direction="rtl"][data-sidebar-style="mini"] .header {
  padding-right: 6.25rem;
}

[direction="rtl"][data-sidebar-style="compact"] .header {
  padding: 0 0.9375rem;
  padding-right: 11.25rem;
}

[direction="rtl"][data-sidebar-style="compact"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 21.563rem;
}

[direction="rtl"][data-sidebar-style="modern"] .header {
  padding: 0 0.9375rem;
  padding-right: 10.625rem;
}

[direction="rtl"][data-sidebar-style="modern"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 16rem;
}

[direction="rtl"] {
  text-align: right;
}

[direction="rtl"] .dlabnav {
  text-align: right;
}

[direction="rtl"] .dlabnav .metismenu ul:after {
  left: auto;
  right: 1.5625rem;
}

[direction="rtl"] .dlabnav .metismenu ul a:before {
  left: auto;
  right: 2.625rem;
}

[data-sidebar-style="full"][direction="rtl"] .dlabnav .metismenu li>a i {
  padding: 0 0 0 0;
  margin-right: 0;
  margin-left: 0.625rem;
}

[direction="rtl"] .dlabnav .metismenu li>a svg {
  margin-left: 0.3125rem;
  margin-right: 0;
}

[data-sidebar-style="compact"][direction="rtl"] .dlabnav .metismenu li>a svg {
  left: auto;
  margin-left: auto;
  margin-right: auto;
}

[data-sidebar-style="icon-hover"][direction="rtl"] .dlabnav .metismenu li>a svg {
  margin-left: 0;
}

[direction="rtl"] .dlabnav .metismenu li ul a {
  padding-right: 6rem;
  padding-left: 0.625rem;
}

[direction="rtl"] .dlabnav .metismenu li.active>.has-arrow:after {
  transform: rotate(45deg) translateY(-50%);
}

[direction="rtl"] .dlabnav .metismenu .has-arrow:after {
  left: 1.5625rem;
  right: auto;
}

[data-layout="horizontal"][direction="rtl"] .dlabnav .metismenu .has-arrow:after {
  left: 1.125rem;
}

[data-sidebar-style="modern"][direction="rtl"] .dlabnav .metismenu .has-arrow:after {
  -webkit-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li .has-arrow:after {
  left: 1.5rem;
  right: auto;
}

[direction="rtl"][data-sidebar-style="mini"] .dlabnav .metismenu>li>a>i {
  padding: 0;
}

[direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li>ul a.has-arrow:after {
  left: 1.5625rem;
  right: auto;
}

[direction="rtl"][data-sidebar-style="compact"] .dlabnav .metismenu li>a i {
  padding: 0;
  margin-left: auto;
  margin-right: auto;
}

[direction="rtl"][data-sidebar-style="compact"] .dlabnav .metismenu li ul a {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu li>ul li:hover ul {
  right: 11.8125rem;
  left: 0;
}

[direction="rtl"] {
  /* ===== Light gallery ===== */
}

[direction="rtl"] .select2-container--default .select2-selection--single .select2-selection__arrow {
  left: 15px;
  right: auto;
}

[direction="rtl"] .input-group>.bootstrap-select:not(:first-child) .dropdown-toggle {
  border-radius: 0.75rem 0 0 0.75rem;
}

[direction="rtl"] .list-group {
  padding-left: 0;
}

[direction="rtl"] .form-check-input {
  margin-left: -1.25rem;
  margin-right: inherit;
}

[direction="rtl"] .form-check-inline .form-check-input {
  margin-right: 0;
  margin-left: 10px;
}

[direction="rtl"] .radio input,
[direction="rtl"] .radio-inline,
[direction="rtl"] .checkbox input,
[direction="rtl"] .checkbox-inline input {
  margin-left: 0;
  margin-right: 0;
}

[direction="rtl"] .content-body {
  margin-right: 21.563rem;
  margin-left: auto;
}

[data-sidebar-style="modern"][direction="rtl"] .content-body {
  margin-right: 9.375rem;
}

[direction="rtl"] .content-body .page-titles {
  text-align: right;
}

[direction="rtl"] .doctor-info-details .media-body span i,
[direction="rtl"] .recovered-chart-deta .col [class*="bg-"] {
  margin-right: 0;
  margin-left: 10px;
}

[direction="rtl"] .patients-chart-deta .col,
[direction="rtl"] .patients-chart-deta .col [class*="bg-"],
[direction="rtl"] .recovered-chart-deta .col {
  margin-right: 0;
  margin-left: 15px;
}

[direction="rtl"] .best-doctor .timeline .timeline-panel .media .number {
  left: auto;
  right: -13px;
}

[direction="rtl"] .doctor-info-details .media i {
  right: 0;
  left: -15px;
}

[direction="rtl"] .review-table .disease {
  border-left: 0;
  border-right: 1px solid #eee;
  padding-left: 0;
  padding-right: 20px;
}

[direction="rtl"] .apexcharts-legend-text {
  margin: 4px;
}

[direction="rtl"] .doctor-info-details .media-body {
  padding-left: 0;
  padding-right: 40px;
}

[direction="rtl"] .custom-control {
  margin-left: 0;
}

[direction="rtl"] .review-tab.nav-pills li:first-child a.nav-link {
  border-radius: 0 0.75rem 0 0;
}

[direction="rtl"] .review-tab.nav-pills li:last-child a.nav-link {
  border-radius: 0.75rem 0 0 0;
}

[direction="rtl"] .form-head .btn i {
  margin-left: 5px;
  margin-right: 0;
}

[direction="rtl"] .iconbox {
  padding-left: 0;
  padding-right: 70px;
}

[direction="rtl"] .iconbox i {
  left: auto;
  right: 0;
}

[direction="rtl"] .table.tr-rounded tr td:first-child, [direction="rtl"] .table.tr-rounded tr th:first-child {
  border-radius: 0 0.625rem 0.625rem 0;
}

[direction="rtl"] .table.tr-rounded tr td:last-child, [direction="rtl"] .table.tr-rounded tr th:last-child {
  border-radius: 0.625rem 0 0 0.625rem;
}

[direction="rtl"] .custom-switch.toggle-switch.text-right {
  padding-left: 48px;
  padding-right: 0;
}

[direction="rtl"] .toggle-switch.text-right .custom-control-label:before {
  right: auto !important;
  left: -47px;
}

[direction="rtl"] .toggle-switch.text-right .custom-control-label:after {
  right: auto !important;
  left: -28px;
}

[direction="rtl"] .toggle-switch.text-right .custom-control-input:checked~.custom-control-label:after {
  left: -62px;
  right: auto !important;
}

[direction="rtl"] .check-switch {
  padding-right: 40px;
}

[direction="rtl"] .check-switch .custom-control-label:after, [direction="rtl"] .check-switch .custom-control-label:before {
  right: -35px !important;
}

[direction="rtl"] .bar-chart .apexcharts-yaxis {
  transform: translatex(101%);
}

[direction="rtl"] .detault-daterange .input-group-text {
  padding: 0.532rem 0.75rem;
  padding-right: auto;
  padding-left: 0;
}

[direction="rtl"] .form-wrapper .input-group .form-control {
  text-align: left;
}

[direction="rtl"] .timeline-chart .apexcharts-yaxis {
  transform: translateX(0);
}

[direction="rtl"] .card-table td:first-child {
  padding-right: 30px;
  padding-left: 10px;
}

[direction="rtl"] .card-table td:last-child {
  padding-left: 30px;
  padding-right: 10px;
}

[direction="rtl"] .chatbox .img_cont {
  margin-right: 0px;
  margin-left: 10px;
}

[direction="rtl"] .profile-tab .nav-item .nav-link {
  margin-right: 0;
  margin-left: 30px;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .profile-tab .nav-item .nav-link {
    margin-left: 0px;
  }
}

[direction="rtl"] .widget-timeline .timeline>li>.timeline-panel {
  margin-left: 0px;
  margin-right: 40px;
}

[direction="rtl"] .widget-timeline.style-1 .timeline .timeline-badge.timeline-badge+.timeline-panel {
  padding: 5px 15px 5px 10px;
  border-width: 0px 5px 0px 0px;
}

[direction="rtl"] .widget-timeline.style-1 .timeline .timeline-badge.timeline-badge+.timeline-panel:after {
  border: 0;
  right: -9px;
  width: 7px;
  left: auto;
  height: 7px;
}

[direction="rtl"] .widget-timeline .timeline>li>.timeline-badge {
  left: auto;
  right: 0px;
}

[direction="rtl"] .widget-timeline .timeline>li>.timeline-panel:after {
  left: auto;
  right: -5px;
}

[direction="rtl"] .chatbox .msg_cotainer {
  margin-left: 0;
  margin-right: 10px;
}

[direction="rtl"] .new-arrival-content .price {
  float: left;
}

[direction="rtl"] .input-group>.bootstrap-select:not(:first-child) .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

[direction="rtl"] .input-group>.bootstrap-select:not(:last-child) .dropdown-toggle {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

[direction="rtl"] .breadcrumb-item+.breadcrumb-item {
  padding-right: 0.5rem;
  padding-left: 0;
}

[direction="rtl"] .breadcrumb-item+.breadcrumb-item::before {
  padding-right: 0;
  padding-left: 0.5rem;
}

[direction="rtl"] .chatbox .chatbox-close {
  left: 340px;
  right: auto;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .chatbox .chatbox-close {
    left: 280px;
  }
}

[direction="rtl"] .separator {
  margin-right: 0;
  margin-left: 9px;
}

[direction="rtl"] .card-tabs .nav-tabs {
  padding-right: 5px;
}

[direction="rtl"] .chatbox .msg_cotainer {
  margin-left: 0;
  margin-right: 10px;
  border-radius: 1.375rem 0 1.375rem 1.375rem;
}

[direction="rtl"] .chatbox .msg_cotainer:after {
  left: auto;
  right: -10px;
  transform: rotate(-90deg);
}

[direction="rtl"] .chatbox .type_msg .input-group .input-group-append .btn {
  border-top-right-radius: 38px !important;
  border-bottom-right-radius: 38px !important;
}

[direction="rtl"] .chatbox .msg_cotainer_send {
  margin-right: 0px;
  margin-left: 10px;
  border-radius: 0 6px 6px 6px;
}

[direction="rtl"] .chatbox .msg_cotainer_send:after {
  right: auto;
  left: -10px;
  transform: rotate(90deg);
}

[direction="rtl"] .new-arrival-content .price {
  float: left;
}

[direction="rtl"] .trending-menus .tr-row .num {
  margin-right: 0;
  margin-left: 15px;
}

[direction="rtl"] .default-select.style-2 .btn:after {
  margin-left: 0;
  margin-right: 0.5em;
}

[direction="rtl"] .widget-timeline-icon li .icon {
  left: auto;
  right: -2px;
}

[direction="rtl"] .widget-timeline-icon li a {
  padding: 25px 0px 0px 50px;
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .widget-timeline-icon .timeline {
    margin-left: 0;
    margin-right: 10px;
  }

  [direction="rtl"] .widget-timeline-icon li {
    border-left: 0;
    border-right: 6px solid #453773;
  }

  [direction="rtl"] .widget-timeline-icon li a {
    padding: 0 30px 30px 0;
  }

  [direction="rtl"] .widget-timeline-icon li .icon {
    right: -12px;
  }

  [direction="rtl"] .widget-timeline-icon li:last-child {
    border-color: transparent;
  }
}

[direction="rtl"] #revenueMap .apexcharts-yaxis, [direction="rtl"] #customerMapkm .apexcharts-yaxis {
  transform: translateX(0px);
}

[direction="rtl"] .mail-list .list-group-item i {
  padding-right: 0;
  padding-left: 0.625rem;
}

[direction="rtl"] .dlab-demo-panel {
  right: auto;
  left: -380px;
}

[direction="rtl"] .dlab-demo-panel.show {
  right: unset;
  left: 0;
}

[direction="rtl"] .dlab-demo-panel .dlab-demo-trigger {
  left: 100%;
  right: auto;
  border-radius: 0 5px 5px 0;
  box-shadow: 5px 3px 5px 0px rgba(119, 119, 119, 0.15);
}

[direction="rtl"][data-layout="horizontal"] .content-body {
  margin-right: 0;
}

[direction="rtl"][data-layout="horizontal"] .dlabnav .metismenu li li .has-arrow:after {
  -webkit-transform: rotate(-4deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
}

[direction="rtl"][data-sidebar-style="mini"]:not([data-layout="horizontal"]) .content-body {
  margin-right: 6.25rem;
}

[direction="rtl"][data-sidebar-style="compact"]:not([data-layout="horizontal"]) .content-body {
  margin-right: 11.25rem;
}

[direction="rtl"][data-sidebar-style="overlay"] .content-body {
  margin-right: 0;
}

[direction="rtl"] #external-events .external-event:before {
  margin-right: 0;
  margin-left: .9rem;
}

[direction="rtl"] .post-input a i {
  margin-left: 15px;
  margin-right: 0;
}

[direction="rtl"][data-sidebar-style="compact"] .dlabnav .metismenu .has-arrow:after {
  -webkit-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
}

[direction="rtl"] .dlabnav .metismenu .has-arrow:after {
  -webkit-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg) translateY(-50%);
}

[direction="rtl"] .dlabnav .metismenu .has-arrow[aria-expanded=true]:after,
[direction="rtl"] .dlabnav .metismenu .mm-active>.has-arrow:after {
  -webkit-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg);
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu .has-arrow[aria-expanded=true]:after,
[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu .mm-active>.has-arrow:after {
  -webkit-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(-45deg);
}

[direction="rtl"] .chatbox {
  left: -500px;
  right: auto;
}

[direction="rtl"] .chatbox.active {
  left: 0;
  right: auto;
}

@media only screen and (max-width: 575px) {
  [direction="rtl"] .best-doctor .timeline .timeline-panel .media {
    float: right;
    margin-right: 0 !important;
    margin-left: 15px !important;
  }
}

[direction="rtl"] .default-select.style-1 .btn:after {
  margin-left: 0;
  margin-right: 0.5em;
}

[direction="rtl"] .pagination .page-indicator {
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

[direction="rtl"] .lg-outer.lg-visible {
  direction: ltr;
}

[direction="rtl"] .chart-point .chart-point-list {
  margin: 0;
  padding-right: 20px;
}

[direction="rtl"] .noUi-target {
  direction: rtl;
}

[direction="rtl"] .noUi-vertical .noUi-pips-vertical {
  left: -20px;
}

[direction="rtl"] .noUi-vertical .noUi-value-vertical {
  padding-left: 0;
  padding-right: 25px;
}

[direction="rtl"] .sidebar-right .ps--active-x>.ps__rail-x {
  display: none;
}

[direction="rtl"] .form-wizard .nav-wizard li .nav-link:after {
  right: 50%;
  left: auto;
}

[direction="rtl"] .dtp>.dtp-content {
  right: 50%;
  left: auto;
}

[direction="rtl"] .modal-header .close {
  margin: 0;
  left: 0;
  top: 0px;
  right: auto;
}

[direction="rtl"] .input-group-prepend .btn+.btn {
  border-radius: 0 !important;
}

[direction="rtl"] .form-control+.input-group-append .btn:first-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

[direction="rtl"] .input-group>.custom-file:not(:first-child) .custom-file-label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0.75rem;
  border-top-left-radius: 0.75rem;
}

[direction="rtl"] .custom-file-label::after {
  border-radius: .75rem 0 0 .75rem;
}

[direction="rtl"] .input-group>.custom-file:not(:last-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

[direction="rtl"] .input-group>.custom-file:not(:last-child) .custom-file-label::after {
  border-radius: 0;
}

@media only screen and (max-width: 1350px) and (min-width: 1200px) {
  [direction="rtl"] .content-body {
    margin-right: 17rem;
  }
}

[direction="rtl"] .sidebar-right {
  right: auto;
  left: -50rem;
}

[direction="rtl"] .sidebar-right.show {
  left: 5.25rem;
  right: unset;
}

[direction="rtl"] .sidebar-right .sidebar-right-trigger {
  left: 100%;
  right: auto;
  border-radius: 0 5px 5px 0;
  box-shadow: 5px 3px 5px 0px rgba(119, 119, 119, 0.15);
}

[direction="rtl"] .sidebar-right .sidebar-close-trigger {
  right: auto;
  left: -48px;
}

[direction="rtl"] .bootstrap-select .dropdown-toggle .filter-option {
  text-align: right;
}

html[dir="rtl"] [direction="rtl"] .footer {
  padding-right: 17.1875rem;
  padding-left: 0;
}

@media (max-width: 767.98px) {
  html[dir="rtl"] [direction="rtl"] .footer {
    padding-right: 0;
  }
}

html[dir="rtl"] [direction="rtl"][data-sidebar-style="overlay"] .footer {
  padding-right: 0;
}

html[dir="rtl"] [direction="rtl"] .menu-toggle .footer {
  padding-right: 3.75rem;
}

html[dir="rtl"] [direction="rtl"][data-container="boxed"] .footer {
  padding-right: 0;
}

html[dir="rtl"] [direction="rtl"][data-sidebar-style="mini"]:not([data-layout="horizontal"]) .footer {
  padding-right: 3.75rem;
}

html[dir="rtl"] [direction="rtl"][data-sidebar-style="compact"]:not([data-layout="horizontal"]) .footer {
  padding-right: 9.375rem;
}

:root {
  --primary: #453773;
  --secondary: #4CBC9A;
  --secondary-dark: #3a9b7e;
  --primary-hover: #ff3c26;
  --primary-dark: #bf1400;
  --rgba-primary-1: rgba(255, 106, 89, 0.1);
  --rgba-primary-2: rgba(255, 106, 89, 0.2);
  --rgba-primary-3: rgba(255, 106, 89, 0.3);
  --rgba-primary-4: rgba(255, 106, 89, 0.4);
  --rgba-primary-5: rgba(255, 106, 89, 0.5);
  --rgba-primary-6: rgba(255, 106, 89, 0.6);
  --rgba-primary-7: rgba(255, 106, 89, 0.7);
  --rgba-primary-8: rgba(255, 106, 89, 0.8);
  --rgba-primary-9: rgba(255, 106, 89, 0.9);
  --rgba-secondary-1: rgba(76, 188, 154, 0.1);
  --rgba-secondary-2: rgba(76, 188, 154, 0.2);
  --rgba-secondary-3: rgba(76, 188, 154, 0.3);
  --rgba-secondary-4: rgba(76, 188, 154, 0.4);
  --rgba-secondary-5: rgba(76, 188, 154, 0.5);
  --rgba-secondary-6: rgba(76, 188, 154, 0.6);
  --rgba-secondary-7: rgba(76, 188, 154, 0.7);
  --rgba-secondary-8: rgba(76, 188, 154, 0.8);
  --rgba-secondary-9: rgba(76, 188, 154, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #374557;
}

[data-theme-version="dark"] {
  background: #20203c;
  color: #828690;
  --nav-headbg: #292d4a;
  --sidebar-bg: #292d4a;
  --headerbg: #292d4a;
}

[data-theme-version="dark"] h1, [data-theme-version="dark"] .h1,
[data-theme-version="dark"] h2,
[data-theme-version="dark"] .h2,
[data-theme-version="dark"] h3,
[data-theme-version="dark"] .h3,
[data-theme-version="dark"] h4,
[data-theme-version="dark"] .h4,
[data-theme-version="dark"] h5,
[data-theme-version="dark"] .h5,
[data-theme-version="dark"] h6,
[data-theme-version="dark"] .h6 {
  color: #fff !important;
}

[data-theme-version="dark"] a.link {
  color: #ddd;
}

[data-theme-version="dark"] a.link:focus,
[data-theme-version="dark"] a.link:hover {
  color: #58bad7;
}

[data-theme-version="dark"] a:hover {
  color: #fff;
}

[data-theme-version="dark"] .border-right {
  border-right: 0.0625rem solid #333754 !important;
}

[data-theme-version="dark"] .border-left {
  border-left: 0.0625rem solid #333754 !important;
}

[data-theme-version="dark"] .border-top {
  border-top: 0.0625rem solid #333754 !important;
}

[data-theme-version="dark"] .border-bottom {
  border-bottom: 0.0625rem solid #333754 !important;
}

[data-theme-version="dark"] .border {
  border: 0.0625rem solid #333754 !important;
}

[data-theme-version="dark"] .card {
  background-color: #292d4a;
  box-shadow: none;
}

[data-theme-version="dark"] .dropdown-menu {
  background-color: #292d4a;
  box-shadow: 0rem 0rem 0rem 0.0625rem rgba(255, 255, 255, 0.1);
}

[data-theme-version="dark"] .dropdown-menu .dropdown-item {
  color: #777777;
}

[data-theme-version="dark"] .dropdown-menu .dropdown-item.selected, [data-theme-version="dark"] .dropdown-menu .dropdown-item.selected.active, [data-theme-version="dark"] .dropdown-menu .dropdown-item.active, [data-theme-version="dark"] .dropdown-menu .dropdown-item:hover, [data-theme-version="dark"] .dropdown-menu .dropdown-item:focus {
  background-color: #292d4a;
  color: #fff;
}

[data-theme-version="dark"] a {
  color: #fff !important;
}

[data-theme-version="dark"] .btn-link g [fill] {
  fill: #fff;
}

[data-theme-version="dark"] .btn-light:active,
[data-theme-version="dark"] .btn-light:focus,
[data-theme-version="dark"] .btn-light:hover {
  color: #000;
}

[data-theme-version="dark"] .form-control {
  background-color: #2E2E40;
  border-color: #333754;
  color: #fff;
}

[data-theme-version="dark"] .modal-content {
  background: #292d4a;
}

[data-theme-version="dark"] .modal-footer,
[data-theme-version="dark"] .modal-header {
  border-color: #333754;
}

[data-theme-version="dark"] .close {
  font-weight: 400;
  color: #fff;
  text-shadow: none;
}

[data-theme-version="dark"] .close:hover {
  color: #fff;
}

[data-theme-version="dark"] .star-rating .product-review,
[data-theme-version="dark"] .new-arrival-content .item {
  color: #fff;
}

[data-theme-version="dark"] .custom-dropdown .dropdown-menu {
  border-color: #333754;
}

[data-theme-version="dark"] .widget-stat .media>span {
  background: #333754;
  border-color: #333754;
  color: #fff;
}

[data-theme-version="dark"] .plus-minus-input .custom-btn {
  background: #20203c;
  border-color: #333754;
}

[data-theme-version="dark"] .dropdown-divider,
[data-theme-version="dark"] .size-filter ul li {
  border-color: #333754;
}

[data-theme-version="dark"] .custom-select {
  border-color: #333754;
  color: #828690;
  background: #20203c;
}

[data-theme-version="dark"] .nav-tabs {
  border-color: #333754 !important;
}

[data-theme-version="dark"] .mail-list .list-group-item.active i {
  color: #fff;
}

[data-theme-version="dark"] hr {
  border-color: #333754;
}

[data-theme-version="dark"] .grid-col {
  background: #20203c;
}

[data-theme-version="dark"] .noUi-target {
  border-color: #333754;
  border-radius: 0.5rem;
  box-shadow: none;
}

[data-theme-version="dark"] .noUi-target .noUi-connects {
  background: #333754;
}

[data-theme-version="dark"] .noUi-marker-large,
[data-theme-version="dark"] .noUi-marker {
  background: #333754;
}

[data-theme-version="dark"] .input-group-text {
  background: #292d4a;
  color: #fff;
  border-color: #333754;
}

[data-theme-version="dark"] .note-editor.note-frame {
  border-color: #333754;
}

[data-theme-version="dark"] .note-editor.note-frame .btn {
  color: #fff;
}

[data-theme-version="dark"] .note-editor.note-frame .note-editing-area .note-editable {
  color: #fff;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel {
  border-color: #333754;
}

[data-theme-version="dark"] .notification_dropdown .dropdown-menu-right .all-notification {
  border-color: #333754;
}

[data-theme-version="dark"] #user-activity .nav-tabs .nav-link {
  border-color: #333754;
}

[data-theme-version="dark"] #user-activity .nav-tabs .nav-link.active {
  background: #20203c;
  color: #fff;
}

[data-theme-version="dark"] .list-group-item-action {
  color: #777777;
}

[data-theme-version="dark"] .list-group-item-action:focus,
[data-theme-version="dark"] .list-group-item-action:hover,
[data-theme-version="dark"] .list-group-item-action:focus {
  background-color: #20203c;
  border-color: #20203c;
}

[data-theme-version="dark"] .list-group-item.active {
  color: #fff;
  border-color: var(--primary);
}

[data-theme-version="dark"] .list-group-item.active:focus, [data-theme-version="dark"] .list-group-item.active:hover, [data-theme-version="dark"] .list-group-item.active:focus {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

[data-theme-version="dark"] .swal2-popup {
  background: #292d4a;
}

[data-theme-version="dark"] .form-head .btn-outline-primary {
  border-color: #333754;
}

[data-theme-version="dark"] .form-head .btn-outline-primary:hover {
  border-color: var(--primary);
}

[data-theme-version="dark"] .review-tab.nav-pills li a.nav-link.active {
  background: transparent;
}

[data-theme-version="dark"] .new-arrival-content h4 a, [data-theme-version="dark"] .new-arrival-content .h4 a {
  color: #fff;
}

[data-theme-version="dark"] .text-black {
  color: #fff !important;
}

[data-theme-version="dark"] .abilities-chart .ct-chart .ct-label {
  fill: #fff;
}

[data-theme-version="dark"] .morris_chart_height text tspan {
  fill: #fff;
}

[data-theme-version="dark"] .btn-link {
  color: #fff;
}

[data-theme-version="dark"] .order-bg {
  background: #20203c;
}

[data-theme-version="dark"] .detault-daterange {
  background: #20203c;
  color: #fff;
}

[data-theme-version="dark"] .detault-daterange .input-group-text {
  background: #292d4a;
  border: 0;
}

[data-theme-version="dark"] .dataTablesCard {
  background-color: #292d4a;
}

[data-theme-version="dark"] .compose-content .dropzone {
  background: #20203c !important;
}

[data-theme-version="dark"] .compose-content .dropzone .dlab-message .dlab-button {
  color: #fff;
}

[data-theme-version="dark"] .daterangepicker {
  background: #20203c;
  border-color: var(--primary);
}

[data-theme-version="dark"] .daterangepicker .calendar-table {
  border-color: var(--primary);
  background: #20203c;
}

[data-theme-version="dark"] .daterangepicker .calendar-table .table-condensed td:hover {
  background-color: var(--primary);
  color: #fff;
}

[data-theme-version="dark"] .daterangepicker:after {
  border-bottom: 0.375rem solid #20203c;
}

[data-theme-version="dark"] .daterangepicker select.hourselect, [data-theme-version="dark"] .daterangepicker select.minuteselect, [data-theme-version="dark"] .daterangepicker select.secondselect, [data-theme-version="dark"] .daterangepicker select.ampmselect {
  background: #20203c;
  border: 0.0625rem solid #333754;
  color: #fff;
}

[data-theme-version="dark"] .daterangepicker td.off,
[data-theme-version="dark"] .daterangepicker td.off.in-range,
[data-theme-version="dark"] .daterangepicker td.off.start-date,
[data-theme-version="dark"] .daterangepicker td.off.end-date {
  background-color: #292d4a;
}

[data-theme-version="dark"] .daterangepicker td.off:hover,
[data-theme-version="dark"] .daterangepicker td.off.in-range:hover,
[data-theme-version="dark"] .daterangepicker td.off.start-date:hover,
[data-theme-version="dark"] .daterangepicker td.off.end-date:hover {
  background-color: var(--primary);
  color: #fff;
}

[data-theme-version="dark"] .app-fullcalendar .fc-button {
  background-color: #20203c;
  border-color: var(--primary);
  color: #fff;
  text-shadow: none;
}

[data-theme-version="dark"] .app-fullcalendar .fc-button:hover, [data-theme-version="dark"] .app-fullcalendar .fc-button.fc-stat-hover {
  background-color: var(--primary);
}

[data-theme-version="dark"] .swal2-popup .swal2-styled:focus {
  outline: 0;
  box-shadow: 0 0 0 0.125rem #333754, 0 0 0 0.25rem var(--rgba-primary-1);
}

[data-theme-version="dark"] .dd-handle {
  border-color: #333754;
}

[data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #292d4a !important;
}

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link {
  border-color: #333754;
}

[data-theme-version="dark"] .nav-tabs .nav-link:hover, [data-theme-version="dark"] .nav-tabs .nav-link.active {
  border-color: var(--primary);
  background: transparent;
  color: white !important;
}

[data-theme-version="dark"] .clockpicker-popover .popover-content {
  background-color: #292d4a;
}

[data-theme-version="dark"] .clockpicker-plate {
  background-color: #20203c;
}

[data-theme-version="dark"] .clockpicker-popover .popover-title {
  background-color: #20203c;
  color: #fff;
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link span {
  background-color: #20203c;
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link:after {
  background: #20203c;
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.active:after, [data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.done:after {
  background: var(--primary);
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.active span, [data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.done span {
  background: var(--primary);
}

[data-theme-version="dark"] .check-switch .custom-control-label:after, [data-theme-version="dark"] .check-switch .custom-control-label:before {
  border-color: var(--primary);
}

[data-theme-version="dark"] .fc-unthemed .fc-today {
  background: #20203c;
}

[data-theme-version="dark"] .fc-unthemed .fc-divider, [data-theme-version="dark"] .fc-unthemed .fc-list-heading td, [data-theme-version="dark"] .fc-unthemed .fc-popover .fc-header {
  background: #333754;
}

[data-theme-version="dark"] .picker__box {
  background: #20203c;
}

[data-theme-version="dark"] .picker__box .picker__button--clear, [data-theme-version="dark"] .picker__box .picker__button--close, [data-theme-version="dark"] .picker__box .picker__button--today {
  background: #292d4a;
  color: #fff;
}

[data-theme-version="dark"] .picker__box .picker__button--clear:hover:before, [data-theme-version="dark"] .picker__box .picker__button--close:hover:before, [data-theme-version="dark"] .picker__box .picker__button--today:hover:before {
  color: #fff;
}

[data-theme-version="dark"] .picker {
  color: #999;
}

[data-theme-version="dark"] .dtp>.dtp-content {
  background: #20203c;
}

[data-theme-version="dark"] .dtp table.dtp-picker-days tr>td>a {
  color: #68686a;
}

[data-theme-version="dark"] .dtp table.dtp-picker-days tr>td>a.selected {
  color: #fff;
}

[data-theme-version="dark"] .order-request tbody tr {
  border-color: #333754;
}

[data-theme-version="dark"] .card-list li {
  color: #fff;
}

[data-theme-version="dark"] .card-bx .change-btn:hover {
  color: var(--primary);
}

[data-theme-version="dark"] .invoice-card.bg-warning {
  background-color: #5b3c1f !important;
}

[data-theme-version="dark"] .invoice-card.bg-success {
  background-color: #2a6729 !important;
}

[data-theme-version="dark"] .invoice-card.bg-info {
  background-color: #4c276a !important;
}

[data-theme-version="dark"] .invoice-card.bg-secondary {
  background-color: #1c3e52 !important;
}

[data-theme-version="dark"] .user-list li {
  border-color: #292d4a;
}

[data-theme-version="dark"] .toggle-switch {
  color: #fff;
}

[data-theme-version="dark"] .bar-chart .apexcharts-text tspan {
  fill: #969ba0;
}

[data-theme-version="dark"] .bar-chart line {
  stroke: #333754;
}

[data-theme-version="dark"] .coin-card {
  background: #0f6a62;
  background: -moz-linear-gradient(left, #0f6a62 0%, #084355 100%);
  background: -webkit-linear-gradient(left, #0f6a62 0%, #084355 100%);
  background: linear-gradient(to right, #0f6a62 0%, #084355 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0f6a62', endColorstr='#084355', GradientType=1);
}

[data-theme-version="dark"] .coin-card .coin-icon {
  background: rgba(0, 0, 0, 0.2);
}

[data-theme-version="dark"] .accordion.style-1 .accordion-item, [data-theme-version="dark"] .invoice-list {
  border-color: #333754;
}

[data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed .user-info a, [data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed .user-info, [data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed>span {
  color: #fff;
}

[data-theme-version="dark"] .ic-card>a {
  background: #25479f;
}

[data-theme-version="dark"] .ic-card>a:first-child {
  border-color: #25479f;
}

[data-theme-version="dark"] .ic-card span {
  color: #fff;
}

[data-theme-version="dark"] table.dataTable thead th, [data-theme-version="dark"] table.dataTable thead td {
  border-color: #333754 !important;
}

[data-theme-version="dark"] .form-check .form-check-input {
  background: transparent;
}

[data-theme-version="dark"] .form-check .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

[data-theme-version="dark"] .paging_simple_numbers.dataTables_paginate {
  background: #20203c;
}

[data-theme-version="dark"] .dataTables_info {
  color: #fff;
}

[data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate span .paginate_button.current,
[data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate span .paginate_button:hover {
  background: var(--primary);
  color: #fff !important;
}

[data-theme-version="dark"] .dashboard-select {
  background: var(--rgba-primary-1);
  color: white;
  border-color: #333754;
}

[data-theme-version="dark"] .dashboard-select .list {
  background: #292d4a;
}

[data-theme-version="dark"] .dashboard-select .option:hover, [data-theme-version="dark"] .dashboard-select .option.focus, [data-theme-version="dark"] .dashboard-select .option.selected.focus {
  background: #20203c;
}

[data-theme-version="dark"] .card-tabs.style-1 .nav-tabs {
  background: #292d4a;
}

[data-theme-version="dark"] .transaction-details {
  border-color: #333754;
}

[data-theme-version="dark"] .description {
  color: #fff;
}

[data-theme-version="dark"] .transaction-details .amount-bx {
  background: #3f250d;
}

[data-theme-version="dark"] .transaction-details .amount-bx i {
  background: #8d3b0c;
}

[data-theme-version="dark"] .nice-select .option {
  background: #292d4a;
}

[data-theme-version="dark"] .nice-select .option:hover, [data-theme-version="dark"] .nice-select .option.focus, [data-theme-version="dark"] .nice-select .option.selected.focus {
  background: #292d4a;
}

[data-theme-version="dark"] .card-tabs.style-1 {
  border-color: #333754;
}

[data-theme-version="dark"] .nice-select .list {
  background: #20203c;
}

[data-theme-version="dark"] .table-search .input-group .input-group-text {
  background: #292d4a;
}

[data-theme-version="dark"] .dark-btn svg path {
  stroke: #fff;
}

[data-theme-version="dark"] .bar-chart .apexcharts-legend-text {
  color: #fff !important;
}

[data-theme-version="dark"] .Sales tspan {
  fill: #fff;
}

[data-theme-version="dark"] .timeline-chart rect {
  fill: #20203c;
}

[data-theme-version="dark"] .header-info span {
  color: #fff !important;
}

[data-theme-version="dark"] .smallipop-instance.blue {
  color: #fff;
}

[data-theme-version="dark"] .property-map .smallipop.smallimap-mapicon .circle-marker {
  border-color: #333754;
}

[data-theme-version="dark"] .property-features li {
  color: #fff;
}

[data-theme-version="dark"] .review-tabs-1 {
  background: #292d4a;
}

[data-theme-version="dark"] .coin-tabs {
  background: #2E2E40;
}

[data-theme-version="dark"] .coin-tabs .nav-link.active {
  background: var(--primary) !important;
  color: #fff;
}

[data-theme-version="dark"] .separate-row>div {
  border-color: #333754;
}

[data-theme-version="dark"] .bg-white {
  background: #2E2E40 !important;
}

[data-theme-version="dark"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-theme-version="dark"] .brand-title path {
  fill: #fff;
}

[data-theme-version="dark"] .brand-title tspan {
  fill: #fff;
}

[data-theme-version="dark"] .shapreter-row>div {
  border-color: #333754;
}

[data-theme-version="dark"] .chartBar line {
  stroke: #333754;
}

[data-theme-version="dark"] .search-job .search-dropdown:after {
  background: #333754;
}

[data-theme-version="dark"] .chartjs line {
  stroke: #333754;
}

[data-theme-version="dark"] .form-label {
  color: #fff;
}

[data-theme-version="dark"] .datepicker-switch {
  color: #fff;
}

[data-theme-version="dark"] .datepicker.datepicker-dropdown td.day, [data-theme-version="dark"] .datepicker.datepicker-dropdown th.next, [data-theme-version="dark"] .datepicker.datepicker-dropdown th.prev {
  color: #fff;
}

[data-theme-version="dark"] .datepicker.datepicker-dropdown th.dow {
  color: #fff;
}

[data-theme-version="dark"] .datepicker.datepicker-dropdown th.datepicker-switch, [data-theme-version="dark"] .datepicker.datepicker-dropdown th.next, [data-theme-version="dark"] .datepicker.datepicker-dropdown th.prev {
  color: #fff;
}

[data-theme-version="dark"] .btn-close {
  color: #fff !important;
}

[data-theme-version="dark"] .bgl-secondary {
  color: #fff;
}

[data-theme-version="dark"] .application tr td .btn {
  color: #fff;
}

[data-theme-version="dark"] .btn-link svg path {
  stroke: #fff;
}

[data-theme-version="dark"] .alert.alert-outline-secondary,
[data-theme-version="dark"] .alert.alert-outline-dark {
  color: #fff;
}

[data-theme-version="dark"] .form-control-plaintext {
  color: #fff;
}

[data-theme-version="dark"] .timeline-panel .media-body small, [data-theme-version="dark"] .timeline-panel .media-body .small {
  color: #fff;
}

[data-theme-version="dark"] .jobs {
  border-color: #333754;
}

[data-theme-version="dark"] .apexcharts-gridlines-vertical line {
  stroke: #333754;
}

[data-theme-version="dark"] .apexcharts-text tspan {
  fill: #fff;
}

[data-theme-version="dark"] .widget-courses .schedule-icon {
  color: #fff;
}

[data-theme-version="dark"] .card-schedule .up-comming-schedule,
[data-theme-version="dark"] .upcomming-task-widget {
  background: #292d4a;
}

[data-theme-version="dark"] .card-schedule .up-comming-schedule.style-1 {
  background: #20203c;
}

[data-theme-version="dark"] .card-schedule .up-comming-schedule.style-1 .date-box {
  background: #292d4a;
  color: #fff;
}

[data-theme-version="dark"] .instructors-box .instructors-media .custome-badge .badge {
  color: #fff;
}

[data-theme-version="dark"] .instructors-box .instructors-media .info-box {
  background: #20203c;
}

[data-theme-version="dark"] .course-details-tab .nav-tabs .nav-link {
  color: #cbc3c3;
}

[data-theme-version="dark"] .message-box,
[data-theme-version="dark"] .chat-icon,
[data-theme-version="dark"] .chat-people,
[data-theme-version="dark"] .chat-tabs .chat-bx,
[data-theme-version="dark"] .share-files,
[data-theme-version="dark"] .type-massage,
[data-theme-version="dark"] .work-ic {
  border-color: #333754;
}

[data-theme-version="dark"] .timeline-active .modulel {
  background: #292d4a;
}

[data-theme-version="dark"] .timeline-active li .time {
  background: #292d4a;
}

[data-theme-version="dark"] .achievements .achievements-content span,
[data-theme-version="dark"] .bio .bio-content {
  background: #20203c;
}

[data-theme-version="dark"] .score-active.style-1 .dashboard-select {
  background: #20203c;
}

[data-theme-version="dark"] #activity line {
  stroke: #333754;
}

[data-theme-version="dark"] .pagination-down ul li a {
  background: #20203c;
}

[data-theme-version="dark"] .pagination-down ul li a.active {
  background: var(--primary);
}

[data-theme-version="dark"] .custome-accordion .accordion-button {
  background: #292d4a;
}

[data-theme-version="dark"] .custome-accordion .acc-courses {
  background: #20203c;
}

[data-theme-version="dark"] .widget-timeline .side-border h4, [data-theme-version="dark"] .widget-timeline .side-border .h4, [data-theme-version="dark"] .widget-timeline .side-border .h4,
[data-theme-version="dark"] .widget-timeline .side-border .custom-dropdown {
  background: #292d4a;
}

[data-theme-version="dark"] .widget-timeline .side-border:after {
  border-color: #333754;
}

[data-theme-version="dark"] #columnChart line {
  stroke: #333754;
}

[data-theme-version="dark"] #sellingActivity svg path {
  stroke: #292d4a;
}

[data-theme-version="dark"] #sellingActivity line {
  stroke: #333754;
}

[data-theme-version="dark"] .review-box {
  background: #292d4a;
}

[data-theme-version="dark"] #NewCustomers line {
  stroke: #fff;
}

[data-theme-version="dark"] .students-list .search-area .form-control,
[data-theme-version="dark"] .students-list .search-area .input-group-text {
  background: #20203c;
  border: #333754;
}

[data-theme-version="dark"] .custome-accordion.style-1 .acc-courses {
  background: #292d4a;
  border-color: #333754;
}

[data-theme-version="dark"] .miac,
[data-theme-version="dark"] .profile-img img, [data-theme-version="dark"] .profile-img span,
[data-theme-version="dark"] .compose-content .dropzone,
[data-theme-version="dark"] .fc-theme-standard td, [data-theme-version="dark"] .fc-theme-standard th,
[data-theme-version="dark"] .fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid, [data-theme-version="dark"] .fc-scrollgrid, [data-theme-version="dark"] table {
  border-color: #333754;
}

[data-theme-version="dark"] .btn-icon-end {
  border-color: #333754;
}

[data-theme-version="dark"] .raiting li {
  border-color: #333754;
}

[data-theme-version="dark"] .miac svg path {
  fill: #fff;
}

[data-theme-version="dark"] .type-massage.style-1 {
  background: #20203c;
}

[data-theme-version="dark"] .header .header-content:after {
  background: #333754;
}

[data-theme-version="dark"] .search-coundry .dashboard-select {
  color: #fff;
  background: transparent;
}

[data-theme-version="dark"] .search-coundry .dashboard-select .option {
  color: #fff;
}

[data-theme-version="dark"] .search-coundry .dashboard-select:after {
  border-bottom: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
}

[data-theme-version="dark"] .sidebar-right .nice-select .option.selected {
  color: #000;
}

[data-theme-version="dark"] #redial .apexcharts-datalabels-group text {
  fill: #fff;
}

[data-theme-version="dark"] .notification_dropdown .dropdown-menu-end .all-notification {
  border-color: #333754;
}

[data-theme-version="dark"] .app-fullcalendar1 .fc .fc-button-group>.fc-button {
  border-color: #333754;
}

[data-theme-version="dark"] .app-fullcalendar1 .fc-direction-ltr .fc-daygrid-event.fc-event-end, [data-theme-version="dark"] .app-fullcalendar1 .fc-direction-rtl .fc-daygrid-event.fc-event-start {
  background: #292d4a !important;
  border-color: #333754;
}

[data-theme-version="dark"] .card-calendar.style-1 .bootstrap-datetimepicker-widget .table-condensed td.day {
  color: #ffffff !important;
}

[data-theme-version="dark"] .dlab-side-menu {
  background: transparent;
}

[data-theme-version="dark"] .nice-select .option.selected {
  color: #fff;
}

[data-theme-version="dark"] .dlab-cource,
[data-theme-version="dark"] .message-box,
[data-theme-version="dark"] .type-massage {
  background-color: #292d4a;
}

[data-theme-version="dark"] .dlab-cource .form-control,
[data-theme-version="dark"] .message-box .form-control,
[data-theme-version="dark"] .type-massage .form-control {
  background-color: transparent;
}

[data-theme-version="dark"] .card-calendar.style-1 .bootstrap-datetimepicker-widget table .picker-switch {
  color: #fff;
}

[data-theme-version="dark"] .fc .fc-button-group>.fc-button {
  border-color: #333754;
}

[data-theme-version="dark"] .fc-daygrid-dot-event {
  background-color: #20203c;
  border-color: #20203c;
}

[data-theme-version="dark"] #redial .apexcharts-radialbar-hollow {
  fill: #292d4a;
}

[data-theme-version="dark"] .chat-tabs .chat-bx.active:after {
  background-color: #20203c;
}

[data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.day:hover, [data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.hour:hover, [data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.minute:hover, [data-theme-version="dark"] .bootstrap-datetimepicker-widget table td.second:hover {
  background: #20203c;
}

[data-theme-version="dark"] .chat-box-area .message-received p {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

[data-theme-version="dark"] .chat-icon ul li a,
[data-theme-version="dark"] .file {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-theme-version="dark"] .chat-icon ul li a svg path,
[data-theme-version="dark"] .file svg path {
  fill: #fff;
}

[data-theme-version="dark"] .course-dedails-bx .description .user-pic span,
[data-theme-version="dark"] .course-learn ul li {
  color: #fff;
}

[data-theme-version="dark"] .course-details-tab .user-pic2 p {
  color: #828690;
}

[data-theme-version="dark"] #columnChart .apexcharts-bar-series path {
  stroke: #292d4a;
}

[data-theme-version="dark"] .btn-close {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'%3E%3C!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3E%3Cpath d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z' fill='%23fff'/%3E%3C/svg%3E");
}

[data-theme-version="dark"] .select-dropdown .selectBtn {
  color: #fff !important;
}

[data-theme-version="dark"] .process-calendar .react-calendar,
[data-theme-version="dark"] .instructor-calendar .react-calendar {
  background: transparent;
}

[data-theme-version="dark"] .process-calendar .react-calendar .react-calendar__month-view__weekdays,
[data-theme-version="dark"] .process-calendar .react-calendar .react-calendar__month-view__days__day,
[data-theme-version="dark"] .process-calendar .react-calendar .react-calendar__navigation__arrow,
[data-theme-version="dark"] .process-calendar .react-calendar .react-calendar__navigation__label__labelText--from,
[data-theme-version="dark"] .instructor-calendar .react-calendar .react-calendar__month-view__weekdays,
[data-theme-version="dark"] .instructor-calendar .react-calendar .react-calendar__month-view__days__day,
[data-theme-version="dark"] .instructor-calendar .react-calendar .react-calendar__navigation__arrow,
[data-theme-version="dark"] .instructor-calendar .react-calendar .react-calendar__navigation__label__labelText--from {
  color: #fff;
}

[data-theme-version="dark"] .timeline-active .modulel .sub-module {
  background: #20203c;
}

[data-theme-version="dark"] .activity-chart line {
  stroke: #333754;
}

[data-theme-version="dark"] .custome-accordion .accordion-header .acc-heading {
  color: #fff;
}

[data-theme-version="dark"] .sidebar-dropdown {
  color: #fff;
}

[data-theme-version="dark"] .sidebar-dropdown .sidebar-select i {
  color: #fff;
}

[data-theme-version="dark"] .compose-content .dzu-dropzone {
  background-color: #292d4a !important;
}

[data-theme-version="dark"] .compose-content .dzu-dropzone label {
  background-color: #292d4a !important;
}

[data-theme-version="dark"] .form-wizard .nav-link span {
  border: 0.125rem solid var(--primary);
  color: var(--primary);
  background-color: #20203c;
}

[data-theme-version="dark"] .form-wizard .nav-link.active span {
  background: var(--primary);
  color: #fff;
}

[data-theme-version="dark"] .form-wizard .nav-link.active:after {
  background: var(--primary) !important;
}

[data-theme-version="dark"] .tox .tox-toolbar, [data-theme-version="dark"] .tox .tox-toolbar__overflow, [data-theme-version="dark"] .tox .tox-toolbar__primary {
  background-color: #292d4a;
}

[data-theme-version="dark"] .tox .tox-edit-area__iframe {
  background-color: #292d4a;
  color: #fff;
}

[data-theme-version="dark"] .tox .tox-tbtn {
  color: #fff;
}

[data-theme-version="dark"] .tox .tox-tbtn svg {
  fill: #fff;
}

[data-theme-version="dark"] .tox .tox-statusbar {
  background-color: #292d4a;
}

[data-theme-version="dark"] .tox .tox-statusbar__wordcount,
[data-theme-version="dark"] .tox .tox-statusbar__path-item,
[data-theme-version="dark"] .tox .tox-statusbar a {
  color: #fff;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-position="fixed"] .header {
  border-color: #333754;
}

[data-theme-version="dark"] .header {
  border-color: #333754;
}

[data-theme-version="dark"] .header.is-fixed {
  background: rgba(0, 0, 0, 0.5);
}

[data-theme-version="dark"] .header-left .dashboard_bar {
  color: #fff;
}

[data-theme-version="dark"] .search-area .form-control {
  background: #292d4a;
}

[data-theme-version="dark"] .search-area .input-group-text {
  border: 0;
  background: #292d4a;
}

[data-theme-version="dark"] .search-area .input-group-text a {
  color: #4f7086;
}

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link {
  /* background: $d-bg!important; */
}

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link .badge {
  border-color: #292d4a;
}

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link svg path {
  fill: #fff;
}

[data-theme-version="dark"] .header-right .dropdown .nav-link {
  color: #fff;
}

[data-theme-version="dark"] .header-right .dropdown .nav-link:hover {
  color: #fff;
}

[data-theme-version="dark"] .nav-header .hamburger .line {
  background: #fff !important;
}

[data-theme-version="dark"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff !important;
}

[data-theme-version="dark"] .nav-header {
  border-color: #333754;
}

[data-theme-version="dark"] .nav-control {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo:hover {
  color: #fff;
}

[data-theme-version="dark"] .svg-title-path {
  fill: #fff;
}

[data-theme-version="dark"] .nav-header {
  border-color: #333754;
}

[data-theme-version="dark"] .nav-control {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo:hover {
  color: #fff;
}

[data-theme-version="dark"] .svg-title-path {
  fill: #fff;
}

[data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li>ul {
  background-color: #292d4a;
}

[data-theme-version="dark"] .fixed-content-box {
  background-color: #292d4a;
}

[data-theme-version="dark"] .fixed-content-box .head-name {
  background: #292d4a;
  color: #fff;
  border-color: #333754;
}

[data-theme-version="dark"] .fixed-content-box+.header+.dlabnav {
  background-color: #20203c;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-position="fixed"] .dlabnav {
  border-color: #333754;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a, [data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a {
  background: transparent;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a i, [data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a i {
  color: var(--primary);
  background: var(--rgba-primary-1);
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu>li a>i {
  color: rgba(255, 255, 255, 0.3);
}

[data-theme-version="dark"] .dlabnav .header-profile>a.nav-link {
  border-color: #333754;
}

[data-theme-version="dark"] .dlabnav .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-theme-version="dark"] .dlabnav .metismenu>li>a {
  color: #b3b3b3;
}

[data-theme-version="dark"] .dlabnav .metismenu>li:hover>a, [data-theme-version="dark"] .dlabnav .metismenu>li:focus>a, [data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a {
  color: #fff !important;
  /* background:var(--rgba-primary-1); */
}

[data-theme-version="dark"] .dlabnav .metismenu>li:hover>a::after, [data-theme-version="dark"] .dlabnav .metismenu>li:focus>a::after, [data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a::after {
  border-top: 0.3125rem solid #fff;
  border-left: 0.3125rem solid #fff;
  border-bottom: 0.3125rem solid transparent;
  border-right: 0.3125rem solid transparent;
}

[data-theme-version="dark"] .dlabnav .metismenu>li:hover>a i, [data-theme-version="dark"] .dlabnav .metismenu>li:focus>a i, [data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-theme-version="dark"] .dlabnav .metismenu>li.mm-active ul ul {
  background-color: transparent;
}

[data-theme-version="dark"] .dlabnav .metismenu ul:after {
  background-color: #333754;
}

[data-theme-version="dark"] .dlabnav .metismenu ul a:hover, [data-theme-version="dark"] .dlabnav .metismenu ul a:focus, [data-theme-version="dark"] .dlabnav .metismenu ul a.mm-active {
  color: #fff !important;
}

[data-theme-version="dark"] .dlabnav .metismenu ul a:before {
  background-color: #fff;
}

[data-theme-version="dark"] .dlabnav .metismenu a {
  color: #b3b3b3 !important;
}

[data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after {
  border-top: 0.3125rem solid #b3b3b3;
  border-left: 0.3125rem solid #b3b3b3;
  border-bottom: 0.3125rem solid transparent;
  border-right: 0.3125rem solid transparent;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs {
  border-color: var(--rgba-primary-1) !important;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link {
  color: #000 !important;
}

[data-theme-version="dark"] .sidebar-right .form-control {
  background: #fff;
  color: #000;
  border-color: #EEEEEE;
}

[data-theme-version="dark"] .sidebar-right .default-select .list {
  background: #fff;
}

[data-theme-version="dark"] .sidebar-right .default-select .list .option.selected, [data-theme-version="dark"] .sidebar-right .default-select .list .option.focus, [data-theme-version="dark"] .sidebar-right .default-select .list .option:hover {
  background: rgba(0, 0, 0, 0.05) !important;
}

[data-theme-version="dark"] .sidebar-right .sidebar-right-inner>h4, [data-theme-version="dark"] .sidebar-right .sidebar-right-inner>.h4 {
  color: #000 !important;
}

[data-theme-version="dark"] .sidebar-right .nice-select .option {
  background: #fff;
}

[data-theme-version="dark"] .footer {
  background-color: transparent;
}

[data-theme-version="dark"] .footer .copyright {
  background-color: transparent;
}

[data-theme-version="dark"] .footer .copyright p {
  color: #fff;
}

